import * as React from "react";
import {
    Alert, Badge, Button, Col, Container, FormControl,
    OverlayTrigger, Row, Table, Tooltip
} from "react-bootstrap";
import {
    KafkaEvents, kafkaInstance, OptionOIS, Source, SourceLayer
} from "../Interfaces/IKafkaEvents";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
import { listModuleInstances } from "../../../BackendFacade/moduleCalls";
import { IListStaticModule } from "../../../BackendFacade/IStaticModules";
import { IModule } from "../../../BackendFacade/IModuleInstances";
import { getEventLogs } from "../BackendFacade/apiCalls";
import { NetworkData } from "../../../BackendFacade";
import { sortListByKey } from "../../../Utils/sorting";
import { convertListOfStringsToOptionsList, deepCopy } from "../../../Utils/transformer";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import styles from "../Dams.module.css";
import { GlobalDictionary } from "../../../Utils/globalDictionary";
import { calcTables, fixModuleName } from "../Utils/eventEvaluation";
import { GetAccessRoles } from "../../Hub/BackendFacade/apiCalls";
import { isUserroleSufficient } from "../../../Utils/authorization";
import { UserRoles } from "../../../Utils";
import { useEffect } from "react";
import { useMain } from "../../../Utils/SessionControls/mainContext";

const animatedComponents = makeAnimated();

const KafkaEventsPage: React.FC = () => {
    const [from, setFrom] = React.useState<string>(
        new Date(new Date().setDate(new Date().getDate() - 2))
            .toISOString().substring(0, 10)
    );
    const [to, setTo] = React.useState<string>(new Date().toISOString().substring(0, 10));
    const [currentlyShownTableLayer, setCurrentlyShownTableLayer] = React.useState<SourceLayer>({} as SourceLayer);
    const [layerHistory, setLayerHistory] = React.useState<SourceLayer[]>([]);
    const [showTree, setShowTree] = React.useState<boolean>(true);
    const [errors, setErrors] = React.useState<any[]>([]);
    const [maxShownEvents, setMaxShownEvents] = React.useState<OptionOIS>({ value: "1000", label: "1000" });
    const [noOfLoadingEvents, setNoOfLoadingEvents] = React.useState<number>(0);
    const [finishedLoadingEvents, setFinishedLoadingEvents] = React.useState<number>(0);
    const [excludedSources, setExcludedSources] = React.useState<OptionOIS[]>([]);
    const [logLevelFilter, setLogLevelFilter] = React.useState<OptionOIS[]>([]);
    const [kafkaTopics, setKafkaTopics] = React.useState<string[]>([]);
    const [filterEdited, setFilterEdited] = React.useState<boolean>(false);
    const [knownUsers, setKnownUsers] = React.useState<Record<string, string>>({});
    const [sortBy, setSortBy] = React.useState<string>("Time");
    const [sortDescend, setSortDescend] = React.useState<boolean>(true);
    const [baseLayer, setBaseLayer] = React.useState<SourceLayer | undefined>(undefined);

    const { setLoading, popupCreate } = useMain();

    useEffect(() => {
        const savedState = GlobalDictionary.get(getGlobalDictionaryId());
        if (savedState) {
            loadState(savedState);
        } else {
            GlobalDictionary.getProjects().then(() => {
                getAllData();
            });
        }
    }, []);

    useEffect(() => {
        if (baseLayer) {
            calculateFilteredTable(baseLayer, logLevelFilter, excludedSources);
        }
    }, [from, to, maxShownEvents]);

    const getGlobalDictionaryId = (): string => {
        let id = "kafkaEvents";
        if (HubPathRouting.currentProject) {
            id += HubPathRouting.currentProject.moduleInstanceId;
        }
        if (HubPathRouting.currentModule) {
            id += HubPathRouting.currentModule.moduleInstanceId;
        }
        return id;
    };

    const getAllData = async () => {
        setLoading(true);

        const kafkaInstances = await prepareKafkaInfos();
        const filteredKafkaInstances = kafkaInstances.filter(
            instance => !excludedSources.some(source => source.value === instance.eventLogTopicName)
        );

        setNoOfLoadingEvents(filteredKafkaInstances.length);
        setKafkaTopics(kafkaInstances.map(instance => instance.eventLogTopicName));

        const promises: Promise<void | NetworkData>[] = [];
        let events: KafkaEvents[] = [];
        const currentProjectId = HubPathRouting.currentProject ? HubPathRouting.currentProject.moduleInstanceId : "null";

        filteredKafkaInstances.forEach(instance => {
            promises.push(
                getEventLogs(
                    currentProjectId,
                    instance.eventLogTopicName,
                    instance.linkedId,
                    {
                        startDay: convertDateToDayOfYear(from),
                        startYear: convertDateToYear(from),
                        endDay: convertDateToDayOfYear(to),
                        endYear: convertDateToYear(to),
                    },
                    null,
                ).then(data => {
                    if (data.success) {
                        events = events.concat(data.data.value);
                    } else {
                        setErrors(prevErrors => [...prevErrors, {
                            kafkaTopic: instance.eventLogTopicName,
                            message: data.message
                        }]);
                    }
                    setFinishedLoadingEvents(prev => prev + 1);
                })
            );
        });

        await Promise.all(promises);

        const layers = calcTables(events);
        setBaseLayer(layers);
        calculateNumberOfEventsTree(layers);

        setCurrentlyShownTableLayer(layers);
        setLayerHistory([]);

        await new Promise(r => setTimeout(r, 1));

        calculateFilteredTable(layers, logLevelFilter, excludedSources);

        if (layers.layer.length > 0) {
            sortByKey(sortBy, layers.layer[0].mergedSources, sortDescend);
        }

        await new Promise(r => setTimeout(r, 1));
        saveState({
            from,
            to,
            baseLayer: layers,
            currentlyShownTableLayer: layers,
            showTree,
            errors,
            maxShownEvents,
            noOfLoadingEvents,
            finishedLoadingEvents,
            excludedSources,
            logLevelFilter,
            kafkaTopics,
            filterEdited,
            knownUsers,
            sortBy,
            sortDescend
        });
        setLoading(false);
    };

    const saveState = (state: any) => {
        GlobalDictionary.set(getGlobalDictionaryId(), state);
    };

    const loadState = (state: any) => {
        setFrom(state.from);
        setTo(state.to);
        setBaseLayer(state.baseLayer);
        setCurrentlyShownTableLayer(state.currentlyShownTableLayer);
        setShowTree(state.showTree);
        setErrors(state.errors);
        setMaxShownEvents(state.maxShownEvents);
        setNoOfLoadingEvents(state.noOfLoadingEvents);
        setFinishedLoadingEvents(state.finishedLoadingEvents);
        setExcludedSources(state.excludedSources);
        setLogLevelFilter(state.logLevelFilter);
        setKafkaTopics(state.kafkaTopics);
        setFilterEdited(state.filterEdited);
        setKnownUsers(state.knownUsers);
        setSortBy(state.sortBy);
        setSortDescend(state.sortDescend);
        setLayerHistory([]);
        calculateFilteredTable(state.baseLayer, state.logLevelFilter, state.excludedSources);
    };

    const getModuleAndKafkaInstances = async (staticModules: IListStaticModule[]): Promise<kafkaInstance[]> => {
        let moduleInstances: IModule[];
        let kafkaInstances: kafkaInstance[];

        if (HubPathRouting.currentProject) {
            moduleInstances = await GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES);
            if (!moduleInstances) {
                await listModuleInstances(HubPathRouting.currentProject.moduleInstanceId).then(data => {
                    GlobalDictionary.set(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES, data.data.value);
                    moduleInstances = data.data.value;
                });
            }

            if (HubPathRouting.currentModule) {
                moduleInstances = moduleInstances.filter(
                    instance => instance.moduleInstanceId === HubPathRouting.currentModule.moduleInstanceId
                );
            }

            kafkaInstances = moduleInstances.map(instance => {
                const staticModule = staticModules.find(
                    staticModule => staticModule.staticModuleId === instance.staticModuleId &&
                        staticModule.eventLogTopicName !== null
                );
                if (!staticModule) return undefined;
                return {
                    linkedId: instance.linkedId.projectId,
                    eventLogTopicName: staticModule.eventLogTopicName
                };
            }).filter(instance => instance !== undefined) as kafkaInstance[];

        } else {
            const projects = await GlobalDictionary.getProjects();
            moduleInstances = (await Promise.all(projects.map(project => {
                return listModuleInstances(project.projectId).then(data => {
                    if (data.success) {
                        return data.data.value.map((x: IModule) => ({ ...x, projectId: project.projectId }));
                    } else {
                        popupCreate("Error", data.message);
                        return [];
                    }
                });
            }))).flat();

            kafkaInstances = staticModules.map(staticModule => {
                if (!staticModule.eventLogTopicName) return undefined;
                return {
                    linkedId: null,
                    eventLogTopicName: staticModule.eventLogTopicName
                };
            }).filter(instance => instance !== undefined) as kafkaInstance[];
        }

        GlobalDictionary.set("kafkaModuleInstances", moduleInstances);
        return kafkaInstances;
    };

    const prepareKafkaInfos = async (): Promise<kafkaInstance[]> => {
        const staticModules: IListStaticModule[] = await GlobalDictionary.getStaticModules();
        await getAllUsers();
        const kafkaInstances: kafkaInstance[] = await getModuleAndKafkaInstances(staticModules);

        if (!HubPathRouting.currentModule) {
            if (window.location.href.includes("stage") || window.location.href.includes("localhost")) {
                kafkaInstances.push({
                    linkedId: HubPathRouting.currentProject ? HubPathRouting.currentProject.moduleInstanceId : null,
                    eventLogTopicName: "devsecops.hub-events.stage.v1.json"
                });
            } else {
                kafkaInstances.push({
                    linkedId: HubPathRouting.currentProject ? HubPathRouting.currentProject.moduleInstanceId : null,
                    eventLogTopicName: "devsecops.hub-events.prod.v1.json"
                });
            }
        }
        return kafkaInstances;
    };

    const getAllUsers = async () => {
        let users: any;
        if (!HubPathRouting.currentProject || isUserroleSufficient(UserRoles.reader)) {
            if (!GlobalDictionary.get("usersnull")) {
                await getUserData();
            }
            users = GlobalDictionary.get("usersnull");
        } else {
            if (!GlobalDictionary.get("users" + HubPathRouting.currentProject.moduleInstanceId)) {
                await getUserData(HubPathRouting.currentProject.moduleInstanceId);
            }
            users = GlobalDictionary.get("users" + HubPathRouting.currentProject.moduleInstanceId);
        }
        users.forEach((key: any) => {
            if (key.mail == null) {
                return
            }
            knownUsers[key.userId] = key.mail;
        });
        setKnownUsers({ ...knownUsers });
        await new Promise(r => setTimeout(r, 1));
    };

    const getUserData = async (project: string = "null") => {
        await GetAccessRoles(project).then(data => {
            if (data.success) {
                GlobalDictionary.set("users" + project, data.data.value);
            } else {
                popupCreate("Error", "Error getting user data " + data.message);
            }
        });
    };

    const convertDateToDayOfYear = (dateString: string): number => {
        const date = new Date(dateString);
        const start = new Date(date.getFullYear(), 0, 0);
        const diff = (date.getTime() - start.getTime()) +
            ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
        const oneDay = 1000 * 60 * 60 * 24;
        return Math.floor(diff / oneDay);
    };

    const convertDateToYear = (dateString: string): number => {
        const date = new Date(dateString);
        return date.getFullYear();
    };

    const resetToolbar = () => {
        setFrom(new Date(new Date().setDate(new Date().getDate() - 14)).toISOString().substring(0, 10));
        setTo(new Date().toISOString().substring(0, 10));
        setLogLevelFilter([]);
        setExcludedSources([]);
        setFilterEdited(false);
    };

    const displayEventCounters = (layer: SourceLayer) => {
        const info = layer.numberOfInfoEvents > 0 ? <Badge pill bg={"secondary"}>{layer.numberOfInfoEvents}</Badge> : null;
        const warning = layer.numberOfWarningEvents > 0 ? <Badge pill bg={"warning"}>{layer.numberOfWarningEvents}</Badge> : null;
        const error = layer.numberOfErrorEvents > 0 ? <Badge pill bg={"danger"}>{layer.numberOfErrorEvents}</Badge> : null;
        return <>
            {info} {warning} {error}
        </>;
    };

    const sortByKey = (key: string, source: Source, descend = false) => {
        if (!source) return;
        source.SortDescend = !source.SortDescend || descend;
        source.KafkaEvents = sortListByKey(source.KafkaEvents, key, source.SortDescend);
        source.SortbyKey = key;
        setSortBy(key);
        setSortDescend(descend);
    };

    const showSortIcon = (key: string, source: Source) => {
        if (key === source.SortbyKey) {
            return source.SortDescend ? <i className="uib-icon--arrow1-down uib-icon"></i> : <i className="uib-icon--arrow1-up uib-icon"></i>;
        }
    };

    const calculateNumberOfEventsTree = (layer: SourceLayer) => {
        layer.numberOfErrorEvents = 0;
        layer.numberOfWarningEvents = 0;
        layer.numberOfInfoEvents = 0;

        if (layer.source !== undefined) {
            layer.numberOfErrorEvents = layer.source.KafkaEvents.filter(event => event.LogLevel === "Error" && event.show).length;
            layer.numberOfWarningEvents = layer.source.KafkaEvents.filter(event => event.LogLevel === "Warning" && event.show).length;
            layer.numberOfInfoEvents = layer.source.KafkaEvents.filter(event => event.LogLevel === "Info" && event.show).length;
        }

        layer.layer.forEach(child => {
            calculateNumberOfEventsTree(child);
            layer.numberOfErrorEvents += child.numberOfErrorEvents;
            layer.numberOfWarningEvents += child.numberOfWarningEvents;
            layer.numberOfInfoEvents += child.numberOfInfoEvents;
        });
    };

    const triggerShow = (newLayer: SourceLayer) => {
        const currentlyShownTableLayerCopy = deepCopy(currentlyShownTableLayer);
        const updatedLayerHistory = [...layerHistory, currentlyShownTableLayerCopy];

        setLayerHistory(updatedLayerHistory);
        setCurrentlyShownTableLayer(newLayer);
    };

    const goBack = () => {
        const layerHistoryLocal = deepCopy(layerHistory);
        const parent = layerHistoryLocal.pop();
        setCurrentlyShownTableLayer(parent);
        setLayerHistory(layerHistoryLocal);
    };

    const triggerArrow = (layer: SourceLayer) => {
        layer.show = !layer.show;
    };

    const showArrow = (layer: SourceLayer) => {
        if (layer.layer.length > 0) {
            return layer.show ?
                <div className={styles.arrowParent} onClick={event => {
                    event.stopPropagation();
                    triggerArrow(layer);
                }}>
                    <i className={`uib-icon--arrow1-down uib-icon ${styles.arrow}`} />
                </div>
                :
                <div className={styles.arrowParent} onClick={event => {
                    event.stopPropagation();
                    triggerArrow(layer);
                }}>
                    <i className={`uib-icon--arrow1-right uib-icon ${styles.arrow}`} />
                </div>;
        }
        return <i className={`uib-icon--arrow1-right uib-icon ${styles.arrow} ${styles.colorWhite}`} />;
    };

    const ShowSourceTree = (layer: SourceLayer): React.ReactNode => {
        return layer.layer.map((childLayer, index) => (
            <React.Fragment key={childLayer.name + index}>
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 200, hide: 50 }}
                    overlay={<Tooltip id="tooltip-key">{childLayer.name}</Tooltip>}
                >
                    <li className={`${childLayer === currentlyShownTableLayer ? styles.selectedSource : styles.clickableSource}`}
                        onClick={() => triggerShow(childLayer)}>
                        <div className={`${styles.sourceItem} row`}>
                        <span className={styles.sourceItemCol}>
                            {showArrow(childLayer)}
                            {fixModuleName(childLayer.name)}
                        </span>
                            <span className={styles.sourceItemLoglevelNumbers}>{displayEventCounters(childLayer)}</span>
                        </div>
                    </li>
                </OverlayTrigger>
                {childLayer.show && ShowSourceTree(childLayer)}
            </React.Fragment>
        ));
    };

    const showTableHeads = (layer: SourceLayer): React.ReactElement => {
        const theads: React.ReactElement[] = [];

        if (layer.mergedSources !== undefined && layer.mergedSources.KafkaEvents.length !== 0) {
            layer.mergedSources.tableHeads.forEach((key, layer2) => {
                if (layer.mergedSources.hiddenColumns.indexOf(key) === -1) {
                    theads.push(<th key={layer2 - 100} onClick={() => { sortByKey(key, layer.mergedSources) }}>
                        {key.replace("Detail_", "")} {showSortIcon(key, layer.mergedSources)}
                    </th>);
                }
            });
        }

        return <thead className={`uib-table__header ${styles.tableHead}`}>
        <tr className="uib-table__row uib-table__row--header">
            {theads}
        </tr>
        </thead>;
    };

    const showTableRows = (layer: SourceLayer): React.ReactNode => {
        if (!layer.mergedSources) {
            return null;
        }

        return layer.mergedSources.KafkaEvents.filter(event => event.show).map((event, index) => {
            if (index >= +maxShownEvents.value) {
                return null;
            }

            return (
                <tr className="uib-table__row" key={event.Time + index} data-event-id={event.Id}>
                    {layer.mergedSources.tableHeads.map((key, headIndex) => {
                        if (layer.mergedSources.hiddenColumns.indexOf(key) === -1) {
                            return (
                                <td
                                    className={event.LogLevel === "Warning" ? styles.warning :
                                        event.LogLevel === "Error" ? styles.danger : ""}
                                    key={key + headIndex}
                                >
                                    {formatTableCell(key, event)}
                                </td>
                            );
                        }
                        return null;
                    })}
                </tr>
            );
        });
    };


    const filterTableRecursive = (layer: SourceLayer, logLevelFilter: OptionOIS[], excludedSources: OptionOIS[]) => {
        layer.layer.forEach(child => {
            filterTableRecursive(child, logLevelFilter, excludedSources);
        });

        const toDate = new Date(to);
        toDate.setHours(23, 59, 59, 999);

        if (layer.mergedSources !== undefined) {
            layer.mergedSources.KafkaEvents.forEach((event, index) => {
                const isWithinDateRange = new Date(from) <= new Date(event.Time) && toDate >= new Date(event.Time);
                const isNotExcluded = excludedSources.length === 0 ||
                    !excludedSources.some(source => source.value === event.Source);
                const matchesLogLevel = logLevelFilter.length === 0 ||
                    logLevelFilter.some(level => level.value === event.LogLevel);

                event.show = isWithinDateRange && isNotExcluded && matchesLogLevel;
            });
        }
    };

    const calculateFilteredTable = (baseLayer: SourceLayer | undefined, logLevelFilter: OptionOIS[], excludedSources: OptionOIS[]) => {
        if (!baseLayer) {
            return;
        }
        const newBaseLayer = deepCopy(baseLayer);
        filterTableRecursive(newBaseLayer, logLevelFilter, excludedSources);
        setBaseLayer(newBaseLayer);
        setCurrentlyShownTableLayer(newBaseLayer);
    };

    const formatTableCell = (key: string, event: KafkaEvents) => {
        switch (key) {
            case "Time":
                return new Date(event[key]).toLocaleString();
            case "Subject":
                if (knownUsers && knownUsers[event[key]]) {
                    return knownUsers[event[key]];
                }
                return event[key];
            default:
                return event[key];
        }
    };

    const renderBreadcrumbs = (): React.ReactNode => {
        const fullBreadcrumbTrail = [...layerHistory, currentlyShownTableLayer];

        return (
            <div className="breadcrumbs">
                {fullBreadcrumbTrail.map((layer, index) => (
                    <React.Fragment key={index}>
                        {index < fullBreadcrumbTrail.length - 1 ? (
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                navigateToLayer(index);
                            }}>
                                {fixModuleName(layer.name)}
                            </a>
                        ) : (
                            <span>{fixModuleName(layer.name)}</span>
                        )}
                        {index < fullBreadcrumbTrail.length - 1 && ' > '}
                    </React.Fragment>
                ))}
            </div>
        );
    };

    const navigateToLayer = (index: number) => {
        const newLayerHistory = layerHistory.slice(0, index + 1);
        const newCurrentlyShownLayer = newLayerHistory.pop();

        setCurrentlyShownTableLayer(newCurrentlyShownLayer);
        setLayerHistory(newLayerHistory);
    };

    const handleSave = () => {
        calculateFilteredTable(baseLayer, logLevelFilter, excludedSources);
        getAllData();
    };

    const filterbar = (): React.ReactElement => {
        return (
            <div className={`form-group row ${styles.noFlexWrap}`}>
                <div className={`col col-md-auto ${styles.RButtons}`}>
                    <label className="col col-form-label"></label>
                    <div className="col">
                        <Button
                            className={`${styles.TextButton} ${styles.ToolbarHeightElement} uib-button uib-button--primary`}
                            id={"toggleTree"}
                            onClick={() => {
                                setShowTree(!showTree);
                            }}
                        >
                            {showTree ? <>Hide Tree</> : <>Show Tree</>}
                        </Button>
                    </div>
                </div>
                <div className="col col-md-auto">
                    <label className="col col-form-label">From</label>
                    <div className="col">
                        <FormControl
                            id="dateFrom"
                            type="date"
                            name="dateFrom"
                            max="3000-12-31"
                            value={from}
                            min="2021-01-01"
                            className={`form-control datepicker ${styles.ToolbarHeightElement}`}
                            onChange={(event) => {
                                setFrom(event.target.value);
                                setFilterEdited(true);
                            }}
                        />
                    </div>
                </div>
                <div className="col col-md-auto">
                    <label className="col col-form-label">Until</label>
                    <div className="col">
                        <FormControl
                            id="dateUntil"
                            type="date"
                            name="dateUntil"
                            max="3000-12-31"
                            value={to}
                            min="2021-01-01"
                            className={`form-control datepicker ${styles.ToolbarHeightElement}`}
                            onChange={(event) => {
                                setTo(event.target.value);
                                setFilterEdited(true);
                            }}
                        />
                    </div>
                </div>
                <div className="col col-md-auto">
                    <label className="col col-form-label">Log Level</label>
                    <div className="col">
                        <Select
                            closeMenuOnSelect={false}
                            className={styles.statePicker}
                            components={animatedComponents}
                            placeholder="Select log levels"
                            isMulti
                            options={convertListOfStringsToOptionsList(["Info", "Warning", "Error"])}
                            onChange={(option: OptionOIS[]) => {
                                setLogLevelFilter(option);
                                setFilterEdited(true);
                            }}
                            value={logLevelFilter}
                            id={"filterByLoglevel"}
                        />
                    </div>
                </div>
                <div className="col col-md-auto">
                    <label className="col col-form-label">Exclude by Topic</label>
                    <div className="col">
                        <Select
                            closeMenuOnSelect={false}
                            className={styles.statePicker}
                            components={animatedComponents}
                            placeholder="Select topics to exclude"
                            isMulti
                            options={convertKafkaTopicsToOptionsList(kafkaTopics)}
                            onChange={(option: OptionOIS[]) => {
                                setExcludedSources(option);
                                setFilterEdited(true);
                            }}
                            value={excludedSources}
                            id={"filterByTopic"}
                        />
                    </div>
                </div>
                <div className={`col col-md-auto ${styles.RButtons}`}>
                    <label className="col col-form-label"></label>
                    <div className="col">
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 150, hide: 200 }}
                            overlay={<Tooltip id="tooltip-disabled">Reset filters</Tooltip>}
                        >
                            <Button
                                className={`uib-button uib-button--primary uib-button--square ${styles.resetButton} ${styles.ButtonLogo} ${styles.ToolbarHeightElement}`}
                                id={"resetToolbar"}
                                onClick={() => {
                                    resetToolbar();
                                    calculateFilteredTable(baseLayer, [], []);
                                }}
                            >
                                <i className={`uib-icon uib-icon--settings-leave ${styles.logo}`}></i>
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 150, hide: 200 }}
                            overlay={<Tooltip id="tooltip-disabled">Save the current date + filters and reload</Tooltip>}
                        >
                            <Button
                                className={`uib-button uib-button--primary uib-button--square ${styles.ButtonLogo} ${styles.ToolbarHeightElement} ${filterEdited ? styles.warning : ""}`}
                                id={"saveAndRefresh"}
                                onClick={handleSave}
                            >
                                <i className={`uib-icon uib-icon--save ${styles.logo}`}></i>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="col col-md-auto">
                    <label className="col col-form-label">Maximal shown events</label>
                    <div className="col">
                        <Select
                            closeMenuOnSelect={true}
                            className={styles.statePicker}
                            components={animatedComponents}
                            placeholder=""
                            id={"maxShownEvents"}
                            options={[
                                { value: "1000", label: "1000" },
                                { value: "10000", label: "10000" },
                                { value: "10000000", label: "All" }
                            ]}
                            onChange={(option: OptionOIS) => {
                                setMaxShownEvents(option);
                                setFilterEdited(true);
                            }}
                            value={maxShownEvents}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const convertKafkaTopicsToOptionsList = (kafkaTopics: string[]): OptionOIS[] => {
        return kafkaTopics.map(topic => ({
            value: topic,
            label: topic.replace("devsecops.", "").replace(".v1.json", "")
        }));
    };

    const showErrorMessages = (): React.ReactElement | null => {
        if (errors.length > 0) {
            return <Alert variant={"danger"} id={"alerts"}>
                {errors.map((error, key) => (
                    <div key={key}>{error.kafkaTopic}: {error.message}</div>
                ))}
            </Alert>;
        }
        return null;
    };

    if (!baseLayer) {
        return (
            <>
                <div>Loading... {finishedLoadingEvents} of {noOfLoadingEvents} finished</div>
                <div>{finishedLoadingEvents === noOfLoadingEvents && noOfLoadingEvents !== 0 ? "Sorting" : ""}</div>
            </>
        );
    }

    return (
        <div className={styles.ScrollPage}>
            {showErrorMessages()}
            {filterbar()}
            {renderBreadcrumbs()}
            <Container className={styles.DamsContainer} fluid>
                <Row lg={2} className={styles.DamsContainerInner}>
                    {showTree &&
                        <Col md={"4"} className={styles.SourceMenu}>
                            <ul className={`uib-list ${styles.sourceOverview}`} id={"treeSource"}>
                                {layerHistory.length > 0 &&
                                    <li className={styles.clickableSource} onClick={goBack}>
                                        <div className={`${styles.sourceItem} row`}>
                                            <span className={styles.sourceItemCol}>
                                                <i className={`uib-icon--arrow1-left uib-icon ${styles.arrow}`} /> Go Back
                                            </span>
                                        </div>
                                    </li>
                                }
                                {ShowSourceTree(currentlyShownTableLayer)}
                            </ul>
                        </Col>
                    }
                    <div className={showTree ? styles.DamsTableOuter : styles.DamsTableOuterWithoutTree}>
                        <Table className={`uib-table uib-table--striped ${styles.DamsTable}`} id={"logsTable"}>
                            {showTableHeads(currentlyShownTableLayer)}
                            <tbody className="uib-table__body">
                            {showTableRows(currentlyShownTableLayer)}
                            </tbody>
                        </Table>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default KafkaEventsPage;
