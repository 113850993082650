export interface IKafkaEvents {
    Callback: () => void,

    layer: SourceLayer
    currentlyShownTableLayer: SourceLayer,

    // toolbar
    State: OptionOIS[],
    From: string,
    To: string,
    showTree: boolean,
    errors: IError[],
    maxShownEvents: OptionOIS,
    noOfLoadingEvents: number,
    finishedLoadingEvents: number,
    excludedSources: OptionOIS[],
    kafkaTopics: string[],

    FilterEdited: boolean,
    knownUsers: IUserList,

    sortBy: string,
    sortDescend: boolean,

    maxAmount: number,
}
export interface IUserList {
    [key: string]: string,
}

export interface IError {
    message: string,
    kafkaTopic: string,
}

export interface KafkaEvents {
    Id: string,         // hidden
    LogLevel: string,
    Source: string,
    Subject: string,
    Time: string,
    Type: string,
    show: boolean,
    [key: string]: string | boolean,
}

export interface Source {
    KafkaEvents: KafkaEvents[],
    tableHeads: string[],
    hiddenColumns: string[],
    SortbyKey: string,
    SortDescend: boolean,
    show: boolean;
}

export interface SourceLayer {
    name: string,
    layer: SourceLayer[],
    source?: Source,
    mergedSources?: Source,
    show: boolean;

    //shownLogLevel: Loglevel,
    numberOfInfoEvents: number,
    numberOfWarningEvents: number,
    numberOfErrorEvents: number,
}

export enum Loglevel {
    Info,
    Warning,
    Error,
}

export interface OptionOIS {
    value: string,
    label: string,
}

export interface IDateFilter {
    startYear: number,
    startDay: number,
    endYear: number,
    endDay: number,
}

export interface kafkaInstance {
    linkedId: string,
    eventLogTopicName: string,
}