import * as React from "react";
import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";


const Module: React.FC = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        window.location.assign("https://app.sendgrid.com/login");
    }, []);

    return <></>;
};

export default Module;