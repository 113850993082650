import * as React from "react";
import {filterState, IUsers, UserData, UserManagement, UsersDic} from "../Interfaces/IUsers";
import {
    ConfigEditData,
    Popup,
    ScopeOverview,
    SearchBy,
    TenantLine,
    UsersEditMode
} from "../Interfaces/interfaces";
import {
    Button,
    Col,
    Form,
    FormControl,
    Modal,
    OverlayTrigger,
    Row,
    Table,
    Tooltip
} from "react-bootstrap";
import Select from "react-select";
import {DisableTenantManagement, ScopeAny, ValueAny} from "../constants";
import {getUserDataCall, sendUsersEditDataCall} from "../BackendFacade/apiCalls";
import makeAnimated from "react-select/animated";
import {Module} from "../module";
import {IconDelete, IconEdit} from "../../../Assets/svgs";
import {convertIntToDate, deepCopy} from "../../../Utils/transformer";
import AddEditUserModal from "./Modals/UsersTab_AddEdit";
import {IAddEditUserModal} from "../Interfaces/IAddEditUserModal";
import styles from './../IDMS.module.css';
import {isUserroleSufficient} from "../../../Utils/authorization";
import {UserRoles} from "../../../Utils";
import {HubPathRouting} from "../../../HubFramework/pathBuilder";
import {ReactElement, useEffect} from "react";
import {GlobalTenantSystemContent, TenantSystemContent} from "../Utils/tenantSystem";
import {isValidEmail} from "../../../Utils/checker";
import {useMain} from "../../../Utils/SessionControls/mainContext";
import {useOutletContext} from "react-router-dom";

const animatedComponents = makeAnimated()

const UsersTab: React.FC = () => {

    const [Sort_Key, setSort_Key] = React.useState("authLevel");
    const [Sort_Reverse, setSort_Reverse] = React.useState(false);
    const [filterBy, setFilterBy] = React.useState(SearchBy.Include);
    const [filterState, setFilterState] = React.useState({} as filterState);
    const [filterState_oldTen, setFilterState_oldTen] = React.useState([]);
    const [filterValueState, setFilterValueState] = React.useState({} as filterState);
    const [listOfScopes, setListOfScopes] = React.useState([]);
    const [IDFilter, setIDFilter] = React.useState("");
    const [Selected_Key, setSelected_Key] = React.useState("");
    const [ShowIDFilter, setShowIDFilter] = React.useState(false);
    const [listOfProjectSubscopes, setListOfProjectSubscopes] = React.useState([]);
    const [tenantSystem, setTenantSystem] = React.useState([]);
    const [userData, setUserData] = React.useState<UsersDic>({});
    const [popup, setPopup] = React.useState<Popup>(Popup.None);
    const [AddEditModal, setAddEditModal] = React.useState(undefined);
    const [PopupContent, setPopupContent] = React.useState("");

    const { setLoading, popupCreateSingle, popupCreate} = useMain();

    const parentModule = useOutletContext() as Module;

    useEffect(() => {
        setLoading(true);
        const prom1 = getUserData();
        const prom2 = parentModule.getConfigData();
        Promise.all([prom1, prom2]).then(() => {
            setLoading(false);
        });
    }, []);

    const getUserData = async () => {
        // this.setState({userData: {}, Popup: Popup.None})
        setUserData({});
        setPopup(Popup.None);
        resetFilter()
        if(HubPathRouting.currentProject === undefined || HubPathRouting.currentModule === undefined) {
            HubPathRouting.setProject()
            HubPathRouting.setModule()
        }
        await getUserDataCall(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId).then((data) => {
            if(!data.success) {
                popupCreate("Error", data.message);
            } else {
                // convert list of users to dictionary
                let userData: UsersDic = {};
                (data.data.value as UserData[]).forEach((user) => {
                    userData[user.id] = user
                    userData[user.id].tenantSystem = convertAuthlevelToTenantsystem(user.authLevel)
                })

                let scopesOverview = createListOfSubScopes(userData as UsersDic)
                setUserData(userData);
                setListOfProjectSubscopes(scopesOverview.subscopelist);
                setListOfScopes(scopesOverview.scopelist);
            }
        })
    }

    /**
     * Creates a list of all subscopes
     */
    const createListOfSubScopes = (userData: UsersDic): ScopeOverview => {
        let scopeList : string[] = []
        let subScopes : string[] = []
        let userKeys = Object.keys(userData)
        userKeys.forEach((key) => {
            let tenantLines = userData[key].tenantSystem
            tenantLines.forEach((tenant) => {
                tenant.scopes.forEach((subscope) => {
                    if(subScopes.indexOf(subscope) === -1)
                        subScopes.push(subscope)
                })
                if(scopeList.indexOf(tenant.scopes.join('/')) === -1)
                    scopeList.push(tenant.scopes.join('/'))
            })
        })
        return {scopelist: scopeList, subscopelist: subScopes}
    }

    /**
     * Displays the values if the
     * @param col key of the user
     * @returns
     */
    const convertUsersValueToButtonOrText = (col: string) : ReactElement | string => {
        let value = userData[col].authLevel
        if(value === undefined)
            return ""
        if(value.indexOf('{') !== -1 && parentModule.state.AppConfig.DisableTenantManagement === "true") {
            return <Button variant="outline-danger" className={styles.details} disabled >Wrong Config</Button>
        } else if(value.indexOf('{') !== -1) {
            return <Button variant="outline-primary" className={styles.details} id={"details"} onClick={() => Details_show(col)}>Details</Button>
        } else if(value.indexOf('{') === -1 && parentModule.state.AppConfig.DisableTenantManagement !== "true") {
            return <Button variant="outline-danger" className={styles.details} disabled >Wrong Config</Button>
        } else {
            return value
        }
    }

    /**
     * Displays the details of the tenant config
     * @param key
     * @constructor
     */
    const Details_show = (key: string) => {
        const globalId = userData[key].tenantSystem.find(x => x.scopes.length === 1 && x.scopes.every(
            (value, index) => value === ["user"][index]
        )).id

        const header = `Details for ${key} ${isValidEmail(key) ?
            "(no key available)" : "(" + userData[key].mail + ")"}`
        const tenantSystem = userData[key].tenantSystem
        const content = <>
            <GlobalTenantSystemContent tenantSystem={tenantSystem} globalId={globalId} />
            <TenantSystemContent tenantSystem={tenantSystem} global={false} />
        </>
        popupCreateSingle(header, content)
    }

    /**
     * prepare user add/edit popup config
     * @param key key of the user, empty for a new user
     */
    const EditEntry_show = (key: string = "")  => {
        let AddEdit: IAddEditUserModal = {
            appConfig: parentModule.state.AppConfig,
            authLevels: parentModule.state.AuthLevels,
            editMode: undefined,
            userData: userData,
            tenantSystem: undefined,
            emailList: [],
            selectedKey: key,
            selectedValue: undefined,
            listOfProjectSubscopes: listOfProjectSubscopes,
            oldSelectedKey: key,
        }
        // new Configline or edited to empty category
        if(userData[key] === undefined) {
            AddEdit.editMode = UsersEditMode.Add
            AddEdit.selectedValue = {authLevel: "", lastLogin: 0, mail: ""}
            AddEdit.tenantSystem = [{id: 0, scopes: ["user"], value: "", tmp: "", fullscope: "user"}]
        } else {
            // convert config entry to new tenant system
            let scopes: TenantLine[] = []
            if(parentModule.state.AppConfig[DisableTenantManagement] !== "true"){
                scopes = convertAuthlevelToTenantsystem(userData[key].authLevel)
            }
            // if the user is new and hasn't logged in so far, edit his pre-profile
            if(isValidEmail(key)) {
                // eslint-disable-next-line react/no-direct-mutation-state -- valid, state is set in next line
                userData[key].mail = key
            }

            AddEdit.editMode = UsersEditMode.Edit
            AddEdit.selectedValue = deepCopy(userData[key])
            AddEdit.tenantSystem = scopes
        }
        
        setPopup(Popup.Users_AddEdit);
        setSelected_Key(key);
        setAddEditModal(AddEdit);
    }


    /**
     * Activate popup for delete config entry confirmation
     * @param key key of the config entry
     */
    const Delete_show = (key: string) => {
        popupCreate("Delete User", `Do you really want to delete ${key}?`,
            Delete_apply.bind(this, key))
    }

    /**
     * Confirm user entry deletion
     */
    const Delete_apply = (key: string) => {
        let sendValue : UserManagement[] = [];
        sendValue.push({id: key, newValue: ""})
        let valueobj: ConfigEditData = {value : sendValue}
            sendUsersEditDataCall(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId, valueobj).then(async (data) => {
                if(!data.success) {
                    popupCreate("Error", data.message)
                } else {
                    await getUserData()
                }
            })
    }

    // convert the tenant string for the GUI
    const convertAuthlevelToTenantsystem = (authlevel: string): TenantLine[] => {
        let scopes = [] as TenantLine[];
        if(authlevel !== undefined && authlevel.indexOf('{') !== -1) {
            let userconf = JSON.parse(authlevel);
            for (let key in userconf) {
                // global scope
                if(userconf.hasOwnProperty(key) && key === "user") {
                    scopes.push({id: scopes.length, scopes : ["user"], value : userconf[key], tmp: "", fullscope: "user"})
                }
                // userscopes
                if (userconf.hasOwnProperty(key) && key !== "user") {
                    let a = key.split('/');
                    scopes.push({id: scopes.length, scopes : a, value : userconf[key], tmp: "", fullscope: key})
                }
            }
        }
        // add global scope if it does not exist
        if(scopes.find(x => x.scopes[0] === "user" && x.scopes.length === 1) === undefined){
            scopes.push({id: scopes.length, scopes : ["user"], value : "", tmp: "", fullscope: "user"})
        }
        return scopes;
    }

    /**
     * Filter the users list by the filter
     * @param list
     * @param listOfKeys
     */
    const applyFilter = (list: UsersDic, listOfKeys: string[]) : string[] => {
        // old tenantmangement
        if(parentModule.state.AppConfig.DisableTenantManagement && parentModule.state.AppConfig.DisableTenantManagement === "true") {
            // list of filters
            let filters = filterState_oldTen.map(x => x.value)
            // filter by value
            if(filters.length > 0) {
                listOfKeys = listOfKeys.filter(x => filters.indexOf(list[x].authLevel) !== -1)
            }
        } else {
            // new tenant management    
            // include filter
            if(filterBy === SearchBy.Include) {
                if(filterState.value === ScopeAny) {
                    // filter by value
                    if(filterValueState.value !== ValueAny) {
                        listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.value === filterValueState.value) !== undefined )
                    }
                } else {
                    if(filterValueState.value === ValueAny) {
                        listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.fullscope === filterState.value) !== undefined )
                    }  else {
                        listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.fullscope === filterState.value && y.value === filterValueState.value) !== undefined )
                    }
                }
            } else {
                // exclude filter
                if(filterState.value === ScopeAny) {
                    // filter by value
                    if(filterValueState.value !== ValueAny) {
                        listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.value === filterValueState.value) === undefined)
                    }
                } else {
                    if (filterValueState.value === ValueAny) {
                        listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.fullscope === filterState.value) === undefined)
                    } else {
                        listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.fullscope === filterState.value && y.value === filterValueState.value) === undefined)
                    }
                }
            }
        }
        // search by ID or email filter
        if(ShowIDFilter && IDFilter !== "" && IDFilter.length >= 3) {
            listOfKeys = listOfKeys.filter(x => {
                 return  (list[x].mail !== undefined && list[x].mail !== null && list[x].mail.indexOf(IDFilter) !== -1)
            })
        }
        return listOfKeys
    }

    /**
     * sort the users list by clicking on the column header
     * @param list
     * @param key
     * @param reverse
     */
    const sortListByKey = (list: UsersDic, key: string, reverse: boolean = false) : string[]  => {
        if(Object.keys(list).length === 0) {
            return []
        }
        let listofKeys = Object.keys(list);

        // apply filters
        listofKeys = applyFilter(list, listofKeys)
        // if first item of list is a number
        switch(key) {
            case "lastLogin":
                listofKeys.sort((a: any, b: any) => {
                    // if lastlogin is undefined, sort it to the end
                    if(list[a].lastLogin === undefined) {
                        return 1
                    }
                    if(list[b].lastLogin === undefined) {
                        return -1
                    }
                    if(list[a].lastLogin < list[b].lastLogin) {
                        return 1;
                    }
                    if(list[a].lastLogin > list[b].lastLogin ) {
                        return -1;
                    }
                    return 0;
                })
                break;
            case "mail":
                listofKeys.sort((a: any, b: any) => {
                    // if key is undefined, sort by userMail instead
                    let aMail = list[a][key] === undefined ? list[a].mail : list[a][key];
                    let bMail = list[b][key] === undefined ? list[b].mail : list[b][key];
                    if(aMail < bMail) {
                        return -1;
                    }
                    if(aMail > bMail) {
                        return 1;
                    }
                    return 0;
                })
                break;
            default:
                listofKeys.sort((a: any, b: any) => {
                    if((list[a][key] as string).toLowerCase() < (list[b][key] as string).toLowerCase()) {
                        return -1;
                    }
                    if((list[a][key] as string).toLowerCase() > (list[b][key] as string).toLowerCase() ) {
                        return 1;
                    }
                    // sort by key LastLogin if they are equal
                    if(list[a].LastLogin === undefined) {
                        return 1
                    }
                    if(list[b].LastLogin === undefined) {
                        return -1
                    }
                    if(list[a].LastLogin < list[b].LastLogin) {
                        return 1;
                    }
                    if(list[a].LastLogin > list[b].LastLogin ) {
                        return -1;
                    }
                    return 0;
                })
        }
        if(reverse) {
            listofKeys.reverse();
        }
        return listofKeys

    }

    const setSortByKey = (key: string) => {
        setSort_Reverse(!Sort_Reverse);
        setSort_Key(key);
    }

    const sort = () => {
        return sortListByKey (userData, Sort_Key, Sort_Reverse)
    }

    const showSortIcon = (key: string) => {
        if(key === Sort_Key) {
            return Sort_Reverse ? <i className="uib-icon--arrow1-down uib-icon"></i> : <i className="uib-icon--arrow1-up uib-icon"></i>
        }
    }

    const resetFilter = () => {
        setFilterState_oldTen([]);
        setFilterState({value: ScopeAny, label: ScopeAny});
        setFilterValueState({value: ValueAny, label: ValueAny});
    }

    /**
     * Displays the filter above the users list
     * @constructor
     */
    const Filterbar = () :ReactElement => {
        let buttons = <div className={`col col-auto ${styles.RButtons}`}>
            <label className="col col-form-label"></label>
            <div className="col-auto ">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 150, hide: 200 }}
                    overlay={
                        <Tooltip id="tooltip-disabled">
                            Reset filters
                        </Tooltip>
                    }
                >
                    <Button className={`uib-button uib-button--primary uib-button--square ${styles.ButtonLogo} ${styles.spaceRightBigger}`} onClick={() => resetFilter()}>
                        <i className={`uib-icon uib-icon--settings-leave ${styles.logo}`}></i>
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 150, hide: 200 }}
                    overlay={
                        <Tooltip id="tooltip-disabled">
                            Show (activate) /hide (deactivate) the filter by key
                        </Tooltip>
                    }
                >
                    <Button className={`uib-button uib-button--primary uib-button--square ${styles.ButtonLogo}`} onClick={() => setShowIDFilter(!ShowIDFilter)}>
                        <i className={`uib-icon uib-icon--search ${styles.logo}`}></i>
                    </Button>
                </OverlayTrigger>
            </div>
        </div>
        let filterByKey = <div></div>
        if(ShowIDFilter) {
            filterByKey = <div className="col col-md-4">
                <label  className="col col-form-label">Filter by key (hidden field) and email</label>
                <div className="col">
                    <FormControl id="plainvalue" placeholder="Value"
                                    onChange={(event: { target: { value: any; }; }) => {setIDFilter(event.target.value)}} value={IDFilter} />
                </div>
            </div>
        }

        // if tenant management is disabled
        if(parentModule.state.AppConfig.DisableTenantManagement && parentModule.state.AppConfig.DisableTenantManagement === "true") {
            return <>
                <div className={`form-group row ${styles.filterRow}`}>
                    <div className="col col-md-4">
                        <label  className="col col-form-label">Filter by Value</label>
                        <div className="col">
                            <Select closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select values. If empty, all are selected"
                                    isMulti
                                    options={parentModule.state.AppConfig.authLevels.split(',').map(x => {return {value: x, label: x}})}
                                    onChange={(option : filterState[]) => setFilterState_oldTen(option)}
                                    value={filterState_oldTen}
                                    className={styles.selectFields}
                            ></Select>
                        </div>
                    </div>
                    {buttons}
                    {filterByKey}
                </div>
            </>
        }

        // add any to option lists
        let scopes = listOfScopes.map(x => {return {value: x, label: x}})
        scopes.push({value: ScopeAny, label: ScopeAny})
        let authLevels = parentModule.state.AuthLevels.map(x => {return {value: x, label: x}})
        authLevels.push({value: ValueAny, label: ValueAny})

        // if tenant management is enabled
        return <><div className={`form-group row ${styles.filterRow}`}>
            <div className="col col-md-auto">
                <label  className="col col-form-label">Search for</label>
                <div className="col">
                    <Button className={filterBy === SearchBy.Exclude ? `uib-button uib-button--secondary ${styles.details} ${styles.spaceRight}` : `uib-button uib-button--primary ${styles.details} ${styles.spaceRight}`}
                            onClick={() => setFilterBy(SearchBy.Include)}>Include</Button>
                    <Button className={filterBy === SearchBy.Exclude ? `uib-button uib-button--primary ${styles.details} ` : `uib-button uib-button--secondary ${styles.details} ` }
                            onClick={() => setFilterBy(SearchBy.Exclude)}>Exclude</Button>
                </div>
            </div>
            <div className="col col-md-4">
                <label  className="col col-form-label">Filter by Scopes</label>
                <div className="col">
                    <Select closeMenuOnSelect={true}
                            components={animatedComponents}
                            placeholder="Select scopes"
                            options={scopes}
                            onChange={(option : filterState) => setFilterState(option)}
                            value={filterState}
                            className={styles.selectFields}
                    ></Select>
                </div>
            </div>
            <div className="col col-md-2">
                <label  className="col col-form-label">Value</label>
                <div className="col">
                    <Select closeMenuOnSelect={true}
                            components={animatedComponents}
                            placeholder="Value of scope"
                            options={authLevels}
                            onChange={(option : filterState) => setFilterValueState(option)}
                            value={filterValueState}
                            className={styles.selectFields}
                    ></Select>
                </div>
            </div>
            {buttons}
            {filterByKey}
        </div>
        </>
    }

    const logInDate = (value: number) : string => {
        let val = convertIntToDate(value)
        if(val === null) {
            return "Never logged in yet."
        } else {
            return val;
        }
    }

    const closePopup = () => {
        setPopup(Popup.None);
    }

        const Sorted = sort()

        let popupContent = <div></div>
        switch(popup) {
            default:
                break;
            case Popup.Users_AddEdit:
                popupContent = <AddEditUserModal onSuccessFunction={getUserData.bind(this)}
                                                 closeFunction={closePopup.bind(this)}
                                                 state={AddEditModal}
                />
                break;
        }

    return <>
            <Modal size="lg" show={popup !== Popup.None}
                     onHide={closePopup} >
                {popupContent}
            </Modal>
            
            <Filterbar/>

            <div className={styles.ScrollPageUsers}>
                <Form>
                    <Table striped className={`${styles.maintable} uib-table uib-table--striped`} id={"tableOfUsers"}>
                    <thead className={`uib-table__header ${styles.tableHead}`}>
                        <tr className={"uib-table__row--header"}>
                            <th className={styles.hoverPointer} onClick={() => setSortByKey("mail")}>Email{showSortIcon("userMail")}</th>
                            <th className={`${styles.alignCenter} ${styles.hoverPointer}`} onClick={() => setSortByKey("authLevel")}>Value{showSortIcon("AuthLevel")}</th>
                            <th className={styles.hoverPointer} onClick={() => setSortByKey("lastLogin")}>Time of last login{showSortIcon("LastLogin")}</th>
                            {isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject?.moduleInstanceId, HubPathRouting.currentModule?.moduleInstanceId) &&
                                <>
                                <th className={styles.alignCenter}>Edit</th>
                                <th className={styles.alignCenter}>Delete</th>
                                </>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {Sorted.map((col, index) => {
                                 if(userData[col].authLevel !== undefined)
                                    return <tr key={index}>
                                        <td>{userData[col].mail === null ? userData[col].id : userData[col].mail}</td>
                                        <td className={styles.alignCenter}>{convertUsersValueToButtonOrText(col)}</td>
                                        <td>{logInDate(userData[col].lastLogin)}</td>
                                        {isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject?.moduleInstanceId, HubPathRouting.currentModule?.moduleInstanceId) &&
                                            <>
                                            <td className={styles.alignCenter}>
                                                <Button variant="outline-primary" id={"edit-" + index}
                                                        className={styles.ButtonLogo}
                                                        onClick={() => {EditEntry_show(col)}}>
                                                    {IconEdit}
                                                </Button>
                                            </td>
                                        <td className={styles.alignCenter}>
                                            <Button variant="outline-danger"  id={"delete-" + index}
                                                    className={styles.ButtonLogo}
                                                    onClick={() => {Delete_show(col)}}
                                                 >
                                                {IconDelete}
                                            </Button>
                                        </td>
                                        </>
                                        }
                                    </tr>
                                 return null
                            })
                        }
                        </tbody>
                    </Table>
                </Form>
            </div>
            <div className={`${styles.container} ${styles.FooterRoot}`}>
                <Row>
                    {isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject?.moduleInstanceId, HubPathRouting.currentModule?.moduleInstanceId) &&
                        <>
                        <Col md={"auto"}>
                            <Button className="uib-button uib-button--primary" id={"add"} onClick={() => EditEntry_show()}>+ Add</Button>
                        </Col>
                        </>
                    }
                    <Col className={styles.FooterUser}>
                        Users: {Sorted.length}
                    </Col>
                </Row>
            </div>
        </>
}

export default UsersTab;