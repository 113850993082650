import {listStaticModules} from "../BackendFacade/moduleCalls";
import {listProjects} from "../Components/Hub/BackendFacade/apiCalls";
import {IProject} from "../Components/Hub/Interfaces/IAllProjects";
import {IListStaticModule} from "../BackendFacade/IStaticModules";

class globalDictionary {
    private static _instance: globalDictionary;

    public MODULEINSTANCES = "moduleInstances"
    public PROJECTS = "projects"
    public STATICMODULES = "staticModules"

    private observers: any[] = [];
    private constructor()
    {

    }

    public static get Instance()
    {
        // Do you need arguments? Make it a regular static method instead.
        return this._instance || (this._instance = new this());
    }

    private dictionary: any = {};

    public subscribe(observer: any) {
        this.observers.push(observer);
    }

    public unsubscribe(observer: any) {
        this.observers = this.observers.filter(obs => obs !== observer);
    }

    public notify() {
        this.observers.forEach(observer => observer());
    }

    public set(key: string, value: any) {
        this.dictionary[key] = value;
        this.notify();
    }

    public get(key: string) {
        return this.dictionary[key];
    }

    public remove(key: string) {
        delete this.dictionary[key];
    }

    async getStaticModules(): Promise<IListStaticModule[]> {
        if (this.dictionary["staticModules"] == undefined) {
            const data = await listStaticModules();
            if(!data.success) {
                return [];
            }
            this.dictionary["staticModules"] = data.data.value;
            return data.data.value;
        }
        return this.dictionary["staticModules"];
    }

    async getProjects(): Promise<IProject[]> {
        if (this.dictionary["projects"] == undefined) {
            const data = await listProjects();
            this.dictionary["projects"] = data.data.value;
            return data.data.value;
        }
        return this.dictionary["projects"];
    }
}

export const GlobalDictionary = globalDictionary.Instance;
