import * as React from "react";
import "@uib/css/themes/draeger-theme-bright.css"
import {moduleContent} from "./moduleContent";
import {MBody} from "./Interfaces/interfaces";
import {getConfigDataCall} from "./BackendFacade/apiCalls";
import {IIDMS} from "./Interfaces/IIDMS";
import {AppConfig} from "./Interfaces/IConfig";
import {HubPathRouting} from "../../HubFramework/pathBuilder";
import {ReactElement} from "react";
import HubFunctions from "../../HubFramework/hubFunctions";
import {HubFramework} from "../../HubFramework";

export class Module extends React.Component<{}, IIDMS> {
    constructor(props: any) {
        super(props);
        HubPathRouting.setProject()
        HubPathRouting.setModule()
        this.state = {
            UserSettings: {} as MBody,
            AuthLevelChanges: [] as string[],
            AppConfig: {} as AppConfig,
            AuthLevels: [] as string[],
            // projectID: HubPathRouting.currentProject.moduleInstanceId,
            // moduleInstanceId: HubPathRouting.currentModule.moduleInstanceId,
        }
    }

    public render() : ReactElement {
        return <HubFramework hubContent={moduleContent} module={this} />
    }

    /**
    * Get the config data from the backend
    */
    async getConfigData() {
        await getConfigDataCall(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId).then((data) => {
            if (!data.success) {
                HubFunctions.PopupCreateSingle("Error", data.message)
            } else {
                let appConfig = data.data.value;
                this.setState({
                    AppConfig: appConfig,
                    AuthLevelChanges: this.createCombinationOfAllAuthLevels(appConfig.authLevels.split(",")),
                    AuthLevels: appConfig.authLevels.split(","),
                })
            }
        })

        // timeout to make sure the state is set before the next function is called
        await new Promise(r => setTimeout(r, 1));
    }
    /**
     * Create a list of all AuthLevelChangeuser-to combinations
     * @param authlevels
     */
    createCombinationOfAllAuthLevels(authlevels: string[]): string[] {
        let authLevelsCombinations: string[] = ["AppDisplayName",
            "defaultAuth",
            "authLevels",
            "groupAuthLevels",
            "userTenant",
            "customDefaultAuthRules",
            "SignUpGroups",
            "SignUpGroups_OnPrem",
            "OverwriteWithGroups",
            "DisableTenantManagement",
            "SignUpWithExceptionAuth",
            "SignUpWithDefaultAuth",
            "SignUpEventAPI"]
        for(let i = 0; i < authlevels.length; i++) {
            for(let j = 0; j < authlevels.length; j++) {
                authLevelsCombinations.push("AuthLevelChange" + authlevels[i] + "To" + authlevels[j])
            }
            authLevelsCombinations.push("AuthLevelChange" + authlevels[i]+ "ToAny")
            authLevelsCombinations.push("AuthLevelChangeAnyTo" + authlevels[i])
        }
        authLevelsCombinations.push("AuthLevelChangeAnyToAny")
        
        return authLevelsCombinations
    }

}
