import * as React from "react";
import "@uib/css/themes/draeger-theme-bright.css"
import {HubFramework} from "../../HubFramework"
import {moduleContent} from "./moduleContent";
import {IDPAS} from "./Interfaces/IDPAS";
import {HubPathRouting} from "../../HubFramework/pathBuilder";
import {getModuleInstanceInformation} from "../../BackendFacade/moduleCalls";
import {IModule} from "../../BackendFacade/IModuleInstances";
import {ReactElement} from "react";

export class Module extends React.Component<{}, IDPAS> {
    constructor(props: any) {
        super(props);
        HubPathRouting.setProject()
        HubPathRouting.setModule()
        this.state = {
            dpasId: ""
        }
    }

    /**
     * Get the project id of the current module
     */
    async getProjectId() : Promise<string> {
        const projectId = HubPathRouting.currentProject.moduleInstanceId
        const moduleId = HubPathRouting.currentModule.moduleInstanceId

        if(this.state.dpasId !== "") {
            return this.state.dpasId
        }

        return await getModuleInstanceInformation(projectId, moduleId).then((data) => {
            if(data.success) {
                let module = data.data.value as IModule
                this.setState({dpasId: module.linkedId.projectId})
                return module.linkedId.projectId
            } else {
                return ""
            }
        })
    }

    public render() : ReactElement {
        return <HubFramework hubContent={moduleContent} module={this} />
    }
}
