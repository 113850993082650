import * as React from "react";
import {moduleContent} from "../moduleContent";
import Documentation from "../../Documentation/Documentation";


// export class IDMSDocumentation extends React.Component<{parentModule: Module}, {}> {
//     constructor(props: any) {
//         super(props);
//
//     }

const IDMSDocumentation = () => {
        return <>
            <Documentation component={moduleContent.moduleName} />
        </>
}

export default IDMSDocumentation;