import {UserData, UsersDic} from "./IUsers";
import {TenantLine} from "../../IDMS/Utils/ITenantSystem";

export interface IHubAddEditModal {
    tenantSystem: TenantLine[],

    Selected_Value: UserData,
    emailList : string[],
    listOfSubscopes: string[],
    Selected_Key: string,
    OldSelected_key: string,
    userData: UsersDic,

    editMode: IUsersEditMode,
}

export enum IUsersEditMode {
    Add,
    Edit,
    None,
}

export interface possibleScopes {
    key: string,
    value: string,
}