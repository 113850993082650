import * as React from "react";
import {
    Button,
    Container,
    ModalBody,
    ModalFooter,
    Row,
} from "react-bootstrap";
import styles from "../../ProjectContent.module.css";
import ModalHeader from "react-bootstrap/ModalHeader";
import {deleteModuleInstance} from "../../BackendFacade/apiCalls";
import {HubPathRouting} from "../../../../HubFramework/pathBuilder";
import {useMain} from "../../../../Utils/SessionControls/mainContext";

interface IProps {
    onSuccessFunction: () => void,
    closeFunction: () => void,
    moduleInstanceId: string,
    deleteMode: boolean
}

const AllModules_Delete: React.FC<IProps> = ({moduleInstanceId, closeFunction, onSuccessFunction, deleteMode}) => {

    const {popupCreate, setLoading, popupCreateSingle} = useMain();
    /**
     * Applies the changes to the project
     */
    const applyProject = () => {
        setLoading(true)
        deleteModuleInstance(HubPathRouting.currentProject.moduleInstanceId, moduleInstanceId, deleteMode).then((data) => {
            if(data.success) {
                onSuccessFunction()
                closeFunction()
            } else {
                popupCreateSingle("Error", data.message)
            }
        })
        setLoading(false)
    }

    return <div>
        <ModalHeader closeButton>
            Delete module instance
        </ModalHeader>
        <ModalBody>
            <Container>
                <Row className={styles.popupSpaceBetween}>
                    Sure you want to delete this module instance?
                </Row>
                {deleteMode &&
                <Row className={styles.popupSpaceBetween}>
                    This will delete all data and cannot be undone!
                </Row>
                }
            </Container>
        </ModalBody>
        <ModalFooter>
            <Button className="uib-button uib-button--secondary" variant="secondary" onClick={() => closeFunction()}>Cancel</Button>
            <Button className={`uib-button uib-button--primary ${styles.applyButton}`}
                    onClick={() => {applyProject()}}>Delete</Button>
        </ModalFooter>
    </div>;

}

export default AllModules_Delete;
