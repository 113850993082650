import * as React from "react";
import {UserRoles} from "../Utils/IAuthorization";
import {IlinkedId} from "../BackendFacade/IModuleInstances";
import {ReactElement} from "react";

export interface IHub {
    Loading: boolean,
    Popup: Popup,
    currentPage: IModulePage,
    PopupHeader: string | ReactElement,
    PopupMessage: string | ReactElement,
    hubContent: IContent,
    module: React.Component,
    title: string,
    defaultPage: string,
    PopupSubmitFunction: () => void,
    loggedIn: boolean,
    locationKeys: string[];
}

export enum Popup {
    None,
    Error,
    Other,
    OtherFull,
}

/**
 * Required parts for the backbone of the hub
 */
export interface IContent {
    moduleName: string,
    defaultPage?: string,
    pages: IModulePage[],
    title: string,
    creationProps?: CreationProps[],
}

export interface CreationProps {
    name: string,
    displayName?: string,
    type: "string" | "string[]" | "number" | "boolean" | "select" | "textarea" | "subProps",
    description: string,
    value: string | string[] | number | boolean ,
    options?: string[],
    subProps?: {[key: string]: CreationProps[]},
}

export interface IModulePage {
    displayName: string,
    contentPage: React.ComponentClass | React.FunctionComponent,
    url?: string,
    minimumRole?: UserRoles,
    separator?: boolean,
}

/**
 * Contains core functions of the framework for visual interaction
 */
export interface IHubFunctions {
    PopupCreateSingle: (header: string | ReactElement, content: string | ReactElement, submitFunction?: () => void) => void,
    PopupCreate: (header: string, content: string | ReactElement, submitFunction?: () => void) => void,
    PopupClose: () => void,
    /** Shows the loading circle on top of the webpage */
    showLoading: () => void, 
    hideLoading: () => void,
    switchModule: (moduleName: string, idName?: string, displayName?: string, page?: string, linkedId?: IlinkedId) => void,
    // updateUrl: () => void,
}

export interface IHubArgs {
    moduleName: string,
    idName?: string,
    displayName?: string,
    linkedId?: IlinkedId,
    page?: string,
}

export interface IListOfModules {
    [moduleName: string]: {
        moduleContent: IContent,
        Module: React.ComponentClass | React.FunctionComponent,
    },
}
