import {ReactElement} from "react";
import {IlinkedId} from "../BackendFacade/IModuleInstances";

export enum HubFunctionType {
    PopupCreateSingle,
    PopupCreate,
    PopupClose,
    showLoading,
    hideLoading,
    updateUrl,
    switchModule
}

class HubFunctions {
    private static _instance: HubFunctions;

    private constructor() {
        //...
    }

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    _popupCreateSingle: any;
    _popupCreate: any;
    _popupClose: any;
    _showLoading: any;
    _hideLoading: any;
    _updateUrl: any;
    _switchModule: any;

    registerFunction(func: any, functionType: HubFunctionType) {
        switch (functionType) {
            case HubFunctionType.PopupCreateSingle:
                this._popupCreateSingle = func;
                break;
            case HubFunctionType.PopupCreate:
                this._popupCreate = func;
                break;
            case HubFunctionType.PopupClose:
                this._popupClose = func;
                break;
            case HubFunctionType.showLoading:
                this._showLoading = func;
                break;
            case HubFunctionType.hideLoading:
                this._hideLoading = func;
                break;
            case HubFunctionType.updateUrl:
                this._updateUrl = func;
                break;
            case HubFunctionType.switchModule:
                this._switchModule = func;
                break;
        }
    }

    unregisterAllFunctions() {
        this._popupCreateSingle = undefined;
        this._popupCreate = undefined;
        this._popupClose = undefined;
        this._showLoading = undefined;
        this._hideLoading = undefined;
        this._updateUrl = undefined;
        this._switchModule = undefined;
    }

    PopupCreateSingle(header: string | ReactElement, content: string | ReactElement, submitFunction?: () => void) {
        this._popupCreateSingle(header, content, submitFunction);
    }

    /**
     * Create a popup with a header and content. On the left side is a label "Message"
     * @param header headline of the popup
     * @param content content of the popup
     * @param submitFunction function to be called when the submit button is pressed
     * @constructor
     */
    PopupCreate(header: string, content: string | ReactElement, submitFunction?: () => void) {
        this._popupCreate(header, content, submitFunction);
    }

    /**
     * Close the popup
     * @constructor
     */
    PopupClose() {
        this._popupClose();
    }

    /**
     * Show the loading spinner
     */
    showLoading() {
        this._showLoading();
    }

    /**
     * Hide the loading spinner
     */
    hideLoading() {
        this._hideLoading();
    }

    /**
     * Update the url of the browser
     */
    updateUrl() {
        this._updateUrl();
    }

    /**
     * Switch the module
     * @param moduleName
     * @param idName
     * @param displayName
     * @param page
     * @param linkedId
     */
    switchModule(moduleName: string, idName?: string, displayName?: string, page?: string, linkedId? : IlinkedId) {
        this._switchModule(moduleName, idName, displayName, page, linkedId);
    }
}

export default HubFunctions.Instance;