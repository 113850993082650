import * as React from "react";
import {moduleContent} from "./moduleContent";
import {HubFramework} from "../../HubFramework";
import {HubPathRouting} from "../../HubFramework/pathBuilder";

const Module: React.FC = () => {
    HubPathRouting.clearProject()
    HubPathRouting.clearModule()
    return <HubFramework hubContent={moduleContent} module={this} />
}

export default Module;