import React, { useEffect } from 'react';
import {useSearchParams} from "react-router-dom";

const Module: React.FC = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        console.log("set url")
        window.location.assign(`https://dev.azure.com/draeger/${searchParams.get("linkedId")}`);
    }, []);

    return <></>;
};

export default Module;