import * as React from "react";
import {IHubArgs} from "../../HubFramework/IHub";
import {ReactElement} from "react";

export class Module extends React.Component<{hubArgs: IHubArgs}, {}> {
    constructor(props: any) {
        super(props);
        this.state = {
        }
        window.location.assign("https://dusswebappprod.z6.web.core.windows.net/");
    }

    public render() : ReactElement {
        return <></>
    }
}
