import * as React from "react";
import {updateModuleInstance} from "../../BackendFacade/apiCalls";
import {
    Button,
    Col,
    Container,
    FormControl,
    ModalBody,
    ModalFooter,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import styles from "../../ProjectContent.module.css";
import ModalHeader from "react-bootstrap/ModalHeader";
import {IAllModules_Edit, Popup} from "../../Interfaces/IAllModules_Edit";
import AllModules_Delete from "./AllModules_Delete";
import {IModule} from "../../../../BackendFacade/IModuleInstances";
import {useMain} from "../../../../Utils/SessionControls/mainContext";

interface IProps {
    onSuccessFunction: () => void,
    closeFunction: () => void,
    state: IModule,
    projectId: string,
}

const AllModules_Edit: React.FC<IProps> = ({onSuccessFunction, closeFunction, state, projectId}) => {
    const [moduleInstanceId, setModuleInstanceId] = React.useState(state.moduleInstanceId)
    const [staticModuleId, setStaticModuleId] = React.useState(state.staticModuleId)
    const [group, setGroup] = React.useState(state.group)
    const [linkedId, setLinkedId] = React.useState(state.linkedId)
    const [displayName, setDisplayName] = React.useState(state.displayName)
    const [creationProps, setCreationProps] = React.useState(state.creationProps)
    const [popup, setPopup] = React.useState<Popup>(Popup.None)

    const {setLoading, popupCreateSingle} = useMain();

    /**
     * Applies the changes to the project
     */
    const applyProject = () => {
        setLoading(true)
        const state = {
            moduleInstanceId: moduleInstanceId,
            staticModuleId: staticModuleId,
            group: group,
            linkedId: linkedId,
            displayName: displayName,
            creationProps: creationProps,
        } as IAllModules_Edit
        updateModuleInstance(projectId, state).then((data) => {
            if(data.success) {
                onSuccessFunction()
                closeFunction()
            } else {
                popupCreateSingle("Error", data.message)
            }
        })
        setLoading(false)
    }

    const closePopup = () => {
        setPopup(Popup.None)
    }

    const showDeletePopup = () => {
        setPopup(Popup.DeleteModule)
    }

    const showHardDeletePopup = () => {
        setPopup(Popup.HardDeleteModule)
    }

    const mainContent = () => {
        let applyButton = <OverlayTrigger
            placement="top"
            delay={{ show: 1, hide: 200 }}
            overlay={
                <Tooltip id="tooltip-disabled">
                    Please make sure all fields except for the description are filled.
                </Tooltip>
            }>
            <Button className={`uib-button uib-button--primary ${styles.applyButton} ${styles.errorButton}`}>
                Apply
            </Button>
        </OverlayTrigger>

        // check if every field is filled, activate apply button
        if(displayName !== "" && group !== "")
        {
            applyButton = <Button className={`uib-button uib-button--primary ${styles.applyButton}`}
                                  onClick={() => {applyProject()}}>
                Apply
            </Button>
        }

        return <div>
            <ModalHeader closeButton>
                Edit Module Instance
            </ModalHeader>
            <ModalBody>
                <Container>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">
                            Display name
                        </Col>
                        <Col>
                            <FormControl
                                placeholder="Display name"
                                value={displayName}
                                id="displayName"
                                onChange={(event) => {
                                    setDisplayName(event.target.value)
                                }} />
                        </Col>
                    </Row>

                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">
                            Group
                        </Col>
                        <Col>
                            <FormControl
                                placeholder="Group name"
                                value={group}
                                id="group"
                                onChange={(event) => {
                                    setGroup(event.target.value)
                                }} />
                        </Col>
                    </Row>

                </Container>
            </ModalBody>
            <ModalFooter>
                <div className={styles.EditModuleFooter}>
                    <div>
                    <Button className={`uib-button uib-button--secondary ${styles.errorButton} ${styles.addEmailElement}`}
                            variant="secondary"
                            onClick={() => {showHardDeletePopup()}}>Hard Delete</Button>
                    <Button className={`uib-button uib-button--secondary ${styles.errorButton}`}
                            variant="secondary"
                            onClick={() => {showDeletePopup()}}>Delete</Button>
                    </div>
                    <div>
                        <Button  className="uib-button uib-button--secondary" variant="secondary" onClick={() => closeFunction()}>Cancel</Button>
                        {applyButton}
                    </div>
                </div>
            </ModalFooter>
        </div>
    }

    const deleteSuccess = () => {
        onSuccessFunction()
        closeFunction()
    }

    let popupContent = <></>
    switch(popup) {
        case Popup.DeleteModule:
            popupContent = <AllModules_Delete moduleInstanceId={moduleInstanceId}
                                              onSuccessFunction={() => {deleteSuccess()}}
                                              closeFunction={() => {closePopup()}}
                                              deleteMode={false}
            />
            break;
        case Popup.HardDeleteModule:
            popupContent = <AllModules_Delete moduleInstanceId={moduleInstanceId}
                                              onSuccessFunction={() => {deleteSuccess()}}
                                              closeFunction={() => {closePopup()}}
                                              deleteMode={true}
            />
            break;
        default:
            popupContent = mainContent()
            break;
    }

    return <div>
        {popupContent}
    </div>;

}

export default AllModules_Edit;
