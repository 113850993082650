import * as React from "react";
import styles from '../../Components/Hub/Hub.module.css'
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {IContact} from "../../Components/Hub/Interfaces/IContact";
import {GlobalDictionary} from "../globalDictionary";
import {PROFILEIMAGE} from "./ProfileImageApi";

interface IProfilImage {
    contact: IContact
    image: string
}
export class ProfilImage extends React.Component<{
    contact: IContact
}, IProfilImage> {

    constructor(props: any) {
        super(props);
        this.state = {
            contact: this.props.contact,
            image: undefined
        }
    }

    componentDidMount() {
        GlobalDictionary.subscribe(() => this.getImage(PROFILEIMAGE + this.props.contact.mail))
    }

    componentWillUnmount() {
        GlobalDictionary.unsubscribe(() => this.getImage(PROFILEIMAGE + this.props.contact.mail))
    }

    getImage(key: string) {
        const image = GlobalDictionary.get(key)
        this.setState({image: image})
        GlobalDictionary.unsubscribe(() => this.getImage(PROFILEIMAGE + this.props.contact.mail))
    }

    render() {
        let image = <div className={`${styles.roundedImage} ${styles.greybackground}`} />

        if(this.state.image ) {
            const imageSrc = `data:image/jpeg;base64,${this.state.image}`;
            image = <img src={imageSrc} alt="Profile Image" className={styles.roundedImage} />
        }

        return <OverlayTrigger
            placement="bottom"
            delay={{ show: 1, hide: 10 }}
            overlay={
                <Tooltip id="tooltip-key">
                    {this.props.contact.mail} - {this.props.contact.job}
                </Tooltip>
            }
        >
            {image}
        </OverlayTrigger>
    }
}
