import {NetworkData} from "./interfaces";
import {fetchFromApi, getBackendApiURL} from "./backendCalls";

/**
 * List all module instances of a project
 * @param projectId
 */
export function listModuleInstances(projectId: string): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects/" + projectId + "/modules"
    return fetchFromApi(url, "GET")
}

/**
 * Get information about a module instance
 * @param projectId
 * @param moduleInstanceId
 */
export function getModuleInstanceInformation(projectId: string, moduleInstanceId: string): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId
    return fetchFromApi(url, "GET")
}

export function listStaticModules(): Promise<NetworkData> {
    const url = getBackendApiURL() + "static/modules"
    return fetchFromApi(url, "GET")
}