import * as React from "react";
import {Button} from "react-bootstrap";

export const FileUploaderUserMails = (props: { handleFile: (arg0: string[]) => void; }) => {
    const hiddenFileInput = React.useRef(null);

    const handleClick = (event: any) => {
        hiddenFileInput.current.click();
    };
    const handleChange = (event: any) => {
        const fileUploaded = event.target.files[0];
        event.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
            const text: string = String(e.target.result)
            // convert text to list of emails
            let emails = text.split('\r\n').map(e => e.split(';'))
            // flatten list of emails
            let flatemails: string[] = Array.prototype.concat.apply([], emails);
            flatemails = flatemails.map(e => {return e.trim()}).filter(e => e !== "")
            props.handleFile(flatemails);
        }
        reader.readAsText(fileUploaded)
    };
    return (
        <>
            <Button className="uib-button uib-button--primary" variant="primary" onClick={handleClick}>
                Upload a file
            </Button>
            <input type="file"
                   ref={hiddenFileInput}
                   onChange={(event: any ) => {handleChange(event)}}
                   style={{display:'none'}}
            />
        </>
    );
};
