/**
 * This folder should all the api calls that are used in the module.
 * Every call must use the fetchFromApi function from the backendCalls.tsx file.
 * This function will handle the authentication and error handling.
 */

import {fetchFromApi, getBackendApiURL, NetworkData} from "../../../BackendFacade";
import {deepCopy} from "../../../Utils/transformer";
import {CreationProps} from "../../../HubFramework/IHub";
import {IProjectInformation_Edit} from "../Interfaces/IProjectInformation_Edit";
import {IAllModules_Edit} from "../Interfaces/IAllModules_Edit";
import {IModule} from "../../../BackendFacade/IModuleInstances";

export function getProject(projectId: string): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects/" + projectId
    return fetchFromApi(url, "GET")
}

export function updateProject(project: IProjectInformation_Edit): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects"
    let value = {value: project}
    return fetchFromApi(url, "POST", value)
}

export function createModuleInstance(projectId: string, module: IModule): Promise<NetworkData> {
    let moduleToSend = deepCopy(module)
    const url = getBackendApiURL() + "projects/" + projectId + "/modules?migrateExisting=" + module.migrateExisting;

    delete (moduleToSend as any).creationProps;
    return fetchFromApi(url, "PUT", {
        value: moduleToSend,
        moduleConfig : convertCreationPropertiesToDictionary(module.creationProps)
    })
}

export function updateModuleInstance(projectId: string, module: IAllModules_Edit): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects/" + projectId + "/modules" + "?migrateExisting=true"
    return fetchFromApi(url, "POST", {
        value: module,
        moduleConfig: module.creationProps
    })
}

export function deleteModuleInstance(projectId: string, moduleInstanceId: string, needsHardDelete: boolean): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "?hardDelete=" + needsHardDelete

    return fetchFromApi(url, "DELETE")
}

function convertCreationPropertiesToDictionary(creationProps: CreationProps[]) {
    let dict: any = {}
    creationProps.forEach((prop) => {
        if (prop.type === "subProps") {
            Object.keys(prop.subProps).forEach((key) => {
                dict[key] = convertCreationPropertiesToDictionary(prop.subProps[key])
            })
        } else {
            dict[prop.name] = prop.value
        }
    })
    return dict
}

export function DeleteProject(projectId: string, hardDelete: boolean): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects/" + projectId + "?hardDelete=" + hardDelete
    // if (hardDelete) {
    //     const headerAdditions = {
    //         "hardDelete": true
    //     }
    //     return fetchFromApi(url, "DELETE", headerAdditions)
    // }
    return fetchFromApi(url, "DELETE")
}


