import React, { useEffect } from 'react';
import {useSearchParams} from "react-router-dom";

const Module: React.FC = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        window.location.assign(`https://appstoreconnect.apple.com/apps/${searchParams.get("linkedId")}/appstore`);
    }, []);

    return <></>;
};

export default Module;