import {fetchFromApi, getBackendApiURLModuleInstance, NetworkData} from "../../../BackendFacade";
import {DirectoryType, MBody} from "../Interfaces/IFiles";
import {
    AutomationConfig,
    Blob1080URL,
    BlobDocuments,
    BlobOriginalURL,
    listDirectoryContent,
    fileDetails, Upload, AssetURL
} from "../constants";
import {IAutomationConfig} from "../Interfaces/IConfig";
import {replaceDoubleSlashesWithSingleSlash} from "../../../Utils/transformer";

/**
 * @param path Expects the following structure: [projectname
 * @param type
 */
export function getDirContentNames(path: string, type: string) {
    // adjust the type for the backend
    type = type.replace("Images", "image")
        .replace("Documents", "document")
        .replace("Secret Files", "secret")
    let url = getBackendApiURLModuleInstance() + listDirectoryContent + "?path=" + path + "&type=" + type
    return fetchFromApi(url, "GET")
}

export function getAutomationConfig() {
    let url = getBackendApiURLModuleInstance() + AutomationConfig
    return fetchFromApi(url, "GET")
}

export function setAutomationConfig(body: IAutomationConfig) {
    let url = getBackendApiURLModuleInstance() + AutomationConfig
    return fetchFromApi(url, "PUT", body)
}

/**
 * returns NetworkData with data-type: IFileDetails
 * @param path relative path to file
 * @param type Constants.FolderImage or Constants.FolderDocuments
 */
export async function getFileAttributesSingle(path: string, type: "image" | "document" | "secret" | "UP" | "") {
    path = path.replace(" ", "%20")
    path = replaceDoubleSlashesWithSingleSlash(path)
    let ret: NetworkData
    const url = getBackendApiURLModuleInstance() + fileDetails
    if(type == DirectoryType.Images) {
        const subUrl = url + "?type=image&filePath=" + path
        ret = await fetchFromApi(subUrl, "GET" )
    } else if(type == DirectoryType.Documents) {
        const subUrl = url + "?type=document&filePath=" + path
        ret = await fetchFromApi(subUrl, "GET")
    } else if(type == DirectoryType["Secret Files"]) {
        const subUrl = url + "?type=secret&filePath=" + path
        ret = await fetchFromApi(subUrl, "GET")
    }
    ret.data.fileName = path
    return ret
}

export function UploadFile(directoryPath: string, file: File, containerType: string) {
    containerType = containerType.replace("Images", "image")
        .replace("Documents", "document")
        .replace("Secret Files", "secret")

    let url = getBackendApiURLModuleInstance() + Upload
    let body: MBody = {
        path: directoryPath + file.name,
        type: containerType
    }
    return fetchFromApi(url, "post", body, "application/json", file)
}

export function deleteFile(filePath: string, containerType: string) {
    containerType = containerType.replace("Images", "image")
        .replace("Documents", "document")
        .replace("Secret Files", "secret")

    const url = getBackendApiURLModuleInstance() + `files?filePath=${filePath}&type=${containerType}`
    return fetchFromApi(url, "DELETE")
}

export function downloadFile(filePath: string, type: string, resolution: string = "") {
    let imageParam = ""
    if (resolution !== "") {
        imageParam = "&imageSize=" + resolution
    }
    const url = getBackendApiURLModuleInstance() + `downloadFile?filePath=${filePath}&type=${type}${imageParam}`
    return fetchFromApi(url, "GET")
}

export function getNightlyResults() {
    const url = getBackendApiURLModuleInstance() + `orchestrationResults`
    return fetchFromApi(url, "GET")
}