import {ConfigMail} from "./IConfig";

export interface IAddEditConfigModal {
    SelectedMail: ConfigMail,
    SelectedValue: string,
    SelectedKey: string,
    tmp: string,
    ErrorMessage: string,
    mode: ConfigModalMode,
}

export enum ConfigModalMode {
    Add,
    Edit,
}