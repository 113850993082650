import {moduleContent as HubModule} from "../Components/Hub";
import {moduleContent as ProjectContentModule} from "../Components/ProjectContent";
import {getListOfAllModules} from "../Utils/modules";
import {ReactElement} from "react";
import * as React from "react";
import {cleansePageName} from "../Utils/transformer";
import {
    getDisplayNameByModuleInstanceId,
    getModuleInstanceDisplayName
} from "../Components/ProjectContent/Utils/projectInfo";
import styles from './../HubFramework/HubFramework.module.css'
import {IlinkedId} from "../BackendFacade/IModuleInstances";
import {Link} from "react-router-dom";

class PathRouting
{
    private static _instance: PathRouting;

    private constructor()
    {
        //...
    }

    public static get Instance()
    {
        // Do you need arguments? Make it a regular static method instead.
        return this._instance || (this._instance = new this());
    }
    
    private _currentProject: IPathRouting;
    private _currentModule: IPathRouting;
    private _currentPage: IPathRouting;

    private _observers: Function[] = [];

    public subscribe(observer: Function) {
        this._observers.push(observer);
    }

    public unsubscribe(observer: Function) {
        this._observers = this._observers.filter(obs => obs !== observer);
    }

    private _notify() {
        this._observers.forEach(observer => observer());
    }

    get currentProject(): IPathRouting {
        return this._currentProject;
    }

    set currentProject(value: IPathRouting) {
        console.log("Setting current project to " + value.moduleInstanceId)
        this._currentProject = value;
        if (!value.displayName) {
            getDisplayNameByModuleInstanceId(value.moduleInstanceId).then(async (displayName) => {
                value.displayName = displayName;
                this._notify()
            });
        }
        this._notify()
    }

    /**
     * Sets the current project based on the URL
     */
    public setProject() {
        let url = window.location.href
        const moduleInstanceId = url.split("/")[5]
        if(!moduleInstanceId) {
            this._currentProject = {moduleInstanceId: "", moduleName: ""}
        }
        this.currentProject = {moduleInstanceId: moduleInstanceId, moduleName: ProjectContentModule.moduleName}

    }

    public setModule() {
        let url = window.location.href
        const moduleInstanceId = url.split("/")[7].split("?")[0]
        // get the module name from the url
        const moduleName = url.split("/")[6]
        // check if the module name is valid
        const modules = getListOfAllModules()
        if(Object.keys(modules).indexOf(moduleName) === -1) {
            console.error("ERROR: module " + moduleName + " not found")
        }
        console.log("set current module")
        this.currentModule = {moduleInstanceId: moduleInstanceId, moduleName: moduleName}
    }

    get currentModule(): IPathRouting {
        return this._currentModule;
    }

    set currentModule(value: IPathRouting) {
        this._currentModule = value;
        if (!value.displayName) {
            getModuleInstanceDisplayName(this.currentProject.moduleInstanceId, value.moduleInstanceId).then((displayName) => {
                value.displayName = displayName;
                this._notify()
            });
        }
        this._notify()
    }

    get currentPage(): IPathRouting {
        return this._currentPage;
    }

    set currentPage(value: IPathRouting) {
        this._currentPage = value;
        this._notify()
    }

    clearProject() {
        this._currentProject = undefined;
        this._notify()
    }

    clearModule() {
        this._currentModule = undefined;
        this._notify()
    }

    /**
     * Creates the path tiles for the top of the page
     */
    createPathTiles(): ReactElement[] {
        let tiles: ReactElement[] = []

        // Add Hub module
        const moduleHub = HubModule
        tiles.push(
            <Link to={"/"} id={`linkToHub`}>
                <span className={`${styles.pathTilesLink}`}>
                    Hub
                </span>
            </Link>
        )

        if (this._currentProject) {
            const moduleProjectContent = ProjectContentModule
            tiles.push(<span className={`${styles.pathTiles}`}>/</span>)
            tiles.push(
                <Link to={"/project/" + this._currentProject.moduleInstanceId + "/" + cleansePageName(moduleProjectContent.pages[0].displayName)}
                      className={styles.crumbLink}
                    id={"linkToProject"}
                >
                    <span className={`${styles.pathTilesLink}`}>
                            {this._currentProject.displayName ?? ""}
                    </span>
                </Link>
            )
        }

        if (this._currentModule) {
            tiles.push(<span className={`${styles.pathTiles}`}>/</span>)
            tiles.push(<span className={`${styles.pathTilesLink}`} id={"linkToModule"}>
                    {this._currentModule.displayName}
            </span>
            )
        }

        // if (this.currentPage) {
        //     tiles.push(<span className={`${styles.pathTiles}`}>/</span>)
        //     tiles.push(<span className={`${styles.pathTilesLink}`} id={"linkToPage"} >{this.currentPage.displayName}</span>)
        // }

        // create a key for each element in tiles
        tiles = tiles.map((tile, index) => {
            return React.cloneElement(tile, {key: index})
        })

        this.setBrowserTabTitle()
        return tiles
    }

    setBrowserTabTitle() {
        let projectName  = ""
        if(this._currentProject && this._currentProject.displayName) {
            projectName = this._currentProject.displayName + " - "
        }
        if(this._currentModule) {
            projectName += this._currentModule.displayName + " - "
        }
        if(this._currentPage) {
            projectName += this._currentPage.displayName
        }
        document.title =  projectName
    }
}

export const HubPathRouting = PathRouting.Instance;

interface IBreadcrumbs {
    breadcrumbs: ReactElement[];

}

export class Breadcrumbs extends React.Component<{}, IBreadcrumbs> {
    constructor(props: {}) {
        super(props);
        this.state = {
            breadcrumbs: HubPathRouting.createPathTiles()
        };
    }

    componentDidMount() {
        HubPathRouting.subscribe(this.updateBreadcrumbs);
    }

    componentWillUnmount() {
        HubPathRouting.unsubscribe(this.updateBreadcrumbs);
    }

    updateBreadcrumbs = () => {
        this.setState({ breadcrumbs: HubPathRouting.createPathTiles() });
    }

    render() {
        return (
            <div id={`breadcrumbs`}>
                {this.state.breadcrumbs}
            </div>
        )
    }
}

export interface IPathRouting {
    /* the name that is displayed in the breadcrumbs */
    displayName?: string;
    /* the module instance id is important for the project module, where the nameId equals the id from the backend for a project */
    moduleInstanceId?: string,
    /* the name of the module */
    moduleName: string,
}

export function switchModule(moduleName: string, options?: Partial<{moduleInstanceId: string, MiiDisplayname: string, page: string, linkedId: IlinkedId}>) {
    return
    // const a_moduleInstanceId = options?.moduleInstanceId ?? '';
    // const a_MiiDisplayname = options?.MiiDisplayname ?? '';
    // const a_page = options?.page ?? '';
    // const a_linkedId = options?.linkedId ?? {id: '', type: ''};
    //say("switching module to " + moduleName + " ,mii:" + a_moduleInstanceId + " ,miiDisplayname:" + a_MiiDisplayname + " ,Page:" + a_page + " ," + a_linkedId + "..")
    //
    // let modules: IListOfModules = getListOfAllModules()
    //
    // let module = modules[moduleName]
    // if(module === undefined) {
    //     console.error("ERROR: module " + moduleName + " not found")
    //     // TODO: show error message popup
    //     return
    // }
    //
    // let moduleInfo = module.moduleContent
    // const moduleInstanceId = options?.moduleInstanceId ?? 'moduleInstanceId';
    // const MiiDisplayname = options?.MiiDisplayname ?? moduleInstanceId;
    // const page = options?.page &&
    //     (moduleInfo.pages.find(p => cleansePageName(p.displayName) === cleansePageName(options.page))?.displayName
    //         ?? moduleInfo.defaultPage);
    // const linkedId = options?.linkedId ?? {id: '', type: ''};
    //
    // // if the page of the current module is clicked, do nothing
    // if(HubPathRouting.currentPage && HubPathRouting.currentPage.displayName === page) {
    //     return
    // }
    //
    // // Don't set the DevSecOps Hub and Project Content module as a module
    // // Clear the modules if the Hub or Project Content module is clicked
    // switch(moduleName) {
    //     // Hub Module
    //     case HubModule.moduleName:
    //         HubPathRouting.clearProject()
    //         HubPathRouting.clearModule()
    //         HubPathRouting.clearParameters()
    //         HubPathRouting.clearPage()
    //         break
    //     // Project layer
    //     case ProjectContentModule.moduleName:
    //         HubPathRouting.clearModule()
    //         HubPathRouting.clearParameters()
    //         HubPathRouting.clearPage()
    //         // if Project Content is already loaded, do not reload it again
    //         if(HubPathRouting.currentModule) {
    //             HubPathRouting.currentProject = {
    //                 moduleInstanceId: moduleInstanceId,
    //                 moduleName: moduleInfo.moduleName
    //             }
    //
    //         }
    //         break
    //     // Module layer
    //     default:
    //         say("module layer")
    //         let currentModuleDisplayName = undefined
    //         // if the module stays the same, but the page changes, update the page
    //         if(HubPathRouting.currentModule && HubPathRouting.currentModule.moduleName === moduleName ) {
    //             currentModuleDisplayName = HubPathRouting.currentModule.displayName
    //         }
    //
    //         HubPathRouting.currentModule = {
    //             moduleInstanceId: moduleInstanceId,
    //             displayName: currentModuleDisplayName,
    //             moduleName: moduleInfo.moduleName
    //         }
    //         // if page is not set, clear it
    //         if((options && options.page === undefined) || options === undefined) {
    //             HubPathRouting.clearPage()
    //         }
    // }
    //
    // if(options && options.page) {
    //     HubPathRouting.currentPage = {
    //         //moduleInstanceId: moduleInstanceId,
    //         displayName: page,
    //         moduleName: moduleInfo.moduleName
    //     }
    // }
    //
    // HubPathRouting.getPathAsUrl()
    //
    // if(Object.keys(modules).indexOf(moduleName) !== -1) {
    //     let hubArgs = {
    //         moduleName: moduleName,
    //         idName: moduleInstanceId,
    //         displayName: MiiDisplayname,
    //         page: page,
    //         linkedId: linkedId
    //     } as IHubArgs
    //     let attributes = {hubArgs: hubArgs} as Attributes
    //
    //     let module = React.createElement(modules[moduleName].Module, attributes)
    //     showRootComponent(module)
    // } else {
    //     this.setState({Popup: Popup.Error, PopupMessage: "Module not found. Please look into module config."})
    // }
}
