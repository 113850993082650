import {hasKey} from "./checker";
import {decodeQueryParam} from "./transformer";
import {Dictionary, LoginState} from "./IAuthentication";
import * as React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import styles from './../HubFramework/HubFramework.module.css'
import {jwtDecode} from "jwt-decode";
import {loginURL, LogoutURL} from "../Config/authorization";
import {fetchFromApi, getBackendApiURL} from "../BackendFacade";

const idToken = "id_token2";
const refreshToken = "refresh_token2";

/**
 * Check if the user is logged in. If not, redirect to login page. Evaluate the code from the url if given.
 * @returns
 */
export async function evaluateTokens(): Promise<LoginState> {
    // is the token in the url?
    const search = window.location.href.split("?");
    if (search.length > 1 && search[1].indexOf('=') !== -1) {
        const params = search[1].split('&');
        let dict: Dictionary = {};
        params.forEach((item) => {
            let parts = item.split('=');
            dict[parts[0]] = parts[1];
        })
        // error code in url received,
        if (hasKey(dict, "error")) {
            // forwarded the error to the user
            if (hasKey(dict, "error_description")) {
                return {success: false, error: decodeQueryParam(decodeURIComponent(dict["error_description"] as string))}
            } else {
                return {success: false, error: decodeQueryParam(decodeURIComponent(dict["error"] as string))}
            }
        }

        // received a code in url, send it to backend to get tokens
        if (hasKey(dict, "code")) {
            let ret : LoginState = {success: false, error: ""};
            await Promise.all([
                fetchFromApi(getBackendApiURL() + "static/b2cLogin", "POST",
                    "code=" + dict["code"] + "&state=" + dict["state"],
                    "application/x-www-form-urlencoded"
                ).then(async response => {
                    // remove the code from the url, everything from ? to the #, keep everything after the #, including the #
                    window.history.replaceState({}, document.title, window.location.pathname + window.location.hash);
                    ret = {success: response.success, error: response.message}
                })
            ])
            return ret
        }
    }

    // return false if no token is set, so the website stops loading while redirecting
    return {success: !(getToken() == null && getRefreshToken() == null), error: ""};
}

/**
 * Does a simple backend call to validate the token
 * Sets the static modules
 */
export function validateToken(): LoginState {
    // check the token first if they are set
    if (getToken() == null || getRefreshToken() == null) {
        openLoginPage()
        return {success: false, error: ""}
    }
    return {success: true, error: ""}
}

export function removeTokens() {
    localStorage.removeItem(idToken);
    localStorage.removeItem(refreshToken);
}

export function openLoginPage() {
    removeTokens()
    window.location.href = loginURL
}

export function openLogoutPage() {
    const token = getToken()
    removeTokens()
    window.location.href = LogoutURL + token
}

export function logoutButton() {
    return <div className={styles.logoutButton2}>
        <button className={`uib-link ${styles.logoutButton}`} onClick={() => {
            openLogoutPage()
        }}>Logout
            <i className={`uib-icon uib-icon--exit ${styles.exitlogo}`}></i>
        </button></div>
}

/**
 * Sets the id_token and refresh_token from the response header.
 * @param dict Response from B2C
 */
export function setTokensFromHeader(dict: Response) {
    if (dict.headers.get("id_token") !== null) {
        setToken(dict.headers.get("id_token") as string)
    }
    if (dict.headers.get("refresh_token") !== null) {
        setRefreshToken(dict.headers.get("refresh_token") as string)
    }
}

export function getUserRole() {
    try {
        let decoded: any = jwtDecode(getToken());
        return decoded.authLevel
    } catch (error) {
        return false
    }
}

export function getUserEmail() {
    try {
        let decoded: any = jwtDecode(getToken());
        return decoded.email_address
    } catch (error) {
        return false
    }
}

export function getUserKey() {
    try {
        let decoded: any = jwtDecode(getToken());
        if(decoded.b2cid !== undefined) {
            return decoded.b2cid
        }
        return decoded.sub
    } catch (error) {
        return false
    }
}

export function getToken() {
    return localStorage.getItem(idToken)
}

export function setToken(token: string) {
    localStorage.setItem(idToken, token)
}

export function setRefreshToken(token: string) {
    localStorage.setItem(refreshToken, token)
}

export function getRefreshToken() {
    return localStorage.getItem(refreshToken)
}


export function showUserAccount() {
    return <OverlayTrigger
        placement="bottom"
        delay={{ show: 50, hide: 2000 }}
        overlay={
            <Tooltip id="tooltip-key">
                {getUserKey()}
            </Tooltip>
        }
    >
        <span>{getUserEmail()}</span>
    </OverlayTrigger>
}
