import * as React from "react";
import {ReactElement, useEffect, useState} from "react";

import {
    Button,
    Col,
    Container,
    FormControl,
    ModalBody,
    ModalFooter,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import {isValidEmail} from "../../../../Utils/checker";
import ModalHeader from "react-bootstrap/ModalHeader";
import {say} from "../../../../Utils/webHelper";
import {UpdateAccessRoles} from "../../BackendFacade/apiCalls";
import {IUserData} from "../../Interfaces/IUserManagement";
import {IconTrash} from "../../../../Assets/svgs";
import {FileUploaderUserMails} from "../../../IDMS/components";
import styles from './../../Hub.module.css'
import {deepCopy} from "../../../../Utils/transformer";
import {HubPathRouting} from "../../../../HubFramework/pathBuilder";
import {TenantLine} from "../../../IDMS/Utils/ITenantSystem";
import {AuthLevels, defaultAuth} from "../../constants";
import UserTenantManagement, {SetGlobalTenantSystemContent} from "../../../IDMS/Utils/tenantSystem";
import {useMain} from "../../../../Utils/SessionControls/mainContext";
import {UsersDic} from "../../Interfaces/IUsers";
import {AppConfig} from "../../../IDMS/Interfaces/IConfig";
import {UserData} from "../../../IDMS/Interfaces/IUsers";
import {IAddEditUserModal} from "../../../IDMS/Interfaces/IAddEditUserModal";
import {IHubAddEditModal, IUsersEditMode} from "../../Interfaces/IHubAddEditModal";

export interface IUserModal {
    onSuccessFunction: () => void;
    closeFunction: () => void;
    state: IHubAddEditModal;
    projectId: string;
}

const Hub_AddEditModal: React.FC<IUserModal> = ({
    onSuccessFunction,
    closeFunction,
    projectId,
    state,
}) => {
    const [tenantSystem, setTenantSystem] = useState(state.tenantSystem);
    const [selectedValue, setSelectedValue] = useState(state.Selected_Value);
    const [emailList, setEmailList] = useState(state.emailList);
    const [listOfProjectSubscopes, setListOfProjectSubscopes] = useState(state.listOfSubscopes);
    const [selectedKey, setSelectedKey] = useState(state.Selected_Key);
    const [oldSelectedKey, setOldSelectedKey] = useState(state.OldSelected_key);
    const [userData, setUserData] = useState(state.userData);
    const [editMode, setEditMode] = useState(state.editMode);

    const {popupCreate, setLoading} = useMain();

    useEffect(() => {
        if (tenantSystem.length <= 1 &&
            HubPathRouting.currentProject &&
            tenantSystem[0].fullscope.indexOf(HubPathRouting.currentProject.moduleInstanceId) === -1) {
                addGlobalScope()
        }
    }, []);

    /**
     * Edit the value of a User scope
     * @param id id of the scope line
     * @param value value
     */
    const EditScopeValue = (id: number, value: string) => {
        let tenantLines = [...tenantSystem]
        tenantLines.find(x => x.id === id).value = value
        setTenantSystem(tenantLines)
    }

    /**
     * Add a full scope to the tenant system to the webpage backend
     */
    const addGlobalScope = () => {
        let scopes = [...tenantSystem];
        let newLine: TenantLine = {id: scopes.length, scopes : ["user"], value : defaultAuth, tmp: "", fullscope: ""}
        if(projectId !== "null") {
            newLine.scopes.push(projectId)
        }
        scopes.push(newLine)
        setTenantSystem(scopes)
    }

    /**
     * Saves the current user config
     */
    const saveUser = async () => {
        // if the key already exists and the user wants to ADD a user, interrupt and show an error message
        if((oldSelectedKey === "" && selectedKey !== "" && Object.keys(userData).indexOf(selectedKey) !== -1) ||
            // if the user is new
            (selectedKey === "" && selectedValue.mail !== "" &&
                // exists by usermail
                (Object.keys(userData).filter(x => userData[x].mail === selectedValue.mail).length > 0 ||
                // exists by usermail in key
                 Object.keys(userData).indexOf(selectedKey) !== -1 ))
        ) {
            popupCreate("Error", "The user already exists. Please choose another key or email.")
            return
        }
        // if a user of the email list already exists, interrupt and show an error message
        if(emailList.length >= 1 && Object.keys(userData).filter(x => emailList.indexOf(userData[x].mail) !== -1).length > 0) {
            popupCreate("Error", "At least one user of the email list already exists. Please choose another email.")
            return
        }

        let user: IUserData = {
            key: selectedKey,
            mail: selectedValue.mail,
            lastLogin: selectedValue.lastLogin,
            tenantData: {}
        }

        // evaluate tenant data
        for (let line of tenantSystem) {
            // skip empty value and empty scopes
            if(line.value === "-" || line.scopes.length === 0) {
                continue
            }
            user.tenantData[line.scopes.join('/')] = line.value
        }

        // use the key if already given
        if(selectedKey !== "") {
            user.userId = selectedKey
            // if the email field contains a valid email as well, add it too
        } else if(isValidEmail(selectedValue.mail) && emailList.indexOf(selectedValue.mail) === -1) {
            user.userId = selectedValue.mail
        } else {
            say("Invalid operation")
        }


        // add the user to the list of users
        if(emailList.find(x => x === user.userId) === undefined && user.userId !== undefined) {
            emailList.push(user.userId)
        }

        // on project level, remove the global scope
        if(HubPathRouting.currentProject) {
            delete user.tenantData["user"]
        }

        setLoading(true)
        let errors: string[] = []
        let promises: Promise<void>[] = []
        emailList.forEach((email) => {
            user.userId = email
            promises.push(UpdateAccessRoles(user, projectId).then(async (data) => {
                if(!data.success) {
                    errors.push(email + ": " + data.message)
                }
            }))

        })
        // wait for all promises
        await Promise.all(promises)
        if(errors.length > 0) {
            popupCreate("Error", "Error while saving user: " + errors.join(", "))
            setLoading(false)
        } else {
            onSuccessFunction()
        }
    }

    /**
     * displays the user config for the individual scopes
     */
    const GetAuthLevel_NewTenantManagement = () => {
        let globalScope = <></>
        // hub level configuration
        if(projectId === "null") {
            // id of the global scope
            let globalid = tenantSystem.find(x => x.scopes.length === 1 && x.scopes.every(
                (value, index) => value === ["user"][index]
            )).id
            // set global to default auth if not set
            if(tenantSystem.find(x => x.id === globalid).value === "") {
                EditScopeValue(globalid, defaultAuth)
            }
            // if called from within project, don't show global scope
            globalScope = <SetGlobalTenantSystemContent
                tenantSystem={tenantSystem}
                authLevels={AuthLevels}
                defaultAuth={defaultAuth}
                globalId={globalid}
                onEditFunction={EditScopeValue.bind(this)}
            />
        }

        return <Row>
            <Col>
                {globalScope}
                {/* Individual scopes */}
                <UserTenantManagement tenantSystemProps={tenantSystem}
                                      setTenantSystemFunctionProps={handleSetTenantSystem.bind(this)}
                                      projectId={projectId}
                                      authLevels={AuthLevels}
                                      listOfIDMSSubscopes={[]}
                                      global={projectId === "null"}
                                      useSelectOptions={true}
                                      />
                <p>Please remember to click "Add" for each new scope.</p>
                <br/>

                <Button className="uib-button uib-button--primary"
                        id={"addScope"}
                        onClick={() => addGlobalScope()}>
                    Add Scope
                </Button>
            </Col>
        </Row>;
    }

    const handleSetTenantSystem = (tenantSystem: TenantLine[]) => {
        setTenantSystemFunction(tenantSystem)
    }

    const setTenantSystemFunction = (tenantSystem: TenantLine[]) => {
        setTenantSystem(tenantSystem)
    }

    const emailListDeleteItem = (item: string) => {
        let emails = emailList;
        emails.splice(emails.indexOf(item), 1);
        setEmailList(emails)
    }

    const addMailsToList = (maillist: string[]) => {
        let errors: string[] = []
        maillist.forEach(item => {
            let error = addMailToList(item)
            if(error !== undefined) {
                errors.push(error);
            }
        })
        if(errors.length > 0) {
            popupCreate("Error", "Errors during adding " + errors.join(", ") + ". Probably wrong or duplicate email address.")
        }
    }

    /**
     * Add mail to emaillist
     */
    const addMailToList = (newMail: string = "") => {
        let newmailtmp: string;
        if(newMail !== "") {
            newmailtmp = newMail;
        } else if (selectedValue.mail !== "") {
            newmailtmp = selectedValue.mail
        }
        if(isValidEmail(newmailtmp)) {
            if(emailList.indexOf(newmailtmp) !== -1) {
                popupCreate("Error", "Email already exists")
                return newmailtmp
            }

            let user = selectedValue;
            user.mail = "";
            setEmailList([...emailList, newmailtmp])
            setSelectedValue(user)
            return
        } else {
            popupCreate("Error", "Please enter a valid email address")
            return newmailtmp
        }
    }

    // let authLevel: ReactElement
    //
    // authLevel = getAuthLevel_NewTenantManagement()

    let applyButton = <div></div>
    if(isValidEmail(selectedValue.mail) ||
        (selectedValue.mail === "" && emailList.length > 0)) {
        // check if all tmp values of users_ tenent system are empty
        if(tenantSystem.every(x => x.tmp === "")) {
            applyButton = <Button className={`uib-button uib-button--primary ${styles.addUserButton}`}
                                  id={"applyButton"}
                                  onClick={() => {saveUser()}}>Apply</Button>
        } else {
            // show a warning if there are still values in the tenant-system
            applyButton = <OverlayTrigger
                placement="top"
                delay={{ show: 50, hide: 200 }}
                overlay={
                    <Tooltip id="tooltip-disabled">
                        Please make sure all Scopes are added by clicking "Add".
                    </Tooltip>
                }>
                <Button className={`uib-button uib-button--primary ${styles.addUserButton} ${styles.warningButton}`}
                        id={"applyButton"}
                        onClick={() => {saveUser()}}>Apply</Button>
            </OverlayTrigger>
        }
    }

    return <div>
        <ModalHeader closeButton>
            {editMode === IUsersEditMode.Add ? "Add" : "Edit"} User
        </ModalHeader>
        <ModalBody>
            <Container>
                {emailList.length > 0 && editMode === IUsersEditMode.Add &&
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">
                            Emails
                        </Col>
                        <Col>
                            {emailList.map((item, index) => {
                                return <Button className={`${styles.spaceRight} ${styles.spaceButtom}`}
                                               variant="primary"
                                               key={index}
                                               id={"emailList" + index}
                                               onClick={() => emailListDeleteItem(item)}>
                                    {item} {IconTrash}
                                </Button>
                            })}
                        </Col>
                    </Row>
                }

                <Row className={styles.popupSpaceBetween}>
                    <Col md={2} className="align-self-center">
                        Email
                    </Col>
                    { editMode === IUsersEditMode.Add ?
                        <>
                            <Col >
                                <FormControl
                                    placeholder="Email adress"
                                    value={selectedValue.mail}
                                    id="users_email"
                                    onChange={(event) => {
                                        let user = selectedValue;
                                        user.mail = event.target.value;
                                        setSelectedValue(deepCopy(user))
                                    }} />
                            </Col>
                            <Col md={"auto"}>
                                <Button className="uib-button uib-button--primary"
                                        variant="primary"
                                        id={"addButton"}
                                        onClick={() => addMailToList()}>
                                    Add
                                </Button>
                            </Col>
                            <Col md={"auto"}>
                                <FileUploaderUserMails handleFile={(e) => addMailsToList(e)}/>
                            </Col>
                        </>
                        : // edit mode
                        <Col >
                            <FormControl
                                placeholder="Email adress"
                                value={selectedValue.mail}
                                id="users_email"
                                disabled={true}
                                 />
                        </Col>
                    }
                </Row>
                {!isValidEmail(selectedKey) &&
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">
                            Key
                        </Col>
                        <Col>
                            {selectedKey}
                        </Col>
                    </Row> }
                {/*<GetAuthLevel_NewTenantManagement />*/
                GetAuthLevel_NewTenantManagement()
                }
            </Container>
        </ModalBody>
        <ModalFooter>
            <Button className="uib-button uib-button--secondary"
                    variant="secondary"
                    onClick={() => closeFunction()}
                    id={"cancelButton"}
            >Cancel</Button>
            {applyButton}
        </ModalFooter>
    </div>;
    
}

export default Hub_AddEditModal;