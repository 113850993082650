import {KafkaEvents, Loglevel, Source, SourceLayer} from "../Interfaces/IKafkaEvents";
import {IModule} from "../../../BackendFacade/IModuleInstances";
import {GlobalDictionary} from "../../../Utils/globalDictionary";
import {HubPathRouting} from "../../../HubFramework/pathBuilder";
import {concatListsTakeUniqueItems, deepCopy} from "../../../Utils/transformer";
import {IProject} from "../../Hub/Interfaces/IAllProjects";


const hiddenColumns = ["Id", "Source", "show"]

export function calcTables(kafkaEvents: KafkaEvents[]) {
    let sources: { [key: string]: Source } = {}
    kafkaEvents.forEach((event) => {
        if (event === undefined || event.Source === undefined) {
            return
        }

        // sort sources
        let source = event.Source.replace("https://draeger.com/", "")
        event.show = true
        if(sources[source] === undefined) {
            sources[source] = {KafkaEvents: [], tableHeads: [], hiddenColumns: hiddenColumns, show: false, SortbyKey: "Time", SortDescend: true}
        }
        sources[source].KafkaEvents.push(event)
    })

    // assign sources into the layers
    let layers = createTemplateSourceLayer("DAMS", undefined, [], true)
    Object.keys(sources).forEach((source) => {
        recursiveSourceLayer(layers, source, sources[source]);
    })
    // reduce depth of layers
    reduceLayerDepth(layers)

    // summarize sources in upper layers
    mergeEventsToAllLayers(layers)

    return layers
}

function createTemplateSourceLayer(name: string, source: Source = undefined, layer: SourceLayer[] = [], show = false, shownLogLevel = Loglevel.Info, numberOfInfoEvents = 0, numberOfWarningEvents = 0, numberOfErrorEvents = 0): SourceLayer {
    return {
        name: name,
        source: source,
        layer: layer,
        show: show,
        numberOfInfoEvents: numberOfInfoEvents,
        numberOfWarningEvents: numberOfWarningEvents,
        numberOfErrorEvents: numberOfErrorEvents
    }
}

function recursiveSourceLayer(layer: SourceLayer, sourceString: string, eventTable: Source) {
    let [sourceName, ...rest] = sourceString.split("/")
    let remainder = rest.join("/")

    if(sourceName.toLowerCase() === "devsecops" ) {
        sourceName = "DevSecOps"
    }
    if (layer.layer.find((layer) => layer.name === sourceName) === undefined) {
        layer.layer.push(createTemplateSourceLayer(sourceName))
    }

    if(remainder != "") {
        recursiveSourceLayer(layer.layer.find((layer) => layer.name === sourceName), remainder, eventTable)
    } else {
        calcTableHeads(eventTable)
        layer.layer.find((layer) => layer.name === sourceName).source = eventTable
    }

    // Replace IDs with display names
    replaceModuleInstanceIdsWithNames(layer)
}

/**
 * Replace the Ids in the source tree with the display names
 * @param layers
 */
function replaceModuleInstanceIdsWithNames(layers: SourceLayer) {
    const moduleInstances = GlobalDictionary.get("kafkaModuleInstances");

    // Define a regular expression pattern to match alphanumeric strings that include at least one number
    const idPattern = /\b[a-zA-Z]*[0-9][a-zA-Z0-9]*\b/g;

    for (const layer of layers.layer) {
        let projectId = "";

        // Search for IDs in the layer name using the regular expression pattern
        const matches = layer.name.match(idPattern);
        if (matches) {
            for (const match of matches) {
                const moduleInstance = moduleInstances.find((instance: { linkedId: { projectId: string; }; }) => match === instance.linkedId.projectId);
                if (moduleInstance) {
                    layer.name = layer.name.replace(new RegExp(`\\b${match}\\b`, 'g'), moduleInstance.displayName);
                    projectId = moduleInstance.projectId;
                }
            }
        }

        // Perform similar replacement for module instance IDs

        // Replace project IDs with project names if needed
        if (HubPathRouting.currentProject) {
            const currentProjectId = HubPathRouting.currentProject.moduleInstanceId;
            const currentProjectName = HubPathRouting.currentProject.displayName;
            layer.name = layer.name.replace(new RegExp(`\\b${currentProjectId}\\b`, 'g'), currentProjectName);
        } else {
            const projects = GlobalDictionary.get("projects");
            projects.forEach((project: { projectId: string; displayName: string; }) => {
                layer.name = layer.name.replace(new RegExp(`\\b${project.projectId}\\b`, 'g'), project.displayName);
            });
        }

        // Recursively apply the same replacement logic to child layers
        replaceModuleInstanceIdsWithNames(layer);
    }
}



/**
 * Merge all events in deeper layers to the upper layers
 * @param currentLayer
 */
function mergeEventsToAllLayers(currentLayer: SourceLayer) {
    currentLayer.layer.forEach((layer) => {
        mergeEventsToAllLayers(layer)

        if(layer.source !== undefined) {
            // if there is no merged source so far, create one
            if(currentLayer.mergedSources === undefined) {
                currentLayer.mergedSources = deepCopy(layer.source)
            } else {
                // merge the source into the merged source
                layer.source.KafkaEvents.forEach((event) => {
                    currentLayer.mergedSources.KafkaEvents.push(event)
                })
                currentLayer.mergedSources.tableHeads = concatListsTakeUniqueItems(currentLayer.mergedSources.tableHeads, layer.source.tableHeads)
            }
        }

        if(layer.mergedSources !== undefined) {
            // if there is no merged source so far, create one
            if(currentLayer.mergedSources === undefined) {
                currentLayer.mergedSources = deepCopy(layer.mergedSources)
            } else {
                // merge the source into the merged source
                layer.mergedSources.KafkaEvents.forEach((event) => {
                    currentLayer.mergedSources.KafkaEvents.push(event)
                })
            }
            // copy values from direct source
            if(layer.source) {
                // merge the source into the merged source
                layer.source.KafkaEvents.forEach((event) => {
                    layer.mergedSources.KafkaEvents.push(event)
                })
                layer.mergedSources.tableHeads = concatListsTakeUniqueItems(layer.mergedSources.tableHeads, layer.source.tableHeads)
            }
            currentLayer.mergedSources.tableHeads = concatListsTakeUniqueItems(currentLayer.mergedSources.tableHeads, layer.mergedSources.tableHeads)
        } else {
            layer.mergedSources = deepCopy(layer.source)
        }
    })
}

/**
 * Reduce the depth of the layer tree, if the layer has only one child
 * @param layer
 */
function reduceLayerDepth(layer: SourceLayer) {
    layer.layer.forEach((child) => {
        reduceLayerDepth(child)
    })

    // layers that should not be merged
    const neverMerge = ["doop", "DevSecOps", "DPAS", "KeyChanger", "idms", "hub"]

    // if the layer has only one child, merge the child into the parent
    if(layer.layer.length === 1 && !neverMerge.some(x => x.toLowerCase() === layer.name.toLowerCase())) {
        layer.name = layer.name + " / " + layer.layer[0].name
        layer.numberOfWarningEvents = layer.layer[0].numberOfWarningEvents
        layer.numberOfErrorEvents = layer.layer[0].numberOfErrorEvents
        layer.numberOfInfoEvents = layer.layer[0].numberOfInfoEvents
        layer.show = layer.layer[0].show
        if(layer.layer[0].source !== undefined) {
            layer.source = layer.layer[0].source
        }
        layer.layer = layer.layer[0].layer
    }
}

/**
 * Computes the possible headers for the table
 * @param source
 */
function calcTableHeads(source: Source) {
    let tableHeads: string[] = ["Id", "Source", "LogLevel", "Type", "Subject", "Time"]

    source.KafkaEvents.forEach((event) => {
        Object.keys(event).forEach((key) => {
            if(tableHeads.indexOf(key) === -1 && source.hiddenColumns.indexOf(key) === -1) {
                tableHeads.push(key)
            }
        })
    })
    source.tableHeads = tableHeads
}

export function fixModuleName(name: string) {
    switch(name.toLowerCase()) {
        case "doop":
            return "DOOP"
        case "hub":
            return "Hub"
        case "idms":
            return "IDMS"
        default:
            return name
    }
}