import * as React from "react";
import KafkaEventsPage from "../../DAMS/Tabs/KafkaEvents";
import {ReactElement} from "react";

/**
 * This is the Logs tab in the Hub module. It is a wrapper around the KafkaEventsPage from the Hub module.
 */

export class Logs extends React.Component<{}, {}> {
    constructor(props: any) {
        super(props);
    }

    public render() : ReactElement {
        return <KafkaEventsPage/>
    }
}