import {IContent} from "../../HubFramework"
import Files from "./Tabs/Files";
import Config from "./Tabs/Config";
import {Logs} from "./Tabs/Logs";

/**
 * The content of the module
 * This is mandatory for every module. It contains all the information about the module.
 */
export const moduleContent: IContent = {
    moduleName: "dpas",
    title: "Dräger Product Asset Storage (DPAS)",
    pages: [
        {
            displayName: "Files",
            contentPage: Files
        },
        {
            displayName: "Configuration",
            contentPage: Config
        },
        // {
        //     displayName: "Logs",
        //     contentPage: Logs
        // }
    ],
    creationProps: [
    ],
    defaultPage: "Files",
};
