import * as React from "react";
import {Table} from "react-bootstrap";
import {IListOfModules} from "../../../HubFramework/IHub";
import {getListOfAllModules} from "../../../Utils/modules";
import {ReactElement} from "react";

const AllModulePageInformation = () => {

    const tableOfModules = (): ReactElement => {
        let mods: IListOfModules = getListOfAllModules()
        return <>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Module Name</th>
                        <th>Pages</th>
                        <th>Default Page</th>
                    </tr>
                </thead>
                <tbody>
                {Object.keys(mods).map((key, index) => {
                    return <tr key={key}>
                        <td>{mods[key].moduleContent.title}</td>
                        <td>{mods[key].moduleContent.moduleName}</td>
                        <td>{mods[key].moduleContent.pages.filter((i) => i.displayName !== "").map((i) => i.displayName).join(', ')}</td>
                        <td>{mods[key].moduleContent.defaultPage}</td>
                    </tr>
                })}

                </tbody>
            </Table>
        </>
    }

        return <>
            <p> This page contains infos about all modules in DevSecOps Hub.</p>
            <br />
            {tableOfModules()}
        </>

}

export default AllModulePageInformation;