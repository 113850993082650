import * as React from "react";
import Documentation from "../../Documentation/Documentation";

const HubDocumentation: React.FC = () => {
        return <>
            <Documentation component={"hub"}/>
        </>

}

export default HubDocumentation;
