import {IContact} from "../../Hub/Interfaces/IContact";
import {IProjectInformation_Edit} from "./IProjectInformation_Edit";

export interface IProjectInformation {
    description: string,
    contacts: IContact[],
    costCenter: number,
    department: string,
    product: string,

    popup: Popup,
    popupMessage: string,
    editModal: IProjectInformation_Edit,

    customProjectIcon: string,
    internalOrderNumber: number,
}

export enum Popup {
    None,
    Error,
    Edit,
    DeleteProject
}

export interface ITableRow {
    showFormControl: {
        mail: boolean,
        job: boolean,
    }
}
