import * as React from "react";
import {Popup, DoopUser, animatedComponents,
    convertListOfStringsToOptionsList,} from "../Interfaces/IDOOP";
import {NetworkData} from "../../../BackendFacade";
import {
    Button,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    Row, Table,
} from "react-bootstrap";
import Select from "react-select";
import {sendUserDelete, sendUserData} from "../BackendFacade/apiCalls";
import ModalHeader from "react-bootstrap/ModalHeader";
import {Module, OptionOIS} from "../module";
import styles from './../Doop.module.css'
import {useEffect} from "react";
import {isUserroleSufficient} from "../../../Utils/authorization";
import {UserRoles} from "../../../Utils";
import {HubPathRouting} from "../../../HubFramework/pathBuilder";
import {useMain} from "../../../Utils/SessionControls/mainContext";
import {useOutletContext} from "react-router-dom";

const EditUsers: React.FC = () => {
    const [popup, setPopup] = React.useState(Popup.None);
    const [PopupMessage, setPopupMessage] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [Name, setName] = React.useState("");
    const [CanAcceptOrders, setCanAcceptOrders] = React.useState(false);
    const [CanInitiateProcess, setCanInitiateProcess] = React.useState(false);
    const [IsContractor, setIsContractor] = React.useState(false);
    const [IsOrderInformed, setIsOrderInformed] = React.useState(false);
    const [Areas, setAreas] = React.useState<OptionOIS[]>([]);

    const parentModule = useOutletContext() as Module;
    const {setLoading, popupCreate} = useMain()

    useEffect(() => {
        getProjectMetaData()
    }, []);

    const getProjectMetaData = async () => {
        setLoading(true)
        let prom1 = parentModule.getProjectData().then(data => {
            if(!data.success) {
                popupCreate("Error", data.message)
            }
            setLoading(false)
        })
        await Promise.all([prom1])
    }

    const PopupClose = () => {
        setPopup(Popup.None)
        setPopupMessage("")
    }

    /**
     * Prepare the variables for the popup
     * @param name
     * @param email
     * @param canAccept
     * @param canInit
     * @param isContractor
     * @param isOrderInformed
     * @param areas
     * @constructor
     */
    const EditUserShow = (name = "", email = "", canAccept = false, canInit = false,
                 isContractor = false, isOrderInformed = false, areas = [] as string[]) => {
        // new user
        if(name === "") {
            setName("")
            setEmail("")
            setCanAcceptOrders(false)
            setCanInitiateProcess(false)
            setIsContractor(false)
            setIsOrderInformed(false)
            setAreas([])
            setPopup(Popup.EditUser)
        // edit user
        } else {
            setPopup(Popup.EditUser)
            setName(name)
            setEmail(email)
            setCanAcceptOrders(canAccept)
            setCanInitiateProcess(canInit)
            setIsContractor(isContractor)
            setIsOrderInformed(isOrderInformed)
            setAreas(convertListOfStringsToOptionsList(areas))
        }
    }

    const performPopupAndClose = async (data: NetworkData) => {
        if(!data.success) {
            popupCreate("Error", data.message)
        } else {
            PopupClose()
            await getProjectMetaData()
        }
    }

    const submitUser = () => {
        if(Name === "" || Email === "") {
            popupCreate("Error", "Name and Email are required")
            return
        }
        let data: DoopUser = {
            name: Name,
            email: Email,
            canAcceptOrders: CanAcceptOrders,
            canInitiateProcess: CanInitiateProcess,
            isContractor: IsContractor,
            isOrderInformed: IsOrderInformed,
            areas: Areas.map(x => x.value),
        }
        setLoading(true)
        sendUserData(data, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId).then(
            data2 => {
                performPopupAndClose(data2).then(() => {
                    setLoading(false) // should be called via getProjectData
                })
            }
        )
    }

    const deleteUserConfirm = (name: string) => {
        setLoading(true)
        sendUserDelete(name, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId).then(
            data2 => {
                performPopupAndClose(data2).then(() => {
                    setLoading(false) // should be called via getProjectData
                })
            }
        )
    }

    const deleteUserShow = (name: string) => {
        setPopup(Popup.DeleteUser)
        setName(name)
    }

    const DeleteUserContent = () =>{
        return <div>
            <ModalHeader>
                Delete User
            </ModalHeader>
            <ModalBody>
                Are you sure you want to delete the user {Name}?
            </ModalBody>
            <ModalFooter>
                <button className={`uib-button uib-button--secondary ${styles.mr10}`}
                        onClick={() => PopupClose()}>
                    Cancel
                </button>
                <button className="uib-button uib-button--primary"
                        onClick={() => deleteUserConfirm(Name)}>
                    Delete
                </button>
            </ModalFooter>
        </div>;
    }

    const EditUserContent = () => {
        return <div>
            <ModalHeader closeButton>
                User Configuration
            </ModalHeader>
            <ModalBody>
                <Container>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={3} className="align-self-center">
                            Name
                        </Col>
                        <Col>
                            <Container>
                                <Row md={2}>
                                    <div className="uib-form-field__control uib-textfield">
                                        <input type="text" className="uib-textfield__input" id="name" value={Name}
                                               onChange={(e) => setName(e.target.value)}/>
                                        <span className="uib-textfield__validation-icon" />
                                    </div>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={3} className="align-self-center">
                            Email
                        </Col>
                        <Col>
                            <Container>
                                <Row md={2}>
                                    <div className="uib-form-field__control uib-textfield">
                                        <input type="text" className="uib-textfield__input" id="email" value={Email}
                                               onChange={(e) => setEmail(e.target.value) }/>
                                        <span className="uib-textfield__validation-icon" />
                                    </div>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={3} className="align-self-center">
                            Accessible Areas
                        </Col>
                        <Col>
                            <Container>
                                <Row md={2}>
                                    <div className="uib-form-field__control uib-textfield">
                                        <Select closeMenuOnSelect={false}
                                                id = "areas"
                                                components={animatedComponents}
                                                placeholder="Select areas. If empty, all are selected"
                                                isMulti
                                                options={convertListOfStringsToOptionsList(parentModule.state.Meta.doopAreas)}
                                                onChange={(option: OptionOIS[]) => {
                                                    setAreas(option)
                                                }}
                                                value={Areas}
                                        ></Select>
                                    </div>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={3} className="align-self-center">
                            Is PO/PM
                        </Col>
                        <Col>
                            <Container>
                                <Row md={2}>
                                    <label className="uib-checkbox">
                                        <input type="checkbox" className="uib-checkbox__input" id="caninit"
                                               checked={CanInitiateProcess}
                                               onChange={(e) => setCanInitiateProcess(e.target.checked)}/>
                                        <span className="uib-checkbox__checkmark" />
                                    </label>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={3} className="align-self-center">
                            Is Service Manager
                        </Col>
                        <Col>
                            <Container>
                                <Row md={2}>
                                    <label className="uib-checkbox">
                                        <input type="checkbox" className="uib-checkbox__input"
                                               checked={CanAcceptOrders}
                                               onChange={(e) => setCanAcceptOrders(e.target.checked)}/>
                                        <span className="uib-checkbox__checkmark" />
                                    </label>
                                </Row>
                            </Container>
                        </Col>
                    </Row>

                    <Row className={styles.popupSpaceBetween}>
                        <Col md={3} className="align-self-center">
                            Is Contractor
                        </Col>
                        <Col>
                            <Container>
                                <Row md={2}>
                                    <label className="uib-checkbox">
                                        <input type="checkbox" className="uib-checkbox__input" id={"IsContractor"}
                                               checked={IsContractor}
                                               onChange={(e) => setIsContractor(e.target.checked) }/>
                                        <span className="uib-checkbox__checkmark"></span>
                                    </label>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={3} className="align-self-center">
                            Is Ext. Service Manager
                        </Col>
                        <Col>
                            <Container>
                                <Row md={2}>
                                    <label className="uib-checkbox">
                                        <input type="checkbox" className="uib-checkbox__input"
                                               checked={IsOrderInformed}
                                               onChange={(e) => setIsOrderInformed(e.target.checked)}/>
                                        <span className="uib-checkbox__checkmark"></span>
                                    </label>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
            <ModalFooter>
                <button className={`uib-button uib-button--secondary ${styles.mr10}`}
                        onClick={PopupClose}>Cancel
                </button>
                <button className="uib-button uib-button--primary" onClick={() => {
                    submitUser()
                }}>Save
                </button>
            </ModalFooter>
        </div>
    }

    const Users_AreasColumn = (areas: string[]) => {
        // if areas is empty or areas contains the same elements as All areas
        if (areas.length === 0) {
            return <div className={styles.green}>All</div>
        }
        return <div>{areas.join(", ")}</div>
    }


    let popupContent = <div></div>
    switch(popup) {
        case Popup.EditUser:
            popupContent = EditUserContent()
            break;
        case Popup.DeleteUser:
            popupContent = <DeleteUserContent />
            break;
        default:
            break;
    }
    return <>
            <Modal size="lg" show={popup !== Popup.None} onHide={PopupClose} >
                {popupContent}
            </Modal>

            <div className={styles.mainScreenHeadline}>Edit Users</div>
            <Table striped className={styles.maintable}>
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Name</th>
                    <th>Areas</th>
                    <th>PO/PM</th>
                    <th>Service Manager</th>
                    <th>Contractor</th>
                    <th>Ext. Service Manager</th>
                    {isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject?.moduleInstanceId, HubPathRouting.currentModule?.moduleInstanceId) && <>
                        <th className={styles.alignCenter}>Edit</th>
                        <th className={styles.alignCenter}>Delete</th>
                    </>}
                </tr>
                </thead>
                <tbody>
                {
                    parentModule.state.DoopUser.map((col, index) => {
                        return <tr key={index}>
                            <td className={styles.formatedTabletext}>{col.email}</td>
                            <td className={styles.formatedTabletext}>{col.name}</td>
                            <td className={styles.formatedTabletext}>{Users_AreasColumn(col.areas)}</td>
                            <td className={`${styles.alignCenter} ${styles.biggerIcons}`}>{col.canInitiateProcess ? <i className={`uib-icon uib-icon--ok ${styles.green}`}></i> : <i className={`uib-icon uib-icon--close ${styles.red}`}></i>}</td>
                            <td className={`${styles.alignCenter} ${styles.biggerIcons}`}>{col.canAcceptOrders ? <i className={`uib-icon uib-icon--ok ${styles.green}`}></i> : <i className={`uib-icon uib-icon--close ${styles.red}`}></i>}</td>
                            <td className={`${styles.alignCenter} ${styles.biggerIcons}`}>{col.isContractor ? <i className={`uib-icon uib-icon--ok ${styles.green}`}></i> : <i className={`uib-icon uib-icon--close ${styles.red}`}></i>}</td>
                            <td className={`${styles.alignCenter} ${styles.biggerIcons}`}>{col.isOrderInformed ? <i className={`uib-icon uib-icon--ok ${styles.green}`}></i> : <i className={`uib-icon uib-icon--close ${styles.red}`}></i>}</td>
                            {isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject?.moduleInstanceId, HubPathRouting.currentModule?.moduleInstanceId) && <>
                                <td className={styles.alignCenter }><button id={`Edit-${index}`} className={`${styles.biggerIcons} ${styles.noShadow}`} onClick={() => {EditUserShow(col.name, col.email, col.canAcceptOrders, col.canInitiateProcess, col.isContractor, col.isOrderInformed, col.areas)}}><i className="uib-icon uib-icon--edit "></i></button></td>
                                <td className={styles.alignCenter }><button id={`Delete-${index}`} className={`${styles.biggerIcons} ${styles.noShadow}`} onClick={() => {deleteUserShow(col.email)}}><i className="uib-icon uib-icon--delete "></i></button></td>
                                </>
                            }
                        </tr>
                    })
                }
                </tbody>
            </Table>
        {
            isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject!.moduleInstanceId, HubPathRouting.currentModule!.moduleInstanceId) &&
            <Button onClick={() => {EditUserShow()}} className="uib-button uib-button--primary">Add</Button>
        }
    </>
}

export default EditUsers;