import * as React from "react";
import { ReactElement, useEffect } from "react";
import { Module, OptionOIS } from "../module";
import {
    animatedComponents,
    Contractor,
    convertAreasToOptionsList,
    findIndexInContractorNames,
    Popup
} from "../Interfaces/IDOOP";
import { CONTRACTORS, DOOPAREAS, EditProjectShownKeys, IconTrash } from "../constants";
import { Button, Col, Container, Form, Modal, ModalBody, ModalFooter, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { setProjectMeta } from "../BackendFacade/apiCalls";
import ModalHeader from "react-bootstrap/ModalHeader";
import styles from './../Doop.module.css';
import { isUserroleSufficient } from "../../../Utils/authorization";
import { UserRoles } from "../../../Utils";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
import { useMain } from "../../../Utils/SessionControls/mainContext";
import { useOutletContext } from "react-router-dom";
import EditProjectModal from "./EditProjectModal";

const Configuration: React.FC = () => {
    const [popup, setPopup] = React.useState(Popup.None);
    const [Name, setName] = React.useState("");
    const [Value, setValue] = React.useState<string | string[] | boolean>("");
    const [Areas, setAreas] = React.useState<OptionOIS[]>([]);
    const [Contractors, setContractors] = React.useState<Contractor[]>([]);
    const parentModule = useOutletContext() as Module;

    const { setLoading, popupCreate } = useMain();

    useEffect(() => {
        setLoading(true);
        let prom1 = getProjectMetaData();
        Promise.all([prom1]).then(() => {
            setLoading(false);
        });
    }, []);

    const getProjectMetaData = async () => {
        let prom1 = parentModule.getProjectData().then(data => {
            if (!data.success) {
                popupCreate("Error", data.message);
            }
        });
        await Promise.all([prom1]);
    };

    const EditProjectShow = (name: string, value: string | string[] | boolean | Contractor[]) => {
        switch (name) {
            case DOOPAREAS:
                setAreas(convertListOfStringsToOptionsList(value as string[]));
                break;
            case CONTRACTORS:
                setContractors(value as Contractor[]);
                setValue("");
                break;
            default:
                setValue(value as string | string[] | boolean);
                break;
        }
        setPopup(Popup.EditProject);
        setName(name);
    };

    const convertListOfStringsToOptionsList = (areas: string[]) => {
        let options: any[] = [];
        if (areas === undefined) {
            return options;
        }
        areas.forEach((x) => {
            options.push({
                value: x,
                label: x
            });
        });
        return options;
    };

    const evaluateDisplayedValue = (value: string | string[] | boolean | Contractor[]) => {
        if (value === undefined)
            return <div></div>;
        // if value is a list
        if (Array.isArray(value)) {
            if (value.length > 0) {
                switch (typeof value[0]) {
                    case "string":
                        return <div>{value.join(", ")}</div>;
                    // default is Contractor[]
                    default:
                        return <div>{(value as Contractor[]).map((v) => v.Name).join(", ")}</div>;
                }
            }
        }
        let strValue = value.toString();
        if (strValue[0] === '[' && strValue[strValue.length - 1] === ']') {
            let val = JSON.parse(strValue);
            return <div>{val.join(', ')}</div>;
        } else if (strValue === "true") {
            return <div className={styles.biggerIcons}><i className="uib-icon uib-icon--ok"></i></div>;
        } else if (strValue === "false") {
            return <div className={styles.biggerIcons}><i className="uib-icon uib-icon--close"></i></div>;
        } else {
            return <div>{strValue}</div>;
        }
    };

    if (parentModule.state.Meta === null) {
        return <div></div>;
    }

    return <>
        <Modal size="lg" show={popup !== Popup.None} onHide={() => setPopup(Popup.None)} id="edit-project-modal">
            <EditProjectModal
                parentModule={parentModule}
                name={Name}
                areas={Areas}
                value={Value}
                contractors={Contractors}
                closePopup={() => setPopup(Popup.None)}
            />
        </Modal>

        <div className={styles.mainScreenHeadline} id="main-screen-headline">Edit Project</div>
        <Table striped className={styles.maintable} id="main-table">
            <thead id="table-header">
            <tr>
                <th id="header-key">Key</th>
                <th id="header-value">Value</th>
                {isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject?.moduleInstanceId, HubPathRouting.currentModule?.moduleInstanceId) && <>
                    <th className={styles.alignCenter} id="header-edit">Edit</th>
                </>}
            </tr>
            </thead>
            <tbody id="table-body">
            {
                EditProjectShownKeys.map((col, index) => {
                    return (
                        <tr key={index} id={`row-${index}`}>
                            <td id={`key-${index}`} className={styles.ePKey}>{col}</td>
                            <td id={`value-${index}`} className={styles.ePKey}>
                                {evaluateDisplayedValue(parentModule.state.Meta[col])}
                            </td>
                            {isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject?.moduleInstanceId, HubPathRouting.currentModule?.moduleInstanceId) && <>
                                <td id={`edit-${index}`} className={`${styles.alignCenter} ${styles.ePEdit}`}>
                                    <button id={`edit-button-${index}`} className={`${styles.biggerIcons} ${styles.noShadow}`} onClick={() => { EditProjectShow(col, parentModule.state.Meta[col]) }}>
                                        <i className="uib-icon uib-icon--edit"></i>
                                    </button>
                                </td>
                            </>}
                        </tr>
                    );
                })
            }
            </tbody>
        </Table>
    </>;
};

export default Configuration;
