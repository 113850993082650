import makeAnimated from "react-select/animated";

export const animatedComponents = makeAnimated();


export interface IDOOP {
    Popup: Popup,
    DoopUser: DoopUser[],
    CurrentLog: "",
    Meta: ProjectMeta,
    AllAreas: Area[],
    // getProjectData: () => Promise<NetworkData>,
    Error: string,
    // projectId: string,
    // moduleInstanceId: string,
}
export function findIndexInContractorNames(list: Contractor[], item: string): number {
    let i = 0
    for(let element of list) {
        if(element.Name === item) {
            return i
        }
        i++
    }
    return -1
}

export interface Contractor {
    kind: "contractor",
    Name: string,
}

export interface Area {
    name: string,
    id: number,
    identifier: string,
}

export interface DoopUser {
    canAcceptOrders: boolean,
    canInitiateProcess: boolean,
    email: string,
    isContractor: boolean,
    isOrderInformed: boolean,
    name: string,
    areas: string[],
}


export interface ProjectData {
    area?: string,
    name?: string,
    id?: string,
    estimatedHours?: string,
    priceModel?: string,
    orderNumber?: string,
    journeyState?: string,
    dateDone?: string,
    dateModified?: string,
    dateCreated?: string,
    isActive?: string,
    isDone?: string,
    isOrdered?: string,
    logs?: string,
    projectName?: string,
    rawProjectName?: string,
    uri?: string,
    rowKew?: string,
    partitionKey?: string,
}
export enum SearchBy {
    LastModified,
    AcceptanceDate
}
export function convertAreasToOptionsList(areas: Area[]) {
    let options: any[] = [];
    if(areas === undefined) {
        return options;
    }
    areas.forEach((area, index) => {
        options.push({
            id: `area-option-${index}`, // Add an ID here
            value: area.name,
            label: area.name
        });
    });
    return options;
}

export function convertListOfStringsToOptionsList(areas: string[]) {
    let options: any[] = [];
    if(areas == undefined) {
        return options;
    }
    areas.forEach((x) => {
        options.push({
            value: x,
            label: x
        })
    })
    return options;
}

export enum Popup {
    None,
    Log,
    EditUser,
    DeleteUser,
    EditProject,
}

export interface ProjectMeta {
    debugMode: boolean,
    name: string,
    nameRaw: string,
    defaultOrderNumber: string,
    defaultPriceModel: string,
    doopAreas: string[],
    contractors: Contractor[],

    [key: string] : string | string[] | boolean | Contractor[]
}

/**
 * This file is used for interfaces that are used throughout the application.
 */
export interface NetworkData {
    success: boolean,
    message: string,
    data: any,
}
