import * as React from "react";

import {
    Button,
    Col,
    Container,
    Form,
    FormControl,
    ModalBody,
    ModalFooter,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import {isValidEmail} from "../../../../Utils/checker";
import ModalHeader from "react-bootstrap/ModalHeader";
import {ConfigEditData, TenantLine, UsersEditMode} from "../../Interfaces/interfaces";
import {IconTrash} from "../../constants";
import {FileUploaderUserMails} from "../../components";
import {IAddEditUserModal, TenantConfig} from "../../Interfaces/IAddEditUserModal";
import {sendUsersEditDataCall} from "../../BackendFacade/apiCalls";
import {UserData, UserManagement} from "../../Interfaces/IUsers";
import styles from './../../IDMS.module.css';
import UserTenantManagement, {SetGlobalTenantSystemContent} from "../../Utils/tenantSystem";
import {HubPathRouting} from "../../../../HubFramework/pathBuilder";
import {useMain} from "../../../../Utils/SessionControls/mainContext";
import {deepCopy} from "../../../../Utils/transformer";

export interface IUserModal {
    onSuccessFunction: () => void,
    closeFunction: () => void,
    state: IAddEditUserModal,
}

const AddEditUserModal: React.FC<IUserModal> = ({
                                                    onSuccessFunction,
                                                    closeFunction,
                                                    state,
                                                }) => {
    const [tenantSystem, setTenantSystem] = React.useState<TenantLine[]>(state.tenantSystem);
    const authLevels = state.authLevels;
    const [selectedValue, setSelectedValue] = React.useState<UserData>(state.selectedValue);
    const [emailList, setEmailList] = React.useState<string[]>(state.emailList);

    const appConfig = state.appConfig;
    const listOfProjectSubscopes = state.listOfProjectSubscopes;
    const selectedKey = state.selectedKey;
    const oldSelectedKey = state.oldSelectedKey;
    const userData = state.userData;
    const editMode = state.editMode;

    const {popupCreate, setLoading} = useMain();

    /**
     * Edit the value of a User scope
     * @param id id of the scope line
     * @param value value
     */
    const EditScopeValue = (id: number, value: string) => {
        let tenantLines = [...tenantSystem]
        tenantLines.find(x => x.id === id).value = value
        setTenantSystem(tenantLines)
    }

    /**
     * Add a full scope to the tenant system to the webpage backend
     * @param value value of the scope
     */
    const addScopeFull = (value: string) => {
        let scopes = [...tenantSystem]
        scopes.push({id: scopes.length, scopes: [], value: value, tmp: "", fullscope: ""})
        setTenantSystem(scopes)
    }

    /**
     * Saves the current user config
     */
    const saveUser = () => {
        // if the key already exists and the user wants to ADD a user, interrupt and show an error message
        if ((oldSelectedKey === "" && selectedKey !== "" && Object.keys(userData).indexOf(selectedKey) !== -1) ||
            // if the user is new
            (selectedKey === "" && selectedValue.mail !== "" &&
                // exists by usermail
                (Object.keys(userData).filter(x => userData[x].mail === selectedValue.mail).length > 0 ||
                    // exists by usermail in key
                    Object.keys(userData).indexOf(selectedKey) !== -1))
        ) {
            popupCreate("Error", "The user already exists. Please choose another key or email.")
            return
        }
        // if a user of the email list already exists, interrupt and show an error message
        if (emailList.length >= 1 && Object.keys(userData).filter(x => emailList.indexOf(userData[x].mail) !== -1).length > 0) {
            popupCreate("Error", "At least one user of the email list already exists. Please choose another email.")
            return
        }
        setLoading(true)

        let value: string
        // if the tenant system is active, convert the tenant config
        if (appConfig.DisableTenantManagement !== "true") {
            let config = {} as TenantConfig
            for (let line of tenantSystem) {
                // skip empty value and empty scopes
                if (line.value === "-" || line.scopes.length === 0) {
                    continue
                }
                config[line.scopes.join('/')] = line.value
            }
            value = JSON.stringify(config)
            // old rights system
        } else {
            value = selectedValue.authLevel
        }

        // delete old entry, if the email or key is changed
        if (oldSelectedKey !== ""
            && oldSelectedKey !== selectedValue.mail
            && oldSelectedKey !== selectedKey) {
            let sendValue: { [key: string]: string } = {};
            sendValue[oldSelectedKey] = "";
            let valueobj: ConfigEditData = {value: sendValue}
            sendUsersEditDataCall(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId, valueobj).then(data => {
                if (!data.success) {
                    popupCreate("Error", "Error while deleting old user: " + data.message)
                    return
                }
            })
        }

        // prepare list of emails which are to be added
        let editUsers: UserManagement[] = [];
        emailList.forEach(item => {
            editUsers.push({id: item, newValue: value})
        })

        // use the key if already given
        if (selectedKey !== "") {
            editUsers.push({id: selectedKey, newValue: value})
            // if the email field contains a valid email as well, add it too
        } else if (isValidEmail(selectedValue.mail) && emailList.indexOf(selectedValue.mail) === -1) {
            editUsers.push({id: selectedValue.mail, newValue: value})
        } else {
            console.error("Invalid operation")
        }

        let valueobj: ConfigEditData = {value: editUsers}
        sendUsersEditDataCall(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId, valueobj).then(async (data) => {
            if (!data.success) {
                popupCreate("Error", "Error while saving user: " + data.message)
            } else {
                onSuccessFunction()
            }
            setLoading(false)
        })
    }

    /**
     * update values for old tenant system
     * @param authlevel
     * @param checked
     */
    const clickAuthlevel_OldTenantSystem = (authlevel: string, checked: boolean) => {
        // if authLevel is not set or the new tenantSystem has to be removed
        let UsersAuthlevels = selectedValue.authLevel === undefined || selectedValue.authLevel[0] === "{" ?
            [] : selectedValue.authLevel.split(',')

        // remove empty authLevels
        UsersAuthlevels = UsersAuthlevels.filter((item) => item !== "")
        let userdata = selectedValue
        if (checked) {
            if (UsersAuthlevels.indexOf(authlevel) === -1) {
                UsersAuthlevels.push(authlevel)
            }
        } else {
            UsersAuthlevels = UsersAuthlevels.filter((items) => items !== authlevel)
        }
        userdata.authLevel = UsersAuthlevels.sort().join(',')
        setSelectedValue(deepCopy(userdata))
    }

    const getAuthLevel_NewTenantManagement = (globalid: number, defaultAuth: string) => {
        return <Row>
            <Col>
                {/* Global scope */}
                <SetGlobalTenantSystemContent tenantSystem={tenantSystem}
                                              globalId={globalid}
                                              authLevels={authLevels}
                                              defaultAuth={defaultAuth}
                                              onEditFunction={EditScopeValue.bind(this)}/>
                {/* Idividual scopes */}
                <UserTenantManagement tenantSystemProps={tenantSystem}
                                      setTenantSystemFunctionProps={setTenantSystemFunction.bind(this)}
                                      projectId={"null"}
                                      authLevels={authLevels}
                                      listOfIDMSSubscopes={listOfProjectSubscopes}
                                      global={false}
                                      useSelectOptions={false}
                />

                <p>Please remember to click "Add" for each new scope.</p>
                <br/>

                <Button className="uib-button uib-button--primary" id={"addScope"} onClick={() => addScopeFull(defaultAuth)}>
                    Add Scope
                </Button>
            </Col>
        </Row>;
    }

    const setTenantSystemFunction = (tenantSystem: TenantLine[]) => {
        setTenantSystem(tenantSystem)
    }

    const emailListDeleteItem = (item: string) => {
        let emails = emailList;
        emails.splice(emails.indexOf(item), 1);
        setEmailList(emails);
    }

    const addMailsToList = (maillist: string[]) => {
        let errors: string[] = []
        maillist.forEach(item => {
            let error = addMailToList(item)
            if (error !== undefined) {
                errors.push(error);
            }
        })
        if (errors.length > 0) {
            popupCreate("Error", "Errors during adding " + errors.join(", ") + ". Probably wrong or duplicate email address.")
        }
    }

    /**
     * Add mail to emaillist
     */
    const addMailToList = (newMail: string = "") => {
        let newmailtmp: string;
        if (newMail !== "") {
            newmailtmp = newMail;
        } else if (selectedValue.mail !== "") {
            newmailtmp = selectedValue.mail
        }
        if (isValidEmail(newmailtmp)) {
            if (emailList.indexOf(newmailtmp) !== -1) {
                popupCreate("Error", "Email already exists")
                return newmailtmp
            }

            let user = selectedValue;
            user.mail = "";
            setEmailList([...emailList, newmailtmp]);
            setSelectedValue(user);
            return
        } else {
            popupCreate("Error", "Please enter a valid email address")
            return newmailtmp
        }
    }


    const AuthLevel = () => {
        let defaultAuth = appConfig.defaultAuth === undefined ? "None" : appConfig.defaultAuth
        // ------ NEW tenant system ------
        if (appConfig.DisableTenantManagement !== "true") {
            // id of the global scope
            let globalid = tenantSystem.find(x => x.scopes.length === 1 && x.scopes.every(
                (value, index) => value === ["user"][index]
            )).id
            // set global to default auth if not set
            if (tenantSystem.find(x => x.id === globalid).value === "") {
                EditScopeValue(globalid, defaultAuth)
            }
            return getAuthLevel_NewTenantManagement(globalid, defaultAuth)

        // ---- OLD tenant system -----
        } else {
            // if the authLevels are set to the new system, ignore the config string, else separate them in a list
            let authlevels: string[] = selectedValue.authLevel === undefined || selectedValue.authLevel.indexOf('{') !== -1 ?
                [""] : selectedValue.authLevel.split(',')

            return <Row>
                <Col md={2} className="align-self-center">
                    Values
                </Col>
                <Col>
                    {authLevels.map((level, index) => {
                        return <Form.Check inline label={level} key={index}
                                           checked={authlevels.indexOf(level) !== -1}
                                           onChange={(item: any) => {
                                               clickAuthlevel_OldTenantSystem(level, item.target.checked)
                                           }}/>
                    })}
                </Col>
            </Row>
        }
    }

    let applyButton = <div></div>
    if (isValidEmail(selectedValue.mail) ||
        (selectedValue.mail === "" && emailList.length > 0)) {
        // check if all tmp values of users_ tenent system are empty
        if ((tenantSystem.every(x => x.tmp === "") && appConfig.DisableTenantManagement !== "true")
            || (appConfig.DisableTenantManagement === "true" && selectedValue.authLevel !== undefined && selectedValue.authLevel !== "" && selectedValue.authLevel[0] !== "{")) {
            applyButton = <Button className="uib-button uib-button--primary addUserButton" id={"applyButton"} onClick={() => {
                saveUser()
            }}>Apply</Button>
        } else {
            // show a warning if there are still values in the tenant-system
            applyButton = <OverlayTrigger
                placement="top"
                delay={{show: 50, hide: 200}}
                overlay={
                    <Tooltip id="tooltip-disabled">
                        Please make sure all Scopes are added by clicking "Add".
                    </Tooltip>
                }>
                <Button
                    className={`uib-button uib-button--primary addUserButton ${styles.warningButton}`} id={"applyButton"}>Apply</Button>
            </OverlayTrigger>
        }
    }

    return <div>
        <ModalHeader closeButton>
            {editMode === UsersEditMode.Add ? <>Add User</> : <>Edit User</>}
        </ModalHeader>
        <ModalBody>
            <Container>
                {emailList.length > 0 && editMode === UsersEditMode.Add ?
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">
                            Emails
                        </Col>
                        <Col>
                            {emailList.map((item, index) => {
                                return <Button className={`${styles.spaceRight} ${styles.spaceButtom}`} key={index}
                                               onClick={() => emailListDeleteItem(item)}>{item} {IconTrash}</Button>
                            })}
                        </Col>
                    </Row>
                    : <div></div>}
                <Row className={styles.popupSpaceBetween}>
                    <Col md={2} className="align-self-center">
                        Email
                    </Col>

                    {editMode === UsersEditMode.Add ?
                        <>
                            <Col>
                                <FormControl
                                    placeholder="Email adress"
                                    value={selectedValue.mail}
                                    id="users_email"
                                    onChange={(event) => {
                                        let user = selectedValue;
                                        user.mail = event.target.value;
                                        setSelectedValue(deepCopy(user));
                                    }}/>
                            </Col>
                            <Col md={"auto"}>
                                <Button className="uib-button uib-button--primary" id={"addButton"} variant="primary"
                                        onClick={() => addMailToList()}> Add </Button>
                            </Col>
                            <Col md={"auto"}>
                                <FileUploaderUserMails handleFile={(e) => addMailsToList(e)}/>
                            </Col>
                        </>
                        :
                        <Col>
                            <FormControl
                                placeholder="Email adress"
                                value={selectedValue.mail}
                                id="users_email"
                                disabled={true}
                            />
                        </Col>
                    }
                </Row>
                {!isValidEmail(selectedKey) ?
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">
                            Key
                        </Col>
                        <Col>
                            {selectedKey}
                        </Col>
                    </Row> : <></>}
                {AuthLevel()}
                {/*<AuthLevel />*/}
            </Container>
        </ModalBody>
        <ModalFooter>
            <Button className={`uib-button uib-button--secondary ${styles.spaceRight}`} variant="secondary"
                    onClick={() => closeFunction()}>Cancel</Button>
            {applyButton}
        </ModalFooter>
    </div>;

}

export default AddEditUserModal;