//todo: move to other files
export interface ConfigDescriptionsTexts {
    [key: string] : string[],
}

export interface ConfigEditData {
    value: any,
}

export interface TenantLine {
    id: number,
    scopes: string[],
    value: string,
    tmp: string,
    fullscope: string,
}

export interface ScopeOverview {
    subscopelist: string[],
    scopelist: string[],
}


export enum Popup {
    None,
    Config_AddEdit,
    Users_AddEdit,
}

export interface MBody {
    [key: string] : any
}

export enum SearchBy {
    Include,
    Exclude
}

export enum UsersEditMode {
    Add,
    Edit,
    None,
}
