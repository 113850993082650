import styles from "../../Dpas.module.css";
import {Button, Form, FormControl, OverlayTrigger, Tooltip} from "react-bootstrap";
import Select from "react-select";
import {IDirectoryContent, IMasonryContent, NoResults} from "../../Interfaces/IFiles";
import {isUserroleSufficient} from "../../../../Utils/authorization";
import {UserRoles} from "../../../../Utils";
import {HubPathRouting} from "../../../../HubFramework/pathBuilder";
import * as React from "react";
import makeAnimated from "react-select/animated";
import {useEffect} from "react";
import {downloadListObjectAsCSV} from "../../../../Utils/objectDownload";

const animatedComponents = makeAnimated()

export interface IFilesToolbar {
    folderPath: string[],
    currentDirectory: IDirectoryContent,
    directoryType: string,
    UploadFileShow: () => void,
    filterCurrentView: () => IMasonryContent[],
    setSearchField: (searchField: string) => void,
    setNoResults: (noResults: NoResults) => void,
    viewDetailsSwitch: boolean,
    setViewDetailsSwitch: (viewDetailsSwitch: boolean) => void
}

export const numberOfResults = () => {
    let options: NoResults[] = []
    options.push({value: "50", label: "50"})
    options.push({value: "100", label: "100"})
    options.push({value: "200", label: "200"})
    options.push({value: "5000", label: "all"})
    return options
}

const FilesToolbar: React.FC<IFilesToolbar> = ({folderPath, currentDirectory, directoryType, UploadFileShow, filterCurrentView, setSearchField, setNoResults, viewDetailsSwitch, setViewDetailsSwitch}) => {
    const [toolbar_SearchField, setToolbar_SearchField] = React.useState<string>("");
    const [toolbar_noResults, setToolbar_noResults] = React.useState<NoResults>(undefined);

    useEffect(() => {

        setToolbar_noResults(numberOfResults()[1])
    }, []);

    const handleSetSearchField = (searchField: string) => {
        setSearchField(searchField)
        setToolbar_SearchField(searchField)
    }

    const handleSetNoResults = (noResults: NoResults) => {
        setNoResults(noResults)
        setToolbar_noResults(noResults)
    }

    const download = () => {
        let files = filterCurrentView()
        // remove "go up"
        files = files.filter(x => x.id != 0)
        // remove directory name in all names
        files = files.map(x => {
            x.name = x.name.replace(folderPath.join('/') + "/", "")
            return x
        })
        downloadListObjectAsCSV(files)
    }

    const searchField_Reset = () => {
        handleSetSearchField("")
        handleSetNoResults(numberOfResults()[1])
    }

    let countAllItems = currentDirectory?.files?.length || 1
    if (folderPath.length > 0 && directoryType !== "") {
        return <div className={styles.toolbar}>
            <div className={"form-group row"}>
                <div className={"col col-md-auto"}>
                    <label className={"col col-form-label"}>Filter by</label>
                    <FormControl type={"text"}
                                 placeholder={"Filename or Displayname"}
                                 className={styles.FilterInput}
                                 onChange={(option) => {
                                     handleSetSearchField(option.target.value)
                                 }}
                                 value={toolbar_SearchField} />
                </div>
                <div className={"col col-md-auto"}>
                    <label  className="col col-form-label">Number of shown items</label>
                    <div className="col">
                        <Select closeMenuOnSelect={true}
                                className={styles.zindex}
                                components={animatedComponents}
                                options={numberOfResults()}
                                onChange={(option : NoResults) => {
                                    handleSetNoResults(option)
                                }}
                                value={toolbar_noResults}
                        ></Select>

                    </div>
                </div>
                <div className={`col col-md-auto  `}>
                    <label className={"col col-form-label"}>View switch (details - tile)</label>
                    <div className={"col"}>
                        <Form>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="view-switch"
                                onClick={() => setViewDetailsSwitch(!viewDetailsSwitch)}
                            />
                        </Form>

                    </div>
                </div>

                <div className={`col col-md-auto ${styles.RButtons}`}>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 150, hide: 200 }}
                        overlay={
                            <Tooltip id="tooltip-disabled">
                                Reset filters
                            </Tooltip>
                        }
                    >
                        <Button className={`uib-button uib-button--primary uib-button--square  ${styles.resetButton} ${styles.ButtonLogo}`} onClick={() => searchField_Reset()}>
                            <i className={`uib-icon uib-icon--settings-leave ${styles.logo}`}></i>
                        </Button>
                    </OverlayTrigger>
                </div>
                {isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId) &&
                    <div className={`col col-md-auto ${styles.RButtons}`}>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 150, hide: 200 }}
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                    Upload File
                                </Tooltip>
                            }
                        >
                            <Button className={`uib-button uib-button--primary uib-button--square ${styles.resetButton} ${styles.ButtonLogo}`} onClick={() => UploadFileShow()}>
                                <i className={`uib-icon uib-icon--upload ${styles.logo}`}></i>
                            </Button>
                        </OverlayTrigger>
                    </div>
                    }
                <div className={`col col-md-auto ${styles.RButtons}`}>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 150, hide: 200 }}
                        overlay={
                            <Tooltip id="tooltip-disabled">
                                Download Current View as CSV
                            </Tooltip>
                        }
                    >
                        <Button className={`uib-button uib-button--primary uib-button--square ${styles.ButtonLogo}`} onClick={() => download()}>
                            <i className={`uib-icon uib-icon--download ${styles.logo}`}></i>
                        </Button>
                    </OverlayTrigger>
                </div>
                <div  className={`col col-md-auto ${styles.numberOfShownItems}`}>
                    Count: {countAllItems - 1}
                </div>
            </div>
        </div>
    } else {
        return <div className={styles.noToolbar}></div>
    }
}

export default FilesToolbar;