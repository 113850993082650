import * as React from "react";
import {InputGroup, Table} from "react-bootstrap";
import {IListOfModules} from "../../../HubFramework/IHub";
import {getListOfAllModules} from "../../../Utils/modules";
import {listModuleInstances, listProjects} from "../BackendFacade/apiCalls";
import {GlobalDictionary} from "../../../Utils/globalDictionary";
import {IModule} from "../../../BackendFacade/IModuleInstances";
import {IProject} from "../Interfaces/IAllProjects";
import {IIdAnswer, IIdFinder} from "../Interfaces/IIdFinder";
import {useEffect} from "react";
import {useMain} from "../../../Utils/SessionControls/mainContext";

const LISTOFPROJECTS = "listOfProjects"

const IdFinder: React.FC = () => {
    const [listOfProjects, setListOfProjects] = React.useState<IProject[]>();
    const [listOfModules, setListOfModules] = React.useState<IModule[]>();
    const [search, setSearch] = React.useState<string>("");

    const listOfAllStaticModules = getListOfAllModules()

    const {popupCreate, setLoading, popupCreateSingle} = useMain();


    useEffect(() => {
        // Define an async function inside the useEffect
        const fetchData = async () => {
            setLoading(true);
            let allProjects;

            if (!GlobalDictionary.get(LISTOFPROJECTS)) {
                allProjects = await getListOfAllProjects();
                if (allProjects.length === 0) return;
                // Assuming you want to cache the projects in the global dictionary
                GlobalDictionary.set(LISTOFPROJECTS, allProjects);
            } else {
                allProjects = GlobalDictionary.get(LISTOFPROJECTS);
            }
            setListOfProjects(allProjects);

            // get all modules to all projects
            await gatherAllUsedModules(allProjects);
            setLoading(false);
        };

        // Call the async function
        fetchData();
    }, []);

    const getListOfAllProjects = async () => {
        return listProjects().then((data) => {
            if(data.success) {
                GlobalDictionary.set(LISTOFPROJECTS, data.data.value)
                return data.data.value as IProject[]
            } else {
                popupCreate("Error", data.message);
                return [] as IProject[]
            }
        })
    }

    /**
     * Get all modules to a project
     * @param projectId
     */
    const getProjectModules = async (projectId: string) => {
        return listModuleInstances(projectId).then((data) => {
            if(data.success) {
                let ret = data.data.value as IModule[]
                // set the project id of every module
                ret.forEach((module) => {
                    module.projectId = projectId
                })
                return ret
            } else {
                popupCreate("Error", data.message);
            }
        })
    }

    const translateProjectIdToProjectName = (projectId: string)  => {
        const projectList = GlobalDictionary.get(LISTOFPROJECTS) as IProject[]
        if(!projectList) return ""
        const project = projectList.find((i) => i.projectId === projectId)
        if(!project) return ""
        return project.displayName
    }

    /**
     * Sort all modules to a table of modules and their usage
     * @param allProjects
     */
    const  gatherAllUsedModules = async(allProjects: IProject[]) => {
        let allModules: IModule[] = []
        let listToLoad = [] as Promise<any>[]
        allProjects.forEach((project) => {
            listToLoad.push(
                getProjectModules(project.projectId).then((modules) => {
                    allModules.push(...modules)
                })
            )
        })
        await Promise.all(listToLoad)
        setListOfModules(allModules)

    }

    const searchForId = (id: string): IIdAnswer[] => {
        // search in project for id
        let answer: IIdAnswer[] = []
        listOfProjects.filter((project) => project.projectId.includes(id)).forEach((project) => {
            answer.push({
                id: project.projectId,
                projectId: project.projectId,
                projectName: project.displayName,
                moduleName: "",
                moduleId: "",
                linkedId: "",
            })
        })
        // search in modules for id
        listOfModules.filter((module) => module.moduleInstanceId.includes(id)).forEach((module) => {
            answer.push({
                id: module.moduleInstanceId,
                projectId: module.projectId,
                projectName: translateProjectIdToProjectName(module.projectId),
                moduleName: listOfAllStaticModules[module.staticModuleId].moduleContent.title,
                moduleId: module.moduleInstanceId,
                linkedId: "",
            })
        })
        // search in linked modules for id
        listOfModules.filter((module) => module.linkedId.projectId.includes(id)).forEach((module) => {
            answer.push({
                id: module.linkedId.projectId,
                projectId: module.projectId,
                projectName: translateProjectIdToProjectName(module.projectId),
                moduleName: listOfAllStaticModules[module.staticModuleId].moduleContent.title,
                moduleId: module.moduleInstanceId,
                linkedId: module.linkedId.projectId,
            })
        })
        return answer
    }

    const matchedBy = (answer: IIdAnswer) => {
        if(answer.projectId === answer.id) return "Project id"
        if(answer.moduleId === answer.id) return "Module id"
        if(answer.linkedId === answer.id) return "Linked id"
        return ""
    }

    const getResults = () => {
        if(search === "") return <></>
        return <Table striped bordered hover>
            <thead>
            <tr>
                <th>Matched By</th>
                <th>Project name</th>
                <th>Module name</th>
                <th>Project id</th>
                <th>Module id</th>
                <th>Linked id</th>
            </tr>
            </thead>
            <tbody>
            {searchForId(search).map((answer, index) => {
                return <tr key={index}>
                    <td>{matchedBy(answer)}</td>
                    <td>{answer.projectName}</td>
                    <td>{answer.moduleName}</td>
                    <td>{answer.projectId}</td>
                    <td>{answer.moduleId}</td>
                    <td>{answer.linkedId}</td>
                </tr>
            })}
            </tbody>
        </Table>
    }

        return <>
            <p> Search for any project id, module instance id and linked id.</p>
            <br />
            <InputGroup size={"lg"}>
                <InputGroup.Text id="inputGroup-sizing-lg">Search for Id</InputGroup.Text>
                <input
                    type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    onChange={(e) =>
                        setSearch(e.target.value)}
                />
            </InputGroup>
            <br />
            {getResults()}
        </>

}

export default IdFinder;