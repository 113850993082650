import {IListStaticModule} from "../../../BackendFacade/IStaticModules";
import {IModule} from "../../../BackendFacade/IModuleInstances";

/**
 * Contains the states for the template - start component.
 */
export interface IAllModules {
    groupModules: IGroup[],
    availableModules: IListStaticModule[],
    popup: Popup,
    newModule: IModule,
    newModuleInfos: IModuleInfos,
    popupMessage: string,

    editModule: IModule,
    loading: boolean,
}

export interface IGroup {
    name: string,
    modules: IModule[],
}

// export interface IModule {
//     moduleInstanceId: string,
//     /* id of the module component */
//     staticModuleId: string,
//
//     group: string,
//     linkedId: IlinkedId,
//     displayName: string,
//
//     creationProps: CreationProps[],
//     migrateExisting: boolean,
// }
// export interface IlinkedId {
//     projectId: string,
// }

export interface IModuleInfos {
    staticModuleDisplayName: string,
    projectIdInfoText: string,

}



export enum Popup {
    None,
    AddModule,
    EditModule
}
