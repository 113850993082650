import * as React from "react";
import {moduleContent} from "../moduleContent";
import Documentation from "../../Documentation/Documentation";

const DOOPDocumentation: React.FC = () => {
    return (
        <Documentation component={moduleContent.moduleName} />
    )
}

export default DOOPDocumentation;