import {getUserRole} from "./authentication";
import {Dictionary} from "./IAuthentication";
import {UserRoles} from "./IAuthorization";
import {say} from "./webHelper";
import {GlobalDictionary} from "./globalDictionary";
import {GetAccessRoles} from "../Components/Hub/BackendFacade/apiCalls";

export function isUserroleSufficient(target: UserRoles = UserRoles.reader, project? : string, module? : string) {
    if(!getUserRole() || getUserRole()[0] !== "{") {
        say("isUserroleSufficient false (no user role)")
        return false
    }
    let userRole = JSON.parse(getUserRole()) as Dictionary

    let accepted = false
    // check global role
    const globalRole = userRole["user"] as keyof typeof UserRoles
    if(UserRoles[globalRole] >= target) {
        accepted = true
    }

    // check project role
    if(project && userRole["user/" + project]) {
        const projectRole = userRole["user/" + project] as keyof typeof UserRoles
        accepted = UserRoles[projectRole] >= target
    }

    // check module role
    if(project && module && userRole["user/" + project + "/" + module]) {
        const moduleRole = userRole["user/" + project + "/" + module] as keyof typeof UserRoles
        accepted = UserRoles[moduleRole] >= target
    }

    // check if user has module rights on project level
    const transitiveRights = Object.keys(userRole).filter(x => x.startsWith("user/" + project + "/"))
    if(project && !module && transitiveRights.length > 0) {
        accepted = UserRoles.reader >= target
    }

    return accepted
}
