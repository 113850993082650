import {ISettings} from "./IConfig";
import {FolderTypeDocument, FolderTypeImage, FolderTypeSecret} from "../constants";

export interface IDirectoryContent {
    directories: string[],
    files: string[],
    category?: string[],
}

// export interface IFile {
//     fileName: string,
//     publicUrl: string,
// }

export interface NoResults {
    value: string,
    label: string,
}

export interface IFileDetails {
    availableResolutions: string[],
    properties: ISettings,
    fileName: string,
    length: number,
}

export enum DpasPopup {
    None,
    AutomationConfig_Edit,
    AutomationConfig_details,
    PictureDetail,
    UploadFile,
}

export interface IMasonryContent {
    id: number,
    name: string,
    src: string,
    type?: FileType,
    folderType?: DirectoryType,
}

export enum FileType {
    Directory,
    File,
    Up,
    Type,
}

export enum DirectoryType {
    "Images" = FolderTypeImage,
    "Documents" = FolderTypeDocument,
    "Secret Files" = FolderTypeSecret,
}

export interface MBody {
    [key: string] : any
}

export enum SortBy {
    Name,
    Date,
    Size
}