import {fetchFromApi, getBackendApiURL} from "../../../BackendFacade";
import {DoopUser} from "../Interfaces/IDOOP";

export function setProjectMeta(projectId: string,moduleInstanceId: string, bodyString: any) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/meta";
    return fetchFromApi(url, "POST", bodyString)
}

export function getProjectMeta(projectId: string,moduleInstanceId: string) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/meta";
    return fetchFromApi(url, "GET")
}
export function getProjectUsersData(projectId: string,moduleInstanceId: string) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/users";
    return fetchFromApi(url, "GET")
}

export function getProjectMetaDataAreas(projectId: string,moduleInstanceId: string) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/areas";
    return fetchFromApi(url, "GET")
}

export function sendUserData(user: DoopUser, projectName: string, moduleInstanceId:string) {
    let url = getBackendApiURL() + "projects/" + projectName + "/modules/" + moduleInstanceId + "/users";
    let bodyString = { usersToUpdate: [user]}
    return fetchFromApi(url, "POST", bodyString)
}

export function sendUserDelete(user: string, projectId: string, moduleInstanceId : string) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/users";
    let bodyString = { usersToDelete: [user]}
    return fetchFromApi(url, "DELETE", bodyString)
}

export function getOrderedItemsForProject(projectId: string, moduleInstanceId: string) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/orders";
    return fetchFromApi(url, "GET")
}
