import * as React from "react";
import {DeleteProject, getProject} from "../BackendFacade/apiCalls";
import {HubPathRouting} from "../../../HubFramework/pathBuilder";
import styles from "../ProjectContent.module.css";
import {Button, Modal} from "react-bootstrap";
import {Popup} from "../Interfaces/IProjectInformation";
import ProjectInformation_Edit from "./Modals/ProjectInformation_Edit";
import {IProjectInformation_Edit} from "../Interfaces/IProjectInformation_Edit";
import {isUserroleSufficient} from "../../../Utils/authorization";
import {UserRoles} from "../../../Utils";
import {GlobalDictionary} from "../../../Utils/globalDictionary";
import {useEffect} from "react";
import {useMain} from "../../../Utils/SessionControls/mainContext";
import {useNavigate} from "react-router-dom";
import {Hub} from "@sentry/react";

const PROJECTINFO: string = "projectInfo"

const ProjectInformation: React.FC = () => {
    const [description, setDescription] = React.useState<string>("");
    const [contacts, setContacts] = React.useState<any[]>([]);
    const [costCenter, setCostCenter] = React.useState<number>(0);
    const [department, setDepartment] = React.useState<string>("");
    const [product, setProduct] = React.useState<string>("");
    const [popup, setPopup] = React.useState<Popup>(Popup.None);
    const [editModal, setEditModal] = React.useState<IProjectInformation_Edit | undefined>(undefined);
    const [customProjectIcon, setCustomProjectIcon] = React.useState<string>("");
    const [internalOrderNumber, setInternalOrderNumber] = React.useState<number>(0);
    const [title, setTitle] = React.useState<string>(HubPathRouting.currentProject.displayName);

    const {popupCreate, setLoading, isLoading} = useMain();
    const navigate = useNavigate();

    useEffect(() => {
        // subscribe for the title, which might be late
        try {
            HubPathRouting.subscribe(() => {
                if (HubPathRouting.currentProject && HubPathRouting.currentProject.displayName)
                    setTitle(HubPathRouting.currentProject.displayName)
            })
        }
        catch (e) {
            // do nothing, project was deleted
        }

        if(!GlobalDictionary.get(PROJECTINFO + HubPathRouting.currentProject.moduleInstanceId)) {
            setLoading(true)
            getProject(HubPathRouting.currentProject.moduleInstanceId).then((data) => {
                if(data.success) {
                    let project = data.data.value
                    GlobalDictionary.set(PROJECTINFO + HubPathRouting.currentProject.moduleInstanceId, project)
                    prepareProjectInfo()
                } else {
                    popupCreate("Error", data.message)
                }
                setLoading(false)
            })
        } else {
            prepareProjectInfo()
            getProjectInfo()
        }
    }, []);

    const getProjectInfo = async () => {
        await getProject(HubPathRouting.currentProject.moduleInstanceId).then((data) => {
            if(data.success) {
                let project = data.data.value
                GlobalDictionary.set(PROJECTINFO + HubPathRouting.currentProject.moduleInstanceId, project)
                prepareProjectInfo()
            } else {
                popupCreate("Error", data.message)
            }
        })
    }

    const prepareProjectInfo = () => {
        let project = GlobalDictionary.get(PROJECTINFO + HubPathRouting.currentProject.moduleInstanceId)
        setDescription(project.description);
        setContacts(project.contacts);
        setCostCenter(project.costCenter);
        setDepartment(project.department);
        setProduct(project.product);
        setCustomProjectIcon(project.customProjectIcon);
        setInternalOrderNumber(project.internalOrderNumber);
    }

    const EditProject_show = () => {
        let editModal: IProjectInformation_Edit = {
            projectId: HubPathRouting.currentProject.moduleInstanceId,
            description: description,
            contacts: contacts,
            costCenter: costCenter,
            department: department,
            product: product,
            customProjectIcon: customProjectIcon,
            internalOrderNumber: internalOrderNumber,
            newContact: {mail: "", job: ""},
            displayName: title,
        }
        setPopup(Popup.Edit);
        console.log(HubPathRouting.currentProject)
        console.log(editModal)
        setEditModal(editModal);
    }

    const closePopup = () => {
        setPopup(Popup.None);
    }

    const deleteProject = (projectId: string, hardDelete: boolean = false) => {
        // HubFunctions.showLoading();
        setLoading(true);
        DeleteProject(projectId, hardDelete).then((data) => {
            if(data.success) {
                closePopup()
                GlobalDictionary.remove(GlobalDictionary.PROJECTS)
                navigate("/")
            } else {
                popupCreate("Error", data.message);
            }
            setLoading(false);
        })
    }

    const showHardDeletePopup = () => {
        popupCreate("Delete Project",
            "Are you sure, Project " + HubPathRouting.currentProject.displayName + " shall be deleted? There is no undo!",
            deleteProject.bind(this, HubPathRouting.currentProject.moduleInstanceId, true)
        )
    }

    const showSoftDeletePopup = () => {
        popupCreate("Delete Project",
            "Are you sure, Project " + HubPathRouting.currentProject.displayName + " shall be deleted? There is no undo!",
            deleteProject.bind(this, HubPathRouting.currentProject.moduleInstanceId)
        )
    }

    const ProjectIcon = () => {
        if(customProjectIcon === "" || customProjectIcon === null) return (<></>)
        // Convert the base64 string to a Uint8Array
        const base64Image = customProjectIcon;
        const byteString = atob(base64Image.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array and display the image
        const blob = new Blob([uint8Array], { type: 'image/png' });
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        return <img src={imageUrl} alt={'Project Icon'} className={styles.projectIcon} />;
    }

    return <>
        <Modal size="lg" show={popup === Popup.Edit} onHide={() => closePopup()} >
            <ProjectInformation_Edit
                onSuccessFunction={getProjectInfo.bind(this)}
                closeFunction={closePopup.bind(this)}
                state={editModal}
            />
        </Modal>
        <div id={"title"} className={`${styles.title}`}>
            <div className={styles.titlecontainer}>
                {/*{this.projectIcon()}*/}
                <ProjectIcon />
                {title}
            </div>
        </div>
        <div className={`${styles.GroupHeader}`}>Description</div>
        <div id={"description"} className={styles.GroupContent}>{description}</div>
        <div className={`${styles.GroupHeader}`}>Contacts</div>
        {contacts.map((contact, key) => {
            return <div id={"contact" + key}
                        className={styles.GroupContent}
                        key={key}
            >
                {contact.mail} - {contact.job}
            </div>
        })}
        <div className={`${styles.GroupHeader}`}>Cost Center</div>
        <div id={"costCenter"} className={styles.GroupContent}>{costCenter}</div>
        <div className={`${styles.GroupHeader}`}>Internal Order Number</div>
        <div id={"internalOrderNumber"} className={styles.GroupContent}>{internalOrderNumber}</div>
        <div className={`${styles.GroupHeader}`}>Department</div>
        <div id={"department"} className={styles.GroupContent}>{department}</div>
        <div className={`${styles.GroupHeader}`}>Product</div>
        <div id={"product"} className={styles.GroupContent}>{product}</div>
        {
            isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject?.moduleInstanceId) &&
            <div className={`${styles.EditButton}`}>
                <Button id={"editInformation"} className="uib-button uib-button--primary" onClick={() => EditProject_show()}>Edit Information</Button>
            </div>
        }
        {
            isUserroleSufficient(UserRoles.admin, HubPathRouting.currentProject?.moduleInstanceId) &&
            <div className={`${styles.EditButton} `}>
                <div className={`${styles.title}`}>Delete the project</div>
                <p>If you want to delete the project including all modules, press "Delete Project (hard)". Remember: There is no undo!<br />
                    "Delete Project (soft)" will delete this project, but all modules instances will preserve. You will need the assist of a Hub Admin to reinstantiate the module instances.<br />
                </p>
                <br />
                <span>
                    <Button id={"deleteProjectHard"} className={`uib-button uib-button--primary ${styles.errorButton} ${styles.spaceRight}`} onClick={() => {showHardDeletePopup()}}>
                        Delete Project (hard)
                    </Button>
                    <Button id={"deleteProjectSoft"} className={`uib-button uib-button--primary ${styles.errorButton}`} onClick={() => {showSoftDeletePopup()}}>
                        Delete Project (soft)
                    </Button>
                </span>
            </div>
        }
    </>

}

export default ProjectInformation;
