import * as React from "react";
import {useEffect} from "react";
import {animatedComponents, ProjectData, SearchBy} from "../Interfaces/IDOOP";
import {Module, OptionOIS} from "../module";
import {optionsOISList} from "../constants";
import {Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import Select from "react-select";
import {getOrderedItemsForProject,} from "../BackendFacade/apiCalls";
import * as Sentry from "@sentry/react";
import styles from './../Doop.module.css'
import {useOutletContext} from "react-router-dom";
import {useMain} from "../../../Utils/SessionControls/mainContext";
import {HubPathRouting} from "../../../HubFramework/pathBuilder";

const Overview: React.FC = () => {
    const [areas, setAreas] = React.useState<OptionOIS[]>([]);
    const [fromDate, setFromDate] = React.useState("2021-01-01");
    const [projectData, setProjectData] = React.useState<ProjectData[]>([]);
    const [search, setSearch] = React.useState(SearchBy.LastModified);
    const [sortAscend, setSortAscend] = React.useState(false);
    const [sortBy, setSortBy] = React.useState("name");
    const [state, setState] = React.useState<OptionOIS[]>([]);
    const [toDate, setToDate] = React.useState(new Date().toISOString().substring(0, 10));
    const parentModule = useOutletContext() as Module;

    const {setLoading, popupCreate, popupCreateSingle} = useMain()

     useEffect(() => {
         setLoading(true)
         let prom1 = getProjectMetaData()
         let prom2 = getProjectData()
         Promise.all([prom1, prom2]).then(() => {
            setLoading(false)
         })
     }, []);

    useEffect(() => {
        setProjectData(sortListByKey(projectData, sortBy, sortAscend))
    }, [sortBy, sortAscend, projectData]);

    const getProjectMetaData = async () => {
        let prom1 = parentModule.getProjectData().then(data => {
            if(!data.success) {
                popupCreate("Error", data.message)
                Sentry.captureMessage(data.message)
            }
        })
        await Promise.all([prom1])
    }
    
    const getProjectData = async () => {
        let prom1 = getOrderedItemsForProject(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId).then(data => {
            if(!data.success) {
                popupCreate("Error", data.message)
                Sentry.captureMessage(data.message)
            } else {
                console.log("set projectData", data.data)
                setProjectData(data.data)
            }
        })
        await Promise.all([prom1])
    }

     /**
      * Sort list by a clicked column
      * @param key
      * @constructor
      */
    const sortByKey = (key: string) => {
        let sort = sortAscend
        // if the same column is clicked again, reverse the order
        if(sortBy === key) {
            sort = !sortAscend
            setSortAscend(sort)
        }
        setSortBy(key)
    }

     /**
      * If the data changes, sort it
      * @constructor
      */
    const sort = () => {
        setProjectData(sortListByKey(projectData, sortBy, sortAscend))
    }

    const showSortIcon = (key: string) => {
        if(key === sortBy) {
            return sortAscend ? <i className="uib-icon--arrow1-down uib-icon"></i> : <i className="uib-icon--arrow1-up uib-icon"></i>
        }
    }

     /**
      * Reset the view to the initial state
      * @constructor
      */
    const resetView = () => {
        setState([])
        setFromDate("2021-01-01")
        setToDate(new Date().toISOString().substring(0, 10))
        sort()
    }

    /**
     * Creates the CSV file of the current view. The order of columns is important
     */
    const downloadView = () => {
        let data = filteredProjects().map((item) => {
            return {
                "Name": item.name,
                "URI": item.id,
                "Ordernumber": item.orderNumber,
                "State": item.journeyState,
                "Estimated Hours": item.estimatedHours,
                "Acceptance Date": trimDate(item.dateDone)
            }
        })
        let additionalData = "Sum of all shown entries;" + sumOfAllShownProjects() + " h"
        downloadListObjectAsCSV(data, HubPathRouting.currentProject.moduleInstanceId, additionalData)
    }

    const convertIDtoLink = (id: string) => {
        return <a target="_blank" href={"https://dev.azure.com/draeger/_workitems/edit/" + id} >{id}</a>
    }

    const getFirstDayOfCurrentMonth = () => {
        let today = new Date();
        let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        firstDay.setHours(2, 0, 0, 0);
        return firstDay.toISOString().substring(0, 10);
    }

    const downloadListObjectAsCSV = (list: any[], projName: string, additional: string = "") => {
        let csv = convertObjectListToCSV(list);
        csv += additional;
        let blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
        const element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download = projName + ".csv";
        document.body.appendChild(element);
        element.click();
    }
    const convertObjectListToCSV = (list: any[]) => {
        let csv = "";
        let keys = Object.keys(list[0]);
        csv += keys.join(";") + "\n";
        list.forEach((item) => {
            let values: any[] = [];
            keys.forEach((key) => {
                values.push(item[key]);
            })
            csv += values.join(";") + "\n";
        })
        return csv
    }
    
    const getLastDayOfCurrentMonth = () => {
        let today = new Date();
        let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        lastDay.setHours(2, 0, 0, 0);
        return lastDay.toISOString().substring(0, 10);
    }

    const getFirstDayOfLastMonth = () => {
        let today = new Date();
        let firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        firstDay.setHours(2, 0, 0, 0);
        return firstDay.toISOString().substring(0, 10);
    }
    const getLastDayOfLastMonth = () => {
        let today = new Date();
        let lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
        lastDay.setHours(2, 0, 0, 0);
        return lastDay.toISOString().substring(0, 10);
    }

    const sortListByKey = (list: any[], key: string, reverse: boolean = false) => {
        if(list.length === 0) {
            return list
        }
        // if list item is a number
        if(!isNaN(list[0][key])) {
            list.sort((a: any, b: any) => {
                if(a[key] < b[key] ) {
                    return -1;
                }
                if(a[key]  > b[key] ) {
                    return 1;
                }
                return 0;
            })
        } else {
            list.sort((a: any, b: any) => {
                if(a[key].toLowerCase() < b[key].toLowerCase() ) {
                    return -1;
                }
                if(a[key].toLowerCase()  > b[key].toLowerCase() ) {
                    return 1;
                }
                return 0;
            })
        }
        if(reverse) {
            list.reverse();
        }
        return list
    }

    const trimDate = (date: string) => {
        if(date.indexOf('1970') !== -1) {
            return "-";
        }
        return date.substring(0, 10)
    }
    
    /**
     * Filter the shown Items by from/to date, then by searchFor state
     */
    const filteredProjects = () => {
        if(!projectData)
            return []

        return projectData.filter((item) => {
            // filter by last modified or acceptance date
            if(fromDate && toDate) {
                if(search === SearchBy.LastModified) {
                    return item.dateModified.substring(0, 10) >= fromDate && item.dateModified.substring(0, 10) <= toDate;
                } else {
                    return item.dateDone.substring(0, 10) >= fromDate && item.dateDone.substring(0, 10) <= toDate;
                }
            } else {
                return true;
            }
            // state filter    
        }).filter((item) => {
            if(state.length > 0) {
                return !!state.find((state) => state.value === item.journeyState);
            } else {
                return true;
            }
        })
        // filter by area
        .filter((item) => {
            if(areas.length > 0) {
                return !!areas.find((area) => area.value === item.area);
            } else {
                return true;
            }
        })
    }

     /**
      * Sum of hours of all shown projects
      */
    const sumOfAllShownProjects = () => {
        if(filteredProjects().length === 0) {
            return 0;
        }
        let a = filteredProjects().map(i => i.estimatedHours).reduce((a, b) => {
            return a + b;
        })
        // round to 2 digits
        return Math.round(+a * 100) / 100;
    }

    const truncateArea = (area: string) => {
        if(!area)
            return ""
        if (area.indexOf('\\') !== -1) {
            return area.split('\\')[1];
        }
        return area
    }

    const LogPopupShow = (logData: string) => {
        let htmlContent = typeof logData === "string" ? <div dangerouslySetInnerHTML={{__html: logData}}></div> : logData;
        popupCreateSingle("Log", htmlContent)
    }

    const getCurrentDoopAreas = () => {
        const areas = parentModule.state.AllAreas
        let optionList: {label: string, value: string}[] = []
        if(areas) {
            optionList = areas.map((area) => {
                const split = area.name.split('\\')
                const name = split[split.length - 1]
                // pick last element of array

                return {label: name, value: area.name}
            })
        }
        return optionList
    }

    // wait for init loading
     if(parentModule.state.Meta === null) {
         return <></>
     }

    return  <>
            <div className={styles.ois}>
                <div className={styles.mainScreenHeadline}>Order Overview</div>
                <div className={`form-group row ${styles.filterRow}`}>
                    <div className="col col-md-auto">
                        <label  className="col col-form-label">Search for</label>
                        <div className="col">
                            <Button className={search === SearchBy.AcceptanceDate ? `uib-button uib-button--secondary ${styles.SearchForButton}` : `uib-button uib-button--primary ${styles.SearchForButton}`}
                                    onClick={() => setSearch(SearchBy.LastModified) }>
                                Last Modified
                            </Button>
                            <Button className={search === SearchBy.AcceptanceDate ? `uib-button uib-button--primary ${styles.BiggerHeight}` : `uib-button uib-button--secondary ${styles.BiggerHeight}`}
                                    onClick={() => setSearch(SearchBy.AcceptanceDate)}>Acceptance Date</Button>
                        </div>
                    </div>
                    <div className="col col-md-auto">
                        <label  className="col col-form-label">From</label>
                        <div className="col">
                            <Form.Control id="dateFrom" type="date" name="dateFrom" max="3000-12-31" value={fromDate}
                                          min="2021-01-01"
                                          className={`form-control ${styles.datepicker}`}
                                          onChange={(event) => setFromDate(event.target.value) }/>
                        </div>
                    </div>
                    <div className="col col-md-auto">
                        <label  className="col col-form-label">Until</label>
                        <div className="col">
                            <Form.Control id="dateUntil" type="date" name="dateUntil" max="3000-12-31" value={toDate}
                                          min="2021-01-01" className={`form-control ${styles.datepicker}`}
                                          onChange={(event) => setToDate(event.target.value)} />
                        </div>
                    </div>
                    <div className="col">
                        <label  className="col col-form-label">State</label>
                        <div className="col">
                            <Select closeMenuOnSelect={false}
                                    className={styles.zindex}
                                    components={animatedComponents}
                                    placeholder="If empty, all are selected"
                                    isMulti
                                    options={optionsOISList}
                                    onChange={(option : OptionOIS[]) => {setState(option) }}
                                    value={state}
                            ></Select>
                        </div>
                    </div>
                    <div className="col">
                        <label  className="col col-form-label">Areas</label>
                        <div className="col">
                            <Select closeMenuOnSelect={false}
                                    className={styles.zindex}
                                    components={animatedComponents}
                                    placeholder="If empty, all are selected"
                                    isMulti
                                // options={convertAreasToOptionsList(this.state.AllAreas)}
                                    options={getCurrentDoopAreas()}
                                    onChange={(option : OptionOIS[]) => {setAreas(option) }}
                                    value={areas}
                            ></Select>
    
                        </div>
                    </div>
                    <div className={`col col-md-auto ${styles.RButtons}`}>
                        <label  className="col col-form-label"></label>
                        <div className="col ">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 150, hide: 200 }}
                                overlay={
                                    <Tooltip id="tooltip-disabled">
                                        Reset filters
                                    </Tooltip>
                                }
                            >
                                <Button id={`resetButton`} className={`uib-button uib-button--primary uib-button--square ${styles.resetButton} ${styles.ButtonLogo}`} onClick={() => resetView()}>
                                    <i className={`uib-icon uib-icon--settings-leave ${styles.logo}`}></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 150, hide: 200 }}
                                overlay={
                                    <Tooltip id="tooltip-disabled">
                                        Refresh
                                    </Tooltip>
                                }
                            >
                                <Button className={`uib-button uib-button--primary uib-button--square ${styles.resetButton} ${styles.ButtonLogo}`} onClick={() => getProjectData()}>
                                    <i className={`uib-icon uib-icon--reload ${styles.logo}`}></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 150, hide: 200 }}
                                overlay={
                                    <Tooltip id="tooltip-disabled">
                                        Download Current View as CSV
                                    </Tooltip>
                                }
                            >
                                <Button className={`uib-button uib-button--primary uib-button--square ${styles.ButtonLogo}`} onClick={() => downloadView()}>
                                    <i className={`uib-icon uib-icon--download ${styles.logo}`}></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
    
                    <div className={`row ${styles.filterRow} ${styles.buttonRow}`}>
                        <div className={`col col-md-auto ${styles.alignCenter}`}>
                            <Button className={`uib-button uib-button--primary ${styles.BiggerHeight}`}
                                    onClick={() => {
                                        setFromDate(getFirstDayOfCurrentMonth());
                                        setToDate(getLastDayOfCurrentMonth());
                                    }}>View Current Month</Button>
                        </div>
                        <div className={`col col-md-auto ${styles.alignCenter}`}>
                            <Button className={`uib-button uib-button--primary ${styles.BiggerHeight}`}
                                    onClick={() => {
                                        setFromDate(getFirstDayOfLastMonth());
                                        setToDate(getLastDayOfLastMonth());
                                    }}>View Last Month</Button>
                        </div>
                        <div className="col">
                        </div>
                        <div className={`col col-md-2 ${styles.alignCenter}`}>
                            Sum of all shown entries: {sumOfAllShownProjects()} h
                        </div>
                    </div>
                </div>
                <div className={styles.OOtable}>
                    <table className={`uib-table uib-table--striped ${styles.tabletopborder}`}>
                        <thead className={`uib-table__header ${styles.tablehead}`}>
                        <tr className="uib-table__row uib-table__row--header">
                            <th className={styles.hoverPointer} onClick={() => sortByKey("name")}>Name{showSortIcon("name")}</th>
                            <th className={`${styles.centerTD} ${styles.hoverPointer}`} onClick={() => sortByKey("id")}>ID{showSortIcon("id")}</th>
                            <th className={`${styles.centerTD} ${styles.hoverPointer}`} onClick={() => sortByKey("estimatedHours")}>Estimate{showSortIcon("estimatedHours")}</th>
                            <th className={`${styles.centerTD} ${styles.hoverPointer}`} onClick={() => sortByKey("priceModel")}>Price Model{showSortIcon("priceModel")}</th>
                            <th className={`${styles.centerTD} ${styles.hoverPointer}`} onClick={() => sortByKey("orderNumber")}>Order Number{showSortIcon("orderNumber")}</th>
                            <th className={`${styles.centerTD} ${styles.hoverPointer}`} onClick={() => sortByKey("area")}>Area{showSortIcon("area")}</th>
                            <th className={`${styles.centerTD} ${styles.hoverPointer}`} onClick={() => sortByKey("journeyState")}>State{showSortIcon("journeyState")}</th>
                            <th className={`${styles.centerTD} ${styles.hoverPointer}`} onClick={() => sortByKey("dateDone")}>Acceptance Date{showSortIcon("dateDone")}</th>
                            <th className={`${styles.centerTD} ${styles.hoverPointer}`} onClick={() => sortByKey("dateModified")}>Last Modified{showSortIcon("dateModified")}</th>
                            <th className={`${styles.centerTD}`}>Logs</th>
                        </tr>
                        </thead>
                        <tbody className="uib-table__body">
                        {/* show all orders, filtered by date and state */}
                        {filteredProjects().map((item, index) => {
                            return <tr className="uib-table__row" key={index}>
                                <td>{item.name}</td>
                                <td className={`${styles.centerTD} ${styles.link}`}>{convertIDtoLink(item.id)}</td>
                                <td className={`${styles.centerTD}`}>{item.estimatedHours} h</td>
                                <td className={`${styles.centerTD}`}>{item.priceModel}</td>
                                <td className={`${styles.centerTD}`}>{item.orderNumber}</td>
                                <td className={`${styles.centerTD}`}>{truncateArea(item.area)}</td>
                                <td className={`${styles.centerTD}`}>{item.journeyState}</td>
                                <td className={`${styles.centerTD}`}>{trimDate(item.dateDone)}</td>
                                <td className={`${styles.centerTD}`}>{trimDate(item.dateModified)}</td>
                                <td className={`${styles.centerTD}`}>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 150, hide: 200 }}
                                        overlay={
                                            <Tooltip id="tooltip-disabled">
                                                Show Logs
                                            </Tooltip>
                                        }
                                    >
                                        <Button onClick={() => {LogPopupShow(item.logs)}} className={`uib-button uib-button--primary uib-button--square ${styles.ButtonLogo}`}>
                                            <i className={`uib-icon uib-icon--view ${styles.logo}`}></i>
                                        </Button>
                                    </OverlayTrigger>
    
                                </td>
                            </tr>
                        })
                        }
                        </tbody>
                    </table>
                </div>
        </div>
    </>

}

export default Overview;