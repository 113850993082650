import {IProject} from "../Components/Hub/Interfaces/IAllProjects";
import {GlobalDictionary} from "./globalDictionary";
import {HubPathRouting} from "../HubFramework/pathBuilder";
import {IModule} from "../BackendFacade/IModuleInstances";


export function decodeQueryParam(p: string): string {
    return decodeURIComponent(p.replace(/\+/g, ' '));
}

export function convertIntToDate(value: number) {
    if(value === undefined || value === 0) {
        return null
    } else {
        // convert int to date, use the local timezone


        const date = new Date(parseInt(value + "000")) //.toUTCString();

        return new Intl.DateTimeFormat('en-GB', {
            timeZone: "Europe/Berlin",
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        }).format(date);
    }
}

export function replaceProjectGuidWithDisplayName(scope: string): string {
    const instances: IProject[] =  GlobalDictionary.get(GlobalDictionary.PROJECTS)
    if(instances === undefined) {
        return scope
    }
    return instances.find(x => x.projectId === scope)?.displayName ?? scope
}

/**
 * replaces the project guid with the display name
 * @param scopes scopes
 * @param position 1 = project, 2 = module
 * @param globalLayer if the scopes are global or not
 */
export function replaceGuidWithDisplayName(scopes: string[], position: number, globalLayer: boolean): string {
    let returnValue = ""
    switch (position) {
        // replace project guid with display name
        case 1:
            const instances: IProject[] =  GlobalDictionary.get(GlobalDictionary.PROJECTS)
            if(instances === undefined) {
                returnValue = scopes[1]
                break;
            }
            returnValue = instances.find(x => x.projectId === scopes[1])?.displayName ?? scopes[1]
            break;
        // replace module guid with display name
        case 2:
            let modules: IModule[] = []
            if(globalLayer) {
                modules = GlobalDictionary.get(scopes[1] + GlobalDictionary.MODULEINSTANCES)
            } else {
                modules = GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES)
            }
            if(!modules) {
                returnValue = scopes[2]
                break;
            }
            returnValue = modules.find(x => x.moduleInstanceId === scopes[2])?.displayName ?? scopes[2]
            break;
        default:
            returnValue = scopes[position]
    }
    // shorten the return value if it is too long
    if(returnValue.length > 15) {
        returnValue = returnValue.substring(0, 20) + "..."
    }
    return returnValue
}

/**
 * Converts c# DateTime to a readable format hh:mm dd.mm.yyyy
 * @param input takes for example: 2023-08-22T14:25:01.0936326+00:00
 */
export function formatDate(input: string): string {
    if(input === undefined || input === null) {
        return "";
    }
    const date = new Date(input);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${hours}:${minutes} ${day}.${month}.${year}`;
}

/**
 * Converts a date to a format that the backend can understand
 * Converts from dd.mm.yyyy to yyyy-mm-ddT00:00:00.000Z
 * @param input
 */
export function formatDateToBackend(input: string): string {
    if(input === undefined || input === null) {
        return "";
    }
    const date = new Date(input);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day}T00:00:00.000Z`;
}

/**
 * Deepcopy an object
 * @param obj
 */
export function deepCopy(obj: any): any {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {} as any;
        for (let attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

export function cleansePageName(pageName: string) {
    return pageName.replace(/\s/g, "");
}

/**
 * Takes two lists and returns a new list with unique items.
 * @param list1
 * @param list2
 */
export function concatListsTakeUniqueItems(list1: any[], list2: any[]) {
    let list = list1.concat(list2);
    return list.filter((v, i, a) => a.indexOf(v) === i);
}

/**
 * Takes a list of strings and returns a list of options. Used for the select component.
 * @param areas
 */
export function convertListOfStringsToOptionsList(areas: string[]) {
    let options: any[] = [];
    if(areas == undefined) {
        return options;
    }
    areas.forEach((x) => {
        options.push({
            value: x,
            label: x
        })
    })
    return options;
}

/**
 * Replace double slashes with single slashes
 */
export function replaceDoubleSlashesWithSingleSlash(input: string) {
    return input.replace(/\/\//g, "/");
}
