import * as React from "react";
import {NetworkData, Popup, ProjectMeta, IDOOP, DoopUser} from "./Interfaces/IDOOP";
import {
    getProjectMeta, getProjectMetaDataAreas,
    getProjectUsersData,
} from "./BackendFacade/apiCalls";
import "@uib/css/themes/draeger-theme-bright.css"
import * as Sentry from "@sentry/react";
import {HubFramework} from "../../HubFramework";
import {moduleContent} from "./moduleContent";
import {HubPathRouting} from "../../HubFramework/pathBuilder";
import {ReactElement, useEffect} from "react";
import {extend} from "jquery";

export class Module extends React.Component<{}, IDOOP> {
    constructor(props: {args: {text: string}}) {
        super(props);
        HubPathRouting.setProject()
        HubPathRouting.setModule()
        this.state = {
            Popup: Popup.None,
            DoopUser: [] as DoopUser[],
            CurrentLog: "",
            Meta: null,
            AllAreas: null,
            // getProjectData: this.getProjectData,
            Error: "",
            // projectId: HubPathRouting.currentProject.moduleInstanceId,
            // moduleInstanceId: HubPathRouting.currentModule.moduleInstanceId,
        }
    }

    PopupClose() {
        this.setState({Popup: Popup.None, CurrentLog: ""})
    }

    async getProjectData() {
        let value: NetworkData = {success: true, message: "", data: null}
        let prom2 = getProjectMeta(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId).then(data => {
            if(!data.success) {
                value = {success: false, message: data.message, data: null}
                Sentry.captureMessage(data.message)
            } else {
                let meta = data.data as ProjectMeta
                if(meta.doopAreas === undefined)
                    meta.doopAreas = []
                this.setState({Meta: meta})
            }
        })
        let prom4 = getProjectMetaDataAreas(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId).then(data => {
            if(!data.success) {
                value = {success: false, message: data.message, data: null}
                Sentry.captureMessage(data.message)
            } else {
                this.setState({AllAreas: data.data.allAreas})
            }
        })
        let prom3 = getProjectUsersData(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId).then(data => {
            if(!data.success) {
                value = {success: false, message: data.message, data: null}
                Sentry.captureMessage(data.message)
            } else {
                this.setState({DoopUser: data.data})
            }
        })
        await Promise.all([ prom2, prom3, prom4])
        return value
    }

    public render() : ReactElement {
        return <HubFramework hubContent={moduleContent} module={this} />
    }
}

// const Module : React.FC = () => {
//     const [popup, setPopup] = React.useState<Popup>(Popup.None);
//     const [doopUser, setDoopUser] = React.useState<DoopUser[]>([]);
//     const [currentLog, setCurrentLog] = React.useState<string>("");
//     const [meta, setMeta] = React.useState<ProjectMeta | null>(null);
//     const [allAreas, setAllAreas] = React.useState<string[] | null>(null);
//     const [error, setError] = React.useState<string>("");
//
//     useEffect(() => {
//         getProjectData();
//     }, []);
//
//     async function getProjectData() {
//         let value: NetworkData = {success: true, message: "", data: null}
//         let prom2 = getProjectMeta(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId).then(data => {
//             if(!data.success) {
//                 value = {success: false, message: data.message, data: null}
//                 Sentry.captureMessage(data.message)
//             } else {
//                 let meta = data.data as ProjectMeta
//                 if(meta.doopAreas === undefined)
//                     meta.doopAreas = []
//                 setMeta(meta)
//                 //HubPathRouting.currentModule.displayName = meta.nameRaw
//             }
//         })
//         let prom4 = getProjectMetaDataAreas(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId).then(data => {
//             if(!data.success) {
//                 value = {success: false, message: data.message, data: null}
//                 Sentry.captureMessage(data.message)
//             } else {
//                 setAllAreas(data.data.allAreas)
//             }
//         })
//         let prom3 = getProjectUsersData(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId).then(data => {
//             if(!data.success) {
//                 value = {success: false, message: data.message, data: null}
//                 Sentry.captureMessage(data.message)
//             } else {
//                 setDoopUser(data.data)
//             }
//         })
//         await Promise.all([ prom2, prom3, prom4])
//         return value
//     }
//
//     const PopupClose = () => {
//         setPopup(Popup.None);
//         setCurrentLog("");
//     }
//
//     return <HubFramework hubContent={moduleContent} module={this} />;
// }
//
// export default Module;

export interface OptionOIS {
    value: string,
    label: string,
}
