import * as React from "react";
import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";


const Module: React.FC = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        window.location.assign("https://keychangeraccessfuncs.azurewebsites.net/api/KeychangerTaskEventTrigger?code=lHbuueUoXccG6PnGEOBXDKauaxWu9UinBACnd92otVsMUbNZvcDZuA%3D%3D&group=true");

    }, []);

    return <></>;
};

export default Module;