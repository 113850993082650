import {ReactElement} from "react";
import {Button, Col, Container, Dropdown, DropdownButton, ModalBody, ModalFooter, Row} from "react-bootstrap";
import styles from "../../Dpas.module.css";
import {AssetURL, BlobDocuments, FolderDocument, FolderImage, FolderTypeImage, FolderTypeSecret} from "../../constants";
import {deleteFile, downloadFile} from "../../BackendFacade/apiCalls";
import {BlobURLBasic} from "../../environmentVars";
import ModalHeader from "react-bootstrap/ModalHeader";
import {isUserroleSufficient} from "../../../../Utils/authorization";
import {UserRoles} from "../../../../Utils";
import {HubPathRouting} from "../../../../HubFramework/pathBuilder";
import * as React from "react";
import {useMain} from "../../../../Utils/SessionControls/mainContext";
import {IFileDetails, DpasPopup} from "../../Interfaces/IFiles";
import {createDownloadObject} from "../../../../Utils/objectDownload";

export interface iFilesDetail {
    setPopup: (popup: any) => void,
    refreshDirectory: () => void,
    fileDetails: IFileDetails,
    directoryType: string,
    projectId: string
}

const FilesDetail: React.FC<iFilesDetail> = ({setPopup, refreshDirectory, fileDetails, directoryType, projectId}) => {
    const {setLoading, popupCreate, popupClose} = useMain()

    const dpasPopupClose = () => {
        setPopup(DpasPopup.None)
    }

    const handleDownloadFile = (path: string, resolution: string = "") => {
        setLoading(true)
        downloadFile(path, directoryType, resolution).then((data) => {
            if(!data.success) {
                popupCreate("Error",  data.message)
            } else {
                createDownloadObject(data.data, path.split('/').pop())
            }
            setLoading(false)
        })
    }

    /**
     * Creates a popup to delete a file
     * @param path
     * @param directoryType
     */
    const popupDeleteFile = (path: string, directoryType: string) => {
        console.log("delete path", path)
        // split the path with / and remove the first element, which is the project name
        // let splittedPath = path.split('/').filter(x => x !== "")
        // splittedPath = splittedPath.slice(1)
        // // join the path again
        // path = splittedPath.join('/')

        popupCreate("Delete" + directoryType,
            "Are you sure, " + path + " shall be deleted? There is no undo!",
            handleDeleteFile.bind(this, path, directoryType)
        )
    }

    const handleDeleteFile = (path: string, directoryType: string) => {
        setLoading(true)
        deleteFile(path, directoryType).then((data) => {
            if(!data.success) {
                popupCreate("Error",  data.message)
            } else {
                dpasPopupClose()
                refreshDirectory()
                popupClose()
            }
            setLoading(false)
        })
    }

    let Infos: ReactElement
    let downloadButtons: ReactElement
    let deleteButton = <Button className={`uib-button uib-button--primary ${styles.spaceRight} ${styles.errorButton}`}
                               onClick={() => {popupDeleteFile(fileDetails.fileName, directoryType)}}>
        Delete
    </Button>

    if(directoryType == FolderTypeImage) {
        Infos = <><Row className={styles.popupSpaceBetween}>
            <Col md={3} className="align-self-center">
                Picture
            </Col>
            <Col >
                <img className={styles.imgDetails} src={AssetURL + "1080px/" + projectId + "/" + fileDetails.fileName} alt={"picture"} />
            </Col>
        </Row>
            <Row className={styles.popupSpaceBetween}>
                <Col md={3} className="align-self-center">
                    Available Solutions
                </Col>
                <Col>
                    {fileDetails.availableResolutions.join(', ')}
                </Col>
            </Row></>
        downloadButtons = <>
            <DropdownButton className={`${styles.dropdown} ${styles.spaceRight}`} id="dropdown-basic-button" title="Download resolution">
                {fileDetails.availableResolutions.map((res, index) => {
                    return <Dropdown.Item key={index}
                                          onClick={() => handleDownloadFile(fileDetails.fileName, res)} >
                        {res}
                    </Dropdown.Item>
                })}
            </DropdownButton>
            <DropdownButton className={`${styles.dropdown}`} id="dropdown-basic-button" title="Copy link">
                {fileDetails.availableResolutions.map((res, index) => {
                    return <Dropdown.Item key={index}
                                          onClick={() => navigator.clipboard.writeText(BlobURLBasic + res + '/' + projectId + "/" + fileDetails.fileName)} >
                        {res}
                    </Dropdown.Item>
                })}
            </DropdownButton></>
    } else {
        Infos = <Row className={styles.popupSpaceBetween}>
            <Col md={3} className="align-self-center">
                File size
            </Col>
            <Col >
                { // round to 2 decimal places
                    Math.round((fileDetails.length / 1024 / 1024) * 100) / 100} MB
            </Col>
        </Row>
        downloadButtons = <>
            <Button className={`uib-button uib-button--primary ${styles.spaceRight}`} onClick={() => {
                handleDownloadFile(fileDetails.fileName)
            }}>Download</Button>
            {directoryType !== FolderTypeSecret &&
                <Button className={`uib-button uib-button--primary ${styles.spaceRight}`} onClick={() => {
                    navigator.clipboard.writeText(BlobDocuments + projectId + "/" + fileDetails.fileName)
                }}>Copy Link</Button>
            }
        </>
    }
    return <div>
        <ModalHeader>
            Details
        </ModalHeader>
        <ModalBody>
            <Container>
                <Row className={styles.popupSpaceBetween}>
                    <Col md={3} className="align-self-center">
                        File Name
                    </Col>
                    <Col>
                        {fileDetails.fileName?.toString().split('/').pop()}
                    </Col>
                </Row>
                {Infos}
                <Row className={styles.popupSpaceBetween}>
                    <Col md={3} className="align-self-center">
                        Created
                    </Col>
                    <Col>
                        {fileDetails.properties.created}
                    </Col>
                </Row>
                <Row className={styles.popupSpaceBetween}>
                    <Col md={3} className="align-self-center">
                        Last Modified
                    </Col>
                    <Col >
                        {fileDetails.properties.lastModified}
                    </Col>
                </Row>
            </Container>
        </ModalBody>
        <ModalFooter>
            <div className={styles.flexgrow}>
                { isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId) ? deleteButton : <></>}
                {downloadButtons}
            </div>
            <Button className={`uib-button uib-button--secondary ${styles.spaceRight}`} onClick={() => {dpasPopupClose(); }}>Close</Button>
        </ModalFooter>
    </div>;
}

export default FilesDetail;