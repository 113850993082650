import {ReactElement, useCallback, useEffect, useState} from "react";
import { MainContext } from "./mainContext";
import * as React from "react";
import {Popup} from "../../HubFramework/IHub";
import ModalHeader from "react-bootstrap/ModalHeader";
import {Button, Col, Container, ModalBody, ModalFooter, Row} from "react-bootstrap";
import styles from "../../HubFramework/HubFramework.module.css";

export const MainProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoadingState, setLoadingState] = useState(false);
    const [dictionary, setDictionary] = useState<any>({});

    // popups
    const [popup, setPopup] = React.useState<Popup>(Popup.None);
    const [PopupHeader, setPopupHeader] = React.useState<string | ReactElement>("");
    const [PopupMessage, setPopupMessage] = React.useState<string | ReactElement>("");
    const [PopupSubmitFunction, setPopupSubmitFunction] = React.useState<any>(null);

    const setGlobalState = (state: any) => {
        setDictionary(state);
    }

    const globalState = (key: string) => {
        return dictionary[key];
    }

    const setLoading = (isLoading: boolean) => {
        setLoadingState(isLoading);
    }

    /**
     * Create a popup with a header and content. On the left side is a label "Message"
     * @param header headline of the popup
     * @param content content of the popup
     * @param submitFunction function to be called when the submit button is pressed
     * @constructor
     */
    const popupCreate = useCallback((header: string, content: string | ReactElement, submitFunction?: () => void) => {
        setPopupSubmitFunction(() => submitFunction)
        setPopupHeader(header)
        setPopupMessage(content)
        setPopup(Popup.Other)
    }, [])

    /**
     * Create a popup with a header and content. The content will be full width
     * @param header headline of the popup
     * @param content content of the popup
     * @param submitFunction function to be called when the submit button is pressed
     * @constructor
     */
    const popupCreateSingle = useCallback((header: string | ReactElement, content: string | ReactElement, submitFunction?: () => void) => {
        setPopupHeader(header)
        setPopupMessage(content)
        setPopupSubmitFunction(() => submitFunction)
        setPopup(Popup.OtherFull)
    }, [])

    /**
     * Create a popup with a header and content
     */
    const PopupContent = ( ) : ReactElement => {
        if(popup === Popup.None) return (<></>);

        let header = PopupHeader;
        let content = PopupMessage;
        let submitFunction = PopupSubmitFunction;
        let single =  popup === Popup.OtherFull;

        return <div id={"Popup"}>
            <ModalHeader id={"PopupHeader"} closeButton>
                {header}
            </ModalHeader>
            <ModalBody className={styles.popupBody}>
                <Container>
                    <Row className={styles.popupSpaceBetween}>
                        { !single && <Col md={3} className="align-self-center">
                            Message
                        </Col> }
                        <Col >
                            <Row id={"PopupContent"}>
                                {content}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button className={`uib-button uib-button--secondary ${styles.spaceRight}`} onClick={() => popupClose()}>Cancel</Button>
                {submitFunction != null && <Button className="uib-button uib-button--primary" onClick={() => {submitFunction(); popupClose()}}>Submit</Button>}
            </ModalFooter>
        </div>;
    }

    const isPopupOpen = () => {
        return popup !== Popup.None;
    }


    const popupClose = () => {
        setPopupSubmitFunction(null)
        setPopup(Popup.None)
    }

    /**
     * Update the url to the current page
     */
    // const updateUrl = () => {
    //     // on push on history if it is not the last element
    //     if('/' + history.location.hash !== HubPathRouting.getPathAsUrl()) {
    //         history.push(HubPathRouting.getPathAsUrl())
    //     }
    // }

    return (
        <MainContext.Provider value={{
            setGlobalState,
            globalState,
            isLoading: isLoadingState,
            setLoading,
            popupCreate,
            popupCreateSingle,
            popupClose,
            PopupContent,
            isPopupOpen
        }}>
            {children}
        </MainContext.Provider>
    );
}