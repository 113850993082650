/**
 * This folder should all the api calls that are used in the module.
 * Every call must use the fetchFromApi function from the backendCalls.tsx file.
 * This function will handle the authentication and error handling.
 */


import {fetchFromApi, getBackendApiURL} from "../../../BackendFacade";

/**
 * Gets the kafka event logs
 * @param component
 */
export function getDocumentation(component: string) {
    let url = getBackendApiURL() + "static/documentation/" + component;
    return fetchFromApi(url, "GET")
}
