import {CreationProps} from "../../../HubFramework/IHub";
import {IlinkedId} from "../../../BackendFacade/IModuleInstances";


export interface IAllModules_Edit {
    moduleInstanceId: string,
    /* id of the module component */
    staticModuleId: string,

    group: string,
    linkedId: IlinkedId,
    displayName: string,
    creationProps: CreationProps[],

}

export enum Popup {
    None,
    DeleteModule,
    HardDeleteModule,
}
