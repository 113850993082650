import * as React from "react";
import {ReactElement, useEffect, useState} from "react";

import {Button, Col, Container, Form, FormControl, ModalBody, ModalFooter, Row} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import styles from './../../Hub.module.css'
import {deepCopy, formatDateToBackend} from "../../../../Utils/transformer";
import {HubPathRouting} from "../../../../HubFramework/pathBuilder";
import {TenantLine} from "../../../IDMS/Utils/ITenantSystem";
import {IApiKeysAddEditState, IApiKeysEditMode} from "../../Interfaces/IApiKeysAddEditState";
import UserTenantManagement, {SetGlobalTenantSystemContent} from "../../../IDMS/Utils/tenantSystem";
import {createApiKeys, updateApiKeys} from "../../BackendFacade/apiCalls";
import {IApiKeyContentTypes} from "../../Interfaces/IApiKeyManagement";
import {AuthLevels, defaultAuth} from "../../constants";
import {useMain} from "../../../../Utils/SessionControls/mainContext";

interface IApiKeysAddEditModal {
    onSuccessFunction: () => void;
    closeFunction: () => void;
    state: IApiKeysAddEditState;
    projectId: string;
}

const ApiKeys_AddEditModal: React.FC<IApiKeysAddEditModal>  = ({
    onSuccessFunction,
    closeFunction,
    projectId,
    state,
}) => {
    const [tenantSystem, setTenantSystem] = useState(state.tenantSystem || [])
    const [Selected_Value, setSelected_Value] = useState(state.Selected_Value)
    const [listOfSubscopes, setListOfSubscopes] = useState(state.listOfSubscopes)
    const [editMode, setEditMode] = useState(state.editMode)
    const [expirationDate, setExpirationDate] = useState(state.expirationDate)
    console.log("tenantSystem", tenantSystem)
    const {popupCreate, setLoading} = useMain();

    useEffect(() => {
        // on project level, if the user has no scopes in this project, add an empty one
        if(Selected_Value.contentType === IApiKeyContentTypes.hubIdmsTenantJson &&
            tenantSystem.length <= 1 &&
            HubPathRouting.currentProject &&
            tenantSystem[0].fullscope.indexOf(HubPathRouting.currentProject.moduleInstanceId) === -1) {
                addGlobalScope()
        }
    }, [])

    /**
     * Add a full scope to the tenant system to the webpage backend
     */
    const addGlobalScope = () => {
        let scopes = [...tenantSystem];
        let newLine: TenantLine = {id: scopes.length, scopes : ["user"], value : defaultAuth, tmp: "", fullscope: ""}
        if(projectId !== "null") {
            newLine.scopes.push(projectId)
        }
        scopes.push(newLine)
        setTenantSystem(scopes)
        return scopes
    }

    /**
     * Saves the current user config
     */
    const saveUser = async () => {
        setLoading(true)
        if(Selected_Value.contentType === IApiKeyContentTypes.hubIdmsTenantJson) {
            let tenantData: {[key: string]: string} = {}
            // evaluate tenant data
            for (let line of tenantSystem) {
                // skip empty value and empty scopes
                if(line.value === "-" || line.scopes.length === 0 || line.value === "") {
                    continue
                }
                tenantData[line.scopes.join('/')] = line.value
            }
            // stringify the tenant data
            Selected_Value.content = JSON.stringify(tenantData)
        }

        if(expirationDate) {
            Selected_Value.validUntil = formatDateToBackend(Selected_Value.validUntil)
        }

        if(editMode === IApiKeysEditMode.Add) {
            createApiKeys(projectId, Selected_Value).then((data) => {
                setLoading(false)
                if(data.success) {
                    onSuccessFunction()
                } else {
                    popupCreate("Error", "Error while saving api key: " + data.message)
                }
            })
        } else {
            updateApiKeys(projectId, Selected_Value).then((data) => {
                setLoading(false)
                if(data.success) {
                    onSuccessFunction()
                } else {
                    popupCreate("Error", "Error while saving api key: " + data.message)
                }
            })
        }

    }

    /**
     * Edit the value of a User scope
     * @param id id of the scope line
     * @param value value
     */
    const EditScopeValue = (id: number, value: string) => {
        let tenantLines = [...tenantSystem]
        tenantLines.find(x => x.id === id).value = value
        setTenantSystem(tenantLines)
    }

    /**
     * displays the user config for the individual scopes
     */
    const getAuthLevel_NewTenantManagement = () => {
        let _tenantSystem = tenantSystem
        if(_tenantSystem.length === 0) {
            _tenantSystem = addGlobalScope()
        }

        let globalScope = <></>
        // hub level configuration
        if(projectId === "null") {
            // id of the global scope
            let mglobalid = _tenantSystem.find(x => x.scopes.length === 1 && x.scopes.every(
                (value, index) => value === ["user"][index]
            )).id
            // set global to default auth if not set
            if(_tenantSystem.find(x => x.id === mglobalid).value === "") {
                EditScopeValue(mglobalid, defaultAuth)
            }
            // if called from within project, don't show global scope
            globalScope = <SetGlobalTenantSystemContent tenantSystem={_tenantSystem}
                                                        globalId={mglobalid}
                                                        authLevels={AuthLevels}
                                                        defaultAuth={defaultAuth}
                                                        onEditFunction={EditScopeValue.bind(this)}
            />
        }

        return <Row>
            <Col>
                {globalScope}
                {/* Individual scopes */}
                <UserTenantManagement tenantSystemProps={_tenantSystem}
                                      setTenantSystemFunctionProps={setTenantSystemFunction.bind(this)}
                                      projectId={projectId}
                                      authLevels={AuthLevels}
                                      listOfIDMSSubscopes={listOfSubscopes}
                                      global={projectId === "null"}
                                      useSelectOptions={true}
                />
                <p>Please remember to click "Add" for each new scope.</p>
                <br/>

                <Button className="uib-button uib-button--primary"
                        id={"addScope"}
                        onClick={() => addGlobalScope()}>
                    Add Scope
                </Button>
            </Col>
        </Row>;
    }

    const setTenantSystemFunction = (tenantSystem: TenantLine[]) => {
        setTenantSystem(tenantSystem)
    }

    let authLevel: ReactElement
    if(Selected_Value.contentType === IApiKeyContentTypes.hubIdmsTenantJson) {
        authLevel = getAuthLevel_NewTenantManagement()
    } else {
        // create a giant text box
        authLevel = <Row>
            <Col>
                <FormControl
                    id={"authLevelCustom"}
                    as="textarea"
                    rows={10}
                    value={Selected_Value.content}
                    onChange={(event) => {
                        let apiKey = Selected_Value;
                        apiKey.content = event.target.value;
                        setSelected_Value(deepCopy(apiKey))
                    }} />
            </Col>
        </Row>
    }

    return <div>
        <ModalHeader closeButton>
            {editMode === IApiKeysEditMode.Add ? "Add" : "Edit"} API Key
        </ModalHeader>
        <ModalBody>
            <Container>
                <Row className={styles.popupSpaceBetween}>
                    <Col md={2} className="align-self-center">
                        Display Name
                    </Col>
                    <Col >
                        <FormControl
                            placeholder="Display Name"
                            id="displayName"
                            value={Selected_Value.displayName}
                            onChange={(event) => {
                                let apiKey = Selected_Value;
                                apiKey.displayName = event.target.value;
                                setSelected_Value(deepCopy(apiKey))
                            }} />
                    </Col>
                </Row>
                <Row className={styles.popupSpaceBetween}>
                    <Col md={2} className="align-self-center">
                        Expiration Date
                    </Col>
                    <Col >
                        <Form.Check
                            checked={expirationDate}
                            id="useExpirationDate"
                            onChange={(event) => {
                                setExpirationDate(event.target.checked)
                            }}
                        />

                    </Col>
                </Row>
                {expirationDate &&
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">
                            Valid Until
                        </Col>
                        <Col >
                            <FormControl
                                type="date"
                                value={Selected_Value.validUntil}
                                id="validUntil"
                                onChange={(event) => {
                                    let apiKey = deepCopy(Selected_Value);
                                    apiKey.validUntil = event.target.value;
                                    setSelected_Value(apiKey)
                                }} />
                        </Col>
                    </Row>
                }
                <Row className={styles.popupSpaceBetween}>
                    <Col md={2} className="align-self-center">
                        Use tenant system
                    </Col>
                    <Col >
                        <Form.Check
                            checked={Selected_Value.contentType === IApiKeyContentTypes.hubIdmsTenantJson}
                            id="useTenantSystem"
                            onChange={(event) => {
                                let apiKey = deepCopy(Selected_Value);
                                apiKey.contentType = event.target.checked ? IApiKeyContentTypes.hubIdmsTenantJson : IApiKeyContentTypes.custom;
                                setSelected_Value(apiKey)
                            }}
                            />

                    </Col>
                </Row>
                {authLevel}
            </Container>
        </ModalBody>
        <ModalFooter>
            <Button className="uib-button uib-button--secondary"
                    variant="secondary"
                    onClick={() => closeFunction()}
                    id={"cancelButton"}
            >Cancel</Button>
            <Button className={`uib-button uib-button--primary ${styles.addUserButton}`}
                    id={"applyButton"}
                    onClick={() => {saveUser()}}>Apply</Button>
        </ModalFooter>
    </div>;

}

export default ApiKeys_AddEditModal;
