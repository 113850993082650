import * as React from "react";
import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";

const Module: React.FC = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        window.location.assign("https://sonarqube.draeger.net/dashboard?id=" + searchParams.get("linkedId"));
    }, []);

    return <></>;
};

export default Module;