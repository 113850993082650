import * as React from "react";
import {Alert, Button, Card, Col, Container, Form, FormControl, FormGroup, Modal, Row} from "react-bootstrap";
import {IModuleInfos, Popup, PopupMessage} from "../Interfaces/INewModuleInstances";
import {createModuleInstance} from "../BackendFacade/apiCalls";
import {getListOfAllModules} from "../../../Utils/modules";
import {IListStaticModule} from "../../../BackendFacade/IStaticModules";
import {deepCopy} from "../../../Utils/transformer";
import {IconTrash} from "../../../Assets/svgs";
import {isUserroleSufficient} from "../../../Utils/authorization";
import {UserRoles} from "../../../Utils";
import {CreationProps} from "../../../HubFramework/IHub";
import styles from "../ProjectContent.module.css"
import {moduleIcons} from "../Icons/Icons";
import {IModule} from "../../../BackendFacade/IModuleInstances";
import {moduleContent as IDMSModule} from "../../IDMS";
import {moduleContent as IDMSDevModule} from "../../IDMSDev";
import {GlobalDictionary} from "../../../Utils/globalDictionary";
import {HubPathRouting} from "../../../HubFramework/pathBuilder";
import Documentation from "../../Documentation/Documentation";
import docuStyles from "../../Documentation/Documentation.module.css";
import {ReactElement, useEffect} from "react";
import {useMain} from "../../../Utils/SessionControls/mainContext";

const NewModuleInstances: React.FC = () => {
    const [availableModules, setAvailableModules] = React.useState<IListStaticModule[]>([]);
    const [newModule, setNewModule] = React.useState<IModule | undefined>(undefined);
    const [newModuleInfos, setNewModuleInfos] = React.useState<IModuleInfos | undefined>(undefined);
    const [popup, setPopup] = React.useState<Popup>(Popup.None);
    const [popupMessage, setPopupMessage] = React.useState<string>("");
    const [popupMessageType, setPopupMessageType] = React.useState<PopupMessage>(PopupMessage.Info);

    const [groups, setGroups] = React.useState<string[]>([]);

    const {popupCreate, setLoading, popupCreateSingle} = useMain();

    useEffect(() => {
        LoadModules().then(() =>
            setGroups(getExistingModuleGroups())
        )
    }, []);

    const LoadModules = async () => {
        setLoading(true)
        setAvailableModules(await GlobalDictionary.getStaticModules())
        setLoading(false)
    }

    /**
     * Gather all groups from existing module instances
     */
    const getExistingModuleGroups = (): string[] => {
        let groups = [] as string[]
        if(GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES)) {
            GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES).forEach((module: IModule) => {
                if(!groups.includes(module.group)) {
                    groups.push(module.group)
                }
            })
        }
        return groups
    }

    const activateModule = (module: IListStaticModule) => {
        const header = <span>{moduleIcons[module.staticModuleId] ?
            <Card.Img src={moduleIcons[module.staticModuleId]} className={`${docuStyles.ModuleIcon}`} /> :
            <Card.Img src={moduleIcons["default"]} className={`${styles.ModuleIcon}`} />
        } {module.displayName} </span>

        popupCreateSingle(header, <Documentation component={module.staticModuleId} />)
    }

    /**
     * Renders the new module cards
     */
    const NewModuleCards = (): ReactElement => {
        let cards = [] as ReactElement[]
        availableModules
            // sort the groups alphabetically but "other" always last
            .sort((a, b) => {
                return a.displayName.localeCompare(b.displayName)
            })
            .forEach((module, key) => {
                cards.push( <Col className="col-3" key={key} >
                    <Card className={`${styles.ModuleCard}`} onClick={() => addModuleShow(module)}>
                        <Card.Body>
                            <Button variant="outline-secondary"
                                            className={`${styles.OptionsButton}`}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                activateModule(module)
                                            }}>
                                ?
                            </Button>
                            <Card.Title>
                                {moduleIcons[module.staticModuleId] ?
                                    <Card.Img src={moduleIcons[module.staticModuleId]} className={`${styles.ModuleIcon}`} />
                                    :
                                    <Card.Img src={moduleIcons["default"]} className={`${styles.ModuleIcon}`} />
                                }
                                {module.displayName}
                            </Card.Title>
                            <Card.Text>
                                {module.description}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>)
            })

        return <>
            <div id={"orderModules"} className={`${styles.GroupHeader}`}>Order New Module Instance</div>
            <Row lg={3}>{cards}</Row>
        </>
    }

    /**
     * Prepares the variables for the popup and opens it
     * @param module
     * @constructor
     */
    const addModuleShow = (module: IListStaticModule) => {
        let allModules = getListOfAllModules()
        let moduleContent = allModules[module.staticModuleId]

        if(moduleContent === undefined) {
            popupCreate("Error", "Module not available")
            return
        }

        let newModule: IModule = {
            staticModuleId: module.staticModuleId,
            moduleInstanceId: "",
            displayName: "",
            group: "Other",
            linkedId: deepCopy(module.requiredLinkedIds),
            creationProps: moduleContent.moduleContent.creationProps ? deepCopy(moduleContent.moduleContent.creationProps) : undefined,
            migrateExisting: false
        }
        newModule.linkedId.projectId = ""

        let newModuleInfo: IModuleInfos = {
            staticModuleDisplayName: module.displayName,
            projectIdInfoText: module.requiredLinkedIds.projectId
        }
        setPopup(Popup.AddModule)
        setNewModule(newModule)
        setNewModuleInfos(newModuleInfo)
        setPopupMessage("")
    }

    /**
     * Creates the necessary fields for the popup.
     * @param creationProps
     * @constructor
     */
    const CreateModuleCreationProps = (creationProps: CreationProps[]): ReactElement => {
        // if there are no creation props or the user wants to migrate an existing module, skip
        if(creationProps === undefined || newModule.migrateExisting) {
            return <></>
        }
        let content = [] as ReactElement[]
        creationProps.forEach((prop) => {
            let formControl: ReactElement
            // we want to create a form control for each property based on the type (on the left side of the screen)
            switch (prop.type) {
                case "string":
                    formControl = <FormControl type="text"
                                               id={prop.name}
                                               value={prop.value as string}
                                               onChange={(e) => {
                                                   prop.value = e.target.value
                                                   // this.setState({newModule: newModule})
                                                    setNewModule({...newModule})
                                               }
                                               }/>
                    break
                case "number":
                    formControl = <FormControl type="number"
                                               id={prop.name}
                                               value={prop.value as number}
                                               onChange={(e) => {
                                                   prop.value = e.target.value
                                                   setNewModule({...newModule})
                                               }
                                               }/>
                    break
                case "boolean":
                    formControl = <Form.Check type="checkbox"
                                              id={prop.name}
                                              checked={prop.value as boolean}
                                              onChange={(e) => {
                                                  prop.value = e.target.checked
                                                  setNewModule({...newModule})
                                              }
                                              }/>
                    break
                case "select":
                    formControl = <Form.Control as="select"
                                                id={prop.name}
                                                value={prop.value as string}
                                                onChange={(e) => {
                                                    prop.value = e.target.value
                                                    setNewModule({...newModule})
                                                }
                                                }>
                        {prop.options!.map((option, key) => {
                            return <option key={key} >
                                {option}
                            </option>
                        })}
                    </Form.Control>
                    break
                case "string[]" :
                    // this is not needed, this is wrong and needs to be fixed
                    formControl = <FormGroup >
                        <div className="addListStrings">
                            <Form.Control type="text" id={"newListElement"} />
                            <div>
                                {(prop.value as string[]).map((item, index) => {
                                    return <Button id={item + "_delete"}
                                                   className={`${styles.spaceRight} ${styles.spaceTop} uib-button uib-button--primary` }
                                                   key={index}
                                                   onClick={() => {
                                                       //let newModule = newModule
                                                       prop.value = (prop.value as string[]).filter((value) => value !== item)
                                                       setNewModule({...newModule})
                                                   }
                                                   }>{item} {IconTrash}</Button>
                                })}
                            </div>
                            <Button className={`${styles.spaceTop} uib-button uib-button--primary`} onClick={() => {
                                let newValue = (document.getElementById("newListElement") as HTMLInputElement).value
                                if(newValue === "" || (prop.value as string[]).includes(newValue)) {
                                    return
                                }
                                //let newModule = newModule
                                prop.value = (prop.value as string[]).concat(newValue)
                                setNewModule({...newModule})
                            }} >Add</Button>
                        </div>
                    </FormGroup>
                    break
                case "subProps":
                    Object.keys(prop.subProps!).forEach((key) => {
                        content.push(CreateModuleCreationProps(prop.subProps![key]))
                    })
                    break;
                default:
                    formControl = <></>
                    break;
            }

            if(prop.type !== "subProps") {
                let c = <Row className={`${styles.popupSpaceBetween}`}>
                    <Col md={3} className="align-self-center">
                        {prop.displayName ? prop.displayName : prop.name}
                    </Col>
                    <Col>
                        {formControl}
                        <small className="text-muted">{prop.description}</small>
                    </Col>
                </Row>
                content.push(c)
            }
        })
        return <>{content}</>
    }

    /**
     * Content of the popup for adding a module.
     * @constructor
     */
    const AddModuleContent = (): ReactElement => {
        if(newModule === undefined || newModuleInfos === undefined) {
            return <></>
        }
        return <>
            <Modal.Header closeButton>
                <Modal.Title>Add Module {newModuleInfos.staticModuleDisplayName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className={`${styles.popupSpaceBetween}`}>
                        <Col md={3} className="align-self-center">
                            Display name
                        </Col>
                        <Col>
                            <FormControl type="text"
                                         id={"displayName"}
                                         placeholder="Display name for this module instance"
                                         value={newModule.displayName}
                                         onChange={(e) => {
                                                setNewModule({...newModule, displayName: e.target.value})
                                         }}/>
                        </Col>
                    </Row>
                    <Row className={`${styles.popupSpaceBetween}`}>
                        <Col md={3} className="align-self-center">
                            Group
                        </Col>
                        <Col>
                            <FormControl type="text"
                                         placeholder="Corresponding group for this module instance"
                                         value={newModule.group}
                                         onChange={(e) => {
                                                setNewModule({...newModule, group: e.target.value})
                                         }}
                                         list={"group"}
                                         id={"group"}
                            />
                            <datalist id={"group"} >
                                {groups.map((scope, index) => {
                                    return <option key={index} value={scope} />
                                })}
                            </datalist>
                        </Col>
                    </Row>
                    {/*{newModuleInfos.}*/}
                    <Row className={`${styles.popupSpaceBetween}`}>
                        <Col md={3} className="align-self-center">
                            Project Id
                        </Col>
                        <Col>
                            <FormControl type="text"
                                         id={"projectId"}
                                         placeholder={newModuleInfos.projectIdInfoText}
                                         value={newModule.linkedId.projectId}
                                         onChange={(e) => {
                                                setNewModule({...newModule, linkedId: {...newModule.linkedId, projectId: e.target.value}})
                                         }}/>
                        </Col>
                    </Row>

                    {isUserroleSufficient(UserRoles.editor) &&
                        <Row className={`${styles.popupSpaceBetween}`}>
                            <Col md={3} className="align-self-center">
                                Migrate Existing Module
                            </Col>
                            <Col>
                                <Form.Check type="checkbox"
                                            id={"migrateExisting"}
                                            checked={newModule.migrateExisting}
                                            onChange={(e) => {
                                                setNewModule({...newModule, migrateExisting: e.target.checked})

                                            } }/>
                            </Col>
                        </Row>
                    }

                    {CreateModuleCreationProps(newModule.creationProps)}

                    <Row>
                        <Col>
                            <Alert variant={popupMessageType} hidden={popupMessage === ""}>
                                {popupMessage}
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button className={`uib-button uib-button--secondary ${styles.spaceRight}`} onClick={() => closePopup()}>
                    Cancel
                </Button>
                <Button className={"uib-button uib-button--primary"} onClick={() => submitNewModuleInstance()}>
                    Submit
                </Button>
            </Modal.Footer>
        </>
    }

    const closePopup = () => {
        setPopup(Popup.None);
    }

    /**
     * Check all creation props for content
     * @constructor
     */
    const areAllValuesFilled = (): boolean => {
        let creationProps = newModule.creationProps
        if(creationProps === undefined) {
            return true
        }
        for(let i = 0; i < creationProps.length; i++) {
            if(creationProps[i].type === "subProps") {
                let subProps = creationProps[i].subProps!["value"]
                for(let j = 0; j < subProps.length; j++) {
                    if(subProps[j].value === undefined || subProps[j].value === "") {
                        return false
                    }
                }
                continue
            }
            if(creationProps[i].value === undefined || creationProps[i].value === "") {
                return false
            }
        }
        return true
    }

    const submitNewModuleInstance = () => {
        let a = areAllValuesFilled()
        if(newModule.displayName === "" || newModule.group === "" || newModule.linkedId.projectId === "" || (!areAllValuesFilled() && !newModule.migrateExisting)) {
            setPopupMessage("Please fill all fields")
            setPopupMessageType(PopupMessage.Alert)
            return
        }

        const newModuleTmp = deepCopy(newModule)

        // if the module is the IDMS module, the project ID must be refactored
        // ths project ID must start with a leading "a" if the first letter is a number
        // all "-" must be removed
        if(newModuleTmp.staticModuleId === IDMSModule.moduleName ||
            newModuleTmp.staticModuleId === IDMSDevModule.moduleName)
        {
            newModuleTmp.linkedId.projectId = newModuleTmp.linkedId.projectId.replace(/-/g, "")
            if(!isNaN(parseInt(newModuleTmp.linkedId.projectId.charAt(0)))) {
                newModuleTmp.linkedId.projectId = "a" + newModuleTmp.linkedId.projectId
            }
        }

        setPopupMessage("Creating new module instance...")
        createModuleInstance(HubPathRouting.currentProject.moduleInstanceId, newModuleTmp).then(async (response) => {
            if (response.success) {
                await LoadModules()
                closePopup()
                // Reset the all module instances cache
                GlobalDictionary.remove(GlobalDictionary.MODULEINSTANCES)
            } else {
                setPopupMessage(response.message)
                setPopupMessageType(PopupMessage.Alert)
            }
        })
    }

    return <>
        <Modal size="lg" show={popup === Popup.AddModule} onHide={() => closePopup()} >
            {AddModuleContent()}
        </Modal>
        <div className={styles.allModulesView}>
            <NewModuleCards />
        </div>
    </>
}

export default NewModuleInstances;