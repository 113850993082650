import * as React from "react";

import {
    Alert,
    Button,
    Col,
    Container,
    FormControl,
    ModalBody,
    ModalFooter,
    OverlayTrigger,
    Row, Table,
    Tooltip
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {IconDelete} from "../../../../Assets/svgs";
import styles from './../../ProjectContent.module.css'
import {IContact} from "../../../Hub/Interfaces/IContact";
import {deepCopy} from "../../../../Utils/transformer";
import {updateProject} from "../../BackendFacade/apiCalls";
import {ImageSelectorProps, IProjectInformation_Edit} from "../../Interfaces/IProjectInformation_Edit";
import {useState} from "react";
import {useMain} from "../../../../Utils/SessionControls/mainContext";

interface IProjectInformation_EditProps {
    onSuccessFunction: () => void,
    closeFunction: () => void,
    state: IProjectInformation_Edit,
}

const ProjectInformation_Edit: React.FC<IProjectInformation_EditProps> = ({ onSuccessFunction, closeFunction, state }) => {
    const [projectId, setProjectId] = useState(state.projectId);
    const [displayName, setDisplayName] = useState(state.displayName);
    const [description, setDescription] = useState(state.description);
    const [contacts, setContacts] = useState(state.contacts);
    const [costCenter, setCostCenter] = useState(state.costCenter);
    const [department, setDepartment] = useState(state.department);
    const [product, setProduct] = useState(state.product);
    const [customProjectIcon, setCustomProjectIcon] = useState(state.customProjectIcon);
    const [internalOrderNumber, setInternalOrderNumber] = useState(state.internalOrderNumber);
    const [newContact, setNewContact] = useState(state.newContact);

    const {popupCreate, setLoading, isLoading} = useMain();

    /**
     * Adds the newContact to the list of contacts, resets the newContact
     */
    const addContactToList = () => {
        if(newContact.mail === "" || newContact.job === "") {
            return
        }
        const contactsTmp = [...contacts];
        contactsTmp.push(newContact)
        setContacts(contactsTmp);
        setNewContact({mail: "", job: ""});
    }

    /**
     * Applies the changes to the project
     */
    const applyProject = () => {
        setLoading(true);
        const state = {
            projectId: projectId,
            displayName: displayName,
            description: description,
            contacts: deepCopy(contacts),
            costCenter: costCenter,
            department: department,
            product: product,
            customProjectIcon: customProjectIcon,
            internalOrderNumber: internalOrderNumber,
            newContact: newContact,
        } as IProjectInformation_Edit;
        updateProject(state).then((data) => {
            if(data.success) {
                onSuccessFunction()
                closeFunction()
            } else {
                popupCreate("Error", data.message)
            }
            setLoading(false)
        })

    }

    const handleImageSelect = (base64Image: string) => {
        // this.setState({ customProjectIcon: base64Image });
        setCustomProjectIcon(base64Image);
    };


    let applyButton = <OverlayTrigger
        placement="top"
        delay={{ show: 1, hide: 200 }}
        overlay={
            <Tooltip id="tooltip-disabled">
                Please make sure all fields except for the description are filled.
            </Tooltip>
        }>
        <Button className={`uib-button uib-button--primary ${styles.applyButton} ${styles.errorButton}`}>
            Apply
        </Button>
    </OverlayTrigger>

    // check if every field is filled, activate apply button
    if(displayName !== ""
        && contacts.length > 0
        && costCenter >= 1000
        && department !== ""
        && product !== "")
    {
        applyButton = <Button className={`uib-button uib-button--primary ${styles.applyButton}`}
                              onClick={() => {applyProject()}}>
            Apply
        </Button>
    }

    return <div>
        <ModalHeader closeButton>
            Edit Project
        </ModalHeader>
        <ModalBody>
            <Container>
                <Row className={styles.popupSpaceBetween}>
                    <Col md={2} className="align-self-center">
                        Display name*
                    </Col>
                    <Col>
                        <FormControl
                            placeholder="Display name"
                            value={displayName}
                            id="displayname"
                            onChange={(event) => {
                                setDisplayName(event.target.value);
                            }} />
                    </Col>
                </Row>

                <Row className={styles.popupSpaceBetween}>
                    <Col md={2} className="align-self-center">
                        Description
                    </Col>
                    <Col>
                        <FormControl
                            placeholder="Description (optional)"
                            value={description}
                            id="inputDescription"
                            onChange={(event) => {
                                setDescription(event.target.value);
                            }} />
                    </Col>
                </Row>

                <Row className={styles.popupSpaceBetween}>
                    <Col md={2} className="align-self-center">
                        Contacts*
                    </Col>
                    <Col >
                        <Table >
                            <thead>
                                <tr>
                                    <th>Mail</th>
                                    <th>Job</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contacts.map((contact, index) => {
                                    return <TableRow
                                        key={index}
                                        index={index}
                                        contact={contact}
                                        onDelete={(index) => {
                                            const contactsTmp = [...contacts];
                                            contactsTmp.splice(index, 1);
                                            setContacts(contactsTmp);
                                        }}
                                        onUpdate={(index, field, value) => {
                                            const newContacts = contacts.map((c, i) => {
                                                if (i === index) {
                                                    return { ...c, [field]: value };
                                                }
                                                return c;
                                            });
                                            setContacts(newContacts);
                                        }}
                                    />
                                })}
                            </tbody>

                        </Table>
                        <span className={`${styles.addEmail}`}>
                        <FormControl
                            className={styles.addEmailElement}
                            placeholder="Email address"
                            value={newContact.mail}
                            id="email"
                            onChange={(event) => {
                                setNewContact({ ...newContact, mail: event.target.value })
                            }} />
                        <FormControl
                            className={styles.addEmailElement}
                            placeholder="Job"
                            value={newContact.job}
                            id="job"
                            onChange={(event) => {
                                setNewContact({ ...newContact, job: event.target.value })
                            }} />
                        <Button className="uib-button uib-button--primary" variant="primary" onClick={() => addContactToList()}> Add </Button>
                            </span>
                    </Col>
                </Row>

                <Row className={styles.popupSpaceBetween}>
                    <Col md={2} className="align-self-center">
                        Cost center*
                    </Col>
                    <Col>
                        <FormControl
                            placeholder="Costcenter (>= 1000)"
                            value={costCenter}
                            id="inputCostcenter"
                            onChange={(event) => {
                                // check if the input is a number
                                if(isNaN(Number(event.target.value))) {
                                    return
                                }
                                setCostCenter(+event.target.value);
                            }} />
                    </Col>
                </Row>

                <Row className={styles.popupSpaceBetween}>
                    <Col md={2} className="align-self-center">
                        Internal Order Number*
                    </Col>
                    <Col>
                        <FormControl
                            placeholder="Internal Order Number"
                            value={internalOrderNumber}
                            id="inputInternalOrderNumber"
                            onChange={(event) => {
                                // check if the input is a number
                                if(isNaN(Number(event.target.value))) {
                                    return
                                }
                                setInternalOrderNumber(+event.target.value);
                            }} />
                    </Col>
                </Row>

                <Row className={styles.popupSpaceBetween}>
                    <Col md={2} className="align-self-center">
                        Department*
                    </Col>
                    <Col>
                        <FormControl
                            placeholder="Department"
                            value={department}
                            id="inout-department"
                            onChange={(event) => {
                                setDepartment(event.target.value);
                            }} />
                    </Col>
                </Row>

                <Row className={styles.popupSpaceBetween}>
                    <Col md={2} className="align-self-center">
                        Product*
                    </Col>
                    <Col>
                        <FormControl
                            placeholder="inputProduct"
                            value={product}
                            id="product"
                            onChange={(event) => {
                                setProduct(event.target.value);
                            }} />
                    </Col>
                </Row>

                <ImageSelector onImageSelected={handleImageSelect} initialImage={customProjectIcon} />

            </Container>
        </ModalBody>
        <ModalFooter>
            <Button  className="uib-button uib-button--secondary" variant="secondary" onClick={() => closeFunction()}>Cancel</Button>
            {applyButton}
        </ModalFooter>
    </div>;

}

export default ProjectInformation_Edit;

type FormControlKey = 'mail' | 'job';

interface ITableRow {
    index: number,
    contact: IContact,
    onDelete: (index: number) => void,
    onUpdate: (index: number, field: string, value: string) => void
}

const TableRow: React.FC<ITableRow> = ({ index, contact, onDelete, onUpdate }) => {
    const [showFormControl, setShowFormControl] = useState({ mail: false, job: false });

    const toggleFormControl = (field: FormControlKey) => {
        setShowFormControl((prevState) => {
            return {
                mail: false,
                job: false,
                [field]: !prevState[field],
            }
        });
    };

    return (
        <tr key={index}>
            <td onClick={() => toggleFormControl("mail")}>
                <div>
                    {showFormControl.mail ? (
                        <FormControl
                            className={styles.addEmailElement}
                            placeholder="Email address"
                            value={contact.mail}
                            id="email"
                            onClick={(event) => event.stopPropagation()}
                            onChange={(event) => onUpdate(index, 'mail', event.target.value)}
                            autoFocus={true}
                        />
                    ) : (
                        contact.mail
                    )}
                </div>
            </td>
            <td onClick={() => toggleFormControl("job")}>
                <div>
                    {showFormControl.job ? (
                        <FormControl
                            className={styles.addEmailElement}
                            placeholder="Job"
                            value={contact.job}
                            id="job"
                            onClick={(event) => event.stopPropagation()}
                            onChange={(event) => onUpdate(index, 'job', event.target.value)}
                            autoFocus={true}
                        />
                    ) : (
                        contact.job
                    )}
                </div>
            </td>
            <td>
                <Button
                    variant="outline-primary"
                    className={styles.ButtonLogo}
                    onClick={() => onDelete(index)}
                >
                    {IconDelete}
                </Button>
            </td>
        </tr>
    );

}

const ImageSelector: React.FC<ImageSelectorProps> = ({ onImageSelected, initialImage  }) => {
    const [customProjectIcon, setCustomProjectIcon] = useState(initialImage || '');
    const [error, setError] = useState('');

    const handleImageSelect = () => {
        let fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.onchange = () => {
            let file = fileInput.files?.item(0);
            if (file === null || file === undefined) {
                return;
            }
            let reader = new FileReader();
            reader.onload = (event) => {
                const base64Image = event.target.result as string;
                if (base64Image.length > 64 * 1024) {
                    setError('The selected image is larger than 64KB. Please choose a smaller image.');
                    setCustomProjectIcon('')
                    onImageSelected('');
                } else {
                    setError('');
                    onImageSelected(base64Image);
                    setCustomProjectIcon(base64Image);
                }
            };
            reader.readAsDataURL(file);
        };
        fileInput.click();
    };

    const deleteImage = () => {
        setCustomProjectIcon('');
        onImageSelected('');
    }

    return (
        <Row className={styles.popupSpaceBetween}>
            <Col md={2} className="align-self-center">
                Project Icon
            </Col>
            <Col>
                {customProjectIcon && (
                   <div>
                       <img
                        src={customProjectIcon}
                        alt="Selected project icon"
                        style={{ maxWidth: '150px', maxHeight: '150px', marginRight: '10px' }}
                        />
                   </div>
                )}
                <Button
                    className={`uib-button uib-button--primary ${styles.spaceRight}`}
                    onClick={handleImageSelect}
                >
                    Select Image
                </Button>
                {customProjectIcon && <Button
                    className={`uib-button uib-button--primary ${styles.errorButton}`}
                    onClick={deleteImage}
                >
                    Delete Image
                </Button> }
                {error && (
                    <Col md={12}>
                        <Alert  className="mt-3">
                            {error}
                        </Alert>
                    </Col>
                )}
            </Col>
        </Row>
    );
};
