import {TenantLine} from "../../IDMS/Utils/ITenantSystem";
import {IApiKey} from "./IApiKeyManagement";

export interface IApiKeysAddEditState {
    tenantSystem: TenantLine[],
    Selected_Value: IApiKey,
    listOfSubscopes: string[],
    editMode: IApiKeysEditMode,
    expirationDate: boolean,
}

export enum IApiKeysEditMode {
    Add,
    Edit,
    None,
}
