import {moduleContent as hubContent} from '../Components/Hub/moduleContent';
import {moduleContent as projectContent} from '../Components/ProjectContent/moduleContent';
import HubModule from "../Components/Hub/module";
import {createHashRouter, RouteObject} from "react-router-dom";
import {getListOfAllModules} from "./modules";
import {IContent} from "../HubFramework";
import {moduleContent as DoopProdContent} from "../Components/DOOP/moduleContent";
import {moduleContent as DoopDevContent} from "../Components/DOOPDev/moduleContent";
import {moduleContent as IDMSProdContent} from "../Components/IDMS/moduleContent";
import {moduleContent as IDMSDevContent} from "../Components/IDMSDev/moduleContent";
import {moduleContent as DPASContent} from "../Components/DPAS/moduleContent";

import { Module as DOOP } from "../Components/DOOP";
import { Module as DOOPDev } from "../Components/DOOPDev";

import { Module as IDMS } from "../Components/IDMS";
import { Module as IDMSDev } from "../Components/IDMSDev";
import { Module as DPAS } from "../Components/DPAS";

import CustomBookmarkModule from "../Components/BookmarkModule/module";
import ProjectContentModule from "../Components/ProjectContent/module";
import AppCenterModule from "../Components/AppCenter/module";
import AppStoreConnectModule from "../Components/AppleStoreConnect/module";
import AzureDevOpsModule from "../Components/AzureDevOps/module";
import AzurePortalModule from "../Components/AzurePortal/module";
import BlackduckModule from "../Components/Blackduck/module";
import FirebaseModule from "../Components/Firebase/module";
import GoogleCloudServicesModule from "../Components/GoogleCloudServices/module";
import GooglePlayModule from "../Components/GooglePlay/module";
import KeyChangerModule from "../Components/KeyChanger/module";
import SendgridModule from "../Components/Sendgrid/module";
import SentryIOModule from "../Components/SentryIO/module";
import SonarqubeModule from "../Components/Sonarqube/module";
import DPODModule from "../Components/DPOD/module";

const router = createHashRouter([
    {
        path: "*",
        element: <HubModule />,
        children: createRouterFromModule(hubContent),
    },
    {
        path: "/project/:moduleInstanceId/*",
        element: <ProjectContentModule />,
        children: createRouterFromModule(projectContent),
    },
    // Bookmark
    {
        path: "/project/:moduleInstanceId/customBookmark/:id",
        element: <CustomBookmarkModule />
    },
    // AppCenter
    {
        path: "/project/:moduleInstanceId/msAppCenter/:id",
        element: <AppCenterModule />
    },
    // AppStoreConnect
    {
        path: "/project/:moduleInstanceId/appleStoreConnect/:id",
        element: <AppStoreConnectModule />
    },
    // AzureDevOps
    {
        path: "/project/:moduleInstanceId/msAzureDevOpsDrg/:id",
        element: <AzureDevOpsModule />
    },
    // AzurePortal
    {
        path: "/project/:moduleInstanceId/azurePortal/:id",
        element: <AzurePortalModule />
    },
    // Blackduck
    {
        path: "/project/:moduleInstanceId/blackduck/:id",
        element: <BlackduckModule />
    },
    // Firebase
    {
        path: "/project/:moduleInstanceId/firebase/:id",
        element: <FirebaseModule />
    },
    // GoogleCloudServices
    {
        path: "/project/:moduleInstanceId/googlecloudservices/:id",
        element: <GoogleCloudServicesModule />
    },
    // GooglePlay
    {
        path: "/project/:moduleInstanceId/googlePlay/:id",
        element: <GooglePlayModule />
    },
    // KeyChanger
    {
        path: "/project/:moduleInstanceId/keyChangerProd/:id",
        element: <KeyChangerModule />
    },
    // Sendgrid
    {
        path: "/project/:moduleInstanceId/sendgrid/:id",
        element: <SendgridModule />
    },
    // SentryIO
    {
        path: "/project/:moduleInstanceId/sentryio/:id",
        element: <SentryIOModule />
    },
    // Sonarqube
    {
        path: "/project/:moduleInstanceId/sonarqube/:id",
        element: <SonarqubeModule />
    },
    // DOOP
    {
        path: "/project/:moduleInstanceId/doopProd/:id/*",
        element: <DOOP />,
        children: createRouterFromModule(DoopProdContent),
    },
    //DOOPDev
    {
        path: "/project/:moduleInstanceId/doopDev/:id/*",
        element: <DOOPDev />,
        children: createRouterFromModule(DoopDevContent),
    },
    // IDMS
    {
        path: "/project/:moduleInstanceId/idmsProd/:id/*",
        element: <IDMS />,
        children: createRouterFromModule(IDMSProdContent),
    },
    // IDMSDev
    {
        path: "/project/:moduleInstanceId/idmsDev/:id/*",
        element: <IDMSDev />,
        children: createRouterFromModule(IDMSDevContent),
    },
    // DPAS
    {
        path: "/project/:moduleInstanceId/dpas/:id/*",
        element: <DPAS />,
        children: createRouterFromModule(DPASContent),
    },
    // DPOD
    {
        path: "/project/:moduleInstanceId/dpodprod/:id",
        element: <DPODModule />
    },
])

function createRouterFromModule(moduleContent: IContent): RouteObject[] {
    let routes: RouteObject[] = moduleContent.pages
        .filter((page) => page.displayName !== "")
        .map((page) => {
        // remove spaces from the display name
            const displayName = page.displayName.replace(/\s/g, "");
            return {
                path: displayName,
                element: <page.contentPage />
            }
        })

    // the first route should be the default route
    routes[0].index = true;

    return routes
}

export default router;
