import * as React from "react";
import {Button, Card, Col, Modal, Row} from "react-bootstrap";
import {IGroup, Popup} from "../Interfaces/IAllModules";
import {HubPathRouting} from "../../../HubFramework/pathBuilder";
import styles from "../ProjectContent.module.css"
import {moduleIcons} from "../Icons/Icons";
import AllModules_Edit from "./Modals/AllModules_Edit";
import {isUserroleSufficient} from "../../../Utils/authorization";
import {UserRoles} from "../../../Utils";
import {listModuleInstances} from "../../../BackendFacade/moduleCalls";
import {IModule} from "../../../BackendFacade/IModuleInstances";
import {isExternalModule} from "../../../Utils/modules";
import {GlobalDictionary} from "../../../Utils/globalDictionary";
import {IconEdit} from "../../../Assets/svgs";
import {ReactElement, useEffect} from "react";
import {Link} from "react-router-dom";
import {IListStaticModule} from "../../../BackendFacade/IStaticModules";
import {useMain} from "../../../Utils/SessionControls/mainContext";

const AllModules: React.FC = () => {
    const [groupModules, setGroupModules] = React.useState<IGroup[]>([]);
    const [availableModules, setAvailableModules] = React.useState<IListStaticModule[]>([]);
    const [popup, setPopup] = React.useState<Popup>(Popup.None);
    const [editModule, setEditModule] = React.useState<IModule | undefined>(undefined);

    const {popupCreate, setLoading, isLoading} = useMain();

    useEffect(() => {
        LoadModules()
    }, []);

    const LoadModules = async () => {
        setLoading(true);

        // if the module instances are not loaded yet, load them
        // otherwise load the modules in the background
        let listToLoad = [] as Promise<any>[]

        if(!GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES)) {
            listToLoad.push(getExistingModules())
        } else {
            createModuleGroups()
            // refresh the modules in the background
            getExistingModules()
        }

        setAvailableModules(await GlobalDictionary.getStaticModules())

        await Promise.all(listToLoad)
        setLoading(false);
    }

    /**
     * Gets the list of all module instances from the backend
     */
    const getExistingModules = async () => {
        const id = HubPathRouting.currentProject.moduleInstanceId
        await listModuleInstances(id).then((data) => {
            if(data.success) {
                let modules = data.data.value as IModule[]
                GlobalDictionary.set(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES, modules)
                createModuleGroups()
            } else {
                popupCreate("Error", data.message)
            }
        })
    }

    /**
     * Creates the groups of modules and sets the state
     */
    const createModuleGroups = () => {
        const modules = GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES) as IModule[]
        // sort the list of modules into groups
        let groups = [] as IGroup[]
        modules.forEach((module ) => {
            let group = groups.find((group) => group.name === module.group)
            if(group) {
                group.modules.push(module)
            } else {
                if(module.group === undefined || module.group === "") {
                    module.group = "Other"
                }
                groups.push({
                    name: module.group,
                    modules: [module]
                })
            }
        })
        setGroupModules(groups)
    }

    /**
     * Creates an url for each module card
     * @param module
     */
    const createCardUrl = (module: IModule) => {
        let parameters = module.linkedId ? "?linkedId=" + module.linkedId.projectId : ""
        return "../" + module.staticModuleId + "/" + module.moduleInstanceId + parameters
    }

    const EditModuleInstance_show = (module: IModule) => {
        setPopup(Popup.EditModule);
        setEditModule(module);
    }

    /**
     * Renders the existing modules
     */
    const ExistingModuleCards = () => {
        let content = [] as ReactElement[]
        groupModules
            // sort the groups alphabetically but "other" always last
            .sort((a, b) => {
                if(a.name === "Other") {
                    return 1
                }
                if(b.name === "Other") {
                    return -1
                }
                return a.name.localeCompare(b.name)
            })
            .forEach((group, key) => {
                content.push(<div  key={key}>
                    <div className={`${styles.GroupHeader}`}>{group.name}</div>
                    <Row lg={3}>
                        {group.modules.map((module, key2) => {
                            return <Col className="col-3" key={key2}>
                                <Link to={createCardUrl(module)}
                                      target={`${isExternalModule(module) ? "_blank" : ""}`}
                                      rel={`${isExternalModule(module) ? "noopener noreferrer" : ""}`}
                                      relative={"route"}
                                >
                                    <Card key={module.moduleInstanceId}
                                          className={`${styles.ModuleCard}`}
                                    >
                                        <Card.Body>
                                            {isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject?.moduleInstanceId) &&
                                                <Button variant="outline-secondary"
                                                       id={module.displayName + "-" + group.name + "-edit"}
                                                        className={`${styles.OptionsButton}`}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            EditModuleInstance_show(module)
                                                        }}>
                                                    {IconEdit}
                                                </Button>
                                            }
                                            <Card.Title>
                                                {moduleIcons[module.staticModuleId] ?
                                                    <Card.Img src={moduleIcons[module.staticModuleId]} className={`${styles.ModuleIcon}`} />
                                                    :
                                                    <Card.Img src={moduleIcons["default"]} className={`${styles.ModuleIcon}`} />
                                                }
                                                {module.displayName}
                                            </Card.Title>
                                            <Card.Text>
                                                {availableModules.find((mod) => mod.staticModuleId === module.staticModuleId)?.description}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>})}
                    </Row>
                    </div>
                )})
        return <div id={"allModulesList"}>
                {content}
        </div>
    }

    const NoExistingModules = () => {
        return <div>
            <div className={`${styles.GroupHeader}`}>No modules instances available. Order new modules</div>
        </div>
    }

    const closePopup = () => {
        setPopup(Popup.None);
    }

    return <>
        <Modal size="lg" show={popup === Popup.EditModule} onHide={() => closePopup()} >
            <AllModules_Edit onSuccessFunction={LoadModules.bind(this)}
                             closeFunction={closePopup.bind(this)}
                             state={editModule}
                             projectId={HubPathRouting.currentProject.moduleInstanceId}

            />
        </Modal>
        {isLoading ? <></> :
            groupModules.length > 0 ?
                <div className={styles.allModulesView}>
                    <ExistingModuleCards />
                </div>
                : <NoExistingModules />}
    </>
}

export default AllModules;