import {
    Button,
    Col,
    Container,
    FormControl,
    FormGroup,
    ModalBody,
    ModalFooter,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import styles from "../../Dpas.module.css";
import ModalHeader from "react-bootstrap/ModalHeader";
import * as React from "react";
import {UploadFile} from "../../BackendFacade/apiCalls";
import {DpasPopup} from "../../Interfaces/IFiles";
import {useMain} from "../../../../Utils/SessionControls/mainContext";

export interface IFilesUpload {
    setPopup: (popup: DpasPopup) => void,
    directoryType: string,
    refreshDirectory: () => void,
    uploadPathParam: string
}

const FilesUpload: React.FC<IFilesUpload> = ({ setPopup, directoryType, refreshDirectory, uploadPathParam}) => {
    const [fileSelectedForUpload, setFileSelectedForUpload] = React.useState<File | undefined>(undefined);
    const [uploadPath, setUploadPath] = React.useState<string>(uploadPathParam);

    const {setLoading, popupCreate, popupClose} = useMain()

    const dpasPopupClose = () => {
        setPopup(DpasPopup.None)
    }

    const cleanPath = (path: string) => {
        // if the path exceeds 150 characters, crop the rest
        if(path.length > 150) {
            path = path.substring(0, 150)
        }
        // clean prescending slash
        let ret = path.split('/').filter(x => x !== "").join('/')
        if(ret !== "") {
            ret += '/'
        }
        return ret
    }

    const UploadFileSubmit = () => {
        // HubFunctions.showLoading()
        setLoading(true)
        UploadFile(cleanPath(uploadPath), fileSelectedForUpload, directoryType).then((data) => {
            if(!data.success) {
                popupCreate("Error",  data.message)
            } else {
                setPopup(DpasPopup.None)
                setFileSelectedForUpload(undefined)
                setUploadPath("")
                refreshDirectory()
            }
            setLoading(false)
        })
    }

    let submitButton = <Button
        className="uib-button uib-button--primary"
        onClick={() => {UploadFileSubmit()}}>
        Submit
    </Button>
    // check if all fields are filled
    if(uploadPath == "" || fileSelectedForUpload == undefined) {
        submitButton = <OverlayTrigger
            placement="top"
            delay={{ show: 10, hide: 200 }}
            overlay={
                <Tooltip id="tooltip-disabled">
                    Please make sure all fields are filled.
                </Tooltip>
            }>
            <Button className={`uib-button uib-button--primary ${styles.warningButton}`}>Submit</Button>
        </OverlayTrigger>
    }
    return <>
        <ModalHeader>
            Upload File
        </ModalHeader>
        <ModalBody>
            <Container>
                <Row className={styles.popupSpaceBetween}>
                    <Col md={3} className="align-self-center">
                        Path
                    </Col>
                    <Col >
                        <FormGroup className="mb-3" controlId="exampleForm.ControlInput1">
                            <FormControl type="text"
                                         value={uploadPath}
                                         onChange={(event) => setUploadPath(event.target.value) } />
                        </FormGroup>
                        <div>You can type new paths, if you want to create a new directory. For example /dir, this will result in /dir/file.txt</div>
                    </Col>
                </Row>
                <Row className={styles.popupSpaceBetween}>
                    <Col md={3} className="align-self-center">
                        Select File
                    </Col>
                    <Col>
                        <FormGroup controlId="formFile" className="mb-3">
                            <FormControl type="file"
                                         onChange={(event) => {setFileSelectedForUpload((event.target as HTMLInputElement).files[0]) } } />
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
        </ModalBody>
        <ModalFooter>
            <Button className={`uib-button uib-button--secondary ${styles.spaceRight}`}
                    onClick={() => {dpasPopupClose(); }}>
                Close
            </Button>
            {submitButton}
        </ModalFooter>
    </>;
}

export default FilesUpload;