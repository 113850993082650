import * as React from "react";
import docuStyles from "../../Documentation/Documentation.module.css";
import {IListStaticModule} from "../../../BackendFacade/IStaticModules";
import {Card, Col, Row} from "react-bootstrap";
import {moduleIcons} from "../../ProjectContent/Icons/Icons";
import styles from "../../ProjectContent/ProjectContent.module.css";
import {GlobalDictionary} from "../../../Utils/globalDictionary";
import Documentation from "../../Documentation/Documentation";
import {NetworkData} from "../../../BackendFacade";
import {getDocumentation} from "../../Documentation/BackendFacade/apiCalls";
import {ReactElement, useEffect} from "react";
import {CircleLoader} from "react-spinners";
import {useMain} from "../../../Utils/SessionControls/mainContext";

const ModuleDocumentations: React.FC = () => {
    const [availableModules, setAvailableModules] = React.useState<IListStaticModule[]>([]);
    const [backgroundLoading, setBackgroundLoading] = React.useState<boolean>(true);

    const {setLoading, popupCreate, popupCreateSingle} = useMain();

    useEffect(() => {
        LoadModules()
    }, []);

    useEffect(() => {
        prepareAllModules()
    }, [availableModules]);

    const LoadModules = async () => {
        setLoading(true)
        setAvailableModules(await GlobalDictionary.getStaticModules())
        setLoading(false)
    }

    const prepareAllModules = () => {
        let listOfPromises = [] as Promise<void>[]
        availableModules.forEach((module) => {
            if(!GlobalDictionary.get("Docu" + module.staticModuleId)) {
                setBackgroundLoading(true)
                listOfPromises.push(getModuleDocumentation(module.staticModuleId))
            }
        })
        Promise.all(listOfPromises).then(() => {
            setBackgroundLoading(false)
        })
    }

    const getModuleDocumentation = async (moduleId: string)  => {
        await getDocumentation(moduleId).then((response: NetworkData) => {
            if (response.success) {
                GlobalDictionary.set("Docu" + moduleId, response.data.value)
            } else {
                popupCreate("Error", response.message);
            }
        })
    }

    const waitForDocumentation = (staticModuleId: string): Promise<void> => {
        setLoading(true)
        return new Promise((resolve) => {
            const checkDocumentation = () => {
                if (GlobalDictionary.get("Docu" + staticModuleId)) {
                    resolve();
                    setLoading(false)
                } else {
                    setTimeout(checkDocumentation, 100);
                }
            };
            checkDocumentation();
        });
    }

    const newModuleCards = (): ReactElement => {
        let cards = [] as ReactElement[]
        availableModules
            // sort the groups alphabetically but "other" always last
            .sort((a, b) => {
                return a.displayName.localeCompare(b.displayName)
            })
            .forEach((module, key) => {
                cards.push( <Col className="col-3" key={key} >
                    <Card className={`${docuStyles.ModuleCard} `}
                          onClick={() => activateModule(module)}
                          id={module.staticModuleId}
                    >
                        <Card.Body>
                            <Card.Title>
                                {moduleIcons[module.staticModuleId] ?
                                    <Card.Img src={moduleIcons[module.staticModuleId]} className={`${docuStyles.ModuleIcon}`} /> :
                                    <Card.Img src={moduleIcons["default"]} className={`${styles.ModuleIcon}`} />
                                }
                                {module.displayName}
                            </Card.Title>
                            <Card.Text>
                                {module.description}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>)
            })
        return <>
            <Row lg={3}>{cards}</Row>
        </>
    }

    const activateModule = async (module: IListStaticModule) => {
        // Wait for the documentation to be loaded
        await waitForDocumentation(module.staticModuleId);

        const header = <span>{moduleIcons[module.staticModuleId] ?
                <Card.Img src={moduleIcons[module.staticModuleId]} className={`${docuStyles.ModuleIcon}`} /> :
                <Card.Img src={moduleIcons["default"]} className={`${styles.ModuleIcon}`} />
        } {module.displayName} </span>

        popupCreateSingle(header,
            <Documentation component={module.staticModuleId} />)
    }

    let loading = <></>
    if(backgroundLoading)
        loading = <div className={docuStyles.DocuHeadlineLoading}>
            <CircleLoader size={20} color={"#0074ff"} loading={true} />
         </div>

    return <>
        <div>
            <div className={docuStyles.DocuHeadline}>
                Module Documentations
                {loading}
            </div>

            This is the documentation for the modules. Click a module to get a closer look.
            <br/>
            {newModuleCards()}
        </div>
    </>

}

export default ModuleDocumentations;