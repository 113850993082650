import {ReactElement} from "react";
import {CONTRACTORS, DOOPAREAS, IconTrash} from "../constants";
import Select from "react-select";
import {
    animatedComponents,
    Contractor,
    convertAreasToOptionsList,
    findIndexInContractorNames,
    Popup
} from "../Interfaces/IDOOP";
import {Module, OptionOIS} from "../module";
import {Button, Col, Container, Form, ModalBody, ModalFooter, Row} from "react-bootstrap";
import styles from "../Doop.module.css";
import ModalHeader from "react-bootstrap/ModalHeader";
import * as React from "react";
import {setProjectMeta} from "../BackendFacade/apiCalls";
import {useMain} from "../../../Utils/SessionControls/mainContext";
import {useOutletContext} from "react-router-dom";
import {HubPathRouting} from "../../../HubFramework/pathBuilder";

export interface IEditProject {
    parentModule: Module,
    name: string,
    areas: OptionOIS[],
    contractors: Contractor[],
    value: string | string[] | boolean,
    closePopup: () => void,
}
const EditProjectModal: React.FC<IEditProject> = ({name, areas, value, contractors, closePopup}) => {
    const [Value, setValue] = React.useState<string | string[] | boolean>(value);
    const [Areas, setAreas] = React.useState<OptionOIS[]>(areas);
    const [Contractors, setContractors] = React.useState<Contractor[]>(contractors);
    const parentModule = useOutletContext() as Module;

    const {setLoading, popupCreate} = useMain()

    const EditProject_AddContractor = () => {
        if(Value === "") {
            return;
        }
        if(findIndexInContractorNames(Contractors, Value as string) === -1) {
            setContractors([...Contractors, {Name: Value as string} as Contractor])
            setValue("")
        }
    }

    const EditProject_DeleteContractor = (item: Contractor) => {
        setContractors(Contractors.filter(i => i !== item))
    }

    const submitProject = () => {
        let body = parentModule.state.Meta
        switch (name) {
            case DOOPAREAS:
                body.doopAreas = Areas.map(i => i.value)
                break;
            case CONTRACTORS:
                body.contractors = Contractors
                break;
            default:
                body[name] = Value
        }
        setLoading(true)

        let bodyString = {projectMetaToUpdate: body}
        setProjectMeta(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId, bodyString).then((response) => {
            if(!response.success) {
                popupCreate("Error", response.message)
                setLoading(false)
            } else {
                parentModule.getProjectData().then(() => {
                    setLoading(false)
                    closePopup()
                })
            }
        })
    }

    let editField: ReactElement;
    switch(name) {
        case DOOPAREAS:
            editField = <Select
                inputId="areas-select" // Add an ID here
                closeMenuOnSelect={false}
                components={animatedComponents}
                placeholder="If empty, all are selected"
                isMulti
                options={convertAreasToOptionsList(parentModule.state.AllAreas)}
                onChange={(option : OptionOIS[]) => {setAreas(option)}}
                value={Areas}
            />
            break;
        case CONTRACTORS:
            editField = <div>
                <Form>
                    <Form.Group className="" controlId="">
                        <Form.Label>Contractor names:</Form.Label>
                        <div>
                            {Contractors.map((item, index) => {
                                return <Button className={styles.spaceRight} key={index}
                                               onClick={() => EditProject_DeleteContractor(item)}>
                                    {item.Name} {IconTrash}
                                </Button>
                            })}
                        </div>
                        <div className={styles.addContractor}>
                            <Form.Control type="text"
                                          placeholder='Contractor name'
                                          value={Value as string}
                                          onChange={(event) => setValue(event.target.value)}
                                          onKeyDown={event => {if(event.key === 'Enter') {EditProject_AddContractor()} }} />
                            <Button className={`${styles.addContractorButton} uib-button uib-button--primary`} onClick={() => EditProject_AddContractor()}>Add</Button>
                        </div>
                    </Form.Group>
                </Form>
            </div>
            break;
        default:
            editField = <input type="text" className="uib-textfield__input" value={Value.toString()}
                               onChange={(e) => setValue(e.target.value) }/>
            break;
    }


    return <div>
        <ModalHeader closeButton>
            Edit Project
        </ModalHeader>
        <ModalBody>
            <Container>
                <Row className={styles.popupSpaceBetween}>
                    <Col md={3} className="align-self-center">
                        {name}
                    </Col>
                    <Col >
                        <Container>
                            <Row md={2}>
                                <div className="uib-form-field__control uib-textfield">
                                    {editField}
                                    <span className="uib-textfield__validation-icon"></span>
                                </div>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </ModalBody>
        <ModalFooter>
            <button className={`uib-button uib-button--secondary ${styles.mr10}`} onClick={closePopup}>Cancel</button>
            <button className="uib-button uib-button--primary" onClick={() => {submitProject()}}>Save</button>
        </ModalFooter>
    </div>
}

export default EditProjectModal;