import {createContext, useContext} from "react";

type MainContextType = {
    setGlobalState: (state: any) => void;
    globalState: (state: string) => any;
    isLoading: boolean;
    setLoading: (isLoading: boolean) => void;
    popupCreate: (header: string, content: string | React.ReactElement, submitFunction?: () => void) => void;
    popupCreateSingle: (header: string | React.ReactElement, content: string | React.ReactElement, submitFunction?: () => void) => void;
    popupClose: () => void;
    PopupContent: () => React.ReactElement;
    isPopupOpen: () => boolean;
}

export const MainContext = createContext<MainContextType | undefined>(undefined);

export const useMain = () => {
    const context = useContext(MainContext);
    if (!context) {
        throw new Error('useMain must be used within a MainProvider');
    }
    return context;
}