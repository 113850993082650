import "./Utils/sentry";
import * as ReactDOM from "react-dom/client";
import {RouterProvider} from "react-router-dom";
import router from "./Utils/router";
import React from "react";
import {showRootComponent} from "./Utils/webHelper";

// handleRouting()

//showRootComponent(<HubModule />)
showRootComponent(<React.StrictMode>
    <RouterProvider router={router} />
</React.StrictMode>)
