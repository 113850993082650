import * as React from "react";
import KafkaEventsPage from "../../DAMS/Tabs/KafkaEvents";

/**
 * This is the Logs tab in the DOOP module. It is a wrapper around the KafkaEventsPage from the DOOP module.
 */

const Logs: React.FC = () => {
    return (
        // <KafkaEventsPage parentModule={new KafkaModule(null)} />
        <KafkaEventsPage/>
    )
}

export default Logs;