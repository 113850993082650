import {
    Button,
    Col,
    Container,
    FormCheck,
    FormControl,
    FormGroup,
    ModalBody, ModalFooter,
    OverlayTrigger,
    Row, Table,
    Tooltip
} from "react-bootstrap";
import styles from "../../Dpas.module.css";
import {invalidCSV} from "../../constants";
import ModalHeader from "react-bootstrap/ModalHeader";
import {IconTrash} from "../../../../Assets/svgs";
import * as React from "react";
import {useEffect} from "react";
import {IAutomationConfig} from "../../Interfaces/IConfig";
import {setAutomationConfig} from "../../BackendFacade/apiCalls";
import {useMain} from "../../../../Utils/SessionControls/mainContext";


interface EditConfigProps {
    emails: string[],
    csv: string,
    csvFileName: string,
    projectName: string,
    fileName: string,
    firstTime: boolean,
    onClose: () => void,
    onSubmit: () => void
}

const EditConfig = (props: EditConfigProps) => {
    const [automationConfigCSV, setAutomationConfigCSV] = React.useState<string>(props.csv);
    const [automationConfigCurrentEmail, setAutomationConfigCurrentEmail] = React.useState<string>('');
    const [automationConfigEmails, setAutomationConfigEmails] = React.useState<string[]>(props.emails);
    const [automationConfigProjectName, setAutomationConfigProjectName] = React.useState<string>(props.projectName);
    const [csvInvalidMessage, setCsvInvalidMessage] = React.useState<string>("");
    const [csvToggleEditTable, setCsvToggleEditTable] = React.useState<boolean>(true);

    const [csvErrorLine, setCsvErrorLine] = React.useState<number>(-1);

    const {setLoading, popupCreate, isLoading} = useMain()

    useEffect(() => {
        validateCSV()
    }, [automationConfigCSV]);


    /**
     * Check if csv text box content is valid.
     * First line matches: "SearchName;DisplayName"
     * An all following lines match: "AnyString;AnyString"
     * @constructor
     */
    const validateCSV = () =>{
        let csvLinesSplitToArray = automationConfigCSV.split(/\r?\n/)
        if ((csvLinesSplitToArray[0].indexOf("DisplayName") !== -1)
            && (csvLinesSplitToArray[0].indexOf("SearchName") !== -1)
            && csvLinesSplitToArray.every(function(element) {
                return ((element.match(/[a-zA-Z0-9];[a-zA-Z0-9]/g) || []).length) >= 1;
            })
        ) {
            setCsvInvalidMessage("")
            setCsvErrorLine(-1)
        } else {
            // check which line does not match the pattern
            let invalidLine = csvLinesSplitToArray.findIndex(function(element) {
                return ((element.match(/[a-zA-Z0-9];[a-zA-Z0-9]/g) || []).length) < 1;
            })
            setCsvErrorLine(invalidLine)
            setCsvInvalidMessage(invalidCSV)
        }
    };

    const automationConfigAddEmail = () => {
        if(automationConfigEmails.indexOf(automationConfigCurrentEmail) === -1) {
            setAutomationConfigEmails(automationConfigEmails.concat(automationConfigCurrentEmail))
            setAutomationConfigCurrentEmail("")
        }
    }

    const automationConfigRemoveEmail = (email: string) =>  {
        setAutomationConfigEmails(automationConfigEmails.filter((x) => x !== email))
    }

    const updateConfig = (row: number, column: number, value: string) => {
        let csvLinesSplitToArray = automationConfigCSV.split(/\r?\n/)
        let csvLinesSplitToArrayRow = csvLinesSplitToArray[row].split(";")
        csvLinesSplitToArrayRow[column] = value
        csvLinesSplitToArray[row] = csvLinesSplitToArrayRow.join(";")
        setAutomationConfigCSV(csvLinesSplitToArray.join("\n"))
    }

    /**
     * Add a column to the csv
     * @constructor
     */
    const addColumn = () => {
        let csvLinesSplitToArray = automationConfigCSV.split(/\r?\n/)
        let csvLinesSplitToArrayRow = csvLinesSplitToArray[0].split(";")
        csvLinesSplitToArrayRow.push("NewColumn")
        csvLinesSplitToArray[0] = csvLinesSplitToArrayRow.join(";")
        // add a new column to each row
        for (let i = 1; i < csvLinesSplitToArray.length; i++) {
            csvLinesSplitToArray[i] = csvLinesSplitToArray[i] + ";"
        }
        setAutomationConfigCSV(csvLinesSplitToArray.join("\n"))
    }

    /**
     * Add a row to the csv
     * @constructor
     */
    const addRow = () => {
        let csvLinesSplitToArray = automationConfigCSV.split(/\r?\n/)
        let columns = csvLinesSplitToArray[0].split(";").length
        let newRow = ""
        for (let i = 1; i < columns; i++) {
            newRow += ";"
        }
        csvLinesSplitToArray.push(newRow)
        setAutomationConfigCSV(csvLinesSplitToArray.join("\n"))
    }

    const uploadConfigCSV = () => {
        // Open file dialog and accept only .csv files
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = ".csv"
        // Read .csv file to string and insert into csv config text box
        input.onchange = e => {
            let file = (e.target as HTMLInputElement).files[0]
            let reader = new FileReader();
            reader.readAsText(file,'UTF-8');
            reader.onload = readerEvent => {
                let content = readerEvent.target.result;
                setAutomationConfigCSV(content.toString())
            }
        }
        input.click();
    }

    const EditConfigAsTable = () => {
        const rows = automationConfigCSV.split(/\r?\n/)
        const headers = rows[0].split(";")
        return <Table striped >
            <thead>
            <tr>
                {headers.map((item, key) => {
                    if(item === "SearchName" || item === "DisplayName")
                    {
                        return <th key={key} className={styles.fixColumnHeader}>
                            {item}
                        </th>
                    }
                    return <th key={key}>
                        { // make the header an input field, which automatically updates the content
                        }
                        <FormControl type="text" value={item} onChange={(event) => {
                            let newHeaders = headers
                            newHeaders[key] = event.target.value
                            // write the headers into the first line of the csv
                            updateConfig(0, key, newHeaders.join(";"))
                        } } />
                    </th>
                })}
                {
                    // add a column for the add column button
                    <th className={styles.fixColumnHeader}>
                        <Button className={`uib-button uib-button--primary `}
                                onClick={() => addColumn()}>
                            Add Column
                        </Button>
                    </th>
                }
            </tr>
            </thead>
            <tbody>
            {rows.slice(1).map((item, key) => {
                return <tr key={key} className={csvErrorLine === key +1 ? styles.warningButton : ''}>
                    {item.split(";").map((subitem, key2) => {
                        return <td key={key2}>
                            <FormControl type="text" value={subitem} onChange={(event) => {
                                updateConfig(key + 1, key2, event.target.value)
                            }} />
                        </td>
                    })}
                </tr>
            })}
            <tr>
                <td className={styles.fixColumnHeader}>
                    <Button className="uib-button uib-button--primary"
                            onClick={() => addRow()}>
                        Add Row
                    </Button>
                </td>
                <td></td>
                {
                    // delete column button for each column if it exists
                }
                {headers.map((item, key) => {
                    if(item === "SearchName" || item === "DisplayName")
                    {
                        return <></>
                    }
                    return <td key={key} className={styles.fixColumnHeader}>
                        <Button className="uib-button uib-button--primary" onClick={() => {
                            let newCSV = automationConfigCSV.split(/\r?\n/)
                            // remove the key-th element of each line
                            for (let i = 0; i < newCSV.length; i++) {
                                let newLine = newCSV[i].split(";")
                                newLine.splice(key, 1)
                                newCSV[i] = newLine.join(";")
                            }
                            setAutomationConfigCSV(newCSV.join("\n"))
                        }}>Delete Column</Button>
                    </td>
                })}
            </tr>
            </tbody>
        </Table>
    }


    const automationConfigSubmit = () => {
        let filename = props.fileName == "" ? automationConfigProjectName + ".csv" : props.fileName
        let body: IAutomationConfig = {csvString : automationConfigCSV,
            config: {csvFileName: filename,
                contactMails: automationConfigEmails,
                projectName: automationConfigProjectName}}
        setLoading(true)
        setAutomationConfig(body).then((data) => {
            setLoading(false)
            if(data.success) {
                props.onSubmit()
            } else {
                popupCreate("Error", data.message)
            }
        })
    }

    const SubmitButton = () => {
        let submitButton = <Button className="uib-button uib-button--primary" onClick={() => {automationConfigSubmit()}}>Submit</Button>
        // check if all fields are filled
        if(automationConfigEmails.length == 0 || automationConfigProjectName == "" || automationConfigCSV == "" || csvInvalidMessage !== "") {
            submitButton = <OverlayTrigger
                placement="top"
                delay={{ show: 10, hide: 200 }}
                overlay={
                    <Tooltip id="tooltip-disabled">
                        Please make sure all fields are filled.
                    </Tooltip>
                }>
                <Button className={`uib-button uib-button--primary ${styles.warningButton}`}>Submit</Button>
            </OverlayTrigger>
        }
        return submitButton
    }

    const CSVErrorMessage = () => {
        let CSVErrorMessage = <></>
        // Provide error message only if csv field content is flagged as invalid
        if (csvInvalidMessage == invalidCSV){
            CSVErrorMessage = <Row className={styles.popupSpaceBetween}>
                <Col md={3} className="align-self-center">
                    Message:
                </Col>
                <Col>
                    This CSV is invalid, please follow this format: <br />SearchName;DisplayName<br />AnyName;AnyDisplayName<br />
                    First invalid expression in line {csvErrorLine + 1} <br />
                    If a column is missing, please add a semicolon at the end of the line. <br />
                    Use the table view to see the marked line.
                </Col>
            </Row>
        }
        return CSVErrorMessage
    }

    return <div>
        <ModalHeader>
            Edit the Automation Config
        </ModalHeader>
        <ModalBody>
            <Container>
                <Row className={styles.popupSpaceBetween}>
                    <Col md={3} className="align-self-center">
                        Project Name
                    </Col>
                    <Col >
                        <FormGroup className="mb-3" controlId="exampleForm.ControlInput1">
                            <FormControl type="text"
                                         value={automationConfigProjectName}
                                         onChange={(event) => setAutomationConfigProjectName(event.target.value)}
                                         disabled={!props.firstTime}
                                         placeholder="Relevant for the url. You may want to use the same name as the project name."
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={styles.popupSpaceBetween}>
                    <Col md={3} className="align-self-center">
                        Email addresses
                    </Col>
                    <Col >
                        <FormGroup className={styles.addNewApp} controlId="NewUserRole">
                            <div>
                                {automationConfigEmails.map((item, index) => {
                                    return <Button id={item + "_delete"}
                                                   className={styles.spaceRight}
                                                   key={index}
                                                   onClick={() => automationConfigRemoveEmail(item)}>
                                        {item} {IconTrash}
                                    </Button>
                                })}
                            </div>
                            <div className={styles.addUserRole}>
                                <FormControl type="text"
                                             placeholder='add email address (click Add)'
                                             value={automationConfigCurrentEmail}
                                             onChange={(event) => setAutomationConfigCurrentEmail(event.target.value) }
                                             onKeyPress={event => {if(event.key == 'Enter') {automationConfigAddEmail()} }} />
                                <Button id="Add" className={`${styles.addUserButton} uib-button uib-button--primary`} onClick={() => automationConfigAddEmail()}>Add</Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={styles.popupSpaceBetween}>
                    <Col md={3} className="align-self-center">
                        CSV Content
                    </Col>
                    <Col >
                        <FormCheck type="switch"
                                   label="Show as table"
                                   checked={csvToggleEditTable}
                                   onChange={(event) => setCsvToggleEditTable(event.target.checked) } />
                        {
                            csvToggleEditTable ?
                                EditConfigAsTable() :
                                <FormGroup className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <FormControl as="textarea"
                                                 rows={10}
                                                 value={automationConfigCSV}
                                                 onChange={(event) => {setAutomationConfigCSV(event.target.value)}}/>
                                </FormGroup>
                        }
                    </Col>
                </Row>
                <CSVErrorMessage />
            </Container>
        </ModalBody>
        <ModalFooter>
            <div className={styles.flexgrow}>
                <Button className={`uib-button uib-button--secondary ${styles.spaceRight}`}
                        onClick={() => {uploadConfigCSV()}}>
                    Import from file
                </Button>
            </div>
            <Button className={`uib-button uib-button--secondary ${styles.spaceRight}`}
                    onClick={() => {props.onClose()}}>
                Cancel
            </Button>
            <SubmitButton />
        </ModalFooter>
    </div>;

}

export default EditConfig;