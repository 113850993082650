import * as React from "react";
import {ReactElement} from "react";
import {ConfigModalMode, IAddEditConfigModal} from "../../Interfaces/IAddEditConfigModal";
import {
    authLevels,
    defaultAuth,
    DisableTenantManagement,
    IconTrash,
    SignUpEventAPI,
    SignUpWithDefaultAuth,
    SignUpWithExceptionAuth,
    userTenant
} from "../../constants";
import {Button, Col, Container, Form, FormControl, ModalBody, ModalFooter, Row} from "react-bootstrap";
import styles from "../../IDMS.module.css";
import ModalHeader from "react-bootstrap/ModalHeader";
import {Module} from "../../module";
import {ConfigEditData} from "../../Interfaces/interfaces";
import {sendConfigEditDataCall} from "../../BackendFacade/apiCalls";
import {useOutletContext} from "react-router-dom";
import {useMain} from "../../../../Utils/SessionControls/mainContext";
import {ConfigMail} from "../../Interfaces/IConfig";
import {HubPathRouting} from "../../../../HubFramework/pathBuilder";


export interface IConfigModal {
    closeFunction: () => void;
    state: IAddEditConfigModal;
}
const Config_AddEditModal: React.FC<IConfigModal> = ({closeFunction, state}) => {
    const [SelectedMail, setSelectedMail] = React.useState<ConfigMail>(state.SelectedMail);
    const [SelectedValue, setSelectedValue] = React.useState<string>(state.SelectedValue);
    const [SelectedKey, setSelectedKey] = React.useState<string>(state.SelectedKey);
    const [tmp, setTmp] = React.useState<string>(state.tmp);
    const [ErrorMessage, setErrorMessage] = React.useState<string>(state.ErrorMessage);
    const [mode, setMode] = React.useState<ConfigModalMode>(state.mode);

    const parentModule = useOutletContext() as Module;

    const {setLoading, popupCreate, popupClose} = useMain();

    /**
     * Add the value to the config string of authLevels
     * @param value value to be added
     */
    const authLevelsAdd = (value: string) => {
        let _authLevels = SelectedValue.split(',')
        if (_authLevels.indexOf(value) === -1) {
            _authLevels.push(value)
            setSelectedValue(_authLevels.join(','));
            setTmp("");
        }
    }

    /**
     * removes an element from the authLevels
     * @param value value to be removed
     */
    const authLevelsDelete = (value: string) => {
        let _authLevels = SelectedValue.split(',')
        _authLevels = _authLevels.filter((items) => items !== value)
        // remove empty authLevels
        _authLevels = _authLevels.filter((items) => items !== "")
        setSelectedValue(_authLevels.join(','));
    }

    const handleSaveEditEntry = () => {
        if(SelectedKey === "") {
            setErrorMessage("Please choose a category");
            return;
        }

        let sendValue : {[key: string] : string} = {};
        if(SelectedKey === SignUpWithExceptionAuth
            || SelectedKey === SignUpWithDefaultAuth
            || SelectedKey === SignUpEventAPI
            || (parentModule.state.AuthLevelChanges.indexOf(SelectedKey) !== -1 && SelectedKey.indexOf("AuthLevelChange") !== -1)) {
            sendValue[SelectedKey] = JSON.stringify(SelectedMail)
        } else {
            if(SelectedValue === "") {
                setErrorMessage("Please enter a value");
                return
            }
            sendValue[SelectedKey] = SelectedValue
        }
        setLoading(true)

        // send config to backend
        let valueObj: ConfigEditData = {value : sendValue}
        sendConfigEditDataCall(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId, valueObj).then(async data => {
            if (!data.success) {
                popupCreate("Error", data.message)
            } else {
                popupClose();
                await parentModule.getConfigData()
            }
            setLoading(false)
        })
        closeFunction()
    }

    const GetDefaultValue = (value: ReactElement) => {
        return <Container>
            <Row className={styles.popupSpaceBetween}>
                <Col md={2} className="align-self-center">Value</Col>
                <Col>
                    {value}
                </Col>
            </Row>
        </Container>
    }

    const GetHeaderTitle = () => {
        if(mode === ConfigModalMode.Add) {
            return "Add Configuration"
        } else {
            return "Edit Configuration"
        }
    }

    const GetSelector = ()  => {
        if(mode === ConfigModalMode.Add) {
            return <Form.Select
                onChange={(event: { target: { value: any; }; }) => {
                    setSelectedKey(event.target.value);
                }}
                defaultValue={SelectedKey}
                data-action={"select-category"}
            >
                <option value="">Please choose</option>
                {parentModule.state.AuthLevelChanges.map((item, n_key) => {
                    if (Object.keys(parentModule.state.AppConfig).indexOf(item) === -1 || SelectedKey === item)
                        return <option value={item} key={n_key}>{item}</option>
                    return <></>
                })}
            </Form.Select>
        } else {
            // disabled input field
            return <FormControl id="plainvalue" placeholder="Value" value={SelectedKey} disabled />
        }
    }

    const isKeyInAuthLevelChanges = () => {
        if(parentModule.state.AuthLevelChanges.indexOf(SelectedKey) !== -1 && SelectedKey.indexOf("AuthLevelChange") !== -1)
            return SelectedKey;
        return "false"
    }

        let mail = SelectedMail
        let ConfigValue: ReactElement;
        switch (SelectedKey) {
            case DisableTenantManagement:
                if (SelectedValue === "") {
                    setSelectedValue("false");
                }
                const val = <Form.Check inline label="Activate to disable tenant management"
                                          checked={SelectedValue === "true"} onChange={(item: any) => {
                    setSelectedValue(item.target.checked.toString());
                }}/>
                ConfigValue = GetDefaultValue(val)

                break;
            case userTenant:
                const val2 = <Form.Select onChange={(event: { target: { value: any; }; }) => {
                    setSelectedValue(event.target.value);
                }} value={SelectedValue}>
                    <option value="">Please choose</option>
                    <option value="draegerConnect">draegerConnect (Dev)</option>
                    <option value="draegerb2c">draegerB2C (Prod)</option>
                    <option value="oktaDev">Okta Dev</option>
                    <option value="oktaTest">Okta Test</option>
                    <option value="oktaProd">Okta Prod</option>
                </Form.Select>
                ConfigValue = GetDefaultValue(val2)
                break;
            case authLevels:
                let _authLevels = SelectedValue.split(',')
                ConfigValue = <Container id={"authLevelsContainer"}>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">
                            Value
                        </Col>
                        <Col>
                            {_authLevels.map((item, index) => {
                                if (item === "") {
                                    return ""
                                }
                                return <Button className={styles.spaceRight} key={index}
                                               onClick={() => authLevelsDelete(item)} data-id={item}>{item} {IconTrash}</Button>
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} className="align-self-center">
                            Set new Value
                        </Col>
                        <Col className={styles.authLevels}>
                            <FormControl
                                className={styles.authLevelsInput}
                                id="plainvalue"
                                placeholder="Value"
                                onChange={(event: { target: { value: any; }; }) => {
                                    setTmp(event.target.value);
                                }}
                                value={tmp}
                                onKeyDown={event => {
                                    if (event.key === 'Enter') {
                                        authLevelsAdd(tmp)
                                    }
                                }}/>
                            <Button className="uib-button uib-button--primary"
                                    onClick={() => authLevelsAdd(tmp)}>Add</Button>
                        </Col>
                    </Row>
                </Container>
                break
            case defaultAuth:
                if (parentModule.state.AppConfig.authLevels !== undefined && parentModule.state.AppConfig.authLevels !== "") {
                    let _authLevels = parentModule.state.AppConfig.authLevels.split(',')
                    if (SelectedValue === "") {
                        setSelectedValue(_authLevels[0]);
                    }
                    const val3 = <Form.Select
                        onChange={(event: { target: { value: any; }; }) => {
                            setSelectedValue(event.target.value);
                        }}
                        value={SelectedValue}>
                            {_authLevels.map((item, index) => {
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </Form.Select>
                    ConfigValue = GetDefaultValue(val3)
                } else {
                    ConfigValue = GetDefaultValue(<p>There are no authLevels defined.</p>)
                }
                break;
            case SignUpEventAPI:
            case SignUpWithDefaultAuth:
            case SignUpWithExceptionAuth:
            case isKeyInAuthLevelChanges():
                ConfigValue = <Container>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">Mail Receivers</Col>
                        <Col><FormControl placeholder="Email" value={SelectedMail.mailReceivers}
                                          id="config_mailrec" onChange={(event: { target: { value: string; }; }) => {
                                              const newMail = {...SelectedMail, mailReceivers: event.target.value};
                            setSelectedMail(newMail);
                        }}/></Col>
                    </Row>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">Mail Content</Col>
                        <Col><FormControl as="textarea" rows={6} placeholder="Value"
                                          value={SelectedMail.mailContent} id="config_mailText"
                                          onChange={(event: { target: { value: string; }; }) => {
                                                const newMail = {...SelectedMail, mailContent: event.target.value};
                            setSelectedMail(newMail);
                                          }}/></Col>
                    </Row>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">Subject</Col>
                        <Col><FormControl placeholder="Value" value={SelectedMail.subject}
                                          id="config_mailSubj" onChange={(event: { target: { value: string; }; }) => {

                            const newMail = {...SelectedMail, subject: event.target.value};
                            setSelectedMail(newMail);
                        }}/></Col>
                    </Row>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">Sender Mail</Col>
                        <Col><FormControl placeholder="Value" value={SelectedMail.senderMail}
                                          id="config_mailSender" onChange={(event: { target: { value: string; }; }) => {
                            const newMail = {...SelectedMail, senderMail: event.target.value};
                            setSelectedMail(newMail);
                        }}/></Col>
                    </Row>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">Template ID</Col>
                        <Col><FormControl placeholder="Value" value={SelectedMail.templateID}
                                          id="config_mailTempId" onChange={(event: { target: { value: string; }; }) => {
                            const newMail = {...SelectedMail, templateID: event.target.value};
                            setSelectedMail(newMail);
                        }}/></Col>
                    </Row>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">Template Data</Col>
                        <Col><FormControl placeholder="Value" value={SelectedMail.templateData}
                                          id="config_mailTempData" onChange={(event: { target: { value: string; }; }) => {
                            const newMail = {...SelectedMail, templateData: event.target.value};
                            setSelectedMail(newMail);
                        }}/></Col>
                    </Row>
                    <Row className={styles.popupSpaceBetween}>
                        <Col>
                            <p>You can use parameters by writing $(parameterName). Available parameters are:</p>
                            <span>userMail: eMail of the affected user.<br></br>
                        AppDisplayName: Display name of the app.<br></br>
                        eventName: Name of the event that triggered this mail.</span>

                            <p>For example: Entering $(userMail) as mail receiver will send this mail to the user that
                                triggered the event.</p>
                        </Col>
                    </Row>
                </Container>
                break;
            default:
                const val4 = <FormControl id="plainvalue" placeholder="Value"
                                          onChange={(event: { target: { value: any; }; }) => {
                                                setSelectedValue(event.target.value);
                                          }} value={SelectedValue}/>
                ConfigValue = GetDefaultValue(val4)
                break;
        }

        return <div>
            <ModalHeader closeButton>
                {GetHeaderTitle()}
            </ModalHeader>
            <ModalBody>
                <Container>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={2} className="align-self-center">
                            Category
                        </Col>
                        <Col>
                            {GetSelector()}
                        </Col>
                    </Row>
                    <br></br>
                    <Row className={styles.popupSpaceBetween}>
                        {ConfigValue}
                    </Row>

                    {ErrorMessage !== "" ? <Row className={styles.popupSpaceBetween}>
                        <Col md={2}>
                        </Col>
                        <Col>
                            {ErrorMessage}
                        </Col>
                    </Row> : ""}
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button className="uib-button uib-button--secondary"
                        onClick={closeFunction}>Cancel</Button>
                {SelectedKey !== "" &&
                    <Button className={`uib-button uib-button--primary ${styles.addUserButton}`}
                            onClick={() => handleSaveEditEntry()}>Apply</Button> }
            </ModalFooter>
        </div>;

}

export default Config_AddEditModal;