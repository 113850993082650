import {BlobURLBasic} from "./environmentVars";

export const listDirectoryContent: string = "listDirectoryContent"
export const Upload: string = "upload"
export const AutomationConfig: string = "automationConfig"
export const fileDetails: string = "fileDetails"

// Config file return messages
export const invalidCSV = "invalidCSV"


// Files fixed folders
export const FolderImage = "Images"
export const FolderDocument = "Documents"
export const FolderSecret = "Secret Files"

export const FolderTypeImage = "image"
export const FolderTypeDocument = "document"
export const FolderTypeSecret = "secret"
export const FolderTypeUP = "UP"

// user roles
export const user = "user"
export const admin = "admin"

export const BlobOriginalURL = BlobURLBasic + "original/"
export const Blob1080URL = BlobURLBasic + "1080px/"
export const BlobDocuments = BlobURLBasic + "documents/"
export const BlobSecrets = BlobURLBasic + "secret/"

export const AssetURL = "https://product-assets.draeger.net/"