import * as React from "react";
import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";

const Module: React.FC = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        window.location.assign("https://dpodfrontendprod.z6.web.core.windows.net/#/project/" + searchParams.get("linkedId") + "/stage/prod");
    }, []);

    return <></>;
};

export default Module;
