import {getProject} from "../BackendFacade/apiCalls";
import {listModuleInstances} from "../../../BackendFacade/moduleCalls";
import {IlinkedId, IModule} from "../../../BackendFacade/IModuleInstances";
import HubFunctions from "../../../HubFramework/hubFunctions";

export async function getDisplayNameByModuleInstanceId(moduleInstanceId: string) {
    return getProject(moduleInstanceId).then((response) => {
        if(response.success === false) {
            return ""
        }
        return response.data.value.displayName as string;
    });
}

export async function getModuleInstanceDisplayName(projectId: string, moduleInstanceId: string) {
    return listModuleInstances(projectId).then((response) => {
        if(response.success === false) {
            return ""
        }
        let modules = response.data.value as IModule[];
        let module = modules.find((module) => module.moduleInstanceId === moduleInstanceId);
        return module.displayName;
    });
}

export async function getLinkedId(projectId: string, moduleInstanceId: string) {
    return listModuleInstances(projectId).then((response) => {
        if(response.success === false) {
            HubFunctions.PopupCreate("Error", response.message);
            return {} as IlinkedId
        }
        let modules = response.data.value as IModule[];
        let module = modules.find((module) => module.moduleInstanceId === moduleInstanceId);
        return module.linkedId;
    });
}
