import * as React from "react";
import {ConfigEditData} from "../Interfaces/interfaces";
import {fetchFromApi, getBackendApiURL} from "../../../BackendFacade";

export function getConfigDataCall(projectId: string, moduleInstanceId: string) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/config";
    return fetchFromApi(url, "GET")
}

export function sendConfigEditDataCall(projectId: string, moduleInstanceId: string, value: ConfigEditData) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/config"; 
    return fetchFromApi(url, "POST", value)
}

export function getUserDataCall(projectId: string, moduleInstanceId: string) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/user";
    return fetchFromApi(url, "GET")
}

export function sendUsersEditDataCall(projectId: string, moduleInstanceId: string, value: ConfigEditData) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/user";
    return fetchFromApi(url, "POST", value)
}
