import * as React from "react";
import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";



const Module: React.FC = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        window.location.assign("https://draeger.sentry.io/projects/" + searchParams.get("linkedId") );
    }, []);

    return <></>;
};

export default Module;