import * as React from "react";
import {IDAMS} from "./Interfaces/IDAMS";
import {moduleContent} from "./moduleContent";
import {IHubArgs} from "../../HubFramework/IHub";
import {ReactElement} from "react";
import {HubFramework} from "../../HubFramework";

export class Module extends React.Component<{hubArgs: IHubArgs}, IDAMS> {
    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    public render() : ReactElement {
        return <HubFramework hubContent={moduleContent} module={this} />
    }
}

// import React, { FC } from "react";
// import { IDAMS } from "./Interfaces/IDAMS";
// import { moduleContent } from "./moduleContent";
// import { IHubArgs } from "../../HubFramework/IHub";
// import { ReactElement } from "react";
// import {HubFramework} from "../../HubFramework";
//
// interface ModuleProps {
//     hubArgs: IHubArgs;
// }
//
// export const Module: FC<ModuleProps> = ({ hubArgs }) => {
//     // If you have any logic or lifecycle methods in your class component that you need to implement,
//     // you should use React hooks here.
//
//     return <HubFramework hubContent={moduleContent} module={{}} />;
// };
