import {IListStaticModule} from "../../../BackendFacade/IStaticModules";
import {IModule} from "../../../BackendFacade/IModuleInstances";

/**
 * Contains the states for the template - start component.
 */
export interface INewModuleInstances {
    availableModules: IListStaticModule[],
    popup: Popup,
    newModule: IModule,
    newModuleInfos: IModuleInfos,
    popupMessage: string,
    popupMessageType: PopupMessage,
}

export interface IGroup {
    name: string,
    modules: IModule[],
}


export interface IModuleInfos {
    staticModuleDisplayName: string,
    projectIdInfoText: string,

}
export enum Popup {
    None,
    AddModule,
}

export enum PopupMessage {
    Alert = "danger",
    Info = "info",
}
