import * as React from "react";
import {ConfigEditData, Popup} from "../Interfaces/interfaces";
import {ConfigMail, IConfig} from "../Interfaces/IConfig";
import {Button, Form, Modal, Table} from "react-bootstrap";
import {
    AppDisplayName,
    authLevels,
    configDescriptionsTexts,
    defaultAuth,
    IconDelete,
    IconEdit,
    userTenant
} from "../constants";
import {sendConfigEditDataCall} from "../BackendFacade/apiCalls";
import {Module} from "../module";
import {isUserroleSufficient} from "../../../Utils/authorization";
import {UserRoles} from "../../../Utils";
import {stringContainsJson} from "../../../Utils/checker";
import styles from './../IDMS.module.css';
import {HubPathRouting} from "../../../HubFramework/pathBuilder";
import {getLinkedId} from "../../ProjectContent/Utils/projectInfo";
import {ConfigModalMode, IAddEditConfigModal} from "../Interfaces/IAddEditConfigModal";
import Config_AddEditModal from "./Modals/Config_AddEdit";
import {useOutletContext} from "react-router-dom";
import {useMain} from "../../../Utils/SessionControls/mainContext";

const FIXED_CONFIGS = [authLevels, AppDisplayName, userTenant, defaultAuth]

const Config: React.FC = () => {

    const [popup, setPopup] = React.useState<Popup>(Popup.None);
    const [clientId, setClientId] = React.useState<string>("");
    const [addEditConfigModal, setAddEditConfigModal] = React.useState<IAddEditConfigModal | undefined>(undefined);

    const parentModule = useOutletContext() as Module;

    const { setLoading, popupCreateSingle, popupCreate} = useMain();

    React.useEffect(() => {
        setLoading(true);
        const prom1 = getIDMSConfig();
        const prom2 = getLinkedIdIDMS();
        Promise.all([prom1, prom2]).then(() => {
            setLoading(false);
        });
    }, []);

    const getIDMSConfig = async () => {
        await parentModule.getConfigData()
    }

    const getLinkedIdIDMS = async () => {
        getLinkedId(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId).then((data) => {
            setClientId(data.projectId);
        })
    }

    /**
     * Activate popup for delete config entry confirmation
     * @param key key of the config entry
     */
    const Config_Delete_show = (key: string) => {
        popupCreateSingle("Delete Entry", "Do you really want to delete " + key + "?", () => Config_Delete_apply(key))
    }

    /**
     * Confirm config entry deletion
     */
    const Config_Delete_apply = (key: string)   => {
        setLoading(true);
        let sendValue : {[key: string] : string} = {};
        sendValue[key] = ""
        console.log(sendValue)
        let valueObj: ConfigEditData = {value: sendValue};
        sendConfigEditDataCall(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId, valueObj).then(async (data) => {
            if (!data.success) {
                popupCreate("Error", data.message)
                throw 'error'
            }
        }).then(async () => {
            await parentModule.getConfigData();
            setLoading(false);
        }).catch()
    }


    /**
     * load the config once if it is available, else provide empty fields
     * @param key
     * @constructor
     */
    const Config_EditEntry_show = (key: string = "") => {
        let mail: ConfigMail = {
            mailReceivers: "",
            mailContent: "",
            subject: "",
            senderMail: "",
            templateID: "",
            templateData: ""
        }
        let AddEditModal = {
            SelectedKey: key,
            SelectedMail: mail,
            ErrorMessage: "",
            tmp: "",
            SelectedValue: "",
            mode: key === "" ? ConfigModalMode.Add : ConfigModalMode.Edit,
        } as IAddEditConfigModal

        // new Config line or edited to empty category
        if (parentModule.state.AppConfig[key] === undefined) {
            AddEditModal.SelectedValue = ""
        } else {
            // config value contains json for mail, fill mail form, plain value else
            if (stringContainsJson(parentModule.state.AppConfig[key])) {
                AddEditModal.SelectedMail = JSON.parse(parentModule.state.AppConfig[key])
                AddEditModal.SelectedValue = ""
            } else {
                AddEditModal.SelectedValue = parentModule.state.AppConfig[key]
            }
        }
        setAddEditConfigModal(AddEditModal);
        setPopup(Popup.Config_AddEdit);
    }

    const closePopup = () => {
        setPopup(Popup.None);
    }

    /**
     * Return description text if available, else a default answer
     * @param key
     * @constructor
     */
    const Config_getDescriptionText = (key: string) => {
        let ret : string[] = []
        if (configDescriptionsTexts[key] === undefined) {
            ret = ["Event: A users role was changed from one to another.", "There are several variations of this event, covering every possible change of roles."]
        } else {
            ret = configDescriptionsTexts[key]
        }
        // put every line in a <p> tag
        return <div>
            {ret.map((line, index) => {
                return <p key={index}>{line}</p>
            })}
        </div>
    }

    const Config_DisplayInfo_show = (key: string) => {
        popupCreate("Info", Config_getDescriptionText(key))
    }

    // Display Mail receiver if possible, value else
    const Config_evaluateDisplayedValue = (value: string) => {
        if (value.indexOf('{') !== -1)
            try {
                let mail: ConfigMail = JSON.parse(value)
                return "mailto: " + mail.mailReceivers
            } catch (e) {
            }
        return value
    }


        return <div className={styles.PageConfig}>
            <Modal size="lg" show={popup !== Popup.None} onHide={closePopup}>
                <Config_AddEditModal closeFunction={() => closePopup()}
                                     state={addEditConfigModal}
                />
            </Modal>
            <Form>
                <Table striped className={styles.maintable} id={"tableOfConfigs"}>
                    <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                        <th className={styles.alignCenter}>Info</th>
                        {isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId) &&
                            <>
                            <th className={styles.alignCenter}>Edit</th>
                            <th className={styles.alignCenter}>Delete</th>
                            </>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className={styles.configValueHeight}>Client ID</td>
                        <td>{clientId}</td>
                        <td className={styles.alignCenter}>
                            <Button variant="outline-primary"
                                    className={styles.ButtonLogo}
                                    onClick={() => {
                                        Config_DisplayInfo_show("clientId")
                                    }}>?
                            </Button>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    {
                        Object.keys(parentModule.state.AppConfig).map((col, index) => {
                            let deleteButton = FIXED_CONFIGS.indexOf(col) !== -1 ? <td></td> :
                                <td className={styles.alignCenter}>
                                    <Button variant="outline-danger"
                                            className={styles.ButtonLogo}
                                            onClick={() => {
                                                Config_Delete_show(col)
                                            }}
                                            data-action={"delete"}>
                                        {IconDelete}
                                    </Button>
                                </td>


                            return <tr key={index} id={col}>
                                <td>{col}</td>
                                <td>{Config_evaluateDisplayedValue(parentModule.state.AppConfig[col])}</td>
                                <td className={styles.alignCenter}>
                                    <Button variant="outline-primary"
                                            className={styles.ButtonLogo}
                                            onClick={() => {
                                                Config_DisplayInfo_show(col)
                                            }}>?
                                    </Button>
                                </td>
                                {isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId) &&
                                    <>
                                    <td className={styles.alignCenter}>
                                        <Button variant="outline-primary"
                                                className={styles.ButtonLogo}
                                                onClick={() => {
                                                    Config_EditEntry_show(col)
                                                }}
                                                data-action={"edit"}>
                                        {IconEdit}
                                        </Button>
                                    </td>
                                    {deleteButton}
                                    </>
                                }
                            </tr>
                        })
                    }
                    </tbody>
                </Table>
                {isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId)?
                    <Button className="uib-button uib-button--primary" onClick={() => Config_EditEntry_show()} id={"add-config"}>
                        + Add</Button> : ""}
            </Form>
        </div>
}

export default Config;