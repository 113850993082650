import * as React from "react";
import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";


const Module: React.FC = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        window.location.assign("https://play.google.com/console/u/0/developers/8849858548297547585/app/" + searchParams.get("linkedId") + "/app-dashboard");
    }, []);

    return <></>;
};

export default Module;