import * as React from "react";
import { DpasPopup} from "../Interfaces/IFiles";
import {
    Button,
    Col, Container, Form, FormCheck, FormControl, FormGroup, Modal,
    ModalBody, ModalFooter,
    OverlayTrigger,
    Row, Table,
    Tooltip
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {IAutomationConfig} from "../Interfaces/IConfig";
import {getAutomationConfig, getNightlyResults, setAutomationConfig} from "../BackendFacade/apiCalls";
import {Module} from "../module";
import {IconEdit, IconTrash} from "../../../Assets/svgs";
import {HubPathRouting} from "../../../HubFramework/pathBuilder";
import {isUserroleSufficient} from "../../../Utils/authorization";
import {UserRoles} from "../../../Utils";
import styles from "../Dpas.module.css";
import {useEffect} from "react";
import {useMain} from "../../../Utils/SessionControls/mainContext";
import {useOutletContext} from "react-router-dom";
import EditConfig from "./Modals/EditConfig";
import {INightlyResults} from "../Interfaces/INightlyResults";

const Config: React.FC = () => {
    const [popup, setPopup] = React.useState<DpasPopup>(DpasPopup.None);
    const [dpasId, setDpasId] = React.useState<string>("");
    const [configLoading, setConfigLoading] = React.useState<boolean>(false);
    const [originalConfig, setOriginalConfig] = React.useState<IAutomationConfig>(null)
    const [nightlyResults, setNightlyResults] = React.useState<INightlyResults>(null)
    const {setLoading, popupCreate, isLoading} = useMain()
    const parentModule = useOutletContext() as Module;
    const [jsonErrorMessage, setJsonErrorMessage] = React.useState<string>("");


    const getProjectId = async () => {
        return await parentModule.getProjectId().then((data) => {
            if (data === "") {
                popupCreate("Error", "No project found")
                return ""
            }
            setDpasId(data)
            return data
        })
    }

    useEffect(() => {
        setLoading(true)
        getProjectId().then((data) => {
            setLoading(false)
            getConfig(data)
        })
        setNightlyResultsHook().then(r => {
            setNightlyResults(r)
        })
    }, []);

    const setNightlyResultsHook = async () => {
        const result = await getNightlyResults()
        if(!result.success) {
            return "There is no automation config for this module, no nightly update results available"
        }
        return (result.data)
    }

    // if the config is still loading and the popup is open, show the loading spinner
    useEffect(() => {
        if(!configLoading && (popup === DpasPopup.AutomationConfig_Edit || popup === DpasPopup.AutomationConfig_details))
            setLoading(false)
    }, [configLoading]);

    const popupClose =() => {
        setPopup(DpasPopup.None)
    }

    /**
     * Get the automation config
     * @param config must be set initially, because of a racing condition in the states
     */
    const getConfig = (config: string = dpasId) => {
        setConfigLoading(true)
        getAutomationConfig().then((data) => {
            if(!data.success) {
                popupCreate("Error", data.message)
                setLoading(false)
                return
            }
            let automationConfig: IAutomationConfig = data.data
            if(automationConfig.config == undefined) {
                automationConfig.config = {csvFileName: "", projectName: "", contactMails: []}
            }
            // if the csv config is empty, prefill it with headers
            if(automationConfig.csvString === "" || automationConfig.csvString === undefined) {
                automationConfig.csvString = "SearchName;DisplayName"
            }
            setOriginalConfig(automationConfig)
            setConfigLoading(false)
        })
    }

    const showDetails = () => {
        if(configLoading)
            setLoading(true)
        setPopup(DpasPopup.AutomationConfig_details)
    }

    const editConfigShow = () => {
        if(configLoading)
            setLoading(true)
        setPopup(DpasPopup.AutomationConfig_Edit)
    }

    const onEditSubmit = () => {
        setPopup(DpasPopup.None)
        getConfig()
    }

    const NightlyResults = () => {
        if (nightlyResults === null || !nightlyResults.message) {
            return <div>Error fetching nightly run results, there might not be an automation config</div>;
        }

        const { errorObjectList, messageString } = nightlyResults.message;

        const handleShowJsonError = () => {
            const jsonString = JSON.stringify(nightlyResults, null, 2);
            setJsonErrorMessage(jsonString);
            popupCreate("JSON Response", <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{jsonString}</pre>);
        };

        let date = new Date(nightlyResults.time).toLocaleString();

        return (
            <div>
                <p>{date}</p>
                <p>{messageString}</p>
                <Button variant="outline-primary" onClick={handleShowJsonError}>
                    Show JSON Response
                </Button>
            </div>
        );
    }


    const AutomationConfigDetailsContent = () => {
        return <div>
            <ModalHeader>
                Automation Config Details
            </ModalHeader>
            <ModalBody>
                <Container>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={3} className="align-self-center">
                            Project Name
                        </Col>
                        <Col >
                            <FormGroup className="mb-3" controlId="exampleForm.ControlInput1">
                                <FormControl type="text" value={originalConfig.config.projectName} disabled />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={3} className="align-self-center">
                            Email addresses
                        </Col>
                        <Col >
                            <FormGroup className={styles.addNewApp} controlId="NewUserRole">
                                <div>
                                    {originalConfig.config.contactMails.map((item, index) => {
                                        return <Button id={item + "_delete"} className={styles.spaceRight} key={index}>{item}</Button>
                                    })}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className={styles.popupSpaceBetween}>
                        <Col md={3} className="align-self-center">
                            CSV Content
                        </Col>
                        <Col >
                            <table className={styles.configTable}>
                                <tbody>
                                    {originalConfig.csvString.split(/\r?\n/).map((item, key) =>
                                        {return <tr key={key} className={styles.configTableContent}>
                                            {item.split(";").map((subitem, key2) =>
                                            {return <td className={styles.configTableContent} key={key2}>
                                                {subitem}
                                            </td>
                                            })}
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button className={`uib-button uib-button--secondary ${styles.spaceRight}`}
                        onClick={() => {popupClose()}}>
                    Close
                </Button>
            </ModalFooter>
        </div>;
    }

    let popupContent = <div></div>
    switch(popup) {
        case DpasPopup.AutomationConfig_Edit:
            popupContent = <EditConfig
                csv={originalConfig.csvString}
                emails={originalConfig.config.contactMails}
                projectName={originalConfig.config.projectName}
                fileName={originalConfig.config.csvFileName}
                firstTime={originalConfig.config.projectName === ""}
                onClose={() => setPopup(DpasPopup.None)}
                onSubmit={() => onEditSubmit()}
                csvFileName={originalConfig.config.csvFileName}
                />

            break;
        case DpasPopup.AutomationConfig_details:
            popupContent = AutomationConfigDetailsContent()
            break;
        default:
            break;
    }

    return <div className={styles.ScrollPage} >
        <Modal size="xl" show={popup !== DpasPopup.None} onHide={() => popupClose()} >
            {popupContent}
        </Modal>
        <Form>
            <Table striped className={styles.maintable}>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th className="alignCenter">Details</th>
                        {isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId) &&
                            <th className="alignCenter">Edit</th>
                        }
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Automation Config</td>
                    <td className="alignCenter">
                        <Button variant="outline-primary"
                                className={`${styles.ButtonLogo} ${styles.OuterIconLogo}`}
                                onClick={() => showDetails()}>
                            <i className={`uib-icon uib-icon--view ${styles.IconLogo}`}/>
                        </Button>
                    </td>
                    {
                        isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId) &&
                        <td className="alignCenter">
                            <Button variant="outline-primary"
                                    id="editButton"
                                    className={styles.ButtonLogo}
                                    onClick={() => editConfigShow()}>
                                {IconEdit}
                            </Button>
                        </td>
                    }
                </tr>
                </tbody>

            </Table>
        </Form>
        <NightlyResults/>
    </div>

}

export default Config;
