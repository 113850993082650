import * as React from "react";
import {Col, ListGroup, Modal, Row,} from "react-bootstrap";
import {CircleLoader} from "react-spinners";
import "@uib/css/themes/draeger-theme-bright.css"
import "@uib/icons/css/uib-iconfont.css"
import {
    evaluateTokens,
    logoutButton,
    openLoginPage,
    showUserAccount,
    validateToken
} from "../Utils/authentication";
import {IContent, IHubArgs, IModulePage} from "./IHub";
import {Breadcrumbs, HubPathRouting} from "./pathBuilder";
import {LoginState} from "../Utils/IAuthentication";
import {isUserroleSufficient} from "../Utils/authorization";
import {cleansePageName} from "../Utils/transformer";
import styles from './HubFramework.module.css'
import {useEffect} from "react";
import {HubFunctionType} from "./hubFunctions";
import HubFunctions from "./hubFunctions";
import {useMain} from "../Utils/SessionControls/mainContext";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";

interface IHubFrameworkProps {
    hubContent: IContent,
    module: MyComponent | React.Component
}

// extend the react.component with the hubArgs
interface MyComponent extends React.Component {
    props: {
        hubArgs: IHubArgs
    }
}

export const HubFramework: React.FC<IHubFrameworkProps> = ({hubContent, module}) => {
    const [currentPage, setCurrentPage] = React.useState<IModulePage>();
    const [loggedIn, setLoggedIn] = React.useState<boolean>(false);

    const {isLoading, setLoading, isPopupOpen, popupClose, popupCreate, popupCreateSingle, PopupContent,  } = useMain()

    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        // check login
        checkLogin()

        registerFunctions()

        // redirect to the overview page if no page is set
        if(getPageFromUrl() === "" || window.location.href.includes("#Hub")) {
            navigate("/" + cleansePageName(hubContent.pages[0].displayName))
            console.log("redirect to overview page")
        }
    } , [])

    /**
     * Redirect to the first page if the page is not set
     * (happens if you hit Hub on the Hub page)
     */
    useEffect(() => {
        if(currentPage && currentPage.displayName === "") {
            navigate("/" + cleansePageName(hubContent.pages[0].displayName), {})
            console.log("redirect to overview page")
        }
    }, [currentPage]);

    /**
     * Set the current page based on the url, if the page is not found, redirect to the default page
     */
    useEffect(() => {
        const currentPage = hubContent.pages.filter((p: IModulePage) => cleansePageName(p.displayName) === getPageFromUrl())[0]
        if(!currentPage) {
            console.log("no page found")
            navigate(createMenuLinkUrl(hubContent.pages[0]), {relative: "path", replace: true})
            return
        }
        setTabState(currentPage.displayName)
    }, [loggedIn])

    /**
     * If the url changes, set the tab state
     */
    useEffect(() => {
        const newPage = hubContent.pages.filter(
            (p: IModulePage) => cleansePageName(p.displayName) === location.pathname.split("/").pop())[0]
        if(newPage && newPage.displayName !== currentPage?.displayName) {
            setTabState(newPage.displayName)
        }
    }, [location]);

    /**
     * Login process
     */
    const checkLogin = async () => {
        let validateState: LoginState;
        let evaluateState: LoginState;
        // evaluate tokens and check for new tokens in url
        await evaluateTokens().then((e) => {evaluateState = e});
        // validate existing tokens. Tokens might be set in evaluateTokens, so await this first
        validateState = validateToken()

        if(evaluateState.error !== "" || validateState.error !== "") {
            popupCreateSingle("Error", evaluateState.error + validateState.error)

        } else if(!evaluateState.success || !validateState.success) {
            openLoginPage()
            setLoggedIn(false)
        } else {
            setLoggedIn(true)
        }
    }

    const setTabState = async (tab: string) => {
        let newPage = hubContent.pages.filter(
            (p: IModulePage) => p.displayName === tab || p.url === tab)[0]

        if(!newPage) {
            popupCreateSingle("Error", "Tab not found. Please look into module config.")
            return 
        }
        HubPathRouting.currentPage = {moduleName: hubContent.moduleName, displayName: newPage.displayName}

        // set the new page
        setCurrentPage(newPage)
        await new Promise(r => setTimeout(r, 1));
    }

    const createMenuLinkUrl = (page: IModulePage) : string => {
        // return window.location.origin + HubPathRouting.getPathForNavigation() + '/' + cleansePageName(page.displayName)
        return cleansePageName(page.displayName)
    }

    const getPageFromUrl = () => {
        let url = window.location.href
        let urlPart = url.split("?")[0]
        let page = urlPart.split("/").pop()
        return page
    }

    /**
     * DEPRECATED
     */
    const registerFunctions = () => {
        HubFunctions.registerFunction(popupCreateSingle, HubFunctionType.PopupCreateSingle)
        HubFunctions.registerFunction(popupCreate, HubFunctionType.PopupCreate)
        HubFunctions.registerFunction(popupClose, HubFunctionType.PopupClose)
        HubFunctions.registerFunction(setLoading.bind(true), HubFunctionType.showLoading)
        HubFunctions.registerFunction(setLoading.bind(false), HubFunctionType.hideLoading)
    }

    let loading = <div></div>;
    if(isLoading) {
        loading = <div className={styles.loading} id={"loadingSpinner"}>
            <div className={styles.spinner}>
                <CircleLoader size={120} color={"#0074ff"} loading={true} />
            </div>
        </div>
    }

    return <div>
            <div id={styles.header} className={`no-gutters row ${styles["flex-row"]} ${styles["flex-grow"]}`}>
                <span id={`${styles.logo}`} className={`uib-logo ${styles["flex-row"]}`}></span>
                <span id={styles.path} className={`${styles["flex-row"]} ${styles["flex-grow"]}`}>
                    <Breadcrumbs />
                </span>
                <span id={styles.username} className={`${styles["flex-row"]}`}>{showUserAccount()}</span>
                <span id={styles.logoutHeader} className={`${styles["flex-row"]}`}>{logoutButton()}</span>
            </div>
            {/* POPUP */}
            <Modal size="lg" show={isPopupOpen()} onHide={() => popupClose()} >
                {PopupContent()}
            </Modal>
            {loading}
            <Row className={styles.mainScreen}>
                <Col lg={2} className={styles.newleftSideBarMenu} id={"Sidebar"}>
                    <ListGroup className={styles.listGroup}>
                        {hubContent.pages.map((page) => {
                            if(isUserroleSufficient(page.minimumRole, HubPathRouting.currentProject?.moduleInstanceId ))
                                if(page.separator) {
                                    return <ListGroup.Item key={ page.displayName} className={styles.navbarSeparator} />
                                } else {
                                    // if(cleansePageName(page.displayName) === getPageFromUrl()) // TODO REMOVE
                                    //     console.log(page.displayName)
                                    return <Link to={createMenuLinkUrl(page)} key={ page.displayName} >
                                        <ListGroup.Item action
                                            active={page.displayName === (currentPage?.displayName || "")}
                                                        key={ cleansePageName(page.displayName)}
                                                        className={styles.listGroupItem}
                                                        onClick={(event) => {
                                                            // event.preventDefault();
                                                            setTabState(page.displayName);
                                                        }}>
                                            {page.displayName}
                                        </ListGroup.Item>
                                    </Link>
                                }
                            else {
                                return null
                            }
                        })}
                    </ListGroup>
                </Col>
                <Col className={styles.overflowAuto}>
                    {loggedIn && currentPage &&
                        isUserroleSufficient(currentPage.minimumRole, HubPathRouting.currentProject?.moduleInstanceId, HubPathRouting.currentModule?.moduleInstanceId) &&
                        <Outlet context={module} />
                    }
                </Col>
            </Row>
    </div>
}
