import {IContent} from "../../HubFramework";

/**
 * The content of the module
 * This is mandatory for every module. It contains all the information about the module.
 */
export const moduleContent: IContent = {
    title: "Apple Store Connect",
    defaultPage: "",
    moduleName: "appleStoreConnect",
    pages: [],
    creationProps: [
    ]
};
