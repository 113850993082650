import * as React from "react";
import {getDocumentation} from "./BackendFacade/apiCalls";
import docuStyles from "./Documentation.module.css";
import ReactMarkdown from "react-markdown";
import {GlobalDictionary} from "../../Utils/globalDictionary";
import remarkGfm from "remark-gfm";
import {useEffect, useState} from "react";
import {useMain} from "../../Utils/SessionControls/mainContext";

interface DocumentationProps {
    component: string;
}

const Documentation: React.FC<DocumentationProps> = ({ component }) => {
    const [content, setContent] = useState<string>("");
    const {setLoading, popupCreate} = useMain();

    useEffect(() => {
        const retrieveDocumentation = async () => {
            if (!component) {
                return;
            }
            const cachedContent = GlobalDictionary.get("Docu" + component);
            if (cachedContent) {
                setContent(cachedContent);
                return;
            }
            setLoading(true);
            try {
                const response = await getDocumentation(component);
                if (response.success) {
                    setContent(response.data.value);
                    if (response.data.value.includes("&")) {
                        const index = response.data.value.indexOf("&");
                        console.log(response.data.value.substring(index - 5, index + 5));
                    }
                    GlobalDictionary.set("Docu" + component, response.data.value);
                } else {
                    popupCreate("Error", response.message);
                }
            } catch (error) {
                // Handle error
            } finally {
                setLoading(false);
            }
        };

        retrieveDocumentation();
    }, [component]);

    const customLink = (href: string, children: React.ReactNode) => {
        const url = href.replace(/&/g, '&');
        return <a href={url}>{children}</a>;
    };

    if (content.includes("&")) {
        const index = content.indexOf("&");
        console.log(content.substring(index - 5, index + 5));
    }

    return (
        <div className={docuStyles.DocuText}>
             <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} components={{a: props => {
                    return customLink(props.href, props.children); } }} />
         </div>
    );
};

export default Documentation;
