import * as React from "react";
import {Button, Form, Table} from "react-bootstrap";
import {createProject} from "../BackendFacade/apiCalls";
import styles from "./../Hub.module.css"
import {IProject} from "../Interfaces/IAllProjects";
import {useEffect} from "react";
import {useMain} from "../../../Utils/SessionControls/mainContext";

const NewProject: React.FC = () => {
    const [contacts, setContacts] = React.useState<{mail: string, job: string}[]>([]);
    const [costCenter, setCostCenter] = React.useState<string>("");
    const [department, setDepartment] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    const [displayName, setDisplayName] = React.useState<string>("");
    const [product, setProduct] = React.useState<string>("");
    const [projectId, setProjectId] = React.useState<string>("");
    const [newMail, setNewMail] = React.useState<string>("");
    const [newJob, setNewJob] = React.useState<string>("");
    const [projectIdError, setProjectIdError] = React.useState<string>("");
    const [projectContactError, setProjectContactError] = React.useState<string>("");
    const [projectCostCenterError, setProjectCostCenterError] = React.useState<string>("");
    const [internalOrderNumber, setInternalOrderNumber] = React.useState<string>("");

    const {popupCreate, setLoading} = useMain();

    useEffect(() => {
        resetFields()
    }, []);

    useEffect(() => {
        checkCostCenter()
    }, [costCenter]);


    const resetFields = () => {
        setContacts([])
        setCostCenter("")
        setDepartment("")
        setDescription("")
        setDisplayName("")
        setProduct("")
        setProjectId("")
        setNewMail("")
        setNewJob("")
    }

    const removeContact = (mail: string) => {
        const filteredContacts = contacts.filter((contact) => contact.mail !== mail)
        setContacts(filteredContacts)
        checkContacts()
    }

    const addContact = () => {
        if(newMail === "" || newJob === "") {
            return
        }
        const filteredContacts = contacts
        contacts.push({mail: newMail, job: newJob})
        setContacts(filteredContacts)
        setNewMail("")
        setNewJob("")
        checkContacts()
    }

    const submit = () => {
        if(//projectId === "" ||
            displayName === "" ||
            description === "" ||
            product === "" ||
            department === "" ||
            costCenter === "" ||
            contacts.length === 0) {
                popupCreate("Error", "Please fill in all fields")
                checkContacts()
                return
        }
        if(projectIdError !== "") {
            popupCreate("Error", "Please fix the project id error")
            return
        }
        if(costCenter.length <= 3) {
            popupCreate("Error", "Cost center must be greater than 1000")
            return
        }
        setLoading(true)
        const send = {
            contacts: contacts,
            costCenter: costCenter,
            department: department,
            description: description,
            displayName: displayName,
            product: product,
            projectId: projectId,
            customProjectIcon: null,
            internalOrderNumber: internalOrderNumber
        } as IProject

        createProject(send).then((data) => {
            if(data.success) {
                resetFields()
                // popup success
                popupCreate("Success", "Project created successfully")
            } else {
                popupCreate("Error", "Project could not be created.\n " + data.message)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const checkProjectId = () => {
        // check if projectid only contains letters and numbers
        if(!projectId.match(/^[a-zA-Z0-9]+$/)) {
            setProjectIdError("Project ID can only contain letters and numbers")
        } else {
            setProjectIdError("")
        }
    }

    const checkContacts = () => {
        // check if projectid only contains letters and numbers
        if(contacts.length === 0) {
            setProjectContactError("Project needs at least one contact")
        } else {
            setProjectContactError("")
        }
    }

    const checkCostCenter = () => {
        if(costCenter === "" || costCenter.length <= 3) {
            setProjectCostCenterError("Project needs a valid cost center. Number must be greater than 1000")
        } else {
            setProjectCostCenterError("")
        }
    }

    const checkInternalOrderNumber = () => {
        if(internalOrderNumber === "" || internalOrderNumber.length <= 0) {
            setProjectCostCenterError("Project needs a valid internal order number. Number must be greater than 0")
        } else {
            setProjectCostCenterError("")
        }
    }

        return <>
            <p> Create a new project here.</p>

            <Form>
                <Form.Group className={`${styles.addNewProject}`} controlId={"displayName"}>
                    <Form.Label>Display name</Form.Label>
                    <Form.Control type={"text"}
                                    placeholder={"Enter display name"}
                                  id={"displayName"}
                                    value={displayName}
                                    onChange={(e) => setDisplayName(e.target.value)}/>
                </Form.Group>
                <Form.Group className={`${styles.addNewProject}`} controlId={"description"}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control type={"text"}
                                    placeholder={"Enter description"}
                                  id={"description"}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}/>
                </Form.Group>
                <Form.Group className={`${styles.addNewProject}`} controlId={"allContacts"}>
                    <Form.Label>Contacts</Form.Label>
                    {contacts.length >= 1 ?
                    <Table hover striped>
                        <tbody>
                        {contacts.map((contact, index) => {
                            return <tr key={index}>
                                <td id={"enteredMail" + {index}}>{contact.mail}</td>
                                <td id={"enteredJob" + index}>{contact.job}</td>
                                <td className={`${styles.contactRemove}`}>
                                    <Button className={`uib-button uib-button--secondary `}
                                            id={`removeContact${index}`}
                                            onClick={() => removeContact(contact.mail)}>
                                        Remove
                                    </Button>
                                </td>
                            </tr>

                        })}
                        </tbody>
                    </Table>
                        : <></>}
                    <div className={`${styles.addContact}`}>
                        <Form.Control type={"text"}
                                        placeholder={"Enter mail"}
                                      id={"newMail"}
                                        value={newMail}
                                        onChange={(e) => setNewMail(e.target.value)}/>
                        <Form.Control type={"text"}
                                        placeholder={"Enter job"}
                                        id={"newJob"}
                                        value={newJob}
                                        onChange={(e) => setNewJob(e.target.value)}/>
                        <Button className={`uib-button uib-button--primary ${styles.spaceLeft}`}
                                id={"addContact"}
                                onClick={() => {addContact()}}>Add contact</Button>
                    </div>
                    <Form.Text className={`text-muted ${styles.ErrorText}`}>
                        {projectContactError}
                    </Form.Text>
                </Form.Group>
                <Form.Group className={`${styles.addNewProject}`} controlId={"costCenter"}>
                    <Form.Label>Cost center</Form.Label>
                    <Form.Control type={"number"}
                                  placeholder={"Enter cost center"}
                                  value={costCenter.toString()}
                                  onChange={(e) => setCostCenter(e.target.value)}/>
                </Form.Group>
                <Form.Group className={`${styles.addNewProject}`} controlId={"internalOrderNumber"}>
                    <Form.Label>Internal Order Number</Form.Label>
                    <Form.Control type={"number"}
                                  placeholder={"Enter internal Order Number (>= 1)"}
                                  value={internalOrderNumber.toString()}
                                  onChange={(e) => setInternalOrderNumber(e.target.value)}/>
                </Form.Group>
                <Form.Group className={`${styles.addNewProject}`} controlId={"department"}>
                    <Form.Label>Department</Form.Label>
                    <Form.Control type={"text"}
                                  placeholder={"Enter department"}
                                  value={department}
                                  onChange={(e) => setDepartment(e.target.value)}/>
                </Form.Group>
                <Form.Group className={`${styles.addNewProject}`} controlId={"product"}>
                    <Form.Label>Product</Form.Label>
                    <Form.Control type={"text"}
                                    placeholder={"Enter product"}
                                    value={product}
                                    onChange={(e) => setProduct(e.target.value)}/>
                </Form.Group>
            </Form>
            <div className={styles.Buttons}>
                <Button className={`uib-button uib-button--secondary `}
                        id={"reset"}
                        onClick={() => {resetFields()}}>Reset</Button>
                <Button className={`uib-button uib-button--primary ${styles.spaceLeft}`}
                        id={"submit"}
                        onClick={() => {submit()}}>Submit</Button>
            </div>
        </>

}

export default NewProject;