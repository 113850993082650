import {IModule} from "../BackendFacade/IModuleInstances";
import {moduleContent as DOOP, Module as DOOPModule} from "../Components/DOOP";
import {moduleContent as DOOPDev, Module as DOOPDevModule} from "../Components/DOOPDev";
import {moduleContent as DPAS, Module as DPASModule} from "../Components/DPAS";
import {moduleContent as IDMS, Module as IDMSModule} from "../Components/IDMS";
import {moduleContent as IDMSDev, Module as IDMSDevModule} from "../Components/IDMSDev";
import {moduleContent as AppCenter} from "../Components/AppCenter";
import {moduleContent as AppStoreConnect} from "../Components/AppleStoreConnect";
import {moduleContent as AzureDevOps} from "../Components/AzureDevOps";
import {moduleContent as AzurePortal} from "../Components/AzurePortal";
import {moduleContent as Blackduck} from "../Components/Blackduck";
import {moduleContent as BookmarkModule} from "../Components/BookmarkModule";
import {moduleContent as DAMS, Module as DAMSModule} from "../Components/DAMS";
import {moduleContent as DPOD} from "../Components/DPOD";
import {moduleContent as DPODDev, Module as DPODDevModule} from "../Components/DPODDev";
import {moduleContent as DUSS, Module as DUSSModule} from "../Components/DUSS";
import {moduleContent as Firebase} from "../Components/Firebase";
import {moduleContent as GoogleCloudServices} from "../Components/GoogleCloudServices";
import {moduleContent as GooglePlay} from "../Components/GooglePlay";
import {moduleContent as KeyChanger} from "../Components/KeyChanger";
import {moduleContent as Sendgrid} from "../Components/Sendgrid";
import {moduleContent as SentryIO} from "../Components/SentryIO";
import {moduleContent as Sonarqube} from "../Components/Sonarqube";
import {moduleContent as Hub} from "../Components/Hub";
import {moduleContent as ProjectContent,  } from "../Components/ProjectContent";

import {IListOfModules} from "../HubFramework/IHub";
import ProjectContentModule from "../Components/ProjectContent/module";
import HubModule from "../Components/Hub/module";
import BookmarkModuleModule from "../Components/BookmarkModule/module";
import AppCenterModule from "../Components/AppCenter/module";
import AppStoreConnectModule from "../Components/AppleStoreConnect/module";
import AzureDevOpsModule from "../Components/AzureDevOps/module";
import AzurePortalModule from "../Components/AzurePortal/module";
import BlackduckModule from "../Components/Blackduck/module";
import FirebaseModule from "../Components/Firebase/module";
import GoogleCloudServicesModule from "../Components/GoogleCloudServices/module";
import GooglePlayModule from "../Components/GooglePlay/module";
import KeyChangerModule from "../Components/KeyChanger/module";
import SendgridModule from "../Components/Sendgrid/module";
import SentryIOModule from "../Components/SentryIO/module";
import SonarqubeModule from "../Components/Sonarqube/module";
import DPODModule from "../Components/DPOD/module";
// import DOOPModule from "../Components/DOOP/module";
// import DOOPDevModule from "../Components/DOOPDev/module";

export const getListOfAllModules = function(): IListOfModules {
    let obj: IListOfModules = {};
    obj[DOOP.moduleName] = {moduleContent: DOOP, Module: DOOPModule}
    obj[DOOPDev.moduleName] = {moduleContent: DOOPDev, Module: DOOPDevModule}
    obj[DPAS.moduleName] = {moduleContent: DPAS, Module: DPASModule}
    obj[IDMS.moduleName] = {moduleContent: IDMS, Module: IDMSModule}
    obj[IDMSDev.moduleName] = {moduleContent: IDMSDev, Module: IDMSDevModule}
    obj[AppCenter.moduleName] = {moduleContent: AppCenter, Module: AppCenterModule}
    obj[AppStoreConnect.moduleName] = {moduleContent: AppStoreConnect, Module: AppStoreConnectModule}
    obj[AzureDevOps.moduleName] = {moduleContent: AzureDevOps, Module: AzureDevOpsModule}
    obj[AzurePortal.moduleName] = {moduleContent: AzurePortal, Module: AzurePortalModule}
    obj[Blackduck.moduleName] = {moduleContent: Blackduck, Module: BlackduckModule}
    obj[BookmarkModule.moduleName] = {moduleContent: BookmarkModule, Module: BookmarkModuleModule}
    obj[DAMS.moduleName] = {moduleContent: DAMS, Module: DAMSModule}
    obj[DPOD.moduleName] = {moduleContent: DPOD, Module: DPODModule}
    obj[DPODDev.moduleName] = {moduleContent: DPODDev, Module: DPODDevModule}
    obj[DUSS.moduleName] = {moduleContent: DUSS, Module: DUSSModule}
    obj[Firebase.moduleName] = {moduleContent: Firebase, Module: FirebaseModule}
    obj[GoogleCloudServices.moduleName] = {moduleContent: GoogleCloudServices, Module: GoogleCloudServicesModule}
    obj[GooglePlay.moduleName] = {moduleContent: GooglePlay, Module: GooglePlayModule}
    obj[KeyChanger.moduleName] = {moduleContent: KeyChanger, Module: KeyChangerModule}
    obj[Sendgrid.moduleName] = {moduleContent: Sendgrid, Module: SendgridModule}
    obj[SentryIO.moduleName] = {moduleContent: SentryIO, Module: SentryIOModule}
    obj[Sonarqube.moduleName] = {moduleContent: Sonarqube, Module: SonarqubeModule}
    obj[Hub.moduleName] = {moduleContent: Hub, Module: HubModule}
    obj[ProjectContent.moduleName] = {moduleContent: ProjectContent, Module: ProjectContentModule}

    return obj
}

/**
 * Returns true if the module is not an internal module
 * @param module module instance
 */
export const isExternalModule = function(module: IModule): boolean {
    const internalModules = [DOOP.moduleName, DPAS.moduleName, IDMS.moduleName, IDMSDev.moduleName, DOOPDev.moduleName];
    return !internalModules.includes(module.staticModuleId);
}
