import {IContent} from "../../HubFramework"
import KafkaEventsPage from "./Tabs/KafkaEvents";

/**
 * The content of the module
 * This is mandatory for every module. It contains all the information about the module.
 */
export const moduleContent: IContent = {
    moduleName: "damsProd",
    title: "DevSecOps Alerting Monitoring Service (DAMS)",
    pages: [
        {
            displayName: "Kafka Events",
            contentPage: KafkaEventsPage
        }
    ],
    defaultPage: "Kafka Events",
};
