import * as React from "react";
import {Table} from "react-bootstrap";
import {getListOfAllModules} from "../../../Utils/modules";
import {listModuleInstances, listProjects} from "../BackendFacade/apiCalls";
import {GlobalDictionary} from "../../../Utils/globalDictionary";
import {IUsageModuleToProject} from "../Interfaces/ModulesUsage";
import {IModule} from "../../../BackendFacade/IModuleInstances";
import {IProject} from "../Interfaces/IAllProjects";
import styles from "./../Hub.module.css";
import {useEffect} from "react";
import {useMain} from "../../../Utils/SessionControls/mainContext";
const LISTOFPROJECTS = "listOfProjects"

const ModulesUsage: React.FC = () => {
    const [listOfProjects, setListOfProjects] = React.useState<IProject[]>([]);
    const [usageModules, setUsageModules] = React.useState<IUsageModuleToProject[]>([]);

    const {popupCreate} = useMain();


    const listOfAllStaticModules = getListOfAllModules()

    useEffect(() => {
        // Define an async function inside the useEffect
        const fetchData = async () => {
            let allProjects: IProject[];
            if (!GlobalDictionary.get(LISTOFPROJECTS)) {
                allProjects = await getListOfAllProjects();
                if (allProjects.length === 0) return;
                // Assuming you want to cache the projects in the global dictionary
                GlobalDictionary.set(LISTOFPROJECTS, allProjects);
            } else {
                allProjects = GlobalDictionary.get(LISTOFPROJECTS);
            }

            // get all modules to all projects
            await gatherAllUsedModules(allProjects);
        };

        // Call the async function
        fetchData();
    }, []);

    const  getListOfAllProjects = async () => {
        return listProjects().then((data) => {
            if(data.success) {
                GlobalDictionary.set(LISTOFPROJECTS, data.data.value)
                return data.data.value as IProject[]
            } else {
                popupCreate("Error", data.message);
                return [] as IProject[]
            }
        })
    }

    /**
     * Get all modules to a project
     * @param projectId
     */
    const getProjectModules = async (projectId: string) => {
        const data = await listModuleInstances(projectId);
        if (data.success) {
            let ret = data.data.value as IModule[];
            // set the project id of every module
            ret.forEach((module) => {
                module.projectId = projectId;
            });
            return ret;
        } else {
            popupCreate("Error", data.message);
        }
    }

    const translateProjectIdToProjectName = (projectId: string) => {
        const projectList = GlobalDictionary.get(LISTOFPROJECTS) as IProject[]
        if(!projectList) return ""
        const project = projectList.find((i) => i.projectId === projectId)
        if(!project) return ""
        return project.displayName
    }

    /**
     * Sort all modules to a table of modules and their usage
     * @param allProjects
     */
    const gatherAllUsedModules = async (allProjects: IProject[]) => {
        let allModules: IModule[] = []
        let listToLoad = [] as Promise<any>[]
        allProjects.forEach((project) => {
            listToLoad.push(
                getProjectModules(project.projectId).then((modules) => {
                    allModules.push(...modules)
                })
            )
        })
        await Promise.all(listToLoad)

        const allStaticModules = getListOfAllModules()

        let usageTable: IUsageModuleToProject[] = []
        allModules.forEach((module) => {
            // find module in usageTable
            let moduleInUsageTable = usageTable.find((i) => i.moduleName === module.staticModuleId)
            if(!moduleInUsageTable) {
                moduleInUsageTable = {
                    moduleName: module.staticModuleId,
                    // projects: [translateProjectIdToProjectName(module.projectId)],
                    projects: [{name: translateProjectIdToProjectName(module.projectId), count: 1}],
                    count: 1,
                }
                usageTable.push(moduleInUsageTable)
            } else {
                moduleInUsageTable.count++
                // add project to list as new entry
                if(!moduleInUsageTable.projects.find((i) => i.name === translateProjectIdToProjectName(module.projectId)))
                    moduleInUsageTable.projects.push({name: translateProjectIdToProjectName(module.projectId), count: 1})
                else
                    moduleInUsageTable.projects.find((i) => i.name === translateProjectIdToProjectName(module.projectId)).count++
            }
        })

        // add static modules that are not used
        Object.keys(allStaticModules).forEach((key) => {
            if(!usageTable.find((i) => i.moduleName === key)) {
                usageTable.push({
                    moduleName: key,
                    projects: [],
                    count: 0,
                })
            }
        })

        // sort by module name
        usageTable.sort((a, b) => {
            if(a.moduleName < b.moduleName) return -1
            if(a.moduleName > b.moduleName) return 1
            return 0
        })

        setUsageModules(usageTable)
    }

    const sortByModuleName = () => {
        const sortedUsageModules = usageModules
        usageModules.sort((a, b) => {
            if(a.moduleName < b.moduleName) return -1
            if(a.moduleName > b.moduleName) return 1
            return 0
        })
        setUsageModules(sortedUsageModules)
    }

    const sortByCount = () => {
        const sortedUsageModules = usageModules
        usageModules.sort((a, b) => {
            if(a.count < b.count) return 1
            if(a.count > b.count) return -1
            return 0
        })
        setUsageModules(sortedUsageModules)
    }


        return <>
            <p> This page contains infos about all modules in DevSecOps Hub.</p>
            <br />
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th onClick={() => sortByModuleName()}>Title</th>
                    <th>Used in </th>
                    <th className={styles.alignCenter} onClick={() => sortByCount()}>No. of instances</th>
                </tr>
                </thead>
                <tbody>
                {usageModules && usageModules.map((module) => {
                    return <tr key={module.moduleName}>
                        <td>{listOfAllStaticModules[Object.keys(listOfAllStaticModules).find(x => x === module.moduleName)].moduleContent.title}</td>
                        <td>{module.projects.map((i) => i.name + " (" + i.count + ")").join(", ")}</td>
                        <td className={styles.alignCenter}>{module.count}</td>
                    </tr>
                })}

                </tbody>
            </Table>
        </>


}

export default ModulesUsage;