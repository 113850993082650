import * as React from "react";
import {useEffect} from "react";
import {
    DirectoryType,
    DpasPopup,
    FileType,
    IDirectoryContent,
    IFileDetails,
    IMasonryContent,
    NoResults,
    SortBy
} from "../Interfaces/IFiles";
import {Module} from "../module";
import {Breadcrumb, Modal, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {
    AssetURL,
    FolderDocument,
    FolderImage,
    FolderSecret,
    FolderTypeDocument,
    FolderTypeImage,
    FolderTypeSecret,
    FolderTypeUP
} from "../constants";
import {HubPathRouting} from "../../../HubFramework/pathBuilder";
import {getDirContentNames, getFileAttributesSingle,} from "../BackendFacade/apiCalls";
import {deepCopy} from "../../../Utils/transformer";
import Masonry from "react-responsive-masonry"
import styles from "../Dpas.module.css"
import {useOutletContext, useSearchParams} from "react-router-dom";
import {useMain} from "../../../Utils/SessionControls/mainContext";
import FilesToolbar, {numberOfResults} from "./Components/FilesToolbar";
import FilesUpload from "./Components/FilesUpload";
import FilesDetail from "./Components/FilesDetail";


// TODO upload valid images types: jpg, jpeg, png, gif, bmp, tif, tiff, webp

type FileList = Record<string, IFileDetails>

/**
 * Management of directories: Since there are two types of directory types, the url splits up in different behaviours.
 * The url to be read is everything after the ?. It is structured as follows:
 * &[projectName]/[directoryType]/[directories]
 * The directoryType is not transmitted to the backend in the url but as type. It is set by state.directoryType
 */
const Files: React.FC = () => {
    const [popup, setPopup] = React.useState(DpasPopup.None);
    // const [dpasId, setDpasId] = React.useState<string>("");
    const [folderPath, setFolderPath] = React.useState<string[]>([]);
    const [currentDirectory, setCurrentDirectory] = React.useState<IDirectoryContent | undefined>(undefined);
    const [toolbar_SearchField, setToolbar_SearchField] = React.useState<string>("");
    const [toolbar_noResults, setToolbar_noResults] = React.useState<NoResults>(numberOfResults()[1]);
    const [directoryType, setDirectoryType] = React.useState<"image" | "document" | "secret" | "UP" | "">("");
    const [fileDetails, setFileDetails] = React.useState<IFileDetails | undefined>(undefined);
    const [projectId, setProjectId] = React.useState<string>("");
    const [viewDetailsSwitch, setViewDetailsSwitch] = React.useState<boolean>(false);
    const [fileListDetails, setFileListDetails] = React.useState<FileList>({});
    const [sortBy, setSortBy] = React.useState<SortBy>(SortBy.Name);
    const [sortAscDirection, setSortAscDirection] = React.useState<boolean>(true);



    const parentModule = useOutletContext() as Module;
    const [path, setPath] = useSearchParams()

    const {setLoading, popupCreate} = useMain()

    useEffect(() => {
        setLoading(true)
        // if path is not defined, set it to ""
        if(path === undefined || path.get("path") === null) {
            setUrlPath("", "")
        }

        let _folderPath = []

        let directoryType = path.get("type") as "image" | "document" | "secret" | "UP" | ""
        if(!directoryType ) {
            directoryType = ""
        }
        // convert directoryType to "image" | "document" | "secret" | "UP" | ""
        if(directoryType === "image" || directoryType === "document" || directoryType === "secret" || directoryType === "UP" || directoryType === "") {
            setDirectoryType(directoryType)
        } else {
            setDirectoryType("")
        }

        _folderPath = getFolderPathFromString(path.get("path") ?? "")

        // if the directory path is not empty, but the directory type, set the path to ""
        if(_folderPath.length > 1 && directoryType === "") {
            _folderPath = [""]
        }

        setFolderPath(_folderPath)
        openDirectory(_folderPath.join('/'), directoryType)

        parentModule.getProjectId().then((data) => {
            setProjectId(data)
            setLoading(false)
        })
    }, []);

    // if detail view is activated, get all details for every file in this folder
    useEffect(() => {
        if(viewDetailsSwitch) {
            setLoading(true)
            let files = filterCurrentView().filter(x => x.type === FileType.File)
            let fileNames = files.map(x => x.name)
            let fileDetails: FileList = fileListDetails
            // filter all files which are already in fileListDetails
            fileNames = fileNames.filter(name => {
                return fileListDetails[name] === undefined
            })
            let promises = fileNames.map(name => {
                return getFileAttributesSingle(name, directoryType).then((data) => {
                    if(data.success) {
                        fileDetails[name] = data.data
                    } else {
                        popupCreate("Error",  data.message)
                    }
                })
            })
            Promise.all(promises).then(() => {
                setFileListDetails(fileDetails)
                setLoading(false)
            })

        }

    }, [viewDetailsSwitch, currentDirectory])

    const sortFiles = (sortByParam: SortBy) => {
        if(sortBy === sortByParam) {
            setSortAscDirection(!sortAscDirection)
        } else {
            setSortAscDirection(true)
            setSortBy(sortByParam)
        }
    }

    const setUrlPath = (path: string, directoryType: string) => {
        setPath({path: path, type: directoryType})
    }

    const getFolderPathFromString = (path: string): string[] => {
        let _folderPath = path.split("/")
        // the first element must be an empty string
        if(_folderPath.length > 0 && _folderPath[0] !== "") {
            // if there is a empty element, because the path ends with a /, remove it
            if(_folderPath.length > 1) {
                if(_folderPath[_folderPath.length - 1] === "") {
                    _folderPath = _folderPath.slice(0, -1)
                }
            }
            _folderPath = [""].concat(_folderPath)
        } else if (_folderPath.length === 0) {
            _folderPath = [""]
        }
        return _folderPath
    }

    /**
     * Opens a directory. If no directory is given, the root directory is opened.
     * @param directory takes full path as string, without folder type
     * @param type takes the folder type
     */
    const openDirectory = (directory: string = "", type : "image" | "document" | "secret" | "UP" | "") => {
        // if the directory is empty, the current directory is empty and the type is up, then go to type root level
        if(type === "UP" && directory === "" && folderPath.length === 1 && folderPath[0] === "") {
            setViewDetailsSwitch(false)
            setSortAscDirection(true)
            setSortBy(SortBy.Name)
            setDirectoryType("")
            setFolderPath([""])
            setUrlPath("", "")
            type = ""
        }
        // if you go up, remove the last element of the directory
        if(type === "UP") {
            let _folderPath = folderPath.slice(0, -1)
            setFolderPath(_folderPath)
            setUrlPath(_folderPath.join('/'), directoryType)
            openDirectory(_folderPath.join('/'), directoryType)
            return
        }

        // the directory path must not start with / but end with / if it is not empty
        if(directory !== "" && directory[0] === "/") {
            directory = directory.slice(1)
        }
        if(directory !== "" && directory[directory.length - 1] !== "/") {
            directory = directory + "/"
        }

        setDirectoryType(type)
        // on root level: display folders "image" and "documents" first
        let newpath: string[];
        if(type == "" && directory == "") {
            setCurrentDirectory({directories: [], files: [], category: [FolderImage, FolderDocument, FolderSecret]})
            setDirectoryType("")
            setUrlPath("", "")
            return
        } else {
            newpath = getFolderPathFromString(directory)
            setFolderPath(newpath)
            setLoading(true)
            getDirContentNames(directory, type).then(
                data => {
                    if(!data.success) {
                        popupCreate("Error",  data.message)
                        return
                    }
                    setCurrentDirectory(data.data as IDirectoryContent)
                }).finally(() => {
                    setLoading(false)
                })
            setUrlPath(newpath.join('/'), type)
        }
    }

    const convertToMasonryItems = (item: IDirectoryContent) : IMasonryContent[] => {
        let i = 0;
        let ret: IMasonryContent[] = []
        // on type level, do not show UP symbol
        if(!item.category) {
            ret.push({
                id: i++,
                name: "Go up",
                type: FileType.Up,
                src: folderPath.slice(0, -1).join('/')
            })
        }
        item.directories.forEach(dir => {
            ret.push({
                id: i++,
                name: dir,
                type: FileType.Directory,
                src: dir,
            })
        })
        item.files.forEach(file => {
            if(directoryType === FolderTypeImage) {
                ret.push({
                    id: i++,
                    name: file,
                    type: FileType.File,
                    src: AssetURL + "156px/" + projectId + "/" + file,
                })
            } else {
                ret.push({
                    id: i++,
                    name: file,
                    type: FileType.File,
                    src: AssetURL + projectId + "/" + file,
                })
            }
        })
        if(item.category) {
            item.category.forEach(cat => {
                ret.push({
                    id: i++,
                    name: cat,
                    type: FileType.Type,
                    src: cat,
                    folderType: DirectoryType[cat as keyof typeof DirectoryType]
                })
            })
        }
        return ret
    }

    const cleanDirectoryName = (dir: string) => {
        return dir.split('/').filter(d => d !== "").pop()
    }

    /**
     * Cleans the filename from the path and the extension
     * @param file
     * @param showExtension
     */
    const cleanFileName = (file: string, showExtension: boolean = false) => {
        if(showExtension)
            return file.split('/').pop()
        return file.split('/').pop().split('.')[0]
    }

    /**
     * Creates a card for the masonry layout
     * @param data
     * @param index
     */
    const Card = (data: {data: IMasonryContent}, index: number) => {
        switch (data.data.type) {
            case FileType.Directory:
                return <div className={styles.card} onClick={() => openDirectory(data.data.src, directoryType)} key={index}>
                    <div className={styles.outerDirectory}>
                        <div className={styles.innerDirectory}>
                            <i className={`uib-icon uib-icon--folder-open ${styles.folderLogo}`} />
                        </div>
                    </div>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 200, hide: 200 }}
                        overlay={
                            <Tooltip id="tooltip-key">
                                {cleanDirectoryName(data.data.name)}
                            </Tooltip>
                        }
                    >
                        <span className={styles.cropText} children={cleanDirectoryName(data.data.name)}/>
                    </OverlayTrigger>
                </div>
            case FileType.File:
                // type image
                let image =  <img className={styles.img} alt="logo" src={data.data.src}/>
                let filename = <span className={styles.cropText} children={cleanFileName(data.data.name)}/>
                // type document
                if(directoryType === FolderTypeDocument || directoryType === FolderTypeSecret) {
                    image = <div className={styles.outerDocument}>
                        <div className={styles.innerDocument}>
                            <i className={`uib-icon uib-icon--attachment ${styles.folderLogo}`} />
                        </div>
                    </div>
                    filename = <span className={styles.cropText} children={cleanFileName(data.data.name, true)}/>
                }

                return <div className={styles.card} key={index} onClick={() => fileDetailOpen(data.data.name)}>
                    {image}
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 200, hide: 200 }}
                        overlay={
                            <Tooltip id="tooltip-key">
                                {cleanFileName(data.data.name, directoryType == FolderTypeDocument)}
                            </Tooltip>
                        }
                    >
                        {filename}
                    </OverlayTrigger>
                </div>
            case FileType.Up:
                return <div className={styles.card} onClick={() => openDirectory(data.data.src, FolderTypeUP)} key={index}>
                    <div className={styles.outerDocument}>
                        <div className={styles.innerDocument}>
                            <i className={`uib-icon uib-icon--arrow1-up ${styles.folderLogo}`} />
                        </div>
                    </div>
                    <span className={styles.cropText} children={data.data.name}/>
                </div>
            // category
            case FileType.Type:
                return <div className={styles.card}
                            onClick={() => {
                                // setDirectoryType(data.data.src)
                                openDirectory("", data.data.folderType)
                            }}
                            key={index}>
                    <div className={styles.outerDirectory}>
                        <div className={styles.innerDirectory}>
                            <i className={`uib-icon uib-icon--folder-open  ${styles.folderLogo}`} />
                        </div>
                    </div>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 200, hide: 200 }}
                        overlay={
                            <Tooltip id="tooltip-key">
                                {cleanDirectoryName(data.data.name)}
                            </Tooltip>
                        }
                    >
                        <span className={styles.cropText} children={cleanDirectoryName(data.data.name)}/>
                    </OverlayTrigger>
                </div>
        }
    }

    const refreshDirectory = () => {
        openDirectory(folderPath.join('/'), directoryType)
    }

    const NavigationBar = () => {
        return <div className={styles.navigationBar}>
            <Breadcrumb>
                <Breadcrumb.Item  onClick={() => openDirectory("", "")} active={folderPath.length == 0}>
                    {HubPathRouting.currentModule?.displayName}
                </Breadcrumb.Item>
                {folderPath.map((dir, index) => {
                    // do not show project name, instead show directory type
                    if(index == 0)
                        dir = directoryType
                    return <Breadcrumb.Item
                        onClick={() => openDirectory(folderPath.join('/'), directoryType)}
                        active={folderPath.length == index+1}
                        key={index}
                    >
                        {dir}
                    </Breadcrumb.Item>
                })}
            </Breadcrumb>
        </div>
    }

    /**
     * Filter and sort the view by the settings of the filter bar
     */
    const filterCurrentView = () :IMasonryContent[] => {
        if(currentDirectory === undefined) {
            return []
        }
        // prepare current Directory
        let filteredCurrentDirectory = deepCopy(currentDirectory) as IDirectoryContent

        // filter by searchfield
        if (toolbar_SearchField !== "" && toolbar_SearchField.length >= 3) {
            filteredCurrentDirectory.directories = filteredCurrentDirectory.directories.filter(dir => dir.toLowerCase().includes(toolbar_SearchField.toLowerCase()))
            filteredCurrentDirectory.files = filteredCurrentDirectory.files.filter(file => file.toLowerCase().includes(toolbar_SearchField.toLowerCase()))
        }
        // remove empty files
        filteredCurrentDirectory.files = filteredCurrentDirectory.files.filter(file => file !== "")

        // sort them by sortby and sort direction
        filteredCurrentDirectory.files.sort((a, b) => {
            const aName = a.split('/').pop()
            const bName = b.split('/').pop()

            if(sortBy === SortBy.Name) {
                if(sortAscDirection) {
                    return aName.localeCompare(bName)
                } else {
                    return bName.localeCompare(aName)
                }
            } else if(sortBy === SortBy.Size) {
                const sizeA = +fileListDetails[aName]?.properties.length
                const sizeB = +fileListDetails[bName]?.properties.length
                if(sortAscDirection) {
                    return sizeA - sizeB
                } else {
                    return sizeB - sizeA
                }
            } else if(sortBy === SortBy.Date) {
                const dateA = fileListDetails[aName]?.properties.lastModified as string
                const dateB = fileListDetails[bName]?.properties.lastModified as string
                if(sortAscDirection) {
                    return dateA.localeCompare(dateB)
                } else {
                    return dateB.localeCompare(dateA)
                }
            }
        })
        return convertToMasonryItems(filteredCurrentDirectory).slice(0, +toolbar_noResults.value + 1)
    }

    const fileDetailOpen = (file: string) => {
        setLoading(true)
        getFileAttributesSingle(file, directoryType).then((data) =>  {
            if(data.success) {
                data.data.length = data.data.properties.length
                setFileDetails(data.data)
                setPopup(DpasPopup.PictureDetail)
            } else {
                popupCreate("Error",  data.message)
            }
            setLoading(false)
        })
    }

    const uploadFileShow = () => {
        setPopup(DpasPopup.UploadFile)
    }

    const SortIcon = (key: SortBy) => {
        if (key === sortBy) {
            return sortAscDirection ? <i className="uib-icon--arrow1-up uib-icon"></i> : <i className="uib-icon--arrow1-down uib-icon"></i>;
        }
    };

    let popupContent = <div></div>

    switch(popup) {
        case DpasPopup.PictureDetail:
            popupContent = <FilesDetail fileDetails={fileDetails}
                                        directoryType={directoryType}
                                        refreshDirectory={refreshDirectory}
                                        setPopup={setPopup}
                                        projectId={projectId}
            />
            break;
        case DpasPopup.UploadFile:
            popupContent = <FilesUpload directoryType={directoryType}
                                        refreshDirectory={refreshDirectory}
                                        setPopup={setPopup}
                                        uploadPathParam={folderPath.slice(1).join('/') + '/'}
            />
            break;
        default:
            break;
    }

    return <div>
        <Modal size="lg" show={popup !== DpasPopup.None} onHide={() => setPopup(DpasPopup.None)} >
            {popupContent}
        </Modal>
        <div className={styles.Projectlist}>
            <NavigationBar/>
            <FilesToolbar currentDirectory={currentDirectory}
                          folderPath={folderPath}
                          directoryType={directoryType}
                          filterCurrentView={filterCurrentView}
                          UploadFileShow={uploadFileShow}
                          setSearchField={setToolbar_SearchField}
                          setNoResults={setToolbar_noResults}
                          setViewDetailsSwitch={setViewDetailsSwitch}
                          viewDetailsSwitch={viewDetailsSwitch}
            />

            <div className={styles.Masony}>
                {
                    viewDetailsSwitch ?
                        <div>
                            <Table striped hover>
                                <thead>
                                <tr>
                                    <th onClick={() => sortFiles(SortBy.Name)}>{SortIcon(SortBy.Name)}Name</th>
                                    <th onClick={() => sortFiles(SortBy.Size)} >{SortIcon(SortBy.Size)}Size</th>
                                    <th onClick={() => sortFiles(SortBy.Date)}>{SortIcon(SortBy.Date)}Modified</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filterCurrentView()
                                    .map((item, index) => {
                                    const size = +fileListDetails[item.name]?.properties.length
                                    const lastModified = fileListDetails[item.name]?.properties.lastModified as string
                                    if(item.type === FileType.Up) {
                                        return <tr key={index} onClick={() => openDirectory(item.src, FolderTypeUP)}>
                                            <td>
                                                <i className={`uib-icon uib-icon--arrow1-up ${styles.detailViewLogoSize}`}/>
                                                {item.name}
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    }
                                    if(item.type === FileType.File) {
                                        return <tr key={index} onClick={() => fileDetailOpen(item.name)}>
                                            <td>
                                                <i className={`uib-icon uib-icon--attachment ${styles.detailViewLogoSize}`} />
                                                {item.name}
                                            </td>
                                            <td>
                                                { Math.round((size / 1024 / 1024) * 100) / 100} MB
                                            </td>
                                            <td>{lastModified?.split('+')[0].replace('T', ' ')}</td>
                                        </tr>
                                    }
                                    return <tr key={index} onClick={() => openDirectory(item.src, directoryType)}>
                                        <td>
                                            <i className={`uib-icon uib-icon--folder-open ${styles.detailViewLogoSize}`}/>
                                            {cleanDirectoryName(item.name)}
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                })}
                                </tbody>
                            </Table>

                        </div>
                        :
                        <Masonry columnsCount={8} gutter={"8px"}>
                            {filterCurrentView().map((item, index) => Card({data: item}, index))}
                        </Masonry>
                }

            </div>

        </div>
        </div>
        }

export default Files;
