import * as React from "react";
import {IProject} from "../Interfaces/IAllProjects";
import { listProjects} from "../BackendFacade/apiCalls";
import {GlobalDictionary} from "../../../Utils/globalDictionary";
import styles from './../Hub.module.css'
import {IContact} from "../Interfaces/IContact";
import {ProfilImage} from "../../../Utils/ProfilImage/ProfileImage";
import {GetProfileImages} from "../../../Utils/ProfilImage/ProfileImageApi";
import {Link} from "react-router-dom";
import {useMain} from "../../../Utils/SessionControls/mainContext";
import {useEffect} from "react";

const AllProjects: React.FC = () => {

    const [listOfProjects, setListOfProjects] = React.useState<IProject[]>([]);
    const [showDescription, setShowDescription] = React.useState<boolean>(false);
    const [showIndex, setShowIndex] = React.useState<number>(-1);
    const [deleteProjectId, setDeleteProjectId] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(true);

    const {popupCreate} = useMain();

    useEffect(() => {
        if(!GlobalDictionary.get(GlobalDictionary.PROJECTS)) {
            getListOfAllProjectsWait()
        } else {
            setProjectList()
            getListOfAllProjects()
        }
    }, []);


    /**
     * Gets the list of all projects from the backend with Loading screen
     */
    const getListOfAllProjectsWait = () => {
        setLoading(true);
        getListOfAllProjects()
    }

    /**
     * Gets the list of all projects from the backend
     */
    const getListOfAllProjects = () => {
        listProjects().then((data) => {
            if(data.success) {
                GlobalDictionary.set(GlobalDictionary.PROJECTS, data.data.value)
                GetProfileImages(extractListOfContacts(data.data.value))
                setProjectList()
            } else {
                popupCreate("Error", data.message);
            }
            setLoading(false)
        })
    }

    const setProjectList = () => {
        const listOfProjects = GlobalDictionary.get(GlobalDictionary.PROJECTS)
        setListOfProjects(listOfProjects)
        setLoading(false)
    }

    const extractListOfContacts = (projects: IProject[]) => {
        const listOfContacts = projects.map((item) => {
            return item.contacts
        })

        // create a list of mail of all contacts
        let setOfContacts: string[] = []
        listOfContacts.forEach((item) => {
            item.forEach((contact: IContact) => {
                setOfContacts.push(contact.mail)
            })
        })

        return Array.from(new Set(setOfContacts))
    }

    const createLinkUrl = (projectId: string)   => {
        return window.location.origin + "/#Hub/ProjectContent/" + projectId
    }

    const handleMouseEnter = (index: number) => {
        setShowDescription(true)
        setShowIndex(index)
    }

    const handleMouseLeave = () => {
        setShowDescription(false)
        setShowIndex(-1)
    }

    const getProfileImage = (contacts: IContact[]) => {
        return contacts.map((contact, c_index) => {
            return (
                    <ProfilImage contact={contact} key={c_index} />
            );
        })
    }

    const getProjectIcon = (customProjectIcon: string) => {
        if(customProjectIcon) {
            return <img src={customProjectIcon} alt={"Project Icon"} className={`${styles.projectIcon}`}/>
        } else {
            return <i className="uib-icon uib-icon--collection"></i>
        }
    }

        return <>
            <div id={"allProjectsList"} className={`${styles.spaceRight} ${styles.Projectlist}`}>
                <ul className={`uib-list ${styles.orderOverview}`}>
                    {
                        loading ? <></> :
                        listOfProjects.length > 0 ?
                            listOfProjects.sort((a, b) => {
                                if(a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
                                    return 1
                                } else if(a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
                                    return -1
                                } else {
                                    return 0
                                }
                            }).map((item, index) => {
                                return <li className="uib-list__item" key={index}>
                                    <Link to={`/project/${item.projectId}`}>
                                    <div className={`uib-list__link ${styles.overviewLink} row`}
                                         onMouseEnter={() => handleMouseEnter(index)}
                                         onMouseLeave={() => handleMouseLeave()}
                                    >
                                        <div className={`${styles.biggerIcons}`}>
                                            {getProjectIcon(item.customProjectIcon)}
                                        </div>
                                        <div className="col-10"  >
                                            {item.displayName}
                                            <div className={`${styles.description}`}>
                                                {item.description}
                                                {getProfileImage(item.contacts)}
                                            </div>
                                        </div>
                                        <div className={`col-auto ${styles.icons}`}>
                                            {/*{state.listOfProjects.length >= 5 ? <div*/}
                                            {/*        className=" star">/!*faveStar(item.appId)*!/</div> :*/}
                                            {/*    <></>}*/}
                                        </div>
                                    </div>
                                    </Link>
                                </li>

                            }) : <p>Keine Projekte vorhanden. Falls ein Zugriff vorhanden sein sollte, bitte neu einloggen.</p>
                    }
                </ul>
            </div>
        </>
    
}

export default AllProjects;