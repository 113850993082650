/**
 * This folder should all the api calls that are used in the module.
 * Every call must use the fetchFromApi function from the backendCalls.tsx file.
 * This function will handle the authentication and error handling.
 */

import {IDateFilter} from "../Interfaces/IKafkaEvents";
import {fetchFromApi, getBackendApiURL} from "../../../BackendFacade";

/**
 * Gets the kafka event logs
 * @param projectId
 * @param projectKafkaTopic
 * @param linkedId
 * @param dateFilter
 */
export function getEventLogs(projectId: string, projectKafkaTopic: string, linkedId: string, dateFilter: IDateFilter,  maxAmount: number) {
    let url = getBackendApiURL() + "logs/" + projectId;
    return fetchFromApi(url, "POST", {projectKafkaTopic: projectKafkaTopic, clientProjectNameFilter: linkedId, dateFilter: dateFilter, maxAmount: maxAmount})
}
