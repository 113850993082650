import {IApiKeysAddEditState} from "./IApiKeysAddEditState";

export interface IApiKeyManagement {
    apiKeys: IApiKey[],
    Sort_Key: string,
    Sort_Reverse: boolean,
    filterBy: SearchByApiKeys,
    filterState: filterState,
    filterValueState: filterState,
    Popup: Popup,
    selectedApiKey: IApiKey,

    currentProject: string,

    showKey1: boolean,
    showKey2: boolean,
    AddEditModal: IApiKeysAddEditState,
}

export interface IApiKey {
    apiKeyId: string,
    displayName: string,
    projectId: string,
    contentType: IApiKeyContentTypes,
    primaryApiKey: string,
    secondaryApiKey: string,
    content: string,
    validUntil: string,
    lastUsedOn: string,
}

export enum SearchByApiKeys {
    Include,
    Exclude
}

export interface filterState {
    value: string,
    label: string,
}

export enum Popup {
    None,
    ApiKey_AddEdit,
}

export enum IApiKeyContentTypes {
    hubIdmsTenantJson = "hubIdmsTenantJson",
    custom = "custom",
}