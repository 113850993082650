/**
 * This folder should all the api calls that are used in the module.
 * Every call must use the fetchFromApi function from the backendCalls.tsx file.
 * This function will handle the authentication and error handling.
 */

import {IProject} from "../Interfaces/IAllProjects";
import {IUserData} from "../Interfaces/IUserManagement";
import {IApiKey} from "../Interfaces/IApiKeyManagement";
import {fetchFromApi, getBackendApiURL, NetworkData} from "../../../BackendFacade";

export function listProjects(): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects"
    return fetchFromApi(url, "GET")
}

export function ProcessB2cLogin(data: any): Promise<NetworkData> {
    const url = getBackendApiURL() + "static/b2cLogin"
    return fetchFromApi(url, "POST", data)
}

export function createProject(project: IProject): Promise<NetworkData> {
    const obj = {
        Value: project
    }
    const url = getBackendApiURL() + "projects"
    return fetchFromApi(url, "PUT", obj)
}

export function updateProject(project: IProject): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects"
    return fetchFromApi(url, "POST", project)
}

export function GetAccessRoles(projectId: string): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects/" + projectId + "/permissions"
    return fetchFromApi(url, "GET")
}

export function UpdateAccessRoles(roles: IUserData, projectId: string): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects/" + projectId + "/permissions"
    const obj = {
        value: roles
    }
    return fetchFromApi(url, "POST", obj)
}

/**
 * List all module instances of a project
 * @param projectId
 */
export function listModuleInstances(projectId: string): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects/" + projectId + "/modules"
    return fetchFromApi(url, "GET")
}

export function getApiKeys(project: string): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects/" + project + "/apiKeys"
    return fetchFromApi(url, "GET")
}

export function updateApiKeys(project: string, data: IApiKey): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects/" + project + "/apiKeys"
    const obj = {
        value: data
    }
    return fetchFromApi(url, "POST", obj)
}

export function createApiKeys(project: string, data: IApiKey): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects/" + project + "/apiKeys"
    const obj = {
        value: data
    }
    return fetchFromApi(url, "PUT", obj)
}

export function deleteApiKeys(project: string, id: string): Promise<NetworkData> {
    const url = getBackendApiURL() + "projects/" + project + "/apiKeys/" + id
    return fetchFromApi(url, "DELETE")
}