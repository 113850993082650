import React, { useEffect } from 'react';
import {useSearchParams} from "react-router-dom";

const Module: React.FC = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        window.location.assign(searchParams.get("linkedId"));
    }, []);

    return <></>;
};

export default Module;