import {
    GlobalTenantSystemContentProps,
    IAuthLevel, IPossibleScope, IUserTenantManagement,
    SetGlobalTenantSystemContentProps,
    TenantLine, TenantSystemContentProps
} from "./ITenantSystem";
import {ReactElement, useEffect} from "react";
import styles from "./tenantSystem.module.css";
import {Button, Form, FormControl, InputGroup} from "react-bootstrap";
import * as React from "react";
import {replaceGuidWithDisplayName} from "../../../Utils/transformer";
import {IconTrash} from "../../../Assets/svgs";
import {HubPathRouting} from "../../../HubFramework/pathBuilder";
import {IProject} from "../../Hub/Interfaces/IAllProjects";
import {GlobalDictionary} from "../../../Utils/globalDictionary";
import {IModule} from "../../../BackendFacade/IModuleInstances";
import {listModuleInstances} from "../../../BackendFacade/moduleCalls";
import HubFunctions from "../../../HubFramework/hubFunctions";
import {listProjects} from "../../Hub/BackendFacade/apiCalls";
import {IListStaticModule} from "../../../BackendFacade/IStaticModules";
import {useMain} from "../../../Utils/SessionControls/mainContext";

export function convertAuthlevelToTenantsystem(authlevel: IAuthLevel, currentProject: string): TenantLine[] {
    let scopes = [] as TenantLine[];

    Object.keys(authlevel).forEach((key) => {
        scopes.push({id: scopes.length, scopes : key.split('/'), value : authlevel[key], tmp: "", fullscope: key})
    })

    // add global scope if it does not exist and on hub level
    if(scopes.find(x => x.scopes[0] === "user" && x.scopes.length === 1) === undefined && currentProject === "null"){
        scopes.push({id: scopes.length, scopes : ["user"], value : "", tmp: "", fullscope: "user"})
    }
    return scopes;
}

/**
 * Used as details popup. Shows all scopes of a user
 * @param tenantSystem
 * @constructor
 */
export const TenantSystemContent: React.FC<TenantSystemContentProps> = ({ tenantSystem, global}): ReactElement => {
    const ret = tenantSystem.map((item: TenantLine, index) => {
        // skip global
        if (item.scopes.length === 1 && item.scopes[0] === 'user') return null;

        return (
            <InputGroup id={'scopeEntry' + index} key={index} className={styles.scopeLine}>
                <Button disabled className={`btn btn-outline-secondary ${styles.scopeRoot}`}>
                    User
                </Button>
                {item.scopes.map((scope, index) => {
                    if (scope === 'user' && index === 0) return null;
                    return (
                        <Button
                            disabled
                            key={index}
                            variant="outline-secondary"
                            id={replaceGuidWithDisplayName(item.scopes, index, global)}
                            className="btn btn-outline-dark region"
                        >
                            {replaceGuidWithDisplayName(item.scopes, index, global)}
                        </Button>
                    );
                })}
                <FormControl disabled placeholder="" value={item.tmp} />
                <Button disabled className="btn btn-outline-secondary">
                    Value
                </Button>
                <FormControl
                    disabled
                    placeholder="undefined"
                    className={styles.tenantValue}
                    id={'scopeValue' + index}
                    value={item.value}
                />
            </InputGroup>
        );
    });
    return <>{ret}</>
};

export const GlobalTenantSystemContent: React.FC<GlobalTenantSystemContentProps> = ({
        tenantSystem,
        globalId,
    }): ReactElement => {
    return (
        <InputGroup className={styles.scopeLine}>
            <Button disabled className={`btn btn-outline-secondary ${styles.scopeRoot}`}>
                Global
            </Button>
            <FormControl
                disabled
                placeholder="Global overwrites config of subscopes"
            ></FormControl>
            <Button disabled className="btn btn-outline-secondary">
                Value
            </Button>
            <FormControl
                disabled
                placeholder="undefined"
                id={'globalValue'}
                className={styles.tenantValue}
                value={tenantSystem.find((x) => x.id === globalId).value}
            ></FormControl>
        </InputGroup>
    );
};

export const SetGlobalTenantSystemContent: React.FC<SetGlobalTenantSystemContentProps> = ({
       tenantSystem,
       globalId,
       authLevels,
       defaultAuth,
       onEditFunction,
   }): ReactElement => {
    return (
        <InputGroup className={styles.scopeLine}>
            <Button disabled className={`btn btn-outline-secondary ${styles.scopeRoot}`}>
                Global
            </Button>
            <FormControl
                disabled
                placeholder="Global overwrites config of subscopes"
                className={styles.fixHeight}
            ></FormControl>
            <Button disabled className="btn btn-outline-secondary">
                Value
            </Button>
            <Form.Select
                className={`${styles.tenantValueGlobal} ${styles.fixHeight}`}
                onChange={(event: { target: { value: any } }) => {
                    onEditFunction(globalId, event.target.value);
                }}
                id={'globalId'}
                value={
                    tenantSystem.find((x) => x.id === globalId).value === ''
                        ? defaultAuth
                        : tenantSystem.find((x) => x.id === globalId).value
                }
            >
                {authLevels.map((item, nkey) => {
                    return (
                        <option value={item} key={nkey}>
                            {item}
                        </option>
                    );
                })}
            </Form.Select>
        </InputGroup>
    );
};

/**
 * Load all possible scopes for the tenant management
 * @param global if true, load all projects and modules, else only the modules of the current project
 * @param popupCreate function to create a popup, from the main context
 */
export async function loadAllTenantScope(global: boolean,
                                      popupCreate: (title: string, message: string) => void)
    {
        let promises = [] as Promise<any>[]

        // all projects
        if(GlobalDictionary.get(GlobalDictionary.PROJECTS) === undefined) {
            promises.push(listProjects().then((data) => {
                if (data.success) {
                    GlobalDictionary.set(GlobalDictionary.PROJECTS, data.data.value)
                } else {
                    popupCreate("Error", data.message);
                }
            }))
        }

        // all modules of the project
        if(!global) {
            if(GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES) === undefined) {
                promises.push(listModuleInstances(HubPathRouting.currentProject.moduleInstanceId).then((data) => {
                    if (data.success) {
                        let modules = data.data.value as IModule[]
                        GlobalDictionary.set(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES, modules)
                    } else {
                        popupCreate("Error", data.message);
                    }
                }))
            }
        } else {
            if(GlobalDictionary.get(GlobalDictionary.PROJECTS) === undefined) {
                await Promise.all(promises)
            }
            // wait = true
            promises = []
            GlobalDictionary.get(GlobalDictionary.PROJECTS).forEach((project: IProject) => {
                if(GlobalDictionary.get(project.projectId + GlobalDictionary.MODULEINSTANCES) === undefined) {
                    promises.push(listModuleInstances(project.projectId).then((data) => {
                        if (data.success) {
                            let modules = data.data.value as IModule[]
                            GlobalDictionary.set(project.projectId + GlobalDictionary.MODULEINSTANCES, modules)
                        } else {
                            // HubFunctions.PopupCreate("Error", data.message)
                            popupCreate("Error", data.message);
                        }
                    }))
                }
            })
        }

        await Promise.all(promises)
}

export interface IUserTenantManagementProps {
    setTenantSystemFunctionProps: (tenantSystem: TenantLine[]) => void,
    tenantSystemProps: TenantLine[],
    projectId: string,
    authLevels: string[],
    listOfIDMSSubscopes: string[],
    global: boolean,
    useSelectOptions: boolean
}

// export class UserTenantManagement extends React.Component<{
const UserTenantManagement: React.FC<IUserTenantManagementProps> = ({
    setTenantSystemFunctionProps,
    tenantSystemProps,
    projectId,
    authLevels,
    listOfIDMSSubscopes,
    global,
    useSelectOptions}) => {

    const [possibleScopes, setPossibleScopes] = React.useState<IPossibleScope[]>([]);
    const [tenantSystem, setTenantSystem] = React.useState<TenantLine[]>(tenantSystemProps);
    const [staticModules, setStaticModules] = React.useState<IListStaticModule[]>([]);

    const {popupCreate, setLoading, isLoading} = useMain();

    React.useEffect(() => {
        // non IDMS
        if(listOfIDMSSubscopes.length === 0) {
            loadAllPossibleScopes(global)
        }
    }, []);

    /**
     * Call the setter function of the parent if the local tenantSystem changes
     */
    useEffect(() => {
        setTenantSystemFunctionProps(tenantSystem);
    }, [tenantSystem]);

    useEffect(() => {
        setTenantSystem(tenantSystemProps);

    }, [tenantSystemProps]);

    const loadAllPossibleScopes = async (global: boolean) => {
        setLoading(true)
        let promises = [] as Promise<any>[]

        promises.push(loadAllTenantScope(global, popupCreate))

        // this.staticModules = await GlobalDictionary.getStaticModules()
        promises.push(GlobalDictionary.getStaticModules().then((data) => {
            setStaticModules(data)
        }))

        await Promise.all(promises)
        setLoading(false)
    }

    /**
     * removes part of the scope from the config
     * @param id Id of the scope line
     * @param scope scope value
     */
    const deleteScopePart = (id: number, scope: string) => {
        const tenants: TenantLine[] = [...tenantSystem];
        let tenantIndex = tenants.findIndex(x => x.id === id);

        // Ensure that the tenant with the given ID was found.
        if (tenantIndex !== -1) {
            let scopes = tenants[tenantIndex].scopes;
            // Find the index of the last occurrence of the scope.
            let lastScopeIndex = scopes.lastIndexOf(scope);

            // Ensure that the scope was found.
            if (lastScopeIndex !== -1) {
                // Remove the last occurrence of the scope from the scopes array.
                scopes.splice(lastScopeIndex, 1);
                tenants[tenantIndex].scopes = scopes;
            }
        }
        setTenantSystem(tenants);
    }


    const EditScopeName = (id: number, value: string) => {
        const tenantLines: TenantLine[] = [...tenantSystem]
        tenantLines.find(x => x.id === id).tmp = value

        setTenantSystem(tenantLines);
    }

    /**
     * returns a list of all possible scopes with display name which are used so far
     * @param scope
     * @param tenantId used for non IDMS. id == 1 -> project level, id == 2 -> module level
     */
    const returnListOfPossibleScopes = (scope: string, tenantId: number): IPossibleScope[] => {
        if(isLoading) {
            return []
        }
        // non IDMS
        if(listOfIDMSSubscopes.length === 0) {
            const tenant = tenantSystem.find(x => x.id === tenantId)

            // project level scope
            if (tenant.scopes.length === 1) {
                const instances: IProject[] = GlobalDictionary.get(GlobalDictionary.PROJECTS)
                return instances.filter(x => x.displayName.toLowerCase().indexOf(scope.toLowerCase()) !== -1).map(x => {
                    return {id: x.projectId, displayName: x.displayName}
                })
            }
            // module level scope
            if (tenant.scopes.length === 2) {
                let instances: IModule[] = []
                if (global) {
                    instances = GlobalDictionary.get(tenant.scopes[1] + GlobalDictionary.MODULEINSTANCES)
                } else {
                    instances = GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES)
                }
                if(instances === undefined || instances === null) {
                    return []
                }
                return instances.filter(x => x.displayName.toLowerCase().indexOf(scope.toLowerCase()) !== -1).map(x => {
                    return {id: x.moduleInstanceId, displayName: x.displayName}
                }) || []
            }
            // scope inside module
            if (tenant.scopes.length === 3) {
                // find the current module
                let instances: IModule[] = []
                if (global) {
                    instances = GlobalDictionary.get(tenant.scopes[1] + GlobalDictionary.MODULEINSTANCES)
                } else {
                    instances = GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES)
                }
                const currentModule = instances?.find(x => x.moduleInstanceId === tenant.scopes[2]).staticModuleId
                const scopes = staticModules.find(x => x.staticModuleId === currentModule)?.endpoints
                if(scopes === undefined || scopes === null) {
                    return []
                }
                return Object.keys(scopes).map(x => {
                    return {id: x, displayName: x}
                })
            }

        }
        // IDMS
        else {
            return listOfIDMSSubscopes.filter(x => x.toLowerCase().indexOf(scope.toLowerCase()) !== -1).map(x => {
                return {id: x, displayName: x}
            })
        }
        return []
    }

    /**
     * Add a new part to a scope
     * @param id Id of the scope line
     * @param scope scope value
     */
    const addScopePart = (id: number, scope: string) => {
        if(scope === "") {
            return
        }
        let tenants: TenantLine[] = [...tenantSystem];
        // if scope does not exist in config, create it
        if(tenants.find(x => x.id === id).scopes.length === 0)
            tenants.find(x => x.id === id).scopes.push("user")
        tenants.find(x => x.id === id).scopes.push(scope);
        tenants.find(x => x.id === id).tmp = "";
        tenants.find(x => x.id === id).fullscope = tenants.find(x => x.id === id).scopes.join("/")
        setTenantSystem(tenants);
    }

    /**
     * removes a complete scope from the config
     * @param id id of the scope line
     */
    const deleteScopeFull = (id: number) => {
        let tenants: TenantLine[] = [...tenantSystem];
        tenants = tenants.filter((items) => items.id !== id)
        setTenantSystem(tenants);
    }

    /**
     * returns all possible auth levels
     */
    const authLevelsOptions = () => {
        return authLevels.map((item, nkey) => {
            if(HubPathRouting.currentProject && item === "neutral") {
                return
            }
            return <option value={item} key={nkey}>{item}</option>
        })
    }

    /**
     * returns all possible scopes
     */
    const scopeOptions = (id: number) => {
        // First, get the list of possible scopes as an array of option elements
        const scopeOptions = returnListOfPossibleScopes("", id).map((item, key) => {
            return <option value={item.id} key={key}>{item.displayName}</option>;
        });

        // Next, create a placeholder option element
        const placeholderOption = <option value="" key="placeholder" >---</option>;

        // Finally, add the placeholder to the beginning of the options array
        return [placeholderOption, ...scopeOptions];
    }

    /**
     * Edit the value of a User scope
     * @param id id of the scope line
     * @param value value
     */
    const EditScopeValue = (id: number, value: string) => {
        let tenantLines: TenantLine[] = [...tenantSystem]
        tenantLines.find(x => x.id === id).value = value
        setTenantSystem(tenantLines);
    }

    return <>
        {tenantSystem.map((item: TenantLine, index) => {
            // skip global
            if (item.scopes.length === 1 && item.scopes[0] === "user" && item.fullscope === "user") {
                return null
            }

            let placeholder = "Scope"
            if(item.scopes.length === 1) {
                placeholder = "Project"
            } else if(item.scopes.length === 2) {
                placeholder = "Module"
            }

            return <InputGroup key={index} className={styles.scopeLine}>
                <Button disabled className={`btn btn-outline-secondary ${styles.scopeRoot}`}>User</Button>
                {item.scopes.map((scope, index2) => {
                    // skip global level
                    if (scope === "user" && index2 === 0) {
                        return null
                    }
                    // if called from within project, project scope cannot be deleted
                    if (scope === projectId && index2 === 1) {
                        return <Button key={index2} variant="outline-secondary"
                                       className="btn btn-outline-secondary region">
                            {replaceGuidWithDisplayName(item.scopes, 1, global)}
                        </Button>
                    }
                    // only the last element in item.scopes shall have the delete button
                    if (index2 === item.scopes.length - 1) {
                        return (
                            <Button key={index2} variant="outline-secondary" className="btn btn-outline-secondary region"
                                    id={"delete" + item.id}
                                    onClick={() => deleteScopePart(item.id, scope)}>
                                {replaceGuidWithDisplayName(item.scopes, index2, global)} {IconTrash}
                            </Button>
                        );
                    } else {
                        return (
                            <Button key={index2} variant="outline-secondary" className="btn btn-outline-secondary region">
                                {replaceGuidWithDisplayName(item.scopes, index2, global)}
                            </Button>
                        );
                    }
                })}

                {
                    useSelectOptions ?
                        <Form.Select className={` ${styles.fixHeight}`}
                                     onChange={(event: { target: { value: any; }; }) => {
                                         EditScopeName(item.id, event.target.value)
                                     }}
                                     id={"scopeProjectValue" + item.id}
                        >
                            {scopeOptions(item.id)}
                        </Form.Select>
                        :
                        <>
                        <FormControl className={styles.fixHeight}
                                     placeholder={placeholder}
                                     value={item.tmp}
                                     onChange={(event) => {
                                         EditScopeName(item.id, event.target.value);
                                            setPossibleScopes(returnListOfPossibleScopes(event.target.value, item.id));
                                     }}
                                     onKeyPress={event => {
                                         if (event.key === 'Enter') {
                                             addScopePart(item.id, item.tmp)
                                         }
                                     }}
                                     list={"id" + item.id}
                                     id={"scope" + item.id}
                        />
                                { // possible projects/modules/scopes
                                    possibleScopes.length > 0 &&
                                    <datalist id={"id" + item.id}>
                                        {possibleScopes.map((scope, index) => {
                                            return <option key={index} value={scope.id}>
                                                {scope.displayName}
                                            </option>
                                        })}
                                    </datalist>
                                }
                        </>
                }


                {item.tmp === "" ?
                    <Button variant="outline-secondary" className="btn btn-outline-secondary">Add</Button> :
                    <Button variant="outline-primary" className={`btn btn-outline-primary ${styles.warningButton}`}
                            id={"add" + item.id}
                            onClick={() => addScopePart(item.id, item.tmp)}>Add</Button>
                }

                <Form.Select className={`${styles.tenantValue} ${styles.fixHeight}`}
                             onChange={(event: { target: { value: any; }; }) => {
                                 EditScopeValue(item.id, event.target.value)
                             }}
                             id={"scopeValue" + item.id}
                             value={tenantSystem.find(x => x.id === item.id).value}
                >
                    {authLevelsOptions()}
                </Form.Select>
                <Button variant="outline-secondary" className="btn btn-outline-secondary region"
                        onClick={() => deleteScopeFull(item.id)}>{IconTrash}</Button>
            </InputGroup>
        })}
    </>;

}

export default UserTenantManagement;
