import {fetchFromApi, getBackendApiURL} from "../../BackendFacade";
import {GlobalDictionary} from "../globalDictionary";

export const PROFILEIMAGE = "profileImage";

export async function GetProfileImages(emails: string[]) {
    if (emails.length === 0) return false;
    // regex check for email structure
    const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;

    // only take valid emails
    const validEmails = emails.filter(email => emailRegex.test(email))
        .map(email => encodeURIComponent(email));

    // check if the valid emails are already in the dictionary, if so return
    const missingEmails = validEmails.filter(email => !GlobalDictionary.get(PROFILEIMAGE + email.replace("%40", "@")));
    if(missingEmails.length === 0) return;

    const url = getBackendApiURL() + `static/profilePictures?mailList=${missingEmails.join(",")}`;

    await fetchFromApi(url, "GET").then((response) => {
        Object.keys(response.data.value).forEach((key) => {
            GlobalDictionary.set(PROFILEIMAGE + key, response.data.value[key]);
        })
    })
}
