

export function stringContainsJson(value: string){
    try {
        JSON.parse(value)
        return !(value === "true" || value === "false");
    }
    catch(e) {
        return false
    }
}

/**
 * check if interface contains key
 * @param obj interface
 * @param key key to check
 * @returns
 */
export function hasKey(obj: any, key: string) {
    return Object.keys(obj).indexOf(key) !== -1;
}


/**
 * Check if the string is a valid email address.
 */
export function isValidEmail(email: string) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}