import * as React from "react";
import {createRoot} from "react-dom/client";
import {MainProvider} from "./SessionControls/mainProvider";

export function say(any: any) {
    console.log(any);
}

// React 18
const container = document.getElementById("root");
const root = createRoot(container)
export function showRootComponent(component: React.ReactElement<any>) {
    root.render(
        <MainProvider>
            {component}
        </MainProvider>
    )
}
