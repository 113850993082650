import { useState } from 'react';
import styles from './ApiKeyField.module.css';
import {Button, FormControl} from "react-bootstrap";
import * as React from 'react';

interface KeyFieldProps {
    keyString: string;
}

const ApiKeyField: React.FC<KeyFieldProps> = ({ keyString }) => {
    const [hidden, setHidden] = useState(true);
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(keyString);
        setCopied(true);
    };

    const handleReveal = () => {
        setHidden(!hidden);
    };

    return (
        <div className={styles.inputContainer}>
            <FormControl
                type={hidden ? "password" : "text"}
                value={keyString}
                readOnly
                className={styles.keyInput}
            />
            <Button onClick={handleCopy} className={`uib-button uib-button--primary ${styles.spaceLeft} `}>
                {copied ? 'Copied' : 'Copy'}
            </Button>
            <Button onClick={handleReveal} className={`uib-button uib-button--primary ${styles.spaceLeft}`}>
                {hidden ? 'Reveal' : 'Hide'}
            </Button>
        </div>
    );
};

export default ApiKeyField;
