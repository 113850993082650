import * as React from "react";
import {ReactElement} from "react";
import {
    Button,
    Form,
    FormControl,
    Modal,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";
import {IUserData, IUserManagement, UsersDic} from "../Interfaces/IUserManagement";
import {Popup, ScopeOverview, SearchBy} from "../../IDMS/Interfaces/interfaces";
import {filterState, UsersDic as UsersDicIDMS} from "../../IDMS/Interfaces/IUsers";
import {GetAccessRoles, listProjects, UpdateAccessRoles} from "../BackendFacade/apiCalls";
import {isValidEmail} from "../../../Utils/checker";
import {ScopeAny, ValueAny} from "../../IDMS/constants";
import {convertIntToDate, deepCopy} from "../../../Utils/transformer";
import Select from "react-select";
import {IconDelete, IconEdit} from "../../../Assets/svgs";
import makeAnimated from "react-select/animated";
import Hub_AddEditModal from "./Modals/Usermngmt_AddEdit";
import {IHubAddEditModal, IUsersEditMode} from "../Interfaces/IHubAddEditModal";
import styles from './../Hub.module.css'
import {HubPathRouting} from "../../../HubFramework/pathBuilder";
import {GlobalDictionary} from "../../../Utils/globalDictionary";
import {isUserroleSufficient} from "../../../Utils/authorization";
import {UserRoles} from "../../../Utils";
import {
    convertAuthlevelToTenantsystem,
    GlobalTenantSystemContent, loadAllTenantScope, TenantSystemContent,
} from "../../IDMS/Utils/tenantSystem";
import {AuthLevels} from "../constants";
import {useMain} from "../../../Utils/SessionControls/mainContext";

const animatedComponents = makeAnimated()

const AdminUserManagement: React.FC = () => {
    const [Sort_Key, setSortKey] = React.useState("mail");
    const [Sort_Reverse, setSortReverse] = React.useState(false);
    const [filterBy, setFilterBy] = React.useState(SearchBy.Include);
    const [filterState, setFilterState] = React.useState({} as filterState);
    const [filterState_oldTen, setFilterStateOldTen] = React.useState([]);
    const [filterValueState, setFilterValueState] = React.useState({} as filterState);
    const [listOfScopes, setListOfScopes] = React.useState([]);
    const [IDFilter, setIDFilter] = React.useState("");
    const [Selected_Key, setSelectedKey] = React.useState("");
    const [ShowIDFilter, setShowIDFilter] = React.useState(false);
    const [listOfSubscopes, setListOfSubscopes] = React.useState([]);
    const [tenantSystem, setTenantSystem] = React.useState([]);
    const [userData, setUserData] = React.useState({} as UsersDic);
    const [popup, setPopup] = React.useState(Popup.None);
    const [AddEditModal, setAddEditModal] = React.useState(undefined);

    const currentProject = HubPathRouting.currentProject?.moduleInstanceId || "null";

    const {popupCreate, setLoading, popupCreateSingle} = useMain();

    React.useEffect(() => {
        getUserData()
    }, [])

    /**
     * Get the config data from the backend
     */
    const getUserData = async (force = false) => {
        if(!GlobalDictionary.get("users" + currentProject) || force) {
            setLoading(true);
            GetAccessRoles(currentProject).then((data) => {
                if (data.success) {
                    GlobalDictionary.set("users" + currentProject, data.data.value)
                    prepareUserData()
                } else {
                    popupCreate("Error", "Error getting user data " + data.message)
                }
                setLoading(false)
            })
        } else {
            prepareUserData()
        }
    }

    const prepareUserData = () => {
        setUserData({})
        setPopup(Popup.None)
        resetFilter()

        const data = GlobalDictionary.get("users" + currentProject)
        let userData: UsersDic = {};
        (data as IUserData[]).forEach((user) => {
            userData[user.userId] = user
            userData[user.userId].tenantSystem = convertAuthlevelToTenantsystem(user.tenantData, currentProject)
        })

        let scopesOverview = createListOfSubScopes(userData as UsersDic)
        setUserData(userData)
        setListOfSubscopes(scopesOverview.subscopelist)
        setListOfScopes(scopesOverview.scopelist)
    }

    /**
     * Creates a list of all subscopes
     * @param userData
     */
    const createListOfSubScopes = (userData: UsersDic): ScopeOverview => {
        let scopeList : string[] = []
        let subScopes : string[] = []
        let userKeys = Object.keys(userData)
        // read out every scope of all users the current user has access to
        userKeys.forEach((key) => {
            let tenantLines = userData[key].tenantSystem
            tenantLines.forEach((tenant) => {
                tenant.scopes.forEach((subscope) => {
                    if(subScopes.indexOf(subscope) === -1)
                        subScopes.push(subscope)
                })
                if(scopeList.indexOf(tenant.scopes.join('/')) === -1)
                    scopeList.push(tenant.scopes.join('/'))
            })
        })
        return {scopelist: scopeList, subscopelist: subScopes}
    }

    /**
     * Displays the details of the tenant config
     * @param key
     * @constructor
     */
    const Details_show = async (key: string) => {
        let promises = []
        setLoading(true)

        promises.push(loadAllTenantScope(true, popupCreate))

        await Promise.all(promises)
        setLoading(false)

        const header = `Details for ${key} ${isValidEmail(key) ?
            "(no key available)" : "(" + userData[key].mail + ")"}`

        const tenantSystem = userData[key].tenantSystem
        let globalScope = <></>
        if(currentProject === "null") {
            let globalId = tenantSystem.find(x => x.scopes.length === 1 && x.scopes.every(
                (value, index) => value === ["user"][index]
            )).id
            globalScope = <GlobalTenantSystemContent tenantSystem={tenantSystem} globalId={globalId} />
        }

        const content = <>
            {globalScope}
            {/* Individual scopes */}
            <TenantSystemContent tenantSystem={tenantSystem} global={currentProject === "null"} />
        </>

        popupCreateSingle(header, content)
    }

    /**
     * prepare user add/edit popup config
     * @param key key of the user, empty for a new user
     */
    const EditEntry_show = (key: string = "") => {
        let AddEdit: IHubAddEditModal = {
            editMode: undefined,
            userData: userData as any as UsersDicIDMS,
            tenantSystem: undefined,
            emailList: [],
            Selected_Key: key,
            Selected_Value: undefined,
            listOfSubscopes: listOfSubscopes,
            OldSelected_key: key,
        }
        // new Configline or edited to empty category
        if(userData[key] === undefined) {
            AddEdit.editMode = IUsersEditMode.Add
            AddEdit.Selected_Value = {authLevel: "", lastLogin: 0, mail: ""}
            AddEdit.tenantSystem = [{id: 0, scopes: ["user"], value: "", tmp: "", fullscope: "user"}]
        } else {
            // convert config entry to new tenant system
            //let scopes = convertAuthlevelToTenantsystem(userData[key].authLevel)
            // if the user is new and hasn't logged in so far, edit his pre-profile
            if(isValidEmail(key)) {
                // eslint-disable-next-line react/no-direct-mutation-state -- valid, state is set in next line
                userData[key].mail = key
            }

            AddEdit.editMode = IUsersEditMode.Edit
            AddEdit.Selected_Value = deepCopy(userData[key])
            AddEdit.tenantSystem = userData[key].tenantSystem
        }

        // setState({Popup: Popup.Users_AddEdit, Selected_Key: key, AddEditModal: AddEdit})
        setPopup(Popup.Users_AddEdit)
        setSelectedKey(key)
        setAddEditModal(AddEdit)
    }


    /**
     * Activate popup for delete config entry confirmation
     * @param key key of the config entry
     */
    const Delete_show = (key: string) => {
        popupCreate("Delete User", `Do you really want to delete ${key}?`,
            Delete_apply.bind(this, key))
    }

    /**
     * Confirm user entry deletion
     */
    const Delete_apply = (key: string) => {
        let user = userData[key]
        user.tenantSystem = undefined
        user.tenantData = {}

        setLoading(true)
        UpdateAccessRoles(user, currentProject).then(async (data) => {
            if(!data.success) {
                popupCreate("Error", data.message)
            } else {
                await getUserData(true)
            }
            setLoading(false)
        })
    }

    /**
     * Filter the users list by the filter
     * @param list
     * @param listOfKeys
     */
    const applyFilter = (list: UsersDic, listOfKeys: string[]) => {
        // include filter
        if(filterBy === SearchBy.Include) {
            if(filterState.value === ScopeAny) {
                // filter by value
                if(filterValueState.value !== ValueAny) {
                    listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.value === filterValueState.value) !== undefined )
                }
            } else {
                if(filterValueState.value === ValueAny) {
                    listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.fullscope === filterState.value) !== undefined )
                }  else {
                    listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.fullscope === filterState.value && y.value === filterValueState.value) !== undefined )
                }
            }
        } else {
            // exclude filter
            if(filterValueState.value === ScopeAny) {
                // filter by value
                if(filterValueState.value !== ValueAny) {
                    listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.value === filterValueState.value) === undefined)
                }
            } else {
                if (filterValueState.value === ValueAny) {
                    listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.fullscope === filterState.value) === undefined)
                } else {
                    listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.fullscope === filterState.value && y.value === filterValueState.value) === undefined)
                }
            }
        }

        // search by ID or email filter
        if(ShowIDFilter && IDFilter !== "") {
            listOfKeys = listOfKeys.filter(x => {
                return list[x].key.indexOf(IDFilter) !== -1 ||
                    (list[x].mail !== undefined && list[x].mail.indexOf(IDFilter) !== -1)
            })
        }
        return listOfKeys
    }

    /**
     * sort the users list by clicking on the column header
     * @param list
     * @param key
     * @param reverse
     */
    const sortListByKey = (list: UsersDic, key: string, reverse: boolean = false) : string[] => {
        if(Object.keys(list).length === 0) {
            return []
        }
        let listofKeys = Object.keys(list);

        // apply filters
        listofKeys = applyFilter(list, listofKeys)
        // if first item of list is a number
        switch(key) {
            case "lastLogin":
                listofKeys.sort((a: any, b: any) => {
                    // if lastlogin is undefined, sort it to the end
                    if(list[a].lastLogin === undefined) {
                        return 1
                    }
                    if(list[b].lastLogin === undefined) {
                        return -1
                    }
                    if(list[a].lastLogin < list[b].lastLogin) {
                        return 1;
                    }
                    if(list[a].lastLogin > list[b].lastLogin ) {
                        return -1;
                    }
                    return 0;
                })
                break;
            case "mail":
                listofKeys.sort((a: any, b: any) => {
                    // if key is undefined, sort by userMail instead
                    let aMail = list[a][key] === undefined ? list[a].mail : list[a][key];
                    let bMail = list[b][key] === undefined ? list[b].mail : list[b][key];
                    if(aMail < bMail) {
                        return -1;
                    }
                    if(aMail > bMail) {
                        return 1;
                    }
                    return 0;
                })
                break;
            default:
                listofKeys.sort((a: any, b: any) => {
                    // sort by key LastLogin if they are equal
                    if(list[a].lastLogin === undefined) {
                        return 1
                    }
                    if(list[b].lastLogin === undefined) {
                        return -1
                    }
                    if(list[a].lastLogin < list[b].lastLogin) {
                        return 1;
                    }
                    if(list[a].lastLogin > list[b].lastLogin ) {
                        return -1;
                    }
                    return 0;
                })
        }
        if(reverse) {
            listofKeys.reverse();
        }
        return listofKeys

    }

    const setSortByKey = (key: string) => {
        // setState({Sort_Reverse: !Sort_Reverse, Sort_Key: key})
        setSortReverse(!Sort_Reverse)
        setSortKey(key)
    }

    const sort = () => {
        return sortListByKey(userData, Sort_Key, Sort_Reverse)
    }

    const showSortIcon = (key: string) => {
        if(key === Sort_Key) {
            return Sort_Reverse ? <i className="uib-icon--arrow1-down uib-icon"></i> : <i className="uib-icon--arrow1-up uib-icon"></i>
        }
    }

    const resetFilter = () => {
        // setState({filterState_oldTen: [], filterState: {value: "any", label: "any"}, filterValueState: {value: "any", label: "any"}})
        setFilterStateOldTen([])
        setFilterState({value: ScopeAny, label: ScopeAny})
        setFilterValueState({value: ValueAny, label: ValueAny})
    }

    /**
     * Displays the filter above the users list
     * @constructor
     */
    const filter = () :ReactElement => {
        let buttons = <div className={`col col-auto ${styles.RButtons}`}>
            <label  className="col col-form-label"></label>
            <div className="col-auto ">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 150, hide: 200 }}
                    overlay={
                        <Tooltip id="tooltip-disabled">
                            Reset filters
                        </Tooltip>
                    }
                >
                    <Button className={`uib-button uib-button--primary uib-button--square ${styles.ButtonLogo} ${styles.spaceRightBigger}`}
                            onClick={() => resetFilter()}
                            id={"resetButton"}>
                        <i className={`uib-icon uib-icon--settings-leave ${styles.logo}`}></i>
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 150, hide: 200 }}
                    overlay={
                        <Tooltip id="tooltip-disabled">
                            Show (activate) /hide (deactivate) the filter by key
                        </Tooltip>
                    }
                >
                    <Button className={`uib-button uib-button--primary uib-button--square ${styles.ButtonLogo}`}
                            onClick={() => setShowIDFilter(!ShowIDFilter)}
                            id={"filterByKeyButton"}>
                        <i className={`uib-icon uib-icon--search ${styles.logo}`}></i>
                    </Button>
                </OverlayTrigger>
            </div>
        </div>
        let filterByKey = <div></div>
        if(ShowIDFilter) {
            filterByKey = <div className="col col-md-4">
                <label  className="col col-form-label">Filter by key (hidden field) and email</label>
                <div className="col">
                    <FormControl id="plainvalue" placeholder="Value" onChange={(event: { target: { value: any; }; }) => {setIDFilter(event.target.value)}}  value={IDFilter} />
                </div>
            </div>
        }

        // add any to option lists
        let scopes = listOfScopes.map(x => {return {value: x, label: x}})
        scopes.push({value: ScopeAny, label: ScopeAny})
        let authLevels = AuthLevels.map(x => {return {value: x, label: x}})
        authLevels.push({value: ValueAny, label: ValueAny})

        // if tenant management is enabled
        return <><div className={`form-group row ${styles.filterRow}`}>
            <div className="col col-md-auto">
                <label  className="col col-form-label">Search for</label>
                <div className="col">
                    <Button className={filterBy === SearchBy.Exclude ? `uib-button uib-button--secondary ${styles.details} ${styles.spaceRight}` : `uib-button uib-button--primary ${styles.details} ${styles.spaceRight}`}
                            onClick={() => setFilterBy(SearchBy.Include)}
                            id={"includeButton"}
                    >Include</Button>
                    <Button className={filterBy === SearchBy.Exclude ? `uib-button uib-button--primary ${styles.details} ` : `uib-button uib-button--secondary ${styles.details} ` }
                            onClick={() => setFilterBy(SearchBy.Exclude)}
                            id={"excludeButton"}
                    >Exclude</Button>
                </div>
            </div>
            <div className="col col-md-4">
                <label className="col col-form-label">Filter by Scopes</label>
                <div className="col">
                    <Select closeMenuOnSelect={true}
                            components={animatedComponents}
                            placeholder="Select scopes"
                            options={scopes}
                            onChange={(option : filterState) => {setFilterState(option)}}
                            value={filterState}
                            id={"filterByScopes"}
                            className={styles.selectFields}
                    ></Select>
                </div>
            </div>
            <div className="col col-md-2">
                <label className="col col-form-label">Value</label>
                <div className="col">
                    <Select closeMenuOnSelect={true}
                            components={animatedComponents}
                            placeholder="Value of scope"
                            options={authLevels}
                            onChange={(option : filterState) => {setFilterValueState(option)}}
                            value={filterValueState}
                            id={"filterByScopesValue"}
                            className={styles.selectFields}
                    ></Select>
                </div>
            </div>
            {buttons}
            {filterByKey}
        </div>
        </>
    }

    const checkConvertIntToDate = (value: number) => {
        let val = convertIntToDate(value)
        if(val === null) {
            return "Never logged in yet."
        } else {
            return val;
        }
    }

    const closePopup = () => {
        // setState({Popup: Popup.None})
        setPopup(Popup.None)
    }

        const Sorted = sort()
        let popupContent = <div></div>

        switch(popup) {
            case Popup.Users_AddEdit:
                popupContent = <Hub_AddEditModal onSuccessFunction={getUserData.bind(this, true)}
                                             closeFunction={closePopup.bind(this)}
                                             state={AddEditModal}
                                             projectId={currentProject}
                />
                break;
            default:
                break;
        }
        return <>
            <Modal size="lg" show={popup !== Popup.None} onHide={() => setPopup(Popup.None)} >
                {popupContent}
            </Modal>

            {filter()}
            <div className={styles.ScrollPageUsers}>
                <Form>
                    <Table striped className={""} id={"tableOfUsers"}>
                        <thead className={`uib-table__header ${styles.tablehead}`}>
                        <tr className={`uib-table__row--header ${styles.tableHeadRow}`}>
                            <th className={styles.hoverPointer} onClick={() => setSortByKey("mail")}>Email{showSortIcon("userMail")}</th>
                            <th className={`${styles.alignCenter} ${styles.hoverPointer}`} onClick={() => setSortByKey("authLevel")}>Value{showSortIcon("AuthLevel")}</th>
                            <th className={styles.hoverPointer} onClick={() => setSortByKey("lastLogin")}>Time of last login{showSortIcon("LastLogin")}</th>
                            {isUserroleSufficient(UserRoles.admin, HubPathRouting.currentProject?.moduleInstanceId) &&
                            <>
                                <th className={styles.alignCenter}>Edit</th>
                                <th className={styles.alignCenter}>Delete</th>
                            </>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {Sorted.map((col, index) => {
                                return <tr key={index}>
                                    <td>{userData[col].mail === null ? userData[col].userId : userData[col].mail}</td>
                                    <td className={styles.alignCenter}>
                                        <Button variant="outline-primary"
                                                className={styles.details}
                                                id={"details"}
                                                onClick={() => Details_show(col)}>
                                            Details
                                        </Button>
                                    </td>
                                    <td>{checkConvertIntToDate(userData[col].lastLogin)}</td>
                                    {isUserroleSufficient(UserRoles.admin, HubPathRouting.currentProject?.moduleInstanceId) &&
                                        <>
                                        <td className={styles.alignCenter}>

                                            <Button variant="outline-primary"
                                                    className={styles.ButtonLogo}
                                                    id={"edit-" + index}
                                                onClick={() => {
                                                    EditEntry_show(col)
                                                }}>
                                                {IconEdit}
                                            </Button>

                                        </td>
                                        <td className={styles.alignCenter}>
                                                <Button variant="outline-danger"
                                                        className={styles.ButtonLogo}
                                                        id={"delete-" + index}
                                                     onClick={() => {
                                                         Delete_show(col)
                                                     }}>
                                                {IconDelete}
                                            </Button>
                                        </td>
                                    </>
                                    }
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                </Form>
            </div>
            <div className={`container ${styles.FooterRoot}`}>
                <div className="row">
                    {isUserroleSufficient(UserRoles.admin, HubPathRouting.currentProject?.moduleInstanceId) &&
                        <div className="col-auto">
                            <Button className="uib-button uib-button--primary"
                                    id={"add"}
                                    onClick={() => EditEntry_show()}>
                                + Add
                            </Button>
                        </div>}
                    <div className={`col-sm ${styles.FooterUser}`} id={"noOfUsers"}>
                        Users: {Sorted.length}
                    </div>
                </div>
            </div>
        </>

}

export default AdminUserManagement;