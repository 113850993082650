import {IContent} from "../../HubFramework"
import DOOPDocumentation from "./Tabs/DOOPDocumentation";
import Logs from "./Tabs/Logs";
import Overview from "./Tabs/Overview";
import Configuration from "./Tabs/Configuration";
import EditUsers from "./Tabs/EditUsers";

/**
 * The content of the module
 * This is mandatory for every module. It contains all the information about the module.
 */
export const moduleContent: IContent = {
    moduleName: "doopProd",
    title: "DOOP",
    pages: [
        {
            displayName: "Orders",
            contentPage: Overview
        },
        {
            displayName: "Configuration",
            contentPage: Configuration
        },
        {
            displayName: "User Management",
            contentPage: EditUsers
        },
        // {
        //     displayName: "Logs",
        //     contentPage: Logs
        // },
        {
            displayName: "Documentation",
            contentPage: DOOPDocumentation
        }
    ],
    creationProps: [
        {
            displayName: "Order Number",
            name: "DefaultOrderNumber",
            type: "string",
            description: "The default order number",
            value: "",
        },
        {
            displayName: "Default Price Model",
            name: "DefaultPriceModel",
            type: "string",
            description: "The default price model",
            value: "",
        },
    ],
    defaultPage: "Orders",
};
